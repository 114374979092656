import { useContext, useMemo } from "react";

import ThemePattern1Image from "@/assets/images/theme-patterns/pattern-1.svg";
import ThemePattern2Image from "@/assets/images/theme-patterns/pattern-2.svg";
import ThemePatternNoneImage from "@/assets/images/theme-patterns/pattern-none.svg";
import { PATTERNS } from "@/constants/common";
import { ThemePatterns } from "@/hooks/useTheme";
import { Context, ContextType } from "@/store/Context-store";

type PatternType = {
    id: ThemePatterns;
    title: string;
    image: any;
};

const Patterns = () => {
    const { setIsSettingsChanged } = useContext(Context) as ContextType;
    const { pattern, setPattern } = useContext(Context) as ContextType;

    const patterns: PatternType[] = [
        {
            id: PATTERNS.PATTERN_NONE,
            image: ThemePatternNoneImage,
            title: "بدون پس زمینه",
        },
        {
            id: PATTERNS.PATTERN_1,
            image: ThemePattern1Image,
            title: "پس زمینه اول‌پرداخت 1",
        },
        {
            id: PATTERNS.PATTERN_2,
            image: ThemePattern2Image,
            title: "پس زمینه اول‌پرداخت 2",
        },
    ];

    const renderPatterns = useMemo(() => {
        return patterns.map(({ id, image: TemplateImage, title }) => (
            <label
                key={id}
                htmlFor={id}
                className={`bg-secondary-40 group cursor-pointer overflow-hidden rounded-[10px] border-2 transition-colors ${
                    pattern === id ? "border-primary" : "border-secondary-100/50 hover:border-primary/50"
                }`}
            >
                <TemplateImage className="w-full opacity-40 dark:opacity-30" />
                <div className="flex items-center justify-between border-t-2 border-secondary-100/50 p-4">
                    <span className="text-text-main">{title}</span>
                    <input
                        onChange={(e) => {
                            setPattern(e.target.value as ThemePatterns);
                            setIsSettingsChanged(true);
                        }}
                        value={id}
                        type="radio"
                        name="pattern"
                        className="cursor-pointer"
                        checked={id === pattern}
                        id={id}
                    />
                </div>
            </label>
        ));
    }, [pattern]);

    return <div className="mt-6 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">{renderPatterns}</div>;
};

export default Patterns;
