import { useEffect, useRef } from "react";

/*
* If condition is true, then call cb function once (only one time)
*/
const useAfter = (cb: () => void, condition: boolean) => {
    const isCalled = useRef(false);

    useEffect(() => {
        if (!isCalled.current && condition) {
            isCalled.current = true;

            cb();
        }
    }, [condition]);
};

export default useAfter;