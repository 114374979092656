import Color from "color";
import { useEffect, useState } from "react";

import useTheme from "@/hooks/useTheme";

type CreditCardBoxesProps = {
    rgb: string | undefined;
};

const CreditCardBoxes: React.FC<CreditCardBoxesProps> = ({ rgb }) => {
    const { isThemeDark } = useTheme();

    const [bgColor, setBgColor] = useState("");
    const [colors, setColors] = useState<string[]>([]);

    useEffect(() => {
        const colorsArray: string[] = [];
        const color = new Color(rgb);

        if (isThemeDark) {
            colorsArray[0] = color.hsl().toString();
            colorsArray[1] = color.darken(0.2).toString();
            colorsArray[2] = color.darken(0.35).toString();
            setBgColor(color.alpha(0.05).toString());
        } else {
            colorsArray[0] = color.hsl().toString();
            colorsArray[1] = color.lighten(0.2).toString();
            colorsArray[2] = color.lighten(0.35).toString();
            setBgColor(color.mix(new Color("#ffffff"), 0.95).toString());
        }

        setColors(colorsArray);
    }, [rgb, isThemeDark]);

    return (
        <div className="absolute left-0 h-full w-full bg-secondary-400">
            <div className="w-full h-full" style={{ backgroundColor: bgColor }}>
                <div
                    className="absolute left-0 top-[-50px] h-[500px] w-[100px] rotate-[10deg] transition-colors duration-300"
                    style={{backgroundColor: colors[2]}}
                />
                <div
                    className="absolute left-[18px] top-[-20px] h-[500px] w-[100px] rotate-[-15deg] transition-colors duration-300"
                    style={{backgroundColor: colors[1]}}
                />
                <div
                    className="absolute left-[10px] h-[500px] w-[120px] rotate-[-38deg] transition-colors duration-300"
                    style={{backgroundColor: colors[0]}}
                />
            </div>
        </div>
    );
};
export default CreditCardBoxes;
