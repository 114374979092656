import { useContext, useState } from "react";
import toast from "react-hot-toast";

import FontSettingBox from "@/components/profile/appereance/text-settings/FontSettingBox";
import LinkSettingBox from "@/components/profile/appereance/text-settings/LinkSettingBox";
import Patterns from "@/components/profile/appereance/theme-settings/Patterns";
import AnimationSetting from "@/components/profile/appereance/theme-settings/SlidersSettings/AnimationSetting";
import GrayscaleSetting from "@/components/profile/appereance/theme-settings/SlidersSettings/GrayscaleSetting";
import HeaderSetting from "@/components/profile/appereance/theme-settings/SlidersSettings/HeaderSetting";
import HueRotationSetting from "@/components/profile/appereance/theme-settings/SlidersSettings/HueRotationSetting";
import SystemThemeSettings from "@/components/profile/appereance/theme-settings/SlidersSettings/SystemThemeSettings";
import Themes from "@/components/profile/appereance/theme-settings/Themes";
import IconFloppyDisk from "@/components/svg/floppy-disk";
import IconHouse from "@/components/svg/house";
import BoxContainer from "@/components/ui/BoxContainer";
import Breadcrumb from "@/components/ui/Breadcrumb";
import Button from "@/components/ui/Button";
import Tabs from "@/components/ui/Tabs";
import Title from "@/components/ui/Title";
import { THEMES } from "@/constants/common";
import useSyncThemeSettings from "@/hooks/queries/useSyncThemeSettings";
import useTheme from "@/hooks/useTheme";
import { Context, ContextType } from "@/store/Context-store";

const AppearancePage = () => {
    const {
        setLinkHighlight,
        setLinkUnderline,
        setFontSizeValue,
        setFontWeightValue,
        setLineHeightValue,
        setWordSpacingValue,
        setGrayScaleValue,
        setHueRotationValue,
        setIsSettingsChanged,
        isSettingsChanged,
    } = useContext(Context) as ContextType;

    const { theme, changeThemeTo } = useTheme();
    const [activeTab, setActiveTab] = useState("theme");
    const { syncThemeFunction, isLoading } = useSyncThemeSettings();

    const resetSettings = (tab: "text" | "theme" | "filters") => {
        if (tab === "text") {
            setLinkHighlight(false);
            setLinkUnderline(false);
            setFontSizeValue("md");
            setFontWeightValue(500);
            setLineHeightValue(1);
            setWordSpacingValue(0);
        } else if (tab === "filters") {
            setHueRotationValue(0);
            setGrayScaleValue(0);
        } else {
            if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
                changeThemeTo(THEMES.DEFAULT_DARK);
            } else {
                changeThemeTo(THEMES.DEFAULT_LIGHT);
            }
        }

        setIsSettingsChanged(true);
    };

    return (
        <div className="relative pb-[250px]">
            <Breadcrumb
                sections={[
                    { link: "/panel", text: "صفحه اصلی", icon: IconHouse },
                    { link: "#", text: "شخصی سازی", active: true },
                ]}
            />

            <div className="mb-10">
                <Tabs
                    active={activeTab}
                    setActive={setActiveTab}
                    tabs={[
                        { id: "theme", text: `پوسته` },
                        { id: "text", text: `ویراست` },
                        // { id: "help", text: `راهنمایی` },
                    ]}
                />
            </div>

            {activeTab === "theme" && (
                <div className="flex w-full flex-col gap-8">
                    <BoxContainer className="flex-1">
                        <div className="-mx-6 flex items-center justify-between border-b border-secondary-200 px-6 pb-3 ">
                            <h2 className="text-[1.14rem] font-semibold text-text-main xs:text-[1.28rem]">
                                انتخاب قالب سایت
                            </h2>
                            <Button
                                onClick={() => {
                                    resetSettings("theme");
                                    setIsSettingsChanged(true);
                                }}
                            >
                                بازگردانی تنظیمات
                            </Button>
                        </div>
                        <Themes />
                    </BoxContainer>

                    {!theme.includes("contrast") && (
                        <BoxContainer className="flex-1">
                            <Title title="انتخاب الگوی پس زمینه" containerClasses="-mx-6 -mt-4" />
                            <Patterns />
                        </BoxContainer>
                    )}

                    <div className="grid w-full grid-cols-1 gap-8 md:grid-cols-2">
                        <BoxContainer className="flex-1">
                            <div className="-mx-6 flex items-center justify-between border-b border-secondary-200 px-6 pb-3 ">
                                <h2 className="text-[1.14rem] font-semibold text-text-main xs:text-[1.28rem]">
                                    تنظیمات رنگ قالب
                                </h2>
                                <Button
                                    onClick={() => {
                                        resetSettings("filters");
                                        setIsSettingsChanged(true);
                                    }}
                                >
                                    بازگردانی تنظیمات
                                </Button>
                            </div>
                            <HueRotationSetting />
                            <GrayscaleSetting />
                        </BoxContainer>
                        <div className="space-y-8">
                            <BoxContainer className="flex-1">
                                <Title title="تنظیمات دیگر" containerClasses="-mx-6 -mt-4" />
                                <AnimationSetting />
                                <HeaderSetting />
                                <SystemThemeSettings />
                            </BoxContainer>
                        </div>
                    </div>
                </div>
            )}

            {activeTab === "text" && (
                <div className="flex w-full flex-col gap-8">
                    <div className="mt-4 grid grid-cols-1 gap-8 lg:grid-cols-2">
                        <div className="space-y-8">
                            <FontSettingBox />
                            <LinkSettingBox />
                        </div>
                        <div className="relative h-full max-h-[2000px]">
                            <BoxContainer className="sticky top-28">
                                <Title title="متن نمونه" containerClasses="-mx-6 -mt-4" />
                                <p className="mt-8 px-2">
                                    با استفاده از سیستم همکاری در فروش اول پرداخت، همه افراد می‌توانند بدون در نظر گرفتن
                                    موقعیت جغرافیایی و تنها از طریق اینترنت کسب درآمد کنند. برای این کار تنها کافیست
                                    لینک ارجاع (Referral Link) خود را در اختیار دیگران قرار دهید تا از طریق این لینک،
                                    سفارش خود در اول پرداخت را ثبت نمایند. به ازای هر ثبت سفارش از طریق لینک ارجاع شما،1
                                    درصد از سود خالص سفارش به شما تعلق می‌گیرد. کسب درآمد از طریق لینک ارجاع نیاز به هیچ
                                    سرمایه‌ اولیه‌ای ندارد و شما می‌توانید در هر ساعتی از شبانه‌روز و بدون انجام دادن
                                    فعالیت خاصی کسب درآمد کنید. تنها کافیست این لینک را در شبکه‌های اجتماعی خود به
                                    اشتراک بگذارید. هیچ محدودیتی در میزان کسب درآمد از این روش وجود ندارد.
                                </p>
                            </BoxContainer>
                        </div>
                    </div>
                    <div>
                        <Button
                            onClick={() => {
                                resetSettings("text");
                                setIsSettingsChanged(true);
                            }}
                        >
                            بازگردانی تنظیمات ویراست
                        </Button>
                    </div>
                </div>
            )}

            <div className="flex w-full justify-end">
                <Button
                    onClick={async () => {
                        const response = await syncThemeFunction({});
                        toast.success(response?.message ?? "تنظیمات ذخیره شد");
                    }}
                    className="fixed bottom-4 z-[100] w-[60px] shadow-md contrast:bg-white contrast:dark:bg-black max-md:left-4 md:bottom-8 md:-translate-x-4"
                    disabled={!isSettingsChanged || isLoading}
                    disabledTooltip="دکمه غیرفعال است"
                    circle
                    icon={IconFloppyDisk}
                    loading={isLoading}
                />
            </div>
        </div>
    );
};

export default AppearancePage;
