import { InputHTMLAttributes } from "react";

interface CustomCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    active: boolean;
    setActive: (active: boolean) => void;
    label?: string;
}

const SwitchBox: React.FC<CustomCheckboxProps> = ({ active, setActive, label, ...rest }) => {
    const onInputChange = (e: any) => {
        setActive(e.target.checked);
        if (rest.onChange) rest.onChange(e);
    };

    return (
        <label className="group flex w-full cursor-pointer items-center justify-between gap-6">
            <span className="select-none text-[1rem] xs:text-[1.2rem] text-text-main">{label}</span>

            <input
                type="checkbox"
                className="peer absolute -z-10 h-0  w-0 overflow-hidden opacity-0"
                {...rest}
                checked={active}
                onChange={onInputChange}
            />
            <div
                className={`
                   relative flex h-[35px] xs:h-[40px] w-[65px] xs:w-[75px] items-center rounded-full border-2 transition-colors
                   ${active ? "border-primary" : "border-secondary-100"}
                   before:absolute before:right-[calc(100%-8px-20px)] xs:before:right-[calc(100%-8px-25px)] before:h-[22px] xs:before:h-[25px] 
                   before:w-[22px] xs:before:w-[25px] before:rounded-full before:bg-secondary-100 
                   before:transition-all before:group-active:scale-90 before:peer-checked:right-2 
                   before:peer-checked:bg-primary peer-focus-visible:border-primary
                `}
            ></div>
        </label>
    );
};

export default SwitchBox;
