import { useQueryClient } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { SubmitHandler, useFormContext, useWatch } from "react-hook-form";
import { useLocation } from "react-router-dom";

import AuthCodeInput from "@/components/auth/AuthCodeInput";
import AlertBox from "@/components/ui/AlertBox";
import Button from "@/components/ui/Button";
import Modal from "@/components/ui/Modal";
import { IMMEDIATE_WITHDRAW_COST } from "@/constants/common";
import { TransactionsResponse } from "@/gate/wallet";
import useWithdraw from "@/hooks/queries/useWithdraw";
import useWithdrawOtp from "@/hooks/queries/useWithdrawOtp";
import useGoogleReCaptchaV3 from "@/hooks/useRecaptcha";
import { formatNumber } from "@/utils";

type WithdrawConfirmProps = {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    walletData: TransactionsResponse | undefined;
};

type WithdrawAmountSchema = {
    bank: { value: string; label: string };
    amount: number;
    isImmediate?: boolean;
};

const WithdrawConfirm: React.FC<WithdrawConfirmProps> = ({ isOpen, setIsOpen, walletData }) => {
    const queryClient = useQueryClient();

    const { control, handleSubmit, reset } = useFormContext<WithdrawAmountSchema>();
    const bank: { value: string; label: string } = useWatch({ control, name: "bank" });
    const amount: number = useWatch({ control, name: "amount" });

    const { pathname, search } = useLocation();

    const isImmediate = useWatch({ control, name: "isImmediate" });

    const {
        mutateAsync: withdraw,
        isLoading: isWithdrawLoading,
        isSuccess: isWithdrawSuccess,
        reset: resetWithdrawQuery,
    } = useWithdraw();
    const { mutateAsync: sendOtp, isLoading: isOtpLoading } = useWithdrawOtp();

    const [isRecaptchaLoading, setIsRecaptchaLoading] = useState(false);
    const { executeReCaptcha } = useGoogleReCaptchaV3({ action: "Withdraw" });

    const [showTfa, setShowTfa] = useState(false);
    const [tfaHash, setTfaHash] = useState("");
    const [tfaCode, setTfaCode] = useState("");

    const bankData = useMemo(() => {
        if (walletData) return walletData?.user.bank_cards.find((item) => item.card_number === bank?.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bank]);

    const getCaptchaToken = async () => {
        let captchaToken: string | undefined = undefined;

        if (walletData?.is_captcha_enable) {
            setIsRecaptchaLoading(true);
            captchaToken = await executeReCaptcha("Withdraw");
            setIsRecaptchaLoading(false);
        }

        return captchaToken;
    };

    const sendOtpValidation = async () => {
        const token = await getCaptchaToken();

        const otpResponse = await sendOtp({
            price: amount,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            card_id: bankData!.id,
            "g-recaptcha-response": token,
        });

        setTfaHash(otpResponse.hash);
        setShowTfa(true);
    };

    const onSubmit: SubmitHandler<WithdrawAmountSchema> = async (value) => {
        const token = await getCaptchaToken();

        if (bankData) {
            await withdraw({
                hash: tfaHash,
                code: tfaHash ? tfaCode : undefined,
                "g-recaptcha-response": token,
                card_id: bankData.id,
                price: value.amount,
                is_immediate: value.isImmediate,
            });

            await queryClient.refetchQueries(["transactions"]);
        }
    };

    const onModalClose = () => {
        if (location.hash === "#modal") {
            window.history.replaceState("", "", pathname + search);
        }

        setIsOpen(false);
        resetWithdrawQuery();
        setShowTfa(false);
        setTfaHash("");
        setTfaCode("");
    };

    const description = (
        <AlertBox
            type="info"
            title="به نکات زیر توجه کنید"
            description={
                <ul className="my-2 space-y-4 pl-2">
                    {isImmediate ? (
                        <>
                            <li className="list-disc">تسویه‌ی آنی فقط در روزهای کاری قابل انجام است.</li>
                            <li className="list-disc">
                                درخواست‌های تسویه‌ی آنی از{" "}
                                <span className="font-extrabold text-text-main">
                                    شنبه تا چهارشنبه از ساعت ۹ صبح تا ۱۶ و روز پنجشنبه از ساعت ۹ صبح تا ۱۳
                                </span>{" "}
                                قابل انجام است.
                            </li>
                            <li className="list-disc">
                                توجه داشته باشید که مبلغ{" "}
                                <span className="font-extrabold text-text-main">
                                    {formatNumber(IMMEDIATE_WITHDRAW_COST)} تومان
                                </span>{" "}
                                بابت تسویه آنی از مبلغ نهایی کسر خواهد شد.
                            </li>
                        </>
                    ) : (
                        <>
                            <li className="list-disc">تسویه در ساعات اداری توسط حواله پایا انجام می‌شود.</li>
                            <li className="list-disc">
                                حواله‌جات پایا طبق اطلاعیه بانک مرکزی ممکن است از 3 ساعت تا 24 ساعت زمان بر باشد.
                            </li>
                            <li className="list-disc">
                                در صورتی که طی 24 ساعت مبلغ را در حساب بانکی‌تان دریافت نکردید، می‌توانید با بانک خود در
                                تماس باشید.
                            </li>
                        </>
                    )}
                    <li className="list-disc">
                        تسویه موجودی حساب در صورتی که مبلغ از طریق جهت شارژ اکانت صورت گرفته باشد، فقط به کارتی که از
                        طریق آن اکانت شارژ شده است برگشت داده می‌شود.
                    </li>
                    <li className="list-disc">
                        در صورتی که تسویه از طریق نقد درآمد ارزی باشد، کارت بانکی که تسویه به آن انجام می‌شود، میبایست
                        در حساب کاربری شما به تایید برسد.
                    </li>
                </ul>
            }
        />
    );

    return (
        <Modal
            show={isOpen}
            onClose={onModalClose}
            onCloseEnd={reset}
            title="تایید برداشت وجه"
            className="max-w-[900px]"
        >
            <div className="flex gap-10">
                <div className="flex-1 max-md:hidden">{description}</div>
                <form className="flex-1">
                    <div className="w-full pb-14 pt-7 text-center text-[3.21rem] text-gold drop-shadow-gold">
                        {formatNumber(amount)}-
                    </div>
                    {bankData && (
                        <div className="space-y-6 pb-8">
                            <div className="flex items-center justify-between">
                                <span>شماره کارت</span>
                                <span className="text-text-paragraph">{bankData.card_number}</span>
                            </div>
                            <div className="flex items-center justify-between">
                                <span>نام حساب</span>
                                <span className="text-text-paragraph">{bankData.name}</span>
                            </div>
                            <div className="flex items-center justify-between">
                                <span>شماره حساب</span>
                                <span className="text-text-paragraph">{bankData.account_number}</span>
                            </div>
                            <div className="flex items-center justify-between">
                                <span>شماره شبا</span>
                                <span className="text-text-paragraph">{bankData.iban}</span>
                            </div>
                        </div>
                    )}

                    {!isWithdrawSuccess && showTfa && (
                        <div className="w-full pb-10 pt-2">
                            <AlertBox
                                title="کد تایید ارسال شد"
                                description="لطفاً کد تایید که برای شما پیامک شده است را وارد نمایید."
                                className="mb-5"
                            />
                            <span className="px-3 text-[0.85rem] text-text-main">کد تایید</span>
                            <AuthCodeInput
                                onChange={(value: string) => {
                                    setTfaCode(value);
                                }}
                                length={4}
                            />
                        </div>
                    )}
                    {isWithdrawSuccess ? (
                        <div className="w-full pb-10 pt-2">
                            <AlertBox
                                title="درخواست شما ثبت شد"
                                description="درخواست برداشت وجه ارسال شد، لطفا منتظر بمانید تا تیم مالی در کوتاه ترین زمان درخواست شما را انجام دهد"
                                type="success"
                            />
                        </div>
                    ) : (
                        <div className="mb-10 flex-1 md:hidden">{description}</div>
                    )}
                    <div className="flex gap-4">
                        {!isWithdrawSuccess &&
                            (showTfa && walletData?.tfa_authenticator === "1" ? (
                                <Button
                                    type="submit"
                                    className="flex-1"
                                    onClick={handleSubmit(onSubmit)}
                                    loading={isRecaptchaLoading || isWithdrawLoading}
                                    disabled={isRecaptchaLoading || isWithdrawLoading || tfaCode.length != 4}
                                    disabledTooltip="دکمه غیرفعال است"
                                >
                                    تایید نهایی
                                </Button>
                            ) : (
                                <Button
                                    type="submit"
                                    className="flex-1"
                                    onClick={
                                        walletData?.tfa_authenticator === "1"
                                            ? sendOtpValidation
                                            : handleSubmit(onSubmit)
                                    }
                                    disabled={isRecaptchaLoading || isOtpLoading}
                                    disabledTooltip="دکمه غیرفعال است"
                                    loading={isRecaptchaLoading || isOtpLoading}
                                >
                                    تایید درخواست
                                </Button>
                            ))}

                        <Button
                            className="flex-1"
                            color={isWithdrawSuccess ? "default" : "error"}
                            variant="stroke"
                            onClick={onModalClose}
                            type="button"
                        >
                            {!isWithdrawSuccess ? "انصراف" : "بازگشت"}
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default WithdrawConfirm;
