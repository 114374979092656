import { FormEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { ServicesListType } from "@/interfaces/dashboard/order/order";

import IconArrowUpRightFromSquare from "../svg/arrow-up-right-from-square";
import IconDollarSign from "../svg/dollar-sign";
import IconSearchModal from "../svg/search-modal";

type OrderFilterType = {
    show: boolean;
    services: ServicesListType;
    setShowSearchModal: (show: boolean) => void;
};

const OrderFilterModal: React.FC<OrderFilterType> = ({ show, services, setShowSearchModal }) => {
    const [filteredServices, setFilteredServices] = useState<Omit<ServicesListType, "cashServices">>({
        services: [],
        categories: [],
    });
    const [filter, setFilter] = useState<string>("");

    useEffect(() => {
        const bodyEl = document.querySelector("body") as HTMLBodyElement;

        if (show) {
            bodyEl.style.overflowY = "hidden";
        } else {
            bodyEl.style.overflowY = "auto";
            setFilter("");
            setFilteredServices({
                services: [],
                categories: [],
            });
        }

        return () => {
            bodyEl.style.overflowY = "auto";
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    const filterHandler = (event: FormEvent<HTMLInputElement>) => {
        setFilter(event.currentTarget.value);
    };

    useEffect(() => {
        if (filter !== "") {
            setFilteredServices(() => {
                const allServices = services.services;

                for (const serviceName in services.cashServices) {
                    const foundedCash = allServices.find((service) => {
                        return (
                            service.slug == "sell-" + serviceName?.toLowerCase() + "_VOUCHER" ||
                            service.slug == "sell-" + serviceName?.toLowerCase()
                        );
                    });

                    if (foundedCash) {
                        continue;
                    }

                    if (serviceName == "PERFECTMONEY_VOUCHER") {
                        allServices.push({
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            title:
                                "فروش " +
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                //@ts-ignore
                                services?.cashServices[serviceName]?.name +
                                " به اول پرداخت",
                            slug: "sell-" + serviceName?.toLowerCase(),
                            type: serviceName,
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            form_file: services.cashServices[serviceName]?.form_file,
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            instruction: services?.cashServices[serviceName]?.instruction,
                            id: -1,
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            currency: services?.cashServices[serviceName]?.currencies,
                        });
                    } else {
                        allServices.push({
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            title: "فروش " + services?.cashServices[serviceName]?.name + " به اول پرداخت",
                            slug: "sell-" + serviceName?.toLowerCase(),
                            type: serviceName + "",
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            form_file: services.cashServices[serviceName]?.form_file,
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            instruction: services?.cashServices[serviceName]?.instruction,
                            id: 0,
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            currency: services?.cashServices[serviceName]?.currencies,
                        });
                    }
                }

                const filteredServices = allServices.filter((service) => {
                    return (
                        service.title.toLowerCase().includes(filter) ||
                        service.slug.toLowerCase().includes(filter) ||
                        service?.currency?.filter((currency) => {
                            return (
                                currency.name.includes(filter) || currency.abbreviation.toLowerCase().includes(filter)
                            );
                        }).length > 0
                    );
                });

                const serviceTypes = [
                    ...new Set(
                        filteredServices.map((service) => {
                            return service.type;
                        }),
                    ),
                ];

                const filteredCategories = services.categories.filter((category) => {
                    return serviceTypes.indexOf(category.slug) !== -1;
                });

                return {
                    categories: filteredCategories,
                    services: filteredServices,
                };
            });
        } else {
            setFilteredServices({
                services: [],
                categories: [],
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    return (
        <div className={`${show ? "fixed" : "hidden"} inset-0 z-[99999] flex h-screen w-full`}>
            <div
                className={`absolute inset-0 h-screen bg-zinc-950/50 backdrop-blur-[4px] cursor-pointer`}
                title="بستن"
                onClick={() => {
                    setShowSearchModal(false);
                }}
            ></div>
            <div
                className={`absolute left-1/2 top-[30px] flex max-h-[calc(100vh-150px)] w-[95%] -translate-x-1/2 flex-col rounded-[10px] bg-secondary-400 p-4 py-8 shadow-xl sm:top-[100px] sm:max-h-[calc(100vh-140px)] sm:w-1/2`}
            >
                <div>
                    <input
                        type="search"
                        dir="auto"
                        autoFocus={true}
                        onChange={filterHandler}
                        className="w-full rounded-[10px] border border-input-border bg-transparent p-4 outline-none placeholder:text-right focus:border-primary"
                        placeholder="سرویس مورد نظر را جستجو کنید"
                    />
                </div>
                <div className="custom-scrollbar mt-4 overflow-auto">
                    {(filter == "" || filteredServices.categories.length == 0) && (
                        <div className="flex flex-col items-center justify-center">
                            <div className="relative isolate flex h-[200px] w-11/12 flex-col items-center justify-center sm:w-1/2">
                                <IconSearchModal className="my-8 h-full w-full rotate-[20deg] opacity-50" />
                                <span className="text-center text-lg text-text-paragraph">
                                    {filter == ""
                                        ? `برای جستجو می‌توانید نام دسته‌بندی، سرویس و یا ارز مورد نظر را در کادر بالا وارد کنید.`
                                        : "سرویسی پیدا نشد!"}
                                </span>
                            </div>
                        </div>
                    )}

                    {filteredServices.categories.map((category) => {
                        return (
                            <div className="mt-8 flex flex-col gap-4 first:mt-0" key={category.id}>
                                <h3 className="font-bold text-primary" key={category.id}>
                                    {category.title}
                                </h3>

                                {filteredServices.services
                                    .filter(
                                        (service) =>
                                            service.type == category.slug || service.type == category.slug + "_VOUCHER",
                                    )
                                    .map((service, index) => {
                                        return (
                                            <Link
                                                to={
                                                    service.id == 0 || service.id == -1
                                                        ? `/panel/cash-income/${service.slug}`
                                                        : `/panel/order/new/${service.slug}`
                                                }
                                                key={index}
                                                className="group flex justify-between rounded-lg border border-input-border p-4 text-text-paragraph transition hover:bg-primary hover:text-secondary-400"
                                            >
                                                {service.title}

                                                {service.id == 0 || service.id == -1 ? (
                                                    <IconDollarSign className="mr-2 h-[16px] fill-primary group-hover:fill-secondary-400 sm:h-[20px] " />
                                                ) : (
                                                    <IconArrowUpRightFromSquare className="mr-2 h-[16px] fill-amber-400 sm:h-[20px]" />
                                                )}
                                            </Link>
                                        );
                                    })}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default OrderFilterModal;
