import { Dispatch, SetStateAction } from "react";
import { Control, Controller } from "react-hook-form";
import toast from "react-hot-toast";

import IconCircleXmarkDuotone from "@/components/svg/circle-xmark-duotone";
import IconFileDuotone from "@/components/svg/file-duotone";
import IconFilePdf from "@/components/svg/file-pdf";
import IconFileZipper from "@/components/svg/file-zipper";
import IconSpinnerThirdDuotone from "@/components/svg/spinner-third-duotone";
import { fileExtension } from "@/utils";
import { compressImage } from "@/utils/image-compressor";
import showQuestion from "@/utils/show-question";

type FileInputButtonProps = {
    id: number;
    inputsIds: number[];
    setInputsIds: Dispatch<SetStateAction<number[]>>;
    disabled?: boolean;
    name: string;
    rules?: any;
    control?: Control<any>;
    className?: string;
    containerClassName?: string;
    onReset: (fieldName: any) => void;
};

const FileInputButton = ({
    id,
    containerClassName,
    name,
    control,
    rules,
    className,
    disabled,
    onReset,
    setInputsIds,
    inputsIds,
}: FileInputButtonProps) => {
    const removeInput = () => {
        setInputsIds((oldVal) => {
            return oldVal.filter((itemId) => itemId !== id);
        });
    };

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => {
                const fileType = fileExtension(field.value?.name);

                const isFileTypeImage =
                    fileType === "png" ||
                    fileType === "jpeg" ||
                    fileType === "jpg" ||
                    fileType === "gif" ||
                    fileType === "svg";

                return (
                    <>
                        {field.value && (
                            <div
                                className={`group relative flex flex-col items-center justify-center gap-4 rounded-[10px] border border-secondary-100 bg-white px-2 pb-2 dark:bg-secondary-200 xs:px-4 ${containerClassName}`}
                            >
                                {inputsIds.length > 1 && (
                                    <button
                                        disabled={disabled}
                                        type="button"
                                        className={disabled ? "grayscale" : undefined}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();

                                            onReset(name);

                                            removeInput();
                                        }}
                                    >
                                        <IconCircleXmarkDuotone className="absolute left-[-12px] top-[-12px] h-[25px] fill-error-300 transition-all hover:scale-110 hover:brightness-[120%] active:scale-[0.85]" />
                                    </button>
                                )}

                                {field.value && (
                                    <div className="flex h-[60px] w-[60px] shrink-0 items-center justify-center rounded-[5px] xs:h-[70px] xs:w-[70px] sm:h-[100px] sm:w-[100px]">
                                        {isFileTypeImage ? (
                                            <img src={URL.createObjectURL(field.value)} className="h-full reset-hue-rotate" alt="alt" />
                                        ) : fileType === "PDF" ? (
                                            <IconFilePdf className="h-[60%] fill-primary" />
                                        ) : fileType === "zip" ? (
                                            <IconFileZipper className="h-[60%] fill-primary" />
                                        ) : (
                                            <IconFileDuotone className="h-[60%] fill-primary" />
                                        )}
                                    </div>
                                )}

                                <div
                                    className={`flex h-full max-w-[60px] items-center justify-center text-text-main xs:max-w-[70px] sm:max-w-[100px] ${className}`}
                                    data-tooltip-id="main-tooltip"
                                    data-tooltip-content={field.value ? field.value.name : ""}
                                    data-tooltip-place="bottom"
                                >
                                    <div className="max-w-full select-none overflow-hidden whitespace-nowrap text-[0.78rem] xs:text-[1rem]">
                                        {field.value ? field.value.name : "انتخاب فایل"}
                                    </div>
                                </div>
                            </div>
                        )}

                        <input
                            onChange={async (e) => {
                                const file = e.target.files![0];

                                const fileType = fileExtension(file.name);

                                const isFileTypeImage =
                                    fileType === "png" ||
                                    fileType === "jpeg" ||
                                    fileType === "jpg" ||
                                    fileType === "gif" ||
                                    fileType === "svg";

                                if (file.size > 2 * 1024 * 1024) {
                                    if (isFileTypeImage) {
                                        const shouldCompress = await showQuestion({
                                            text: "حجم فایل انتخاب شده باید زیر 2 مگابایت باشد، آیا مایلید که حجم فایل تصویر شما توسط هوش مصنوعی کاهش پیدا کند؟",
                                            avatar: URL.createObjectURL(file),
                                            cancelButtonText: "آپلود عکس دیگر",
                                            acceptButtonText: "کاهش بده",
                                            duration: 20000,
                                        });

                                        if (shouldCompress) {
                                            const toastId = toast(
                                                () => (
                                                    <span className="text-text-main" dir="ltr">
                                                        <div className="flex items-center justify-between gap-4">
                                                            <IconSpinnerThirdDuotone className="h-[18px] animate-spin fill-primary" />
                                                            <span>درحال فشرده سازی فایل تصویر</span>
                                                        </div>
                                                        <div className="-mx-6">
                                                            <div className="mt-4 flex items-center justify-between gap-4 border-t border-secondary-100 px-6 pt-4">
                                                                <span className="max-w-[150px] flex-1 overflow-hidden text-ellipsis">
                                                                    {file.name}
                                                                </span>
                                                                <span className="" id="compression-progress">
                                                                    0%
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="mt-4 h-[6px] w-full rounded-full bg-secondary-100">
                                                            <div
                                                                className="h-full rounded-full bg-primary transition-all"
                                                                id="compression-progressbar"
                                                            ></div>
                                                        </div>
                                                    </span>
                                                ),
                                                {
                                                    duration: Infinity,
                                                    className: "!bg-toast",
                                                },
                                            );

                                            setTimeout(async () => {
                                                const progressEl = document.querySelector(
                                                    "#compression-progress",
                                                ) as HTMLDivElement;
                                                const progressBarEl = document.querySelector(
                                                    "#compression-progressbar",
                                                ) as HTMLDivElement;

                                                const compressedFile = await compressImage({
                                                    file,
                                                    progress: (p) => {
                                                        progressEl.innerHTML = `${p}%`;
                                                        progressBarEl.style.width = `${p}%`;
                                                    },
                                                });

                                                toast.dismiss(toastId);

                                                field.onChange(compressedFile);
                                            }, 100);
                                        }
                                    } else {
                                        toast.error("حجم فایل انتخاب شده باید کمتر از 2 مگابایت باشد");
                                    }
                                } else {
                                    field.onChange(e.target.files![0]);
                                }
                            }}
                            id={`file-input-${id}`}
                            className="absolute -z-10 hidden opacity-0"
                            type="file"
                            disabled={disabled}
                        />
                    </>
                );
            }}
        />
    );
};

export default FileInputButton;
