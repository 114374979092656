import { ReactNode } from "react";
import toast from "react-hot-toast";

import BuildError from "./BuildError";

export const ToastError = (err: any) => {
    let error = null;

    if (err?.response && (err.response?.data?.errors || err.response?.data?.message)) {
        error = {
            message: err?.response.data.errors || err?.response.data.message,
        };
    } else if (err?.data && (err.data?.errors || err?.data?.message)) {
        error = {
            message: err?.data.errors || err?.data.message,
        };
    } else if (err?.request && err.request?._response) {
        error = { message: err.request._response };
    } else if (err?.data && err?.data?.message) {
        error = { message: err.data.message };
    } else {
        error = err;
    }

    let message: ReactNode;
    message = error?.message || "";

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const errorData = error?.data ?? {};

    if (errorData?.errors && Array.isArray(errorData.errors) && errorData.errors.length !== 0) {
        message = (
            <div>
                <div className="font-[Vazirmatn]" dangerouslySetInnerHTML={{ __html: errorData.errors[0] }} />
            </div>
        );
    } else if (errorData?.errors && typeof errorData.errors === "object") {
        for (const error in errorData.errors) {
            message = (
                <div>
                    <BuildError errors={[errorData.errors[error]]} />
                </div>
            );
        }
    } else if (errorData?.error && errorData?.message && typeof errorData?.message === "string") {
        message = (
            <div>
                <BuildError errors={[errorData?.message]} />
            </div>
        );
    } else if (Array.isArray(message)) {
        message = (
            <div>
                <BuildError errors={message} />
            </div>
        );
    } else if (typeof message === "string") {
        message = (
            <div>
                <BuildError errors={[[message]]} />
            </div>
        );
    } else {
        message = (
            <div>
                <BuildError errors={message ? Object.values(message) : []} />
            </div>
        );
    }

    toast.error(<>{message}</>);
};

export default ToastError;
