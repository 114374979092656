import { useEffect, useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useParams, useSearchParams } from "react-router-dom";

import Spinner from "@/components/loader/Spinner";
import Button from "@/components/ui/Button";
import CashTotal from "@/components/ui/CashTotal";
import CustomCheckbox from "@/components/ui/CustomCheckbox";
import CustomRadio from "@/components/ui/CustomRadio";
import Input from "@/components/ui/Input";
import Select from "@/components/ui/Select";
import Textarea from "@/components/ui/Textarea";
import { NUMBER_INPUT_FLOATING_POINT } from "@/constants/common";
import {
    CashCalculationRequestType,
    CashCreateResponse,
    CashCreateService,
    Currency,
} from "@/interfaces/dashboard/order/order";

import CashRefundOptions from "../CashRefundOptions";

type WebmoneyCashRequest = {
    service: string;
    amount: number | "";
    fk_currency_id: number;
    account_type: "personal" | "business" | "";
    cash_type: "ADD_TO_ACCOUNT_BALANCE" | "REFUND";
    bank_id: number;
    user_desc?: string;
    rules: boolean;
};

type ServiceInstruction = {
    isLoading: boolean;
    instructions?: CashCreateResponse;
    isCashPricesLoading: boolean;
    calculatedPrice?: Currency;
    onCalculation: (data: CashCalculationRequestType) => void;
    onFormSubmit: (data: WebmoneyCashRequest) => void;
};

type WebmoneyCash = {
    amount: number | "";
    currency: {
        label: string;
        value: number;
    };
    account_type: "personal" | "business" | "";
    cash_type: "ADD_TO_ACCOUNT_BALANCE" | "REFUND";
    bank_id?: {
        label: string;
        value: number;
    };
    user_desc?: string;
    rules: boolean;
    is_immediate: boolean;
};

const WebmoneyCashForm: React.FC<ServiceInstruction> = ({
    isLoading,
    instructions,
    isCashPricesLoading,
    calculatedPrice,
    onCalculation,
    onFormSubmit,
}) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const [serviceData, setServiceData] = useState<CashCreateService>(instructions?.service);

    const [acceptRules, setAcceptRules] = useState<boolean>(false);
    const [total, setTotal] = useState<number>(0);

    const [isImmediate, setIsImmediate] = useState<boolean>(false);

    const [queryString] = useSearchParams();

    const { serviceName } = useParams();

    const {
        control,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm<WebmoneyCash>({
        defaultValues: {
            amount: queryString.get("amount") ? Number(queryString.get("amount")) : "",
            currency: {
                value: queryString.get("currency")
                    ? serviceData.currencies.find((currency) => currency.abbreviation == queryString.get("currency"))
                          ?.id
                    : serviceData?.currencies[0]?.id,
                label: queryString.get("currency")
                    ? serviceData.currencies.find((currency) => currency.abbreviation == queryString.get("currency"))
                          ?.name
                    : serviceData?.currencies[0]?.name,
            },
            account_type: "",
            cash_type: "ADD_TO_ACCOUNT_BALANCE",
            user_desc: "",
            is_immediate: isImmediate,
        },
    });

    useEffect(() => {
        setTotal(calculatedPrice?.total || 0);
    }, [calculatedPrice]);

    const [watchedAmount, watchedCurrency, watchedCashType] = useWatch({
        control,
        name: ["amount", "currency", "cash_type"],
        defaultValue: {
            amount: "",
            currency: {
                value: serviceData.currencies[0]?.id,
                label: serviceData.currencies[0]?.name,
            },
            cash_type: "ADD_TO_ACCOUNT_BALANCE",
        },
    });

    useEffect(() => {
        if (instructions) {
            setServiceData(instructions.service);

            reset();
            setTotal(0);

            if (instructions.service.currencies[0]) {
                setValue("currency", {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    value: instructions.service.currencies[0].id,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    label: instructions.service.currencies[0].name,
                });
            }
        }
    }, [instructions, reset, setValue]);

    useEffect(() => {
        if (watchedCurrency && watchedAmount && Number(watchedAmount) >= 0) {
            onCalculation({
                serviceName: serviceName?.replace("sell-", "").toUpperCase() || "",
                amount: +watchedAmount,
                currency: watchedCurrency.value || "",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchedAmount, watchedCurrency]);

    useEffect(() => {
        const amount = queryString.get("amount");

        onCalculation({
            serviceName: serviceName?.replace("sell-", "").toUpperCase() || "",
            amount: amount ? +amount : 0,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            currency: serviceData.currencies[0]!.id,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submitHandler: SubmitHandler<WebmoneyCash> = (data) => {
        const submitData = {
            service: serviceName?.replace("sell-", "").toUpperCase() || "",
            amount: data.amount,
            fk_currency_id: data.currency.value,
            account_type: data.account_type,
            cash_type: data.cash_type,
            bank_id: data.bank_id?.value || -1,
            user_desc: data.user_desc,
            rules: acceptRules,
            is_immediate: isImmediate,
        };

        onFormSubmit(submitData);
    };

    return (
        <form id="order-form" onSubmit={handleSubmit(submitHandler)}>
            <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-4 lg:flex-row">
                    <div className="w-full lg:w-7/12">
                        <Input
                            containerClassName="w-full"
                            variant="fill"
                            required
                            ltr
                            name="amount"
                            topTitle="میزان ($)"
                            inputMode="decimal"
                            type="number"
                            min={0}
                            step={NUMBER_INPUT_FLOATING_POINT}
                            placeholder="میزان"
                            control={control}
                            rules={{
                                required: "میزان الزامی است",
                            }}
                        />
                    </div>
                    <div className="w-full lg:w-5/12">
                        <Select
                            control={control}
                            topTitle="نوع ارز"
                            required
                            name="currency"
                            variant="fill"
                            placeholder="انتخاب کنید"
                            rules={{
                                required: "انتخاب نوع ارز الزامی است",
                            }}
                            defaultValue={{
                                value: serviceData.currencies[0]?.id,
                                label: serviceData.currencies[0]?.name,
                            }}
                            options={serviceData.currencies.map((currency: { id: number; name: string }) => {
                                return {
                                    value: currency.id,
                                    label: currency.name,
                                };
                            })}
                        />
                    </div>
                </div>

                <CashTotal isLoading={isCashPricesLoading} total={total} />

                <div className="flex flex-col gap-4 rounded-[10px]">
                    <span className="text-text-main">نوع حساب واریز کننده</span>
                    <div className="flex gap-8">
                        <CustomRadio
                            control={control}
                            rules={{
                                required: "نوع حساب واریز کننده الزامی است",
                            }}
                            label="شخصی"
                            name="account_type"
                            value="personal"
                        />
                        <CustomRadio
                            control={control}
                            rules={{
                                required: "نوع حساب واریز کننده الزامی است",
                            }}
                            label="شرکتی"
                            name="account_type"
                            value="business"
                        />
                    </div>
                    {errors && errors.account_type && (
                        <div className="-mt-2 text-[0.78rem] text-error">
                            <span>{errors.account_type.message}</span>
                        </div>
                    )}
                </div>

                {instructions && instructions?.can_choose_payment_method && (
                    <CashRefundOptions
                        control={control}
                        balance={instructions.balance}
                        instructions={instructions}
                        cashType={watchedCashType}
                        onIsImmediateChange={(value: boolean) => setIsImmediate(value)}
                    />
                )}

                <Textarea
                    name="user_desc"
                    control={control}
                    label="توضیحات"
                    placeholder="توضیحات خود را اینجا بنویسید"
                />

                <CustomCheckbox
                    onChange={() => setAcceptRules((prev) => !prev)}
                    label={
                        <span>
                            <a
                                href="https://avalpardakht.com/terms"
                                target="_blank"
                                rel="noreferrer nofollow"
                                className="text-primary hover:text-primary"
                            >
                                قوانین و مقررات
                            </a>{" "}
                            سایت را مطالعه کرده‌ام و آن را قبول دارم.
                        </span>
                    }
                />
                <Button type="submit" disabled={!acceptRules || isLoading} disabledTooltip="دکمه غیرفعال است">
                    {isLoading ? <Spinner /> : "ثبت درخواست"}
                </Button>
            </div>
        </form>
    );
};

export default WebmoneyCashForm;
