import Skeleton from "react-loading-skeleton";

const GiftFormPlaceholder = () => {
    return (
        <div className="flex flex-col gap-2">
            <Skeleton width={250} />
            {Array(3)
                .fill(null)
                .map((_, index) => {
                    return (
                        <Skeleton key={index} containerClassName="mb-2" height={55} style={{ borderRadius: "10px" }} />
                    );
                })}

            <Skeleton width={40} />
            <Skeleton height={40} style={{ borderRadius: "10px" }} />

            <Skeleton width={50} />
            <Skeleton height={80} style={{ borderRadius: "10px" }} />

            <Skeleton width={110} className="mt-4" />
            <Skeleton width={100} height={40} style={{ borderRadius: "10px" }} />
        </div>
    );
};

export default GiftFormPlaceholder;
