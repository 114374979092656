import { useContext, useEffect, useState } from "react";

import IconNotFound from "@/components/svg/not-found";
import Button from "@/components/ui/Button";
import PageTitle from "@/components/utility/PageTitle";
import { Context, ContextType } from "@/store/Context-store";

const TooManyRequest = () => {
    const { error } = useContext(Context) as ContextType;

    const [countdown, setCountdown] = useState(59);

    useEffect(() => {
        if (countdown == 0) {
            window.location.reload();
        }

        const interval = setInterval(() => {
            if (countdown >= 0) {
                setCountdown((prev) => {
                    return prev - 1;
                });
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [countdown]);

    const refreshPage = () => {
        window.location.reload();
    };

    return (
        <div className="flex min-h-[100svh] flex-col items-center justify-center gap-8 bg-background dark:bg-secondary">
            <PageTitle>تعداد درخواست بیش از حد مجاز</PageTitle>

            <div className="relative flex flex-col items-center justify-center">
                <div className="text-[80px] font-[150] text-primary [line-height:0.5] sm:text-[230px]">
                    {error?.response?.status || 429}
                </div>
                <IconNotFound className="h-auto max-w-[90%] md:max-w-full" />
            </div>

            <span className="text-center text-lg text-text-main sm:text-2xl">تعداد درخواست‌ها بیش از حد مجاز است.</span>
            <span className="w-8/12 text-center">
                این صفحه بعد از یک دقیقه بصورت خودکار دوباره بارگذاری خواهد شد. در صورتی که این اتفاق نیفتاد، می‌توانید
                از دکمه‌ی زیر برای این کار استفاده کنید.
            </span>
            <Button disabledTooltip="دکمه غیرفعال است" disabled={countdown > 0} onClick={refreshPage} type="button" className="mb-4">
                {countdown > 0 ? <>{countdown} ثانیه</> : "بارگذاری مجدد"}
            </Button>
        </div>
    );
};

export default TooManyRequest;
