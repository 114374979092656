import { FC, useMemo } from "react";
import { Link } from "react-router-dom";

import IconEyeDuotone from "@/components/svg/eye-duotone";
import Table from "@/components/ui/Table";
import { TableTdProps } from "@/components/ui/Table/TableTd";
import { Order } from "@/interfaces/dashboard/layout/panel/summary";
import { formatNumber } from "@/utils";
import { orderStatus } from "@/utils/cases";

import EmptyIndicator from "../EmptyIndicator";

interface OrderTabContentProps {
    orders: Order[] | undefined;
    isLoading?: boolean;
}

const OrderTabContent: FC<OrderTabContentProps> = ({ orders, isLoading }) => {
    const tableRows = useMemo<TableTdProps[][] | undefined>(() => {
        return orders?.map((order) => {
            const { icon, color } = orderStatus(order.status);

            return [
                {
                    header: "شماره سفارش",
                    children: "#" + order.id,
                    link: `/panel/order/list/${order.id}`,
                },
                {
                    header: "سفارش",
                    children: order.title,
                },
                {
                    header: "میزان",
                    children: order.amount,
                },
                {
                    header: "نرخ (تومان)",
                    children: formatNumber(Number(order.rate)),
                },
                {
                    header: "مبلغ کل (تومان)",
                    children: formatNumber(Number(order.total)),
                },
                {
                    header: "وضعیت",
                    children: order.status,
                    color: color,
                    icon: icon,
                    iconColor: color,
                },
                {
                    header: "عملیات",
                    children: (
                        <Link to={`/panel/order/list/${order.id}`}>
                            <IconEyeDuotone className="w-6 cursor-pointer fill-black dark:fill-white" />
                        </Link>
                    ),
                },
            ];
        });
    }, [orders]);

    return (
        <div className="my-6">
            <Table
                rows={tableRows}
                loading={isLoading}
                emptyIndicator={
                    <EmptyIndicator
                        link="/panel/order"
                        message="سفارشی برای نمایش وجود ندارد"
                        linkText="ثبت سفارش جدید"
                    />
                }
            />

            <div className="-mb-6 flex w-full justify-end pl-6 pt-6">
                <Link to="/panel/order/list" className="text-primary transition-colors hover:text-primary">
                    لیست همه سفارش‌ها
                </Link>
            </div>
        </div>
    );
};

export default OrderTabContent;
