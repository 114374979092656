import { ReactNode } from "react";
import Skeleton from "react-loading-skeleton";

type OrderItemProps = {
    title: string | ReactNode;
    value?: string | number | ReactNode;
    isLink?: boolean;
};

const OrderItemDetail: React.FC<OrderItemProps> = ({ value, title, isLink = false }) => {
    return (
        <div className="flex w-full flex-wrap justify-between">
            <div className="text-text-paragraph">{title}:</div>
            {isLink && typeof value == "string" ? (
                <a
                    href={value}
                    dir="ltr"
                    className="break-all text-primary hover:text-primary"
                    target="_blank"
                    rel="noreferrer"
                >
                    {value}
                </a>
            ) : (
                <div className="break-words text-text-main">{value ?? <Skeleton width={120} height={15} />}</div>
            )}
        </div>
    );
};

export default OrderItemDetail;
