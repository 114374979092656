import { useMutation } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import gate from "@/gate";
import { clearOnLogout } from "@/utils";

const useAuth = () => {
    const navigate = useNavigate();
    const [mustRedirect, setMustRedirect] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { mutate: logoutMutation } = useMutation<any, Error, any>({
        mutationFn: gate.logout,
        onSuccess() {
            clearOnLogout();

            if (mustRedirect) {
                navigate("/");
            }
        },
    });

    const [token, setToken] = useState(() => {
        return Cookies.get("access_token") as string | undefined;
    });

    const setAuthToken = (token: string) => {
        Cookies.set("access_token", token, {
            path: "/",
            sameSite: "lax",
            secure: true,
            expires: 30,
        });
        setToken(token);
    };

    const logout = (redirect = false) => {
        logoutMutation({});
        if (redirect) {
            setMustRedirect(true);
        }
    };

    return { token, setAuthToken, logout };
};

export default useAuth;
