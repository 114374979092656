import { useQuery } from "@tanstack/react-query";

import gate from "@/gate";

const useClubHistory = (page = 1, perPage = 10) => {
    return useQuery(["club-history", page, perPage], () => gate.getClubHistory(page, perPage), {
        select: (data) => {
            return {
                list: data.data,
                ...data.meta,
            };
        },
    });
};

export default useClubHistory;
