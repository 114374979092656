import IconCircleCheckDuotone from "@/components/svg/circle-check-duotone";
import IconCircleXmarkDuotone from "@/components/svg/circle-xmark-duotone";
import IconClock from "@/components/svg/clock";
import IconCoin from "@/components/svg/coin";

export const orderStatus = (status: string | undefined) => {
    let icon = undefined;
    let color = "";

    switch (status) {
        case "انجام شده":
            icon = IconCircleCheckDuotone;
            color = "#1BB169";
            break;
        case "کنسل شده":
            icon = IconCircleXmarkDuotone;
            color = "#FF4747";
            break;
        case "در انتظار انجام":
            color = "#db2777";
            break;
        case "در حال انجام":
            color = "#2563eb";
            break;
        case "معلق شده":
            color = "#ca8a04";
            break;
        case "در انتظار بررسی کارشناس":
            color = "#ca8a04";
            break;
        case "در انتظار تاییدیه ویدیویی":
            color = "#FF4747";
            break;
        case "درحال انجام توسط کارگزار":
            color = "#2563eb";
            break;
        case "در انتظار پرداخت":
            color = "#ec4899";
            break;
        case "ناموفق":
            icon = IconCircleXmarkDuotone;
            color = "#FF4747";
            break;
        case "پرداخت شده":
            icon = IconCircleCheckDuotone;
            color = "#1BB169";
            break;
        case "رد شده":
            icon = IconCircleXmarkDuotone;
            color = "#FF4747";
            break;
        case " در انتظار تایید تیم مالی":
            icon = IconClock;
            color = "#22D0FC";
            break;
        default:
            color = "#7b7b7b";
    }
    return {
        icon,
        color,
    };
};

export const cashStatus = (status: string | undefined) => {
    let icon;
    let color = "";

    switch (status) {
        case "انجام شده ":
            icon = IconCircleCheckDuotone;
            color = "#1BB169";
            break;
        case "رد شده":
            icon = IconCircleXmarkDuotone;
            color = "#FF4747";
            break;
        case "در انتظار تسویه":
            color = "#facc15";
            break;
        case "در انتظار صدور فاکتور":
            color = "#06b6d4";
            break;
        case "در انتظار تاییدیه ویدیویی":
        case "در انتظار تاییدیه ویدیویی (اتوماتیک)":
            color = "#FF4747";
            break;
        case "در انتظار پردازش":
            color = "#3b82f6";
            break;
        case "در انتظار پرداخت":
            color = "#FFC105";
            break;
        case "اتوماتیک":
            color = "#3b82f6";
            break;
        default:
            color = "fill-yellow-500";
    }

    return {
        icon,
        color,
    };
};

export const ticketStatus = (status: string | undefined) => {
    let color = undefined;

    switch (status) {
        case "حل شده":
            color = "#1BB169";
            break;
        case "بدون پاسخ":
            color = "#FF4747";
            break;
        case "پاسخ داده شده":
            color = "#06b6d4";
            break;
        case "در حال بررسی ":
            color = "#f59e0b";
            break;
        case "بسته شده":
            color = "#cb62ff";
            break;
        case "ارجاع به من":
            color = "#cb62ff";
            break;
        case "در انتظار پاسخ شما":
            color = "#06b6d4";
            break;
        default:
            color = undefined;
    }

    return {
        color,
    };
};

export const transactionStatus = (status: string | undefined) => {
    let icon = IconCoin;
    let color = "";

    switch (status) {
        case "ACCEPTED":
        case "":
            icon = IconCircleCheckDuotone;
            color = "#1BB169";
            break;
        case "FAILED":
        case "ناموفق":
            icon = IconCircleXmarkDuotone;
            color = "#FF4747";
            break;
        case "PAID":
        case "پرداخت شده":
            icon = IconCircleCheckDuotone;
            color = "#1BB169";
            break;
        case "INIT":
        case "در انتظار پرداخت":
            icon = IconCoin;
            color = "#facc15";
            break;
        case "REJECTED":
        case "رد شده":
            icon = IconCircleXmarkDuotone;
            color = "#FF4747";
            break;
        case "PENDING":
        case " در انتظار تایید تیم مالی":
            icon = IconClock;
            color = "#22D0FC";
            break;
        default:
            icon = IconCoin;
            color = "#22D0FC";
            break;
    }

    return {
        icon,
        color,
    };
};

export const verificationUploadStatus = (status: string | undefined) => {
    let icon = undefined;
    let color = "";

    switch (status) {
        case "ACCEPTED":
        case "":
            icon = IconCircleCheckDuotone;
            color = "#1BB169";
            break;
        case "FAILED":
        case "ناموفق":
            icon = IconCircleXmarkDuotone;
            color = "#FF4747";
            break;
        case "PAID":
        case "پرداخت شده":
            icon = IconCircleCheckDuotone;
            color = "#1BB169";
            break;
        case "INIT":
        case "در انتظار پرداخت":
            icon = IconCoin;
            color = "#facc15";
            break;
        case "REJECTED":
        case "رد شده":
            icon = IconCircleXmarkDuotone;
            color = "#FF4747";
            break;
        case "PENDING":
        case "در انتظار تایید تیم مالی":
            icon = IconClock;
            color = "#22D0FC";
            break;
        default:
            icon = IconCoin;
            color = "#22D0FC";
            break;
    }

    return {
        icon,
        color,
    };
};
