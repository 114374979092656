import { PropsWithChildren } from "react";
import { Helmet } from "react-helmet-async";

const PageTitle: React.FC<PropsWithChildren> = ({ children = null }) => {
    return (
        <Helmet>
            <title>
                {children ? children : ""}
                {import.meta.env.VITE_SITE_TITLE ? " | " + import.meta.env.VITE_SITE_TITLE : ""}
            </title>
        </Helmet>
    );
};
export default PageTitle;
