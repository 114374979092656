import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import ToastError from "@/components/error/ToastError";
import FileInput from "@/components/ui/FileInput";
import Input from "@/components/ui/Input";
import Textarea from "@/components/ui/Textarea";
import { CalculationRequestType, ServiceInstructionType, SubmitOrderType } from "@/interfaces/dashboard/order/order";
import { objectToArray } from "@/utils";

import Plans from "../Plans";

type OrderValidationSchema = {
    email: string;
    serviceId: number;
    fk_plan_id: number;
    user_description: string | null | undefined;
    attachments?: Record<string, File>;
};

type SubmitOrderRequest = SubmitOrderType & {
    fk_plan_id: number;
    meta: {
        email: string;
    };
};

type ServiceInstruction = ServiceInstructionType & {
    onCalculation: (data: CalculationRequestType) => void;
    onFormSubmit: (data: SubmitOrderRequest, serviceId: number) => void;
};

const OpeningForm: React.FC<ServiceInstruction> = ({ instructions, onCalculation, onFormSubmit }) => {
    const [selectedPlan, setSelectedPlan] = useState<number | undefined>(instructions.plans[0]?.id);

    const { control, handleSubmit, resetField } = useForm<OrderValidationSchema>({
        defaultValues: {
            email: "",
            serviceId: instructions.id,
            user_description: "",
            fk_plan_id: instructions.plans[0]?.id,
        },
    });

    useEffect(() => {
        if (selectedPlan && selectedPlan > 0) {
            onCalculation({
                fk_plan_id: selectedPlan,
                serviceId: instructions.id,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPlan]);

    useEffect(() => {
        onCalculation({
            fk_plan_id: instructions.plans[0]?.id || -1,
            serviceId: instructions.id,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submitHandler: SubmitHandler<OrderValidationSchema> = (data) => {
        if (selectedPlan) {
            onFormSubmit(
                {
                    meta: {
                        email: data.email,
                    },
                    fk_plan_id: selectedPlan,
                    user_description: data.user_description,
                    attachments: data.attachments ? objectToArray(data.attachments) : undefined,
                },
                instructions.id,
            );
        } else {
            ToastError(new Error("برای ثبت سفارش ابتدا باید یک پلن انتخاب کنید."));
        }
    };

    return (
        <form id="order-form" onSubmit={handleSubmit(submitHandler)}>
            <div className="flex flex-col gap-4">
                <Plans
                    currency={instructions.currency[0]}
                    plans={instructions.plans}
                    onPlanChange={(id: number) => setSelectedPlan(id)}
                />
                <Input
                    containerClassName="w-full"
                    variant="fill"
                    topTitle={instructions.form_file.includes("perfectmoney") ? "ایمیل پرفکت مانی" : "ایمیل پی پال"}
                    required
                    inputMode="text"
                    placeholder={instructions.form_file.includes("perfectmoney") ? "ایمیل پرفکت مانی" : "ایمیل پی پال"}
                    ltr
                    name="email"
                    control={control}
                    rules={{
                        required: `ایمیل ${
                            instructions.form_file.includes("perfectmoney") ? "پرفکت مانی" : "پی پال"
                        } الزامی است`,
                    }}
                />

                <Textarea
                    name="user_description"
                    control={control}
                    label="توضیحات"
                    placeholder="توضیحات خود را اینجا بنویسید"
                />
                <FileInput control={control} name="attachments" onReset={resetField} repeater />
            </div>
        </form>
    );
};
export default OpeningForm;
