import { motion } from "framer-motion";
import React, { useMemo, useState } from "react";
import toast from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "react-responsive";
import Masonry from "react-responsive-masonry";
import { Link } from "react-router-dom";

import CoinsImage from "@/assets/images/coins.png";
import toastError from "@/components/error/ToastError";
import PlaceholderLayout from "@/components/placeholder/PlaceholderLayout";
import IconCopy from "@/components/svg/copy";
import IconFacebook from "@/components/svg/facebook";
import IconGmail from "@/components/svg/gmail";
import IconLogo from "@/components/svg/logo";
import IconSackDollar from "@/components/svg/sack-dollar";
import IconShareNodes from "@/components/svg/share-nodes";
import IconTelegram from "@/components/svg/telegram";
import IconUsersDuotone from "@/components/svg/users-duotone";
import IconWhatsapp from "@/components/svg/whatsapp";
import IconXTwitter from "@/components/svg/x-twitter";
import BoxContainer from "@/components/ui/BoxContainer";
import Button from "@/components/ui/Button";
import Title from "@/components/ui/Title";
import PageTitle from "@/components/utility/PageTitle";
import useAffiliate from "@/hooks/queries/useAffiliate";
import SocialMediaItem from "@/pages/dashboard/affiliate/SocialMediaItem";

const Affiliate = () => {
    const { data: affiliateInfo } = useAffiliate();
    const [selectedBanner, setSelectedBanner] = useState<string>();
    const isMobile = useMediaQuery({ maxWidth: 640 });

    const linkText = "برای استفاده از سرویس‌های متنوع ارزی و نقد درآمد ارزی به جمع مجموعه‌ی اول پرداخت بپیوندید.";
    const linkTextUrl = linkText + "\n\r" + affiliateInfo?.link;

    const socialMediaItems = [
        {
            icon: IconTelegram,
            link: `https://t.me/share/url?url=${affiliateInfo?.link}&text=${linkText}`,
            color: "fill-[#00bbcc]",
        },
        {
            icon: IconGmail,
            link: `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=${encodeURIComponent(
                "لینک ثبت نام در اول‌پرداخت",
            )}&body=${
                affiliateInfo?.link ? encodeURIComponent(linkText + " \n\r " + affiliateInfo.link) : ""
            }&ui=2&tf=1&pli=1`,
            color: "fill-red-400",
            className: "scale-[0.85]",
        },
        // {
        //     icon: IconYahoo,
        //     link: `http://twitter.com/share?text=${linkText}&url=${affiliateInfo?.link}`,
        //     color: "fill-[#8e43ba]",
        // },
        // {
        //     icon: IconLinkedin,
        //     link: `https://www.linkedin.com/shareArticle?mini=true&url=${
        //         affiliateInfo?.link ? encodeURIComponent(affiliateInfo.link) : ""
        //     }&title=${encodeURIComponent(linkText)}&source=${encodeURIComponent("https://avalpardakht.com")}`,
        //     color: "fill-[#0077b5]",
        // },
        {
            icon: IconFacebook,
            link: `http://www.facebook.com/sharer/sharer.php?m2w&s=100&p[url]=${
                affiliateInfo?.link ? encodeURIComponent(affiliateInfo.link) : ""
            }`,
            color: "fill-[#1877F2]",
        },
        // {
        //     icon: IconMessageSms,
        //     link: `sms:?body=${linkTextUrl}`,
        //     color: "fill-orange-400",
        //     className: "translate-y-[2px]",
        // },
        {
            icon: IconXTwitter,
            link: `https://twitter.com/intent/tweet?text=${encodeURIComponent(linkText)}&url=${
                affiliateInfo?.link ? encodeURIComponent(affiliateInfo.link) : ""
            }`,
            color: "fill-black dark:fill-[#ffffff]",
        },
        // {
        //     icon: IconDiscord,
        //     link: "https://asdasd.com",
        //     color: "fill-[#717eec] ",
        // },
        // {
        //     icon: IconThreads,
        //     link: "https://asdasd.com",
        //     color: "fill-white",
        // },
        {
            icon: IconWhatsapp,
            link: `https://web.whatsapp.com/send?text=${encodeURIComponent(linkTextUrl)}&title=${encodeURIComponent(
                linkText,
            )}`,
            color: "fill-[#59ce72]",
        },
    ];

    const renderSocialMediaCircleItem = useMemo(() => {
        const deg = Math.floor(360 / socialMediaItems.length);

        return socialMediaItems.map((item, index) => {
            const rotateDeg = `${deg * index}deg`;
            return <SocialMediaItem {...item} rotateDeg={rotateDeg} key={index} />;
        });
    }, [affiliateInfo]);

    const renderSocialMediaBoxItem = useMemo(() => {
        return socialMediaItems.map((item, index) => {
            return (
                <Link
                    to={item.link}
                    key={index}
                    className="rounded-[10px] bg-secondary-200 p-4 transition-transform duration-75 active:scale-90"
                >
                    <item.icon className={"h-[20px] sm:h-[26px] " + item.color} />
                </Link>
            );
        });
    }, [affiliateInfo]);

    const renderImages = useMemo(() => {
        const copyOfData = { ...affiliateInfo };

        delete copyOfData.link;
        delete copyOfData.earn_commission;
        delete copyOfData.commission;
        delete copyOfData.referred_count;

        return Object.keys(copyOfData).map((key) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const imgLink = copyOfData[key];

            const code = `<a target="_blank" rel="noreferrer" href="${affiliateInfo?.link}"><img height="240" width="120" src="${imgLink}" alt="avalpardakht" /></a>`;

            return (
                <div className="relative flex flex-col items-center justify-start p-4" key={key}>
                    <button
                        className="group/banner"
                        onClick={() => {
                            setSelectedBanner(code);
                            copy(affiliateInfo?.link);
                        }}
                    >
                        <img
                            src={imgLink}
                            alt="avalpardakht"
                            className={`reset-hue-rotate rounded-[15px] border-[5px]  transition-transform duration-75 group-hover/banner:border-primary group-active/banner:scale-95 ${
                                code === selectedBanner ? "border-success" : "border-transparent"
                            }`}
                        />
                    </button>
                </div>
            );
        });
    }, [affiliateInfo, selectedBanner]);

    const copy = async (text: string | undefined) => {
        if (text) {
            try {
                await navigator.clipboard.writeText(text);
                toast.success("کپی شد");
            } catch (e) {
                toastError(new Error("کپی نشد"));
            }
        }
    };

    return (
        <PlaceholderLayout>
            <PageTitle>
                همکاری در فروش
            </PageTitle>
            <svg className="absolute -z-10">
                <defs>
                    <linearGradient id="Gradient1" x1="0" x2="0" y1="1" y2="0">
                        <stop style={{ stopColor: "#fdd367" }} offset="0%" />
                        <stop style={{ stopColor: "#d93b75" }} offset="50%" />
                        <stop style={{ stopColor: "#7d49be" }} offset="100%" />
                    </linearGradient>
                </defs>
            </svg>
            <div className="mt-10 px-1">
                <div className="flex w-full justify-between gap-10 max-sm:flex-col-reverse">
                    <div className="flex flex-1 flex-col items-start justify-center">
                        <h2 className="pb-4 text-[1.57rem] font-semibold text-text-main lg:text-[2rem]">
                            عنوان همکاری در فروش
                        </h2>
                        <p className="text-justify leading-loose text-text-paragraph lg:text-[1.28rem]">
                            با استفاده از سیستم همکاری در فروش اول پرداخت، همه افراد می‌توانند بدون در نظر گرفتن موقعیت
                            جغرافیایی و تنها از طریق اینترنت کسب درآمد کنند. برای این کار تنها کافیست لینک ارجاع
                            (Referral Link) خود را در اختیار دیگران قرار دهید تا از طریق این لینک، سفارش خود در اول
                            پرداخت را ثبت نمایند. به ازای هر ثبت سفارش از طریق لینک ارجاع شما،
                            <span className="font-bold text-success">{affiliateInfo?.commission} درصد </span>
                            از سود خالص سفارش به شما تعلق می‌گیرد. کسب درآمد از طریق لینک ارجاع نیاز به هیچ سرمایه‌
                            اولیه‌ای ندارد و شما می‌توانید در هر ساعتی از شبانه‌روز و بدون انجام دادن فعالیت خاصی کسب
                            درآمد کنید. تنها کافیست این لینک را در شبکه‌های اجتماعی خود به اشتراک بگذارید. هیچ محدودیتی
                            در میزان کسب درآمد از این روش وجود ندارد.
                        </p>
                    </div>
                    <div className="flex flex-1 items-center justify-center max-sm:hidden">
                        <img src={CoinsImage} className="h-auto w-[250px] md:w-[300px] lg:w-[400px]" alt="coins" />
                    </div>
                </div>
                <div className="mt-20 flex w-full justify-between gap-[50px] max-lg:flex-col-reverse max-lg:items-center md:max-lg:mb-[250px] md:max-lg:gap-[300px]">
                    <motion.div
                        whileHover="hover-social-item"
                        whileFocus="hover-social-item"
                        className="flex h-[150px] w-full flex-1 items-center justify-center max-md:hidden sm:h-[400px]"
                    >
                        <div className="relative flex items-center justify-center">
                            {renderSocialMediaCircleItem}
                            <div className="absolute left-0 flex h-[70px] w-[70px] -translate-x-1/2 items-center justify-center rounded-full border-secondary-200 bg-white dark:border-2 dark:bg-secondary sm:h-[80px] sm:w-[80px]">
                                <IconLogo className="h-[32px] sm:h-[38px]" />
                            </div>
                        </div>
                    </motion.div>

                    {/*<div className="flex w-full flex-1 flex-wrap items-center justify-center gap-4 md:hidden">*/}
                    {/*   */}
                    {/*    {renderSocialMediaBoxItem}*/}
                    {/*</div>*/}

                    <div className="flex-1 max-md:w-full">
                        <div className="flex items-center justify-center gap-6 xs:gap-10">
                            <BoxContainer className="w-full">
                                <div className="flex h-full flex-col items-center justify-center gap-2 py-1 xs:px-4 xs:py-2 lg:gap-4">
                                    <IconUsersDuotone className="h-[40px] shrink-0 fill-primary lg:h-[50px]" />
                                    <span className="mt-2 whitespace-nowrap text-text-main xs:text-[1.14rem] lg:mt-4 xl:mt-8">
                                        کاربران ارجاع شده
                                    </span>
                                    <div className="text-[2.14rem] text-primary">
                                        {affiliateInfo?.referred_count ?? <Skeleton width={120} height={15} />}
                                    </div>
                                </div>
                            </BoxContainer>
                            <BoxContainer className="w-full">
                                <div className="flex h-full flex-col items-center justify-center gap-2 py-1 xs:px-4 xs:py-2 lg:gap-4">
                                    <IconSackDollar className="h-[40px] shrink-0 fill-primary lg:h-[50px]" />
                                    <span className="mt-2 whitespace-nowrap text-text-main xs:text-[1.14rem] lg:mt-4 xl:mt-8">
                                        مجموع پورسانت
                                    </span>
                                    <div className="text-[2.14rem] text-primary">
                                        {affiliateInfo ? (
                                            <>
                                                {affiliateInfo.earn_commission}
                                                <span className="text-[1.14rem]">تومان</span>
                                            </>
                                        ) : (
                                            <Skeleton width={120} height={15} />
                                        )}
                                    </div>
                                </div>
                            </BoxContainer>
                        </div>
                        <BoxContainer className="mt-6 w-full xs:mt-10">
                            <div className="flex h-full flex-col items-center justify-center gap-4">
                                <span className="text-[1.42rem] text-text-main">لینک ارجاع (Referral Link)</span>
                                <div className="flex flex-wrap items-center justify-center gap-4">
                                    <div
                                        dir="ltr"
                                        className="w-full max-w-[250px] cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap text-[1rem] text-text-paragraph transition-colors hover:text-primary sm:text-[1.14rem] xl:text-[1.42rem]"
                                        onClick={() => copy(affiliateInfo?.link)}
                                    >
                                        {affiliateInfo?.link ?? <Skeleton width={250} height={15} />}
                                    </div>
                                    <div className="flex items-center justify-center gap-4">
                                        <button
                                            onClick={() => {
                                                try {
                                                    window.navigator.share({
                                                        title: linkText,
                                                        url: affiliateInfo?.link,
                                                    });
                                                } catch (error) {
                                                    //
                                                }
                                            }}
                                            className="flex h-[40px] items-center justify-center gap-4 rounded-[10px] bg-secondary-200 px-4 text-text-main transition-transform duration-75 active:scale-90 xs:hidden"
                                        >
                                            <span>دعوت از دوستان</span>
                                            <IconShareNodes className={"h-[20px] fill-primary sm:h-[26px]"} />
                                        </button>
                                        <Button onClick={() => copy(affiliateInfo?.link)} icon={IconCopy}>
                                            کپی
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </BoxContainer>
                    </div>
                </div>
                <BoxContainer colorBox={false} className="mt-[40px]">
                    <Title className="w-full" containerClasses="-m-5" title="بنر های اول پرداخت" expandebale>
                        {selectedBanner && (
                            <pre
                                className="w-full overflow-x-scroll rounded-[10px] bg-secondary-200 p-4 text-left text-[0.85rem]"
                                dir="rtl"
                            >
                                {selectedBanner}
                            </pre>
                        )}
                        <Masonry columnsCount={isMobile ? 1 : 2} gutter="10px" className="mt-10">
                            {renderImages}
                        </Masonry>
                    </Title>
                </BoxContainer>
            </div>
        </PlaceholderLayout>
    );
};
export default Affiliate;
