import { useMemo } from "react";

import IconCircleCheckDuotone from "@/components/svg/circle-check-duotone";
import IconClock from "@/components/svg/clock";

import IconCircleExclamation from "../svg/circle-exclamation";

type UploadStatusBase = {
    status: "success" | "pending" | "error";
    text: string;
};

type UploadStatusWithImage = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    image: any;
    video?: never;
};

type UploadStatusWithVideo = {
    image?: never;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    video: any;
};

type UploadStatusProps = UploadStatusBase & (UploadStatusWithImage | UploadStatusWithVideo);

const UploadStatus = ({ image, text, status, video }: UploadStatusProps) => {
    const { icon: Icon, color } = useMemo(() => {
        switch (status) {
            case "pending":
                return {
                    icon: IconClock,
                    color: "#facc15",
                };
            case "success":
                return {
                    icon: IconCircleCheckDuotone,
                    color: "#1BB169",
                };
            case "error":
                return {
                    icon: IconCircleExclamation,
                    color: "#b11b1b",
                };
        }
    }, [status]);

    return (
        <div className="absolute top-0 h-full w-full overflow-hidden rounded-[15px]">
            {image && (
                <img src={image} alt="test" className="absolute h-full w-full object-cover dark:brightness-[65%]" />
            )}
            {video && (
                <video
                    autoPlay={true}
                    loop
                    muted
                    src={video}
                    className="absolute h-full w-full object-cover dark:brightness-[65%]"
                />
            )}
            <div
                className="absolute flex h-full w-full items-center justify-center rounded-[15px] bg-gradient-to-t from-[rgba(0,0,0,0.5)] from-5% to-[rgba(0,0,0,0.1)] backdrop-blur-sm dark:border dark:from-[hsl(225,20%,14%)] dark:to-[hsla(225,20%,16%,0.4)]"
                style={{ borderColor: color }}
            >
                <div className="flex w-[300px] flex-col items-center justify-center gap-3 rounded-[10px] py-5 xs:gap-5">
                    <Icon
                        className="dark:brightness-120 h-[35px] brightness-150 xs:h-[40px] sm:h-[45px]"
                        style={{ fill: color }}
                    />
                    <p
                        className={`px-10 text-center text-[110%] brightness-150 dark:brightness-100 ${
                            status == "error" ? "font-bold" : ""
                        }`}
                        style={{ color }}
                    >
                        {text}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default UploadStatus;
