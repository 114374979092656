import { useQuery } from "@tanstack/react-query";

import gate from "@/gate";

const useProvinces = (enabled = true) => {
    return useQuery(["provinces-list"], gate.getProvinces, {
        select: (data) =>
            data.data.provinces.map((province) => {
                return {
                    value: province.province_id,
                    label: province.name,
                };
            }),
        enabled: enabled,
    });
};

export default useProvinces;
