import { useState } from "react";

import IconCartShoppingDuotone from "@/components/svg/cart-shopping-duotone";
import IconMoneyBillWaveDuotone from "@/components/svg/money-bill-wave-duotone";
import IconTicketDuotone from "@/components/svg/ticket-duotone";
import BoxContainer from "@/components/ui/BoxContainer";
import usePanelSummary from "@/hooks/queries/usePanelSummary";
import useAfter from "@/hooks/useAfter";

import CashTabContent from "./CashTabContent";
import OrderTabContent from "./OrderTabContent";
import TicketTabContent from "./TicketTabContent";

const ORDERS = "ORDERS";
const CASHES = "CASHES";
const TICKETS = "TICKETS";

const PanelSummary = ({ isLoading }: { isLoading: boolean }) => {
    const [activeTab, setActiveTab] = useState(ORDERS);
    const { data: summary } = usePanelSummary();

    const isOrdersEmpty = summary?.data?.orders.length === 0 && summary?.data?.cash_incomes.length > 0;

    useAfter(() => {
        setActiveTab(isOrdersEmpty ? CASHES : ORDERS);
    }, !!summary);

    return (
        <BoxContainer colorBox={false}>
            <div>
                <div className="tab-header -mx-5 -mt-[10px] flex gap-1 border-b border-primary px-3 sm:-mt-0 sm:px-8 ">
                    <div
                        onClick={() => {
                            setActiveTab(ORDERS);
                        }}
                        className={`flex cursor-pointer items-center rounded-se-xl rounded-ss-xl p-4 transition-all ${
                            isOrdersEmpty ? "order-2" : "order-1"
                        } ${activeTab == ORDERS && "bg-primary/10"}`}
                    >
                        <h2
                            className={`flex items-center gap-2 text-primary sm:gap-3 ${
                                activeTab != ORDERS && "text-text-paragraph"
                            }`}
                        >
                            <IconCartShoppingDuotone
                                className={`w-4 transition-all sm:w-7 ${
                                    activeTab == ORDERS ? "fill-primary" : "fill-icon-disabled"
                                }`}
                            />
                            <div className="sm:text-md text-sm">
                                <span className="hidden sm:inline">آخرین</span> سفارش‌ها
                            </div>
                        </h2>
                    </div>
                    <div
                        onClick={() => {
                            setActiveTab(CASHES);
                        }}
                        className={`flex cursor-pointer items-center rounded-se-xl rounded-ss-xl p-4 transition-all ${
                            isOrdersEmpty ? "order-1" : "order-2"
                        } ${activeTab == CASHES && "bg-primary/10"}`}
                    >
                        <h2
                            className={`flex items-center gap-2 text-primary sm:gap-3 ${
                                activeTab != CASHES && "text-text-paragraph"
                            }`}
                        >
                            <IconMoneyBillWaveDuotone
                                className={`w-4 transition-all sm:w-7 ${
                                    activeTab == CASHES ? "fill-primary" : "fill-icon-disabled"
                                }`}
                            />
                            <div className="sm:text-md text-sm">
                                <span className="hidden sm:inline">آخرین</span> نقد‌ها
                            </div>
                        </h2>
                    </div>
                    <div
                        onClick={() => {
                            setActiveTab(TICKETS);
                        }}
                        className={`order-3 flex cursor-pointer items-center rounded-se-xl rounded-ss-xl p-4 transition-all ${
                            activeTab == TICKETS && "bg-primary/10"
                        }`}
                    >
                        <h2
                            className={`flex items-center gap-2 text-primary sm:gap-3 ${
                                activeTab != TICKETS && "text-text-paragraph"
                            }`}
                        >
                            <IconTicketDuotone
                                className={`w-4 transition-all sm:w-7 ${
                                    activeTab == TICKETS ? "fill-primary" : "fill-icon-disabled"
                                }`}
                            />
                            <div className="sm:text-md text-sm">
                                <span className="hidden sm:inline">آخرین</span> تیکت‌ها
                            </div>
                        </h2>
                    </div>
                </div>
                <div className="tab-wrapper -mx-5">
                    {activeTab == ORDERS && <OrderTabContent orders={summary?.data?.orders} isLoading={isLoading} />}
                    {activeTab == CASHES && (
                        <CashTabContent cashIncomes={summary?.data?.cash_incomes} isLoading={isLoading} />
                    )}
                    {activeTab == TICKETS && (
                        <TicketTabContent tickets={summary?.data?.tickets} isLoading={isLoading} />
                    )}
                </div>
            </div>
        </BoxContainer>
    );
};

export default PanelSummary;
