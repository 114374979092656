import { Navigate, RouteObject } from "react-router-dom";

import ForgotPassword from "@/pages/auth/ForgotPassword";
import AuthLayout from "@/pages/auth/layout/AuthLayout";
import Login from "@/pages/auth/Login";
import Register from "@/pages/auth/Register";
import ResetPassword from "@/pages/auth/ResetPassword";
import TwoFaValidate from "@/pages/auth/TwoFaValidate";

const AuthRoutes: RouteObject[] = [
    {
        path: "login",
        element: <Navigate to={"/auth/login"} />,
    },
    {
        path: "register",
        element: <Navigate to={"/auth/register"} />,
    },
    {
        path: "auth",
        element: <AuthLayout />,
        children: [
            {
                path: "*",
                element: <Navigate to={"/auth/login"} />,
            },
            {
                index: true,
                path: "login",
                element: <Login />,
            },
            {
                path: "2fa-validate",
                element: <TwoFaValidate />,
            },
            {
                path: "register",
                element: <Register />,
            },
            {
                path: "forgot-password",
                element: <ForgotPassword />,
            },
            {
                path: "reset-password/:token",
                element: <ResetPassword />,
            },
        ],
    },
];

export default AuthRoutes;
