import { useMutation } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

import gate from "@/gate";

import useUser from "../useUser";

const useReportError = () => {
    const user = useUser();
    const location = useLocation();

    const { mutateAsync } = useMutation({
        mutationFn: gate.reportError,
        retry: 1,
    });

    const report = (message = "", meta: unknown = null) => {
        const data = {
            agentPlatform: navigator.userAgent,
            message: message,
            metadata: {
                userId: user.data.data.user.id,
                address: location,
                meta: meta,
            },
            stack: new Error().stack,
            url: location.pathname,
        };

        mutateAsync(data);
    };

    return {
        report,
    };
};

export default useReportError;
