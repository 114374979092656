import Skeleton from "react-loading-skeleton";

import PlaceholderLayout from "@/components/placeholder/PlaceholderLayout";
import IconWalletDuotone from "@/components/svg/wallet-duotone";
import BoxContainer from "@/components/ui/BoxContainer";
import { formatNumber } from "@/utils";

type WalletBoxProps = {
    balance: number | undefined;
};

const WalletBox: React.FC<WalletBoxProps> = ({ balance }) => {
    return (
        <PlaceholderLayout>
            <BoxContainer className="grow" colorBox={false}>
                <div className="-mx-5 -mt-5 flex flex-row-reverse items-center justify-between border-b border-secondary-200 px-5 py-2 md:py-3">
                    <div>
                        <IconWalletDuotone className="h-[16px] fill-gray-400 dark:fill-gray-300 md:h-[20px]" />
                    </div>
                    <div className="text-text-main">دارایی کیف پول</div>
                </div>
                <div className="flex h-full items-center justify-start min-[420px]:justify-center sm:justify-start">
                    <span
                        className={`inline-block pl-2 text-[2rem] leading-tight brightness-[110%] sm:text-[1.42rem] md:text-[1.78rem] lg:text-[2.5rem] ${
                            typeof balance == "number" && balance < 0 ? "text-error" : "text-success"
                        }`}
                        dir="ltr"
                    >
                        {typeof balance == "number" ? formatNumber(balance) : <Skeleton width={140} height={15} />}
                    </span>
                    <span
                        className={`text-[1.42rem] ${
                            balance && balance < 0 ? "text-error" : "text-success"
                        } sm:text-[1.28rem] lg:text-[1.42rem]`}
                    >
                        تومان
                    </span>
                </div>
            </BoxContainer>
        </PlaceholderLayout>
    );
};

export default WalletBox;
