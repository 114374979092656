import { useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import ToastError from "@/components/error/ToastError";
import SlideSection from "@/components/profile/authentication/SlideSection";
import Button from "@/components/ui/Button";
import Input from "@/components/ui/Input";
import useUpdateBusiness from "@/hooks/queries/useUpdateBusiness";
import useUser from "@/hooks/queries/useUser";
import useVerifyRequest from "@/hooks/queries/useVerifyRequest";
import { scrollToTop } from "@/utils";

type BusinessDetailsProps = {
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
};

type BusinessDetailsSchema = {
    business_name: string;
    business_financial_code: string;
    business_register_number: string;
    business_national_index: string;
};

const BusinessDetails: React.FC<BusinessDetailsProps> = ({ setActiveIndex }) => {
    const queryClient = useQueryClient();
    const { mutateAsync: verifyRequest } = useVerifyRequest();
    const { data: user, refetch: refetchUser } = useUser();

    const businessDetailsStatus = user.data.verifications.find(
        (verification) => verification.verification_type === "BUSINESS",
    )?.resultStatus;

    const isBusinessDetailsConfirmed = businessDetailsStatus === "CONFIRMED";
    const isBusinessDetailsPending = businessDetailsStatus === "PENDING";
    const isBusinessDetailsRejected = businessDetailsStatus === "REJECTED";

    const businessInfo = user.data.user?.info?.business_information;

    const {
        control,
        handleSubmit,
        formState: { isValid },
    } = useForm<BusinessDetailsSchema>({
        defaultValues: {
            business_financial_code: businessInfo?.financial_code ?? "",
            business_name: businessInfo?.name ?? "",
            business_national_index: businessInfo?.national_index ?? "",
            business_register_number: businessInfo?.register_number ?? "",
        },
        mode: "all",
    });

    const { mutateAsync: updateBusinessDetails, isLoading: isUpdateBusinessLoading } = useUpdateBusiness();

    const submitHandler: SubmitHandler<BusinessDetailsSchema> = async (data) => {
        if (isValid) {
            try {
                await updateBusinessDetails(data);
                await verifyRequest({ verifyType: "BUSINESS" });
                await refetchUser();
                setActiveIndex((oldIndex) => oldIndex + 1);
            } catch (e) {
                console.log(e);
                ToastError(new Error("خطایی سمت سرور رخ داده است"));
            }
        }

        queryClient.invalidateQueries(["userData"]);
    };

    const postalSectionSubTitle = useMemo(() => {
        if (isBusinessDetailsConfirmed) return "اطلاعات حقوقی شما تایید شده است";
        if (isBusinessDetailsPending) return "اطلاعات حقوقی شما در حال بررسی است";
        if (isBusinessDetailsRejected) return "اطلاعات حقوقی شما رد شده است";
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const postalSectionSubTitleStatus = useMemo(() => {
        if (isBusinessDetailsConfirmed) return "success";
        if (isBusinessDetailsPending) return "warning";
        if (isBusinessDetailsRejected) return "error";
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
        <form className="flex h-full w-full flex-col">
            <div className="flex h-full max-lg:flex-col">
                <SlideSection
                    title="تایید اطلاعات حقوقی"
                    subTitle={postalSectionSubTitle}
                    subTitleStatus={postalSectionSubTitleStatus}
                    className="h-full space-y-10 pt-6"
                    separator
                >
                    <div className="flex w-full gap-10">
                        <Input
                            required
                            containerClassName="flex-1"
                            topTitle="نام شرکت"
                            name="business_name"
                            control={control}
                            variant="fill"
                            placeholder="نام شرکت"
                            readOnly={isBusinessDetailsConfirmed || isBusinessDetailsPending}
                            disabled={isBusinessDetailsConfirmed}
                            showConfirmedStatus={isBusinessDetailsConfirmed}
                            rules={{
                                required: "نام شرکت الزامی است",
                            }}
                        />
                        <Input
                            required
                            ltr
                            containerClassName="flex-1"
                            topTitle="کد اقتصادی"
                            name="business_financial_code"
                            control={control}
                            variant="fill"
                            placeholder="کد اقتصادی"
                            inputMode="numeric"
                            type="number"
                            readOnly={isBusinessDetailsConfirmed || isBusinessDetailsPending}
                            disabled={isBusinessDetailsConfirmed}
                            showConfirmedStatus={isBusinessDetailsConfirmed}
                            rules={{
                                required: "کد اقتصادی الزامی است",
                            }}
                        />
                    </div>
                    <div className="flex w-full gap-10">
                        <Input
                            required
                            ltr
                            containerClassName="flex-1"
                            topTitle="شماره ثبت"
                            name="business_register_number"
                            control={control}
                            variant="fill"
                            placeholder="شماره ثبت"
                            inputMode="numeric"
                            type="number"
                            readOnly={isBusinessDetailsConfirmed || isBusinessDetailsPending}
                            disabled={isBusinessDetailsConfirmed}
                            showConfirmedStatus={isBusinessDetailsConfirmed}
                            rules={{
                                required: "شماره ثبت الزامی است",
                            }}
                        />
                        <Input
                            required
                            ltr
                            containerClassName="flex-1"
                            topTitle="شناسه ملی"
                            name="business_national_index"
                            control={control}
                            variant="fill"
                            placeholder="شناسه ملی"
                            inputMode="numeric"
                            type="number"
                            readOnly={isBusinessDetailsConfirmed || isBusinessDetailsPending}
                            disabled={isBusinessDetailsConfirmed}
                            showConfirmedStatus={isBusinessDetailsConfirmed}
                            rules={{
                                required: "شناسه ملی الزامی است",
                            }}
                        />
                    </div>
                </SlideSection>
            </div>
            <div className="flex w-full items-center justify-end gap-4 border-t border-secondary-200 px-4 py-6 max-xs:pb-4 xs:px-6">
                <Button
                    type="button"
                    className="w-full xs:w-[150px]"
                    loading={isUpdateBusinessLoading}
                    disabled={isUpdateBusinessLoading}
                    disabledTooltip="دکمه غیرفعال است"
                    onClick={
                        isBusinessDetailsConfirmed || isBusinessDetailsPending
                            ? () => {
                                  scrollToTop(1000, 150);
                                  setActiveIndex((oldIndex) => oldIndex + 1);
                              }
                            : handleSubmit(submitHandler)
                    }
                >
                    {isBusinessDetailsConfirmed || isBusinessDetailsPending ? "بعدی" : "تایید و بعدی"}
                </Button>
            </div>
        </form>
    );
};

export default BusinessDetails;
