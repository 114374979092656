import { useState } from "react";
import { useNavigate } from "react-router-dom";

import IconCircleQuestion from "@/components/svg/circle-question";
import IconHeadset from "@/components/svg/headset";
import Button from "@/components/ui/Button";

type NeedSupportProps = {
    title: string;
    ticketLink: string;
    supportText: React.ReactNode;
};

const NeedSupport = ({ supportText, ticketLink, title }: NeedSupportProps) => {
    const navigate = useNavigate();
    const [showButtons, setShowButtons] = useState(false);

    const sendSupportMessage = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Goftino.open();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Goftino.sendMessage({
            text: supportText,
        });
    };

    return (
        <div className="flex w-full flex-wrap items-center justify-between gap-4 max-[1460px]:flex-col max-[1460px]:items-start max-lg:flex-row max-lg:items-center max-sm:flex-col max-sm:items-start">
            <button className="flex items-center gap-3" onClick={() => setShowButtons((oldVal) => !oldVal)}>
                <IconCircleQuestion
                    className={`h-[16px] fill-primary xs:h-[20px]`}
                    style={{ animationIterationCount: "infinite" }}
                />
                <div className="mt-[2px] whitespace-nowrap text-[0.85rem] font-semibold text-text-main xs:text-[1rem]">
                    {title}
                </div>
            </button>
            <div
                className={`${
                    showButtons ? "opacity-1 scale-1 visible" : "invisible scale-90 opacity-0"
                } flex h-[40px] w-min justify-end gap-4 transition-all max-[1460px]:self-end`}
            >
                <Button
                    variant="stroke"
                    icon={IconHeadset}
                    className="whitespace-nowrap !text-[1rem]"
                    onClick={sendSupportMessage}
                >
                    پشتیبانی
                </Button>
                <Button
                    variant="stroke"
                    className="whitespace-nowrap !text-[1rem]"
                    onClick={() => navigate(ticketLink)}
                >
                    ارسال تیکت
                </Button>
            </div>
        </div>
    );
};

export default NeedSupport;
