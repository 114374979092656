import { useContext, useEffect } from "react";

import useLocalStorage from "@/hooks/useLocalStorage";
import { Context, ContextType } from "@/store/Context-store";

const useAnimeSetting = () => {
    const [animationStatusLocal, setAnimationStatusLocal] = useLocalStorage("app-theme-animation-status", true);
    const { animationStatus, setAnimationStatus } = useContext(Context) as ContextType;

    const applySetting = () => {
        const htmlEl = document.querySelector("html") as HTMLHtmlElement;

        if (animationStatus) {
            htmlEl.classList.remove("theme-disable-animations");
        } else {
            htmlEl.classList.add("theme-disable-animations");
        }
    };

    useEffect(() => {
        setAnimationStatus(animationStatusLocal);
        setAnimationStatusLocal(animationStatusLocal);
    }, []);

    useEffect(() => {
        applySetting();
        setAnimationStatusLocal(animationStatus);
    }, [animationStatus]);

    return {
        isAnimationEnabled: animationStatus,
        setAnimationStatus,
    };
};

export default useAnimeSetting;
