import { GetTransactionResponse, PaymentResponse } from "@/interfaces/dashboard/invoice/invoice";

import api from "../api";

type PayWithDraftRequest = {
    id: number;
    data: {
        meta?: {
            bank_name: string;
            bank_fish_number: string;
            bank_pay_date: string;
            bank_pay_description?: string;
        };
        transaction_attachment?: File;
    };
};

type PayOnlineRequest = {
    id: number;
};

type PayWalletRequest = {
    id: number;
};

type VoucherRequest = {
    id: number;
    code: string;
};

export default {
    voucher: (data: VoucherRequest) => api.post(`/panel/vouchers/check/${data.id}`, { code: data.code }),
    getTransaction: (id: number) => api.get<GetTransactionResponse>(`/panel/api/v1/transactions/${id}`),
    payWithDraft: async (data?: PayWithDraftRequest) => {
        return api.file(`/panel/wallet/invoice/${data?.id}/send`, {
            ...data?.data,
            choose_pay_method: "draft",
        });
    },
    payOnline: (data?: PayOnlineRequest) =>
        api.post<PaymentResponse>(`/panel/wallet/invoice/${data?.id}/send`, {
            choose_pay_method: "online",
        }),
    payWallet: (data?: PayWalletRequest) =>
        api.post<PaymentResponse>(`/panel/wallet/invoice/${data?.id}/send`, {
            choose_pay_method: "credit",
        }),
};
