import React from "react";

import classes from "./ServiceInstruction.module.scss";

type DescriptionProps = {
    children: React.ReactNode;
};

const ServiceInstruction: React.FC<DescriptionProps> = ({ children }) => {
    return (
        <div className={classes.instruction}>
            {children}
        </div>
    );
};
export default ServiceInstruction;
