import Skeleton from "react-loading-skeleton";

import PlaceholderLayout from "./PlaceholderLayout";

const ServicesPricePlaceholder: React.FC = () => {
    return (
        <PlaceholderLayout>
            <Skeleton style={{ borderRadius: "10px" }} containerClassName="w-full block h-[49px]" height={"100%"} />
        </PlaceholderLayout>
    );
};
export default ServicesPricePlaceholder;
