import { useContext } from "react";

import BoxContainer from "@/components/ui/BoxContainer";
import SwitchBox from "@/components/ui/SwitchBox";
import Title from "@/components/ui/Title";
import { Context, ContextType } from "@/store/Context-store";

const FontSettingBox = () => {
    const { linkUnderline, setLinkUnderline, setIsSettingsChanged } = useContext(Context) as ContextType;

    return (
        <>
            <BoxContainer>
                <Title title="تنظیمات لینک ها" containerClasses="-mx-6 -mt-4" />
                <div className="mt-6 flex flex-col items-center justify-evenly gap-8">
                    {/*<SwitchBox active={linkHighlight} setActive={setLinkHighlight} label="هایلایت کردن لینک ها" />*/}
                    <SwitchBox
                        active={linkUnderline}
                        setActive={(value) => {
                            setLinkUnderline(value);
                            setIsSettingsChanged(true);
                        }}
                        label="نمایش خط زیر لینک ها"
                    />
                    {/*<SwitchBox active={activeItem3} setActive={setActiveItem3} label="------------" />*/}
                </div>
            </BoxContainer>
        </>
    );
};

export default FontSettingBox;
