import { Control } from "react-hook-form";

import Spinner from "../loader/Spinner";
import Button from "../ui/Button";
import FileInput from "../ui/FileInput";

type PaymentAnnouncementFormCommonInputsProps = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: Control<any>;
    isLoading: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resetField: (name: any) => void;
};

const PaymentAnnouncementFormCommonInputs: React.FC<PaymentAnnouncementFormCommonInputsProps> = ({
    isLoading,
    control,
    resetField,
}) => {
    return (
        <>
            <div className="flex flex-col gap-4">
                <FileInput control={control} name="user_attachment" onReset={resetField} repeater />
            </div>

            <Button disabled={isLoading} type="submit" disabledTooltip="دکمه غیرفعال است">
                {isLoading ? <Spinner size="md" /> : "اعلام پرداخت"}
            </Button>
        </>
    );
};
export default PaymentAnnouncementFormCommonInputs;
