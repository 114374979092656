import Skeleton from "react-loading-skeleton";

type PaypalCashPlaceholderProps = {
    isPaypal: boolean;
};

const PaypalCashPlaceholder: React.FC<PaypalCashPlaceholderProps> = ({ isPaypal }) => {
    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-4 lg:flex-row">
                <div className="w-full lg:w-7/12">
                    <Skeleton width={55} className="mb-3" />
                    <Skeleton height={40} style={{ borderRadius: "10px" }} />
                </div>
                <div className="w-full lg:w-5/12">
                    <Skeleton width={40} className="mb-3" />
                    <Skeleton height={40} style={{ borderRadius: "10px" }} />
                </div>
            </div>

            <div className="flex items-center justify-between rounded-[8px] bg-secondary p-3">
                <Skeleton width={190} />
                <Skeleton width={90} />
            </div>

            <div className={`flex flex-col gap-4 rounded-[10px] ${isPaypal ? "bg-secondary-400 p-4" : ""}`}>
                <Skeleton width={161} />
                <div className="flex gap-8">
                    <Skeleton width={62} />
                    <Skeleton width={56} />
                </div>
                {isPaypal && <div></div>}
            </div>

            <div className="flex flex-col gap-2">
                <Skeleton width={50} />
                <Skeleton height={80} style={{ borderRadius: "10px" }} />
            </div>

            <Skeleton width={320} />
            <Skeleton height={40} style={{ borderRadius: "8px" }} />
        </div>
    );
};

export default PaypalCashPlaceholder;
