import { RouteObject } from "react-router-dom";

import Invoice from "@/pages/dashboard/invoice/Invoice";

const InvoiceRoutes: RouteObject[] = [
    {
        path: "invoice/:invoiceId",
        element: <Invoice />,
    },
];

export default InvoiceRoutes;
