import { useMutation } from "@tanstack/react-query";

import gate from "@/gate";

const useOrderCalculation = () => {
    return useMutation({
        mutationFn: gate.orderCalculation,
    });
};

export default useOrderCalculation;
