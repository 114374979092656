import { yupResolver } from "@hookform/resolvers/yup";
import { ReactNode, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { InferType, object, string } from "yup";

import Spinner from "@/components/loader/Spinner";
import IconEnvelope from "@/components/svg/envelope";
import AlertBox from "@/components/ui/AlertBox";
import Button from "@/components/ui/Button";
import Input from "@/components/ui/Input";
import PageTitle from "@/components/utility/PageTitle";
import { EMAIL_REQUIRED_MESSAGE, INVALID_EMAIL_MESSAGE } from "@/constants/InavlidMessages";
import useForgotPassword from "@/hooks/queries/useForgotPassword";

const emailSchema = object({
    email: string().required(EMAIL_REQUIRED_MESSAGE).email(INVALID_EMAIL_MESSAGE),
}).required();

type EmailValidationSchema = InferType<typeof emailSchema>;

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [successMessage, setSuccessMessage] = useState<ReactNode>();

    const {
        control,
        handleSubmit,
        resetField,
        formState: { errors },
    } = useForm<EmailValidationSchema>({
        resolver: yupResolver(emailSchema),
        defaultValues: {
            email: "",
        },
    });

    const { mutate: sendForgotPassEmail, isLoading, isSuccess } = useForgotPassword();

    const authenticateHandler: SubmitHandler<EmailValidationSchema> = (data) => {
        sendForgotPassEmail({
            email: data.email,
        });
    };

    useEffect(() => {
        if (isSuccess) {
            resetField("email");
            setSuccessMessage(<AlertBox type="success" description={"ایمیل یادآوری رمز عبور برای شما ارسال شد."} />);
        }
    }, [isSuccess, resetField]);

    const back = () => {
        navigate(-1);
    };

    return (
        <>
            <PageTitle>بازیابی رمز عبور</PageTitle>
            <form onSubmit={handleSubmit(authenticateHandler)} className="w-full">
                {successMessage ?? (
                    <h2 className="mb-8 text-lg text-text-main">
                        ایمیلی که با آن در سایت ثبت نام کرده‌اید را وارد کنید.
                    </h2>
                )}
                <div className="my-12">
                    <Input
                        name="email"
                        control={control}
                        ltr
                        topTitle="ایمیل"
                        placeholder="ایمیل"
                        type="email"
                        variant="fill"
                        autoFocus
                        autoComplete="email"
                        className={`${errors.email && "border-red-500"}`}
                        addon={<IconEnvelope className="h-6 fill-gray-400" />}
                    />
                </div>
                <Button disabledTooltip="دکمه غیرفعال است" disabled={isLoading} className="w-full">
                    {isLoading ? <Spinner /> : "بازیابی رمز عبور"}
                </Button>

                <button type="button" onClick={back} className="my-6 text-text-paragraph hover:text-primary">
                    بازگشت به ورود به حساب کاربری
                </button>
            </form>
        </>
    );
};

export default ForgotPassword;
