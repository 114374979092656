import { useMemo } from "react";

import EmptyIndicator from "@/components/dashboard/main/EmptyIndicator";
import IconHouse from "@/components/svg/house";
import BoxContainer from "@/components/ui/BoxContainer";
import Breadcrumb from "@/components/ui/Breadcrumb";
import Pagination from "@/components/ui/Pagination";
import Table from "@/components/ui/Table";
import { TableTdProps } from "@/components/ui/Table/TableTd";
import PageTitle from "@/components/utility/PageTitle";
import useTickets from "@/hooks/queries/useTickets";
import useAfter from "@/hooks/useAfter";
import useQueryParams from "@/hooks/useQueryParams";
import { ticketStatus } from "@/utils/cases";

const Tickets = () => {
    const { updateQueryParams, searchParams } = useQueryParams();

    const page = Number(searchParams.get("page") ?? 1);
    const perPageValue = Number(searchParams.get("perPage") ?? 10);
    const perPage = { value: perPageValue, label: perPageValue };

    const { data: tickets, isLoading } = useTickets(page, perPage.value);

    const forcePerPage = tickets?.per_page ? Number(tickets?.per_page) : 10;

    // set force per_page once after data is loaded
    useAfter(() => {
        updateQueryParams({ perPage: forcePerPage });
    }, !!tickets);

    const tableRows = useMemo<TableTdProps[][] | undefined>(() => {
        return tickets?.list.map((ticket) => {
            const { color } = ticketStatus(ticket.locale_status);

            return [
                {
                    header: "شماره سفارش",
                    children: "#" + ticket.id,
                    link: "/panel/ticket/" + ticket.id,
                },
                {
                    header: "موضوع",
                    children: ticket.title,
                },
                {
                    header: "دپارتمان",
                    children: ticket.department,
                },
                {
                    header: "اولویت",
                    children: ticket.locale_priority,
                },
                {
                    header: "تاریخ ثبت",
                    children: ticket.created_at,
                },
                {
                    header: "وضعیت",
                    children: ticket.locale_status,
                    color: color,
                    iconColor: color,
                },
            ];
        });
    }, [tickets]);

    return (
        <>
            <PageTitle>تیکت ها</PageTitle>

            <Breadcrumb
                sections={[
                    { link: "/panel", text: "صفحه اصلی", icon: IconHouse },
                    { link: "#", text: "تیکت‌ها", active: true },
                ]}
            />
            <BoxContainer colorBox={false}>
                <div className="-mx-5 pb-14">
                    {tableRows?.length !== 0 ? (
                        <Table placeholderRows={12} loading={isLoading} rows={tableRows} className="max-sm:-mt-5" />
                    ) : (
                        <EmptyIndicator
                            message="تیکتی برای نمایش وجود ندارد"
                            linkText="ثبت اولین تیکت"
                            link="/panel/ticket/new#support"
                        />
                    )}
                </div>
            </BoxContainer>
            {!isLoading && tableRows?.length !== 0 && (
                <Pagination
                    onPerPageChange={(perPage) => updateQueryParams({ perPage })}
                    onPageChange={(page) => updateQueryParams({ page })}
                    prevPage={() => updateQueryParams({ page: page - 1 })}
                    nextPage={() => updateQueryParams({ page: page + 1 })}
                    paginationInfo={{
                        from: tickets?.from,
                        to: tickets?.to,
                        pages: tickets?.last_page,
                        count: tickets?.total,
                        perPage: perPage.value,
                        forcePerPage,
                        page,
                    }}
                />
            )}
        </>
    );
};

export default Tickets;
