import { useQueryClient } from "@tanstack/react-query";
import { motion, useAnimationControls, Variants } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";

import IconCircleExclamation from "@/components/svg/circle-exclamation";
import IconCoin from "@/components/svg/coin";
import Button from "@/components/ui/Button";
import useClubTasks from "@/hooks/queries/useClubTasks";
import useEarnPoint from "@/hooks/queries/useEarnPoint";

const DailyPoints = () => {
    const { data: clubTasks } = useClubTasks();
    const { mutateAsync: earnPoint, isLoading } = useEarnPoint();
    const queryClient = useQueryClient();
    const animationControls = useAnimationControls();
    const [totalEarnedPoints, setTotalEarnedPoints] = useState(0);

    const canEarn = clubTasks?.dailyTask?.can_earn;

    useEffect(() => {
        animationControls.start("idle");
    }, [clubTasks]);

    const earnPointHandler = async () => {
        await earnPoint("daily");
        await queryClient.refetchQueries(["club-tasks"]);
        await animationControls.start("blast");
        await animationControls.start("idle");
    };

    const iconVariants: Variants = {
        idle: {
            y: [0, 6, 0],
            transition: { duration: 1, repeat: Infinity },
        },
        blast: {
            y: [0, -60, 0, 0, 0],
            scale: [1, 2, 1, 1, 1],
            opacity: [1, 0, 0, 0, 1],
            transition: {
                duration: 1,
            },
        },
    };

    const renderDays = useMemo(() => {
        if (clubTasks) {
            const dailyTasks = clubTasks.dailyTask!.meta!;
            const eachDay = dailyTasks.eachDay;
            const todayIs = dailyTasks.toDayASDayOf;

            const dailyPoints = Object.keys(eachDay).map((key) => ({
                points: eachDay[key].points,
                earned: eachDay[key].earned,
            }));

            setTotalEarnedPoints(dailyPoints.filter((day) => day.earned).reduce((prev, curr) => prev + curr.points, 0));

            return dailyPoints.map((day, index) => {
                const isEarned = day.earned;
                const isActiveDay = todayIs === index + 1 && !isEarned;

                return (
                    <div key={index} className="flex flex-col items-center justify-center gap-3">
                        <div
                            className={`flex h-[60px] w-[40px] flex-col justify-end bg-gradient-to-t sm:h-[80px] sm:w-[50px] lg:h-[100px] lg:w-[80px] ${
                                isActiveDay ? "from-gold/20" : isEarned ? "from-success/25" : "from-secondary-100/25"
                            } to-transparent`}
                        >
                            <motion.div
                                variants={iconVariants}
                                animate={todayIs === index + 1 && !isEarned ? animationControls : undefined}
                                className={`${
                                    isActiveDay ? "mb-3 sm:mb-5" : "mb-[6px] sm:mb-3"
                                } flex flex-col items-center justify-center gap-1`}
                            >
                                <IconCoin
                                    className={`h-[16px] shrink-0 sm:h-[22px] lg:h-[35px] ${
                                        isActiveDay
                                            ? "fill-gold"
                                            : isEarned
                                            ? "fill-success"
                                            : "fill-secondary-100 brightness-95"
                                    }`}
                                />
                                <div
                                    className={`shrink-0 text-[0.85rem] lg:text-[1.14rem] ${
                                        isActiveDay
                                            ? isEarned
                                                ? "text-success"
                                                : "text-gold"
                                            : isEarned
                                            ? "text-success"
                                            : "text-text-paragraph"
                                    }`}
                                >
                                    {day.points}+
                                </div>
                            </motion.div>
                            <div
                                className={`h-[3px] w-full shrink-0 rounded-full sm:h-[5px] ${
                                    isActiveDay ? "bg-gold" : isEarned ? "bg-success" : "bg-secondary-100"
                                }`}
                            />
                        </div>
                        <span className="text-[0.85rem] text-text-main lg:text-[1.28rem]">
                            {isActiveDay ? "امروز" : `روز ${index + 1}`}
                        </span>
                    </div>
                );
            });
        }
    }, [clubTasks]);

    return (
        <>
            <div className="-mx-5 pt-6 xs:pt-10">
                <div className="border-b border-secondary-200 px-4 xs:px-10 pb-3 text-[1.42rem]">
                    {clubTasks?.dailyTask?.information.title}
                </div>
            </div>
            <div className="xs:pr-5 pt-3 text-text-paragraph">{clubTasks?.dailyTask?.information.short_description}</div>
            <div className="-mx-5 flex-1 rounded-[10px]">
                <div className="flex w-full flex-col items-center justify-center gap-4 pb-2 sm:gap-8">
                    <div className="grid w-full grid-cols-4 flex-wrap items-end justify-between gap-2 px-[20px] pb-4 pt-6 xs:flex sm:gap-5 sm:pt-10 md:px-[50px] lg:px-[80px]">
                        {clubTasks ? (
                            renderDays
                        ) : (
                            <>
                                <div className="flex flex-col items-center justify-center gap-2 sm:gap-4">
                                    <Skeleton className="aspect-square !w-[35px] sm:!w-[50px]" circle />
                                    <Skeleton className="h-[8px] !w-[30px] sm:h-[10px] sm:!w-[40px]" />
                                </div>
                                <div className="flex flex-col items-center justify-center gap-2 sm:gap-4">
                                    <Skeleton className="aspect-square !w-[35px] sm:!w-[50px]" circle />
                                    <Skeleton className="h-[8px] !w-[30px] sm:h-[10px] sm:!w-[40px]" />
                                </div>
                                <div className="flex flex-col items-center justify-center gap-2 sm:gap-4">
                                    <Skeleton className="aspect-square !w-[35px] sm:!w-[50px]" circle />
                                    <Skeleton className="h-[8px] !w-[30px] sm:h-[10px] sm:!w-[40px]" />
                                </div>
                                <div className="flex flex-col items-center justify-center gap-2 sm:gap-4">
                                    <Skeleton className="aspect-square !w-[35px] sm:!w-[50px]" circle />
                                    <Skeleton className="h-[8px] !w-[30px] sm:h-[10px] sm:!w-[40px]" />
                                </div>
                                <div className="flex flex-col items-center justify-center gap-2 sm:gap-4">
                                    <Skeleton className="aspect-square !w-[35px] sm:!w-[50px]" circle />
                                    <Skeleton className="h-[8px] !w-[30px] sm:h-[10px] sm:!w-[40px]" />
                                </div>
                                <div className="flex flex-col items-center justify-center gap-2 sm:gap-4">
                                    <Skeleton className="aspect-square !w-[35px] sm:!w-[50px]" circle />
                                    <Skeleton className="h-[8px] !w-[30px] sm:h-[10px] sm:!w-[40px]" />
                                </div>
                                <div className="flex flex-col items-center justify-center gap-2 sm:gap-4">
                                    <Skeleton className="aspect-square !w-[35px] sm:!w-[50px]" circle />
                                    <Skeleton className="h-[8px] !w-[30px] sm:h-[10px] sm:!w-[40px]" />
                                </div>
                            </>
                        )}
                    </div>
                    <div className="flex w-full flex-col items-center justify-between gap-4 px-8">
                        <div className="flex gap-2 text-gold">
                            <IconCircleExclamation className="h-[16px] fill-gold sm:h-[20px]" />
                            {clubTasks ? (
                                <span className="brightness-110 max-sm:text-[0.71rem]">
                                    {clubTasks?.dailyTask?.information.description}
                                </span>
                            ) : (
                                <Skeleton width={220} />
                            )}
                        </div>
                        <div className="flex items-center justify-center gap-4">
                            <Button
                                className="w-[230px] max-sm:text-[1rem]"
                                onClick={earnPointHandler}
                                loading={isLoading}
                                disabled={isLoading || !canEarn}
                            >
                                {canEarn ? "دریافت امتیاز امروز" : `تا حالا ${totalEarnedPoints} امتیاز گرفته‌اید!`}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DailyPoints;
