import axios, { AxiosProgressEvent, AxiosRequestConfig, Method } from "axios";
import Cookies from "js-cookie";

import { UserData } from "@/interfaces/dashboard/layout/panel/panel";

import { clearOnLogout, isEmpty } from "../utils/index";

const client = axios.create({
    baseURL: import.meta.env.VITE_BASE_URL,
});

let needToCancelRequest = false;

client.defaults.timeout = 30000;
client.interceptors.response.use(
    (config) => {
        const controller = new AbortController();

        if (needToCancelRequest) {
            controller.abort();
        }

        return {
            ...config,
            signal: controller.signal,
        };
    },
    (error: any) => {
        return Promise.reject(error);
    },
);

const call = async <T>(method: Method, url: string, data: any = {}): Promise<T> => {
    const accessToken = Cookies.get("access_token");

    const userData = localStorage.getItem("user_data");
    let userId = 0;

    if (userData) {
        const user = JSON.parse(userData) as UserData;
        userId = user?.data?.user?.id;
    }

    const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-scope": "avalpardakht-web-application",
        "X-Requested-With": "XMLHttpRequest",
        "x-user-id": userId,
    };

    if (accessToken) {
        client.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    }

    const request: AxiosRequestConfig = { headers, method, url };

    if (!isEmpty(data)) {
        if (method === "get") {
            request.params = data;
        } else {
            request.data = data;
        }
    }

    try {
        const response = await client(request);
        return Promise.resolve(response.data);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        if (error.response && error.response.status == 401) {
            if (
                location.pathname != "/auth/login" &&
                location.pathname != "/auth/forgot-password" &&
                location.pathname != "/panel/login-with-token" &&
                !location.pathname.startsWith("/broker") &&
                !location.pathname.startsWith("/panel/invoice/") &&
                location.pathname != "/auth/2fa-validate" &&
                location.pathname != "/auth/register"
            ) {
                clearOnLogout();
                window.location.href = "/auth/login?redirect=" + location.pathname + location.search;
            }
        }

        return Promise.reject(error);
    }
};

const file = async (
    url: string,
    data: FormData | object,
    onUploadProgress?: ((progressEvent: AxiosProgressEvent) => void) | undefined,
) => {
    client.defaults.timeout = 120000;
    try {
        const userData = localStorage.getItem("user_data");
        let userId = 0;

        if (userData) {
            const user = JSON.parse(userData);
            userId = user.data.user.id;
        }

        const headers = {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            "x-scope": "avalpardakht-web-application",
            "X-Requested-With": "XMLHttpRequest",
            "x-user-id": userId,
        };

        const response = await client({
            url,
            data,
            method: "post",
            headers,
            onUploadProgress,
        });

        return Promise.resolve(response.data);
    } catch (error: any) {
        return Promise.reject(error.response);
    }
};

export default {
    delete: <T, D = any>(url: string, data?: D | null) => call<T>("delete", url, data),
    get: <T, D = any>(url: string, data?: D | null) => call<T>("get", url, data),
    patch: <T, D = any>(url: string, data?: D | null) => call<T>("patch", url, data),
    post: <T, D = any>(url: string, data?: D | null) => call<T>("post", url, data),
    put: <T, D = any>(url: string, data?: D | null) => call<T>("put", url, data),
    file: (
        url: string,
        data: object | FormData,
        onUploadProgress?: ((progressEvent: AxiosProgressEvent) => void) | undefined,
    ) => file(url, data, onUploadProgress),
};
