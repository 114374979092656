import { useMemo } from "react";

import EmptyIndicator from "@/components/dashboard/main/EmptyIndicator";
import BoxContainer from "@/components/ui/BoxContainer";
import Pagination from "@/components/ui/Pagination";
import Table from "@/components/ui/Table";
import { TableTdProps } from "@/components/ui/Table/TableTd";
import PageTitle from "@/components/utility/PageTitle";
import AddFund from "@/components/wallet/Addfound";
import WalletBox from "@/components/wallet/WalletBox";
import Withdraw from "@/components/wallet/Withdraw";
import useTransactions from "@/hooks/queries/useTransactions";
import useAfter from "@/hooks/useAfter";
import useQueryParams from "@/hooks/useQueryParams";
import { formatNumber } from "@/utils";
import { transactionStatus } from "@/utils/cases";

const Wallet = () => {
    const { updateQueryParams, searchParams } = useQueryParams();

    const page = Number(searchParams.get("page") ?? 1);
    const perPageValue = Number(searchParams.get("perPage") ?? 10);
    const perPage = { value: perPageValue, label: perPageValue };

    const { data: walletData, isLoading } = useTransactions(page, perPage.value);

    const forcePerPage = walletData?.transactions.per_page ? Number(walletData?.transactions.per_page) : 1;

    // set force per_page once after data is loaded
    useAfter(() => {
        updateQueryParams({ perPage: forcePerPage });
    }, !!walletData);

    const tableRows = useMemo<TableTdProps[][] | undefined>(() => {
        return walletData?.transactions.data.map((transaction) => {
            const { icon, color } = transactionStatus(transaction.status);

            return [
                {
                    header: "شماره",
                    children: "#" + transaction.id,
                },
                {
                    header: "تراکنش",
                    children: transaction.locale_type,
                },
                {
                    header: "توضیحات",
                    children: (
                        <div className="max-w-[200px]">
                            {/* {transaction.is_immediate ? (
                                <>
                                    <span className="text-warning">تسویه آنی</span>
                                    <br />
                                </>
                            ) : (
                                ""
                            )}{" "} */}
                            {transaction.short_admin_desc ?? "-"}
                        </div>
                    ),
                    detail:
                        transaction.short_admin_desc !== (transaction?.transaction_desc || transaction?.admin_desc)
                            ? transaction?.transaction_desc || transaction?.admin_desc
                            : false,
                },
                {
                    header: "مبلغ (تومان)",
                    children: (
                        <>
                            {formatNumber(transaction.amount)}
                            {/* {transaction.is_immediate ? (
                                <>
                                    <br />
                                    <span className="text-warning">{transaction.immediate_wage} کارمزد</span>
                                </>
                            ) : (
                                ""
                            )} */}
                        </>
                    ),
                },
                {
                    header: "موجودی بعد تراکنش",
                    children: String(transaction.bc) === "-" ? "-" : formatNumber(transaction.bc),
                },
                {
                    header: "تاریخ",
                    children: transaction.created_at_jalali,
                },
                {
                    header: "وضعیت",
                    children: transaction.locale_status,
                    color: color,
                    icon: icon,
                    iconColor: color,
                },
            ];
        });
    }, [walletData]);

    const withdrawDisabledMessages = useMemo(() => {
        if (walletData?.user.can_withdraw !== 1) {
            return "جهت برداشت وجه با پشتیبانی در تماس باشید";
        } else if (walletData?.user.balance <= 0) {
            return "موجودی حساب شما قابل برداشت نیست";
        }
    }, [walletData]);

    const addFundDisabledMessages = useMemo(() => {
        if (walletData?.user.can_addfund !== 1) {
            return "جهت افزایش وجه با پشتیبانی در تماس باشید";
        }
    }, [walletData]);

    return (
        <div className="min-h-screen w-full">
            <PageTitle>مدیریت مالی</PageTitle>

            <div className="flex h-[250px] justify-between gap-5 max-sm:flex-col sm:h-[80px] md:h-[100px] lg:h-[125px]">
                <WalletBox balance={walletData?.user.balance} />
                <div className="flex grow justify-between gap-5">
                    <AddFund
                        disabledMessage={addFundDisabledMessages}
                        disabled={walletData?.user.can_addfund !== 1 || isLoading}
                    />
                    <Withdraw
                        disabledMessage={withdrawDisabledMessages}
                        disabled={walletData?.user.can_withdraw !== 1 || isLoading || walletData?.user.balance <= 0}
                    />
                </div>
            </div>

            <div className="mt-10">
                <BoxContainer colorBox={false}>
                    <div className="-mx-5 pb-14">
                        {tableRows?.length !== 0 ? (
                            <Table placeholderRows={12} loading={isLoading} rows={tableRows} />
                        ) : (
                            <EmptyIndicator message="تراکنشی برای نمایش وجود ندارد" linkText="ثبت اولین سفارش" />
                        )}
                    </div>
                </BoxContainer>
                {!isLoading && tableRows?.length !== 0 && (
                    <Pagination
                        onPerPageChange={(perPage) => updateQueryParams({ perPage })}
                        onPageChange={(page) => updateQueryParams({ page })}
                        nextPage={() => updateQueryParams({ page: page + 1 })}
                        prevPage={() => updateQueryParams({ page: page - 1 })}
                        paginationInfo={{
                            from: walletData?.transactions.from ?? 1,
                            to: walletData?.transactions.to ?? 1,
                            pages: walletData?.transactions.last_page ?? 1,
                            count: walletData?.transactions.total ?? 1,
                            perPage: perPage.value,
                            forcePerPage,
                            page,
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default Wallet;
