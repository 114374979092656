import { useContext } from "react";

import useTheme from "@/hooks/useTheme";
import { Context, ContextType } from "@/store/Context-store";

import NotFound from "./NotFound";
import ServerError from "./ServerError";
import TooManyRequest from "./TooManyRequest";
// import Unauthorized from "./Unauthorized";
import UnknownError from "./UnknownError";

const ErrorPage = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { theme } = useTheme();

    const { error } = useContext(Context) as ContextType;

    if (error) {
        switch (error.response?.status) {
            case 403:
                return <NotFound />;
            // return <Unauthorized />;
            case 429:
                return <TooManyRequest />;
            case 501:
            case 502:
            case 503:
            case 504:
                return <ServerError />;
        }
    }

    return <UnknownError />;
};

export default ErrorPage;
