import classNames from "classnames";

import IconMedal from "@/components/svg/medal";
import useUser from "@/hooks/queries/useUser";

type LevelProps = {
    className?: string;
};

const Level = ({ className }: LevelProps) => {
    const { data: userData } = useUser();

    const user = userData?.data?.user;

    const levelClasses = classNames({
        "flex items-center gap-2": 1,
        "text-gold": user?.level == "A",
        "text-silver": user?.level == "B",
        "text-bronze": user?.level == "C",
    });

    const levelIconClasses = classNames(
        {
            "h-[14px] xs:h-[22px]": 1,
            "fill-gold": user?.level == "A",
            "fill-[#C0C0C0]": user?.level == "B",
            "fill-[#CD7F32]": user?.level == "C",
        },
        className,
    );

    return (
        <div className={levelClasses}>
            <span className="whitespace-nowrap text-[0.85rem] xs:translate-y-px xs:text-[1rem]">
                {user?.level == "A" ? "سطح طلایی" : user?.level == "B" ? "سطح نقره‌ای" : "سطح برنزی"}
            </span>
            <IconMedal className={levelIconClasses} />
        </div>
    );
};

export default Level;
