import { useEffect, useState } from "react";

import BoxContainer from "./ui/BoxContainer";
import Button from "./ui/Button";

type UpdatePromptType = {
    handleUpdate: () => void;
};

const UpdatePrompt: React.FC<UpdatePromptType> = ({ handleUpdate }) => {
    const [countdown, setCountdown] = useState<number>(30);

    // const startCountdown = () => {
    //     const timer = setInterval(() => {
    //         setCountdown((prev) => {
    //             if (prev == 0) {
    //                 clearInterval(timer);
    //             }

    //             return prev > 0 ? prev - 1 : 0;
    //         });
    //     }, 1000);
    // };

    useEffect(() => {
        // startCountdown();
        setTimeout(() => {
            handleUpdate();
        }, 1800000);
        
    }, [handleUpdate]);

    return <></>;

    // return (
    //     <BoxContainer className="!fixed left-1/2 z-[10000] w-11/12 -translate-x-1/2 bg-secondary-250 p-2 max-sm:top-10 sm:bottom-10 sm:p-4 md:w-10/12 lg:w-1/2">
    //         <div className="mb-4 flex justify-between gap-8 border-b pb-2">
    //             <span className="text-2xl font-semibold text-text-main">توجه!</span>
    //         </div>
    //         <div className="flex flex-col items-center justify-between gap-4 sm:flex-row sm:gap-8">
    //             <p className="text-text-main">
    //                 داشبورد کاربری اول پرداخت بروز شد!
    //                 <br />
    //                 <span className="text-sm text-text-paragraph">
    //                     صفحه بعد از
    //                     <span className="inline-block w-[24px] text-center font-bold text-text-main">{countdown}</span>
    //                     ثانیه رفرش و آپدیت میشه!
    //                 </span>
    //             </p>
    //             <Button className="flex-shrink-0" onClick={handleUpdate}>
    //                 همین الان آپدیت کن!
    //             </Button>
    //         </div>
    //     </BoxContainer>
    // );
};

export default UpdatePrompt;
