import { Link } from "react-router-dom";

import { orderStatus } from "@/utils/cases";

import AlertBox from "../ui/AlertBox";

type OrderAlertProps = {
    status:
        | "در انتظار انجام"
        | "در انتظار پرداخت"
        | "در حال انجام"
        | "انجام شده"
        | "معلق شده"
        | "کنسل شده"
        | "در انتظار بررسی کارشناس"
        | "در انتظار تاییدیه ویدیویی"
        | "درحال انجام توسط کارگزار";
};

const OrderAlert: React.FC<OrderAlertProps> = ({ status }) => {
    const alerts = [
        {
            title: "در انتظار پرداخت",
            description: "کار شناسان ما در حال بررسی و پیگیری ادامه روند انجام سفارش هستند. از صبوری شما متشکریم.",
        },
        {
            title: "در انتظار انجام",
            description: "کار شناسان ما در حال بررسی و پیگیری ادامه روند انجام سفارش هستند. از صبوری شما متشکریم.",
        },
        {
            title: "در حال انجام",
            description: "کار شناسان ما در حال بررسی و پیگیری ادامه روند انجام سفارش هستند. از صبوری شما متشکریم.",
        },
        {
            title: "انجام شده",
            description: "سفارش شما با موفقیت انجام شده است.",
        },
        {
            title: "معلق شده",
            description: "سفارش شما معلق شده است، لطفا با کارشناسان ما همکاری لازم را انجام دهید.",
        },
        {
            title: "کنسل شده",
            description: "سفارش شما کنسل شده و قابل پیگیری نیست، در صورت نیاز به این سفارش  مجددا آن را ثبت کنید.",
        },
        {
            title: "در انتظار بررسی کارشناس",
            description: "",
        },
        {
            title: "در انتظار تاییدیه ویدیویی",
            description: (
                <>
                    سفارش شما معلق شده است، لطفا ابتدا{" "}
                    <Link to="/panel/authentication" className="text-primary hover:text-primary">
                        درخواست تایید ویدیویی
                    </Link>{" "}
                    بدهید. پس از تایید ویدیوی ارسالی، سفارش شما انجام خواهد شد.
                </>
            ),
        },
        {
            title: "درحال انجام توسط کارگزار",
            description: "",
        },
    ];

    const alert = alerts.find((alert) => {
        return alert.title == status;
    });

    if (alert?.description) {
        return (
            <AlertBox
                customColor={orderStatus(status).color}
                description={alert?.description || ""}
                title={`سفارش در وضعیت "${status}" قرار دارد`}
            />
        );
    } else {
        return <></>;
    }
};
export default OrderAlert;
