import { useMemo } from "react";
import { Link } from "react-router-dom";

import IconChevronLeft from "@/components/svg/chevron-left";

type Section = {
    hide?: boolean;
    active?: boolean;
    text: string;
    link?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon?: any;
};

type BreadcrumbProps = {
    sections: Section[];
    className?: string;
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({ sections, className }) => {
    const renderSections = useMemo(() => {
        return sections
            .filter((section) => !section.hide)
            .map((section) => {
                return (
                    <div className="group flex items-center gap-1 xs:gap-2 " key={section.text}>
                        <div
                            className={`rounded-full px-[10px] py-1 transition-colors hover:bg-primary hover:bg-opacity-10 sm:px-3`}
                        >
                            {section.icon && (
                                <section.icon
                                    className={`inline-block h-[10px] pl-2 transition-colors group-hover:fill-primary xs:h-[14px] ${
                                        section.active ? "fill-primary" : "fill-text-paragraph"
                                    }`}
                                />
                            )}

                            {section.link ? (
                                <Link
                                    to={section.link}
                                    className={`transition-colors group-hover:text-primary max-sm:text-[0.85rem] max-xs:text-[0.85rem] ${
                                        section.active ? "text-primary" : "text-text-paragraph"
                                    }`}
                                >
                                    {section.text}
                                </Link>
                            ) : (
                                <div
                                    className={`inline-block transition-colors group-hover:text-primary max-sm:text-[0.85rem] max-xs:text-[0.85rem] ${
                                        section.active ? "text-primary" : "text-text-paragraph"
                                    }`}
                                >
                                    {section.text}
                                </div>
                            )}
                        </div>
                        <span className="group-last:hidden">
                            <IconChevronLeft className="inline-block h-[10px] fill-secondary-100 xs:h-[14px]" />
                        </span>
                    </div>
                );
            });
    }, [sections]);

    return (
        <div
            className={`flex w-full items-center justify-start overflow-x-auto whitespace-nowrap py-2 pb-[18px] max-xs:mx-auto max-xs:w-[calc(100vw-24px)] xs:py-4 sm:px-4 sm:pb-6 sm:pt-3 ${className}`}
        >
            {renderSections}
        </div>
    );
};

export default Breadcrumb;
