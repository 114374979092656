import { ShowOrderResponse } from "@/interfaces/dashboard/order/order";

import BoxContainer from "../ui/BoxContainer";
import Title from "../ui/Title";

type OrderTimelineProps = {
    adminDescription: ShowOrderResponse["data"]["admin_description"];
    userLogs: [] | ShowOrderResponse["data"]["user_logs"];
};

const OrderTimeline: React.FC<OrderTimelineProps> = ({ adminDescription, userLogs }) => {
    if (userLogs.length) {
        return (
            <BoxContainer className="mt-4" colorBox={false}>
                <Title title="توضیحات کارشناس" containerClasses="pb-4 -mx-5 -my-5" />

                <div className="flex flex-col gap-4 whitespace-pre-wrap pt-4">
                    {userLogs.length > 0 && (
                        <table className="flex flex-col gap-4">
                            <thead className="flex flex-col gap-4">
                                <tr className="flex gap-4">
                                    <th className="flex-[2] text-text-main">توضیح</th>
                                    <th className="flex-1 flex-shrink-0 text-text-main">تاریخ</th>
                                </tr>
                            </thead>
                            <tbody className="flex flex-col gap-4">
                                {userLogs
                                    .sort((a, b) => (a.id <= b.id ? 1 : -1))
                                    .map((log) => {
                                        return (
                                            <tr className="flex gap-4" key={log.id}>
                                                {log.admin_name !== "" ? (
                                                    <td
                                                        data-tooltip-id="main-tooltip"
                                                        data-tooltip-html={`توسط ${log.admin_name}`}
                                                        data-tooltip-place={"top"}
                                                        className="flex-[2]"
                                                    >
                                                        {log.description}
                                                    </td>
                                                ) : (
                                                    <td
                                                        className="flex-[2]"
                                                        dangerouslySetInnerHTML={{ __html: log.description }}
                                                    />
                                                )}
                                                <td className="flex-1 flex-shrink-0">{log.created_at}</td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    )}
                </div>
            </BoxContainer>
        );
    }

    if (adminDescription) {
        return (
            <BoxContainer className="mt-4" colorBox={false}>
                <Title title="توضیحات کارشناس" containerClasses="pb-4 -mx-5 -my-5" />
                <div
                    className="flex flex-col gap-4 whitespace-pre-wrap pt-4"
                    dangerouslySetInnerHTML={{ __html: adminDescription }}
                />
            </BoxContainer>
        );
    }

    return <></>;
};
export default OrderTimeline;
