import { useContext } from "react";

import SwitchBox from "@/components/ui/SwitchBox";
import useAnimeSetting from "@/hooks/useAnimeSetting";
import { Context, ContextType } from "@/store/Context-store";

const AnimationSetting = () => {
    const { setIsSettingsChanged } = useContext(Context) as ContextType;
    const { isAnimationEnabled, setAnimationStatus } = useAnimeSetting();
    console.log(isAnimationEnabled);
    return (
        <div className="mt-6 flex flex-col items-center justify-evenly gap-8">
            <SwitchBox
                active={isAnimationEnabled}
                setActive={(state) => {
                    setAnimationStatus(state);
                    setIsSettingsChanged(true);
                }}
                label="انیمیشن ها"
            />
        </div>
    );
};

export default AnimationSetting;
