import { FC } from "react";

type CustomError = {
    errors: string[][];
};

const BuildError: FC<CustomError> = ({ errors }) => {
    return (
        <>
            {errors.flatMap((error, index) => {
                return <div className="font-[Vazirmatn]" key={index} dangerouslySetInnerHTML={{ __html: error }} />;
            })}
        </>
    );
};

export default BuildError;
