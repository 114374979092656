import { useMutation } from "@tanstack/react-query";
import { UseFormSetFocus } from "react-hook-form";
import toast from "react-hot-toast";

import ToastError from "@/components/error/ToastError";
import gate from "@/gate";

type ResendTwoFaValidateRequest = {
    email: string;
};

const useResendTwoFaValidate = (
    startCountdown: () => void,
    setResendingCode: (value: boolean) => void,
    setFocus: UseFormSetFocus<{ code: string }>,
) => {
    return {
        ...useMutation<unknown, Error, ResendTwoFaValidateRequest>({
            mutationFn: gate.resendToken,
            onSuccess(data) {
                startCountdown();
                setFocus("code");
                if (data) {
                    toast.success("کد احراز هویت دوباره برای شما ارسال شد");
                } else {
                    ToastError(new Error("خطایی رخ داده است"));
                }
            },
            onError(error) {
                setResendingCode(false);
                ToastError(error);
            },
        }),
    };
};

export default useResendTwoFaValidate;
