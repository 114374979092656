import React, { useContext, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import ScrollToTop from "@/components/ScrollToTop";
import OfflineOverlay from "@/components/ui/OfflineOverlay";
import useAuth from "@/hooks/useAuth";
import { Context, ContextType } from "@/store/Context-store";

import DashboardHeader from "./DashboardHeader";
import classes from "./DashboardLayout.module.scss";
import DashboardSidebar from "./DashboardSidebar";

const DashboardLayout: React.FC = () => {
    const { token } = useAuth();
    const { pathname, search } = useLocation();
    const navigate = useNavigate();
    const { setError } = useContext(Context) as ContextType;

    useEffect(() => {
        if (!token && !pathname.includes("/login-with-token")) {
            setError(undefined);
            navigate(`/auth/login?redirect=${pathname}${search}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, token]);

    return (
        <>
            <ScrollToTop />
            <div className={`flex ${classes.dashboard}`}>
                <DashboardSidebar />
                <div
                    className={`flex flex-grow flex-col items-center justify-center`}
                >
                    <div className="relative w-full max-w-[1250px] flex-grow">
                        <DashboardHeader />
                        <div className="flex flex-col">
                            <div className="min-h-full flex-grow px-3 py-[30px] xs:px-8">
                                <Outlet />
                                <OfflineOverlay />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DashboardLayout;
