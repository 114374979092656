import { useQuery } from "@tanstack/react-query";

import gate from "@/gate";
import { ServiceType } from "@/interfaces/dashboard/order/order";

const useGetOrderServiceInstruction = (serviceType: string | undefined) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return useQuery<any, Error, ServiceType>(
        [serviceType + "_instruction"],
        gate.getServiceInstruction.bind(null, serviceType || ""),
        {
            select: (data) => {
                return data.service;
            },
            refetchOnWindowFocus: false,
        },
    );
};

export default useGetOrderServiceInstruction;
