import { useMemo } from "react";

import Select from "@/components/ui/Select";

type PerPageProps = {
    activePerPage: number;
    onPerPageChange: (perPage: string) => void;
    count: number;
    forcePerPage: number;
    pages: number;
    onPageChange: (page: string) => void;
};

const PerPage: React.FC<PerPageProps> = ({
    pages,
    activePerPage,
    onPerPageChange,
    count,
    forcePerPage,
    onPageChange,
}) => {
    const selectOptions = useMemo(() => {
        const shouldOptionDisable = (value: number) => count < value;

        const options = [
            {
                value: 10,
                label: 10,
                isDisabled: shouldOptionDisable(10),
            },
            {
                value: 20,
                label: 20,
                isDisabled: shouldOptionDisable(20),
            },
            {
                value: 30,
                label: 30,
                isDisabled: shouldOptionDisable(30),
            },
            {
                value: 40,
                label: 40,
                isDisabled: shouldOptionDisable(40),
            },
            {
                value: 50,
                label: 50,
                isDisabled: shouldOptionDisable(50),
            },
        ];

        const isDuplicated = options.find((opt) => opt.value === forcePerPage);

        if (!isDuplicated) {
            return [
                {
                    value: forcePerPage,
                    label: forcePerPage,
                },
                ...options,
            ];
        }

        return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forcePerPage, pages]);

    return (
        <Select
            variant="fill"
            defaultValue={{
                value: activePerPage,
                label: activePerPage,
            }}
            onChange={(newValue: any) => {
                onPerPageChange(String(newValue.value));
                onPageChange("1");
            }}
            options={selectOptions.sort((a, b) => a.value - b.value)}
        />
    );
};

export default PerPage;
