import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import IconCircleCheckDuotone from "@/components/svg/circle-check-duotone";
import IconCircleXmarkDuotone from "@/components/svg/circle-xmark-duotone";
import IconSpinnerThirdDuotone from "@/components/svg/spinner-third-duotone";
import PageTitle from "@/components/utility/PageTitle";
import useLoginWithToken from "@/hooks/queries/useLoginWithToken";
import useAuth from "@/hooks/useAuth";
import {sleep} from "@/utils";

const LoginWithToken = () => {
    const { setAuthToken, logout } = useAuth();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { mutateAsync: loginWithToken } = useLoginWithToken();
    const [loginStatus, setLoginStatus] = useState("idle");
    const isCalledOnce = useRef(false);

    const uuid = searchParams.get("uuid");
    const redirect = searchParams.get("redirect_to") || "/panel";

    useEffect(() => {
        if (!isCalledOnce.current && loginStatus !== "success") {
            isCalledOnce.current = true;
            login();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    const login = async () => {
        try {
            if (uuid) {
                const response = await loginWithToken({ uuid });

                setAuthToken(response as string);
                setLoginStatus("success");
                setTimeout(() => {
                    window.location.href = redirect;
                }, 100);
            } else {
                navigate("/auth/login");
            }
        } catch (e) {
            setLoginStatus("error");
            logout(true);
        }
    };

    return (
        <div className="flex min-h-screen w-full items-center justify-center bg-secondary">
            <PageTitle>ورود با توکن</PageTitle>

            {uuid && (
                <div className="flex h-full w-full flex-col items-center justify-center gap-8 text-[1.78rem] text-text-main sm:gap-10 sm:text-[2.5rem]">
                    {loginStatus === "idle" && (
                        <IconSpinnerThirdDuotone className="h-[60px] animate-spin fill-zinc-500 brightness-125 sm:h-[80px]" />
                    )}
                    {loginStatus === "success" && (
                        <IconCircleCheckDuotone className="h-[60px] fill-success sm:h-[80px]" />
                    )}
                    {loginStatus === "error" && <IconCircleXmarkDuotone className="h-[60px] fill-error sm:h-[80px]" />}

                    {loginStatus === "idle" && <span className="text-zinc-400">در حال لاگین به پنل</span>}
                    {loginStatus === "success" && (
                        <span className="text-success brightness-150">با موفقیت وارد شدید</span>
                    )}
                    {loginStatus === "error" && <span className="text-error brightness-150">مشکلی پیش آمده است</span>}
                </div>
            )}
        </div>
    );
};

export default LoginWithToken;
