// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Num2persian from "num2persian";
import { useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import IconArrowDownToBracket from "@/components/svg/arrow-down-to-bracket";
import Button from "@/components/ui/Button";
import Input from "@/components/ui/Input";
import Modal from "@/components/ui/Modal";
import WalletActionButton from "@/components/wallet/WalletActionButton";
import useAddFund from "@/hooks/queries/useAddFund";
import useTransactions from "@/hooks/queries/useTransactions";
import { formatNumber } from "@/utils";

type AddFundProps = {
    disabled: boolean;
    disabledMessage?: string;
};

type AddFundAmountSchema = {
    amount: number;
};

const AddFund: React.FC<AddFundProps> = ({ disabled, disabledMessage }) => {
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);
    const { mutateAsync: addFund, isLoading } = useAddFund();
    const { data: walletData } = useTransactions();

    const walletBalance = walletData ? walletData.user.balance : 1;

    const {
        reset,
        setValue,
        control,
        trigger,
        formState: { isValid },
        handleSubmit,
    } = useForm<AddFundAmountSchema>({
        shouldUnregister: false,
        reValidateMode: "onChange",
        mode: "onChange",
        defaultValues: {
            amount: 10000,
        },
    });

    const amount = useWatch({ control, name: "amount" });
    const onRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.target.value);
        setValue("amount", value > 0 ? (value * Number(walletData?.add_fund_max)) / 100 : 1000);
        trigger();
    };

    const onSubmit: SubmitHandler<AddFundAmountSchema> = async (data) => {
        const response = await addFund({ price: data.amount });
        reset();
        setIsOpen(false);
        navigate(`/panel/invoice/${response.transaction.id}`, { state: "ADDFUND" });
    };

    return (
        <>
            <WalletActionButton
                onClick={() => setIsOpen(true)}
                disabled={disabled}
                disabledMessage={disabledMessage}
                color="success"
                icon={IconArrowDownToBracket}
                text="افزایش موجودی"
            />

            <Modal
                show={isOpen}
                onClose={() => {
                    reset();
                    setIsOpen(false);
                }}
                title="افزایش موجودی"
                className="max-w-[500px]"
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex items-center justify-between rounded-[10px] bg-secondary-200 contrast:border contrast:bg-transparent p-4 text-text-main">
                        <span>موجودی فعلی کیف پول</span>
                        <div className="flex items-center justify-end gap-2">
                            <span className="text-primary">{formatNumber(walletBalance)}</span>
                            <span>تومان</span>
                        </div>
                    </div>

                    <div className="w-full pb-8 pt-16  text-center text-[3.21rem] text-success drop-shadow-success">
                        {formatNumber(amount > 0 ? amount : 1000)}+
                    </div>

                    <div className="w-full pt-10">
                        <div className="flex w-full flex-col items-center justify-center pb-8 max-[520px]:hidden">
                            <input
                                onChange={onRangeChange}
                                dir="ltr"
                                type="range"
                                className="custom-range-slider my-4"
                                step={0.5}
                                defaultValue={0.5}
                            />

                            <div className="flex w-full flex-row-reverse items-center justify-between gap-4 py-3">
                                <div className="flex-1 text-left">{formatNumber(Number(walletData?.add_fund_min))}</div>
                                <div className="flex-1 text-center">
                                    {formatNumber(Number(walletData?.add_fund_max) / 2)}
                                </div>
                                <div className="flex-1 text-right">
                                    {formatNumber(Number(walletData?.add_fund_max))}
                                </div>
                            </div>
                        </div>

                        <Input
                            ltr
                            autoFocus
                            control={control}
                            type="number"
                            name="amount"
                            variant="fill"
                            topTitle="مقدار واریز را وارد کنید"
                            step="1"
                            min="0"
                            rules={{
                                required: "لطفا مقدار واریز را وارد کنید",
                                validate: (value: string) => {
                                    const parsedValue = Number(value);
                                    if (
                                        parsedValue >= Number(walletData?.add_fund_min) &&
                                        parsedValue <= Number(walletData?.add_fund_max)
                                    )
                                        return true;
                                    return `مقدار واریز باید بین ${Number(walletData?.add_fund_max)} تا ${Number(
                                        walletData?.add_fund_min,
                                    )} تومان باشد`;
                                },
                            }}
                        />
                        <div className="px-4 pt-3 text-[0.85rem] text-text-paragraph">{Num2persian(amount)} تومان</div>

                        <Button
                            type="submit"
                            disabled={!isValid || isLoading}
                            disabledTooltip="دکمه غیرفعال است"
                            className="mt-12 w-full"
                            loading={isLoading}
                        >
                            افزایش موجودی
                        </Button>
                        <div className="mt-1 flex items-center justify-between">
                            <div className="whitespace-nowrap px-3 pt-3 text-text-paragraph">
                                موجودی بعد از افزایش :
                                <span className="text-primary">
                                    {formatNumber(
                                        walletBalance + Number(amount) < 0 ? 0 : walletBalance + Number(amount),
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>
        </>
    );
};

export default AddFund;
