import {useMutation} from "@tanstack/react-query";

import ToastError from "@/components/error/ToastError";
import gate from "@/gate";

const useVoucher = () => {
    return useMutation({
        mutationFn: gate.voucher,
        onError: (error: Error) => {
            ToastError(error);
        },
    });
}

export default useVoucher;