import { useQuery } from "@tanstack/react-query";

import gate from "@/gate";

const useComments = (page = 1, perPage = 10) => {
    return useQuery(["comments", page, perPage], () => gate.getComments(page, perPage), {
        select: (data) => {
            return {
                pagination: {
                    ...data,
                    data: undefined,
                },
                comments: data.data,
            };
        },
    });
};

export default useComments;
