type AvatarProps = {
    className?: string;
    picture: string;
    id?: string | number;
};

const Avatar = ({ picture, className, id }: AvatarProps) => {
    return (
        <div
            className={`relative aspect-square w-[40px] justify-self-center overflow-hidden rounded-full bg-secondary-250 dark:bg-secondary-200 ${className}`}
        >
            <img
                src={picture}
                className="absolute top-0 h-full w-full object-contain"
                id={`avatar-${id}`}
                alt={`avatar-${id}`}
            />
        </div>
    );
};

export default Avatar;
