import { useMutation } from "@tanstack/react-query";

import ToastError from "@/components/error/ToastError";
import gate from "@/gate";

const useChange2FA = () => {
    return useMutation({
        mutationFn: gate.change2FA,
        onError: (error: Error) => {
            ToastError(error);
        },
    });
};

export default useChange2FA;
