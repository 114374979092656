import { useMemo } from "react";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { SubmitHandler, useForm } from "react-hook-form";
import { DateObject } from "react-multi-date-picker";
import { useLocation, useNavigate } from "react-router-dom";

import CreditCard from "@/components/profile/credit-cards/CreditCard";
import AlertBox from "@/components/ui/AlertBox";
import Button from "@/components/ui/Button";
import Description from "@/components/ui/Description";
import Input from "@/components/ui/Input";
import useBankCards from "@/hooks/queries/useBankCards";
import useUpdateBank from "@/hooks/queries/useUpdateBank";
import useUpdateUser from "@/hooks/queries/useUpdateUser";
import useUser from "@/hooks/queries/useUser";
import useUploadHandler from "@/hooks/useUploadHandler";
import { scrollToTop } from "@/utils";

import SlideSection from "../SlideSection";

type BasicAuthenticationProps = {
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
};

type BasicAuthSchema = {
    name: string;
    card_number: string;
    account_number: string;
    iban: string;
    birthday: DateObject;
    national_code: string;
    credit_image: File | null;
};

const BasicAuthentication: React.FC<BasicAuthenticationProps> = ({ setActiveIndex }) => {
    const { data: user, refetch: refetchUserData } = useUser();
    const { data: bankCards, refetch: refetchBanks } = useBankCards();

    const { state } = useLocation();
    const navigate = useNavigate();

    const isBankConfirmed = user.data.verificationSettings.bank_card_verification_status === "CONFIRMED";
    const isBankPending = user.data.verificationSettings.bank_card_verification_status === "PENDING";

    const autoGetBankData = user.data.verificationSettings.auto_get_bank_data;

    const shouldRenderBankForm = useMemo(() => {
        const bankData: { code?: string; status: string } = {
            code: "",
            status: user.data.verificationSettings.bank_card_verification_status,
        };

        const isOldUserBankExist = bankCards?.main_account;

        if (isBankConfirmed) {
            const bank = bankCards?.cards.find((bank) => bank.status === "CONFIRMED");
            if (bank) {
                bankData.code = bank?.card_number;
                return bankData;
            }

            if (isOldUserBankExist && isOldUserBankExist.status === "CONFIRMED") {
                bankData.code = isOldUserBankExist.value;
                return bankData;
            }

            return bankData;
        } else if (isBankPending) {
            const bank = bankCards?.cards.find((bank) => bank.status === "PENDING");
            if (bank) {
                bankData.code = bank?.card_number;
                return bankData;
            }

            if (isOldUserBankExist && isOldUserBankExist.status === "PENDING") {
                bankData.code = isOldUserBankExist.value;
                return bankData;
            }
        }

        return null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBankPending, isBankConfirmed, bankCards]);

    const convertedBirthday = useMemo(() => {
        return user.data.user.birthday?.split("-").join("/");
    }, [user]);

    const {
        control,
        setValue,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<BasicAuthSchema>({
        defaultValues: {
            name: (user.data.user?.originalName?.includes("کاربر ") ? "" : user.data.user?.originalName) ?? "",
            credit_image: undefined,
            national_code: user.data.user.national_code ?? "",
            birthday: convertedBirthday ? new DateObject(new Date(convertedBirthday ?? "")) : "",
            card_number: user.data.user.info?.bank_account_card_number ?? "",
            account_number: user.data.user.info?.bank_account_number ?? "",
            iban: user.data.user.info?.bank_account_sheba ?? "",
        },
        mode: "all",
    });

    const { renderTemplate } = useUploadHandler({
        title: "لطفا عکس کارت بانکی خود را آپلود کنید",
        name: "credit_image",
        setFormValue: setValue,
        control,
        rules: {
            required: !autoGetBankData,
        },
        disabled: isBankConfirmed || isBankPending,
        error: !!errors.credit_image,
    });

    const { mutateAsync: updateUser, isLoading: isUserLoading } = useUpdateUser();
    const { mutateAsync: updateBank, isLoading: isBankLoading } = useUpdateBank();

    const submitHandler: SubmitHandler<BasicAuthSchema> = async (data) => {
        if (isValid) {
            await updateUser({
                name: user.data.user.is_business ? data.name : undefined,
                birthday: !isBankConfirmed ? data.birthday.toString() : undefined,
                national_code: !isBankConfirmed ? data.national_code : undefined,
            });

            if (!isBankConfirmed && !isBankPending) {
                await updateBank({
                    iban: data.iban,
                    account_number: data.account_number,
                    card_number: data.card_number.replace(/\s/g, ""),
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    card_image: data.credit_image!,
                });
            }

            await refetchUserData();
            await refetchBanks();

            if (state && state?.label && state.label === "draft-verification-redirect") {
                navigate("/panel/invoice/" + state.id, { replace: true });
            } else {
                setActiveIndex((oldIndex) => oldIndex + 1);
            }
        }
    };

    return (
        <form className="flex h-full w-full flex-col">
            <div className="flex h-full w-full justify-end max-lg:flex-col-reverse">
                <div className="flex w-full flex-col items-start justify-start">
                    <SlideSection
                        subTitle={
                            isBankConfirmed
                                ? "اطلاعات شما تایید شده است"
                                : isBankPending
                                ? "اطلاعات شما در حال بررسی است"
                                : "لطفا تمام اطلاعات خود را تایید کنید"
                        }
                        subTitleStatus={isBankConfirmed /* && isEmailConfirmed */ ? "success" : "warning"}
                        title={`اطلاعات ${user.data.user.is_business ? "مدیرعامل" : "شخصی"}`}
                        className="flex w-full items-start justify-center gap-4 max-sm:flex-col lg:!min-h-min"
                    >
                        {user.data.user.is_business && (
                            <Input
                                containerClassName="w-full"
                                variant="fill"
                                required
                                topTitle={`نام ${user.data.user.is_business ? "مدیرعامل" : ""}`}
                                name="name"
                                control={control}
                                readOnly={isBankConfirmed || isBankPending}
                                disabled={isBankConfirmed || isBankPending}
                                showConfirmedStatus={isBankConfirmed}
                                rules={{
                                    required: "نام الزامی است",
                                }}
                            />
                        )}

                        <Input
                            containerClassName="w-full"
                            variant="fill"
                            ltr
                            required
                            topTitle={`کد ملی ${user.data.user.is_business ? "مدیرعامل" : ""}`}
                            inputMode="numeric"
                            name="national_code"
                            control={control}
                            readOnly={isBankConfirmed || isBankPending}
                            disabled={isBankConfirmed || isBankPending}
                            showConfirmedStatus={isBankConfirmed}
                            maskProps={{
                                mask: "9999999999",
                                maskChar: "",
                            }}
                            rules={{
                                required: "کد ملی الزامی است",
                            }}
                        />

                        <Input
                            name="birthday"
                            control={control}
                            topTitle={`تاریخ تولد ${user.data.user.is_business ? "مدیرعامل" : ""}`}
                            ltr
                            required
                            variant="fill"
                            placeholder="13--/--/--"
                            datePicker
                            containerClassName="w-full"
                            readOnly={isBankConfirmed || isBankPending}
                            disabled={isBankConfirmed || isBankPending}
                            showConfirmedStatus={isBankConfirmed}
                            datePickerProps={{
                                currentDate: new DateObject({ calendar: persian }).subtract(18, "year"),
                                format: "YYYY/MM/DD",
                                calendar: persian,
                                locale: persian_fa,
                                maxDate: new DateObject({ calendar: persian }).subtract(18, "year"),
                            }}
                            rules={{
                                required: "تاریخ تولد الزامی است",
                            }}
                        />
                    </SlideSection>
                    <SlideSection
                        title={`کارت بانکی ${user.data.user.is_business ? "شرکت/مدیرعامل" : ""}`}
                        className="lg:!min-h-0"
                    >
                        {user.data.user.is_business && (
                            <AlertBox
                                className="mb-6"
                                description="در صورتی که حساب بانکی حقوقی شما دارای شماره کارت فعال نمی‌باشد؛‌ اطلاعات حساب شخص مدیرعامل را وارد کنید"
                            />
                        )}
                        <div className="flex gap-6 max-sm:flex-col">
                            {!shouldRenderBankForm ? (
                                <>
                                    <div className="flex w-full flex-col items-start justify-start gap-6 sm:w-1/2">
                                        <Input
                                            name="card_number"
                                            control={control}
                                            ltr
                                            required
                                            containerClassName="w-full"
                                            variant="fill"
                                            topTitle="شماره کارت"
                                            inputMode="numeric"
                                            maskProps={{
                                                mask: "9999 9999 9999 9999",
                                                maskChar: "",
                                            }}
                                            rules={{
                                                required: "شماره کارت الزامی است",
                                                pattern: { value: /^.{19}$/, message: "شماره کارت باید 16 رقم باشد" },
                                            }}
                                        />
                                        {!autoGetBankData && (
                                            <Input
                                                ltr
                                                control={control}
                                                required
                                                type="number"
                                                containerClassName="w-full"
                                                variant="fill"
                                                topTitle="شماره حساب"
                                                inputMode="numeric"
                                                name="account_number"
                                                rules={{
                                                    required: !isBankPending ? "شماره حساب الزامی است" : undefined,
                                                }}
                                            />
                                        )}
                                        {!autoGetBankData && (
                                            <Input
                                                control={control}
                                                ltr
                                                required
                                                topTitle="شماره شبا"
                                                containerClassName="w-full"
                                                variant="fill"
                                                name="iban"
                                                maskProps={{
                                                    mask: "IR 99 9999 9999 9999 9999 9999 99",
                                                    maskChar: "",
                                                    alwaysShowMask: true,
                                                }}
                                                rules={{
                                                    required: !isBankPending ? "شماره شبا الزامی است" : undefined,
                                                    pattern: {
                                                        value: /^.{33}$/,
                                                        message: "شماره شبا باید 24 رقم باشد",
                                                    },
                                                }}
                                            />
                                        )}
                                    </div>
                                    {!autoGetBankData && (
                                        <div className="mt-[28px] aspect-[3/2] w-full sm:w-1/2">{renderTemplate}</div>
                                    )}
                                </>
                            ) : (
                                <div className="flex w-full items-center justify-center pt-6">
                                    <CreditCard
                                        serial={shouldRenderBankForm.code ?? ""}
                                        iban={""}
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        status={shouldRenderBankForm.status as any}
                                        name={user.data.user.name}
                                    />
                                </div>
                            )}
                        </div>
                    </SlideSection>
                </div>
                <SlideSection
                    title="مطالعه شود"
                    containerClasses="w-full lg:w-[500px]"
                    className="flex h-full flex-col gap-6"
                    separator
                >
                    <Description title="تایید کارت بانکی">
                        همچنین تقاضا می شود شماره کارت بانکی خود را وارد کنید تا امکان انجام تراکنش‌های مالی در پنل
                        کاربری خود را داشته باشید، همچنین تصویر کارت بانکی خود را جهت تایید هویت و اعتبار ارسال کنید تا
                        ما بتوانیم هویت و اعتبار شما را تأیید کنیم. اطلاعات کارت بانکی شما به صورت کاملاً محرمانه
                        نگهداری میشود.
                    </Description>
                </SlideSection>
            </div>
            <div
                className={`flex w-full items-center ${
                    user.data.user.is_business ? "justify-between" : "justify-end"
                } gap-4 border-t border-secondary-200 px-4 py-6 max-xs:pb-4 xs:px-6`}
            >
                {user.data.user.is_business && (
                    <Button
                        type="button"
                        className="w-[90px] flex-shrink-0 bg-zinc-400 xs:w-[150px]"
                        disabled={isUserLoading || isBankLoading}
                        disabledTooltip="دکمه غیرفعال است"
                        onClick={() => {
                            scrollToTop(1000, 150);
                            setActiveIndex((oldIndex) => oldIndex - 1);
                        }}
                    >
                        قبلی
                    </Button>
                )}
                <Button
                    type="button"
                    className="w-full xs:w-[150px]"
                    loading={isUserLoading || isBankLoading}
                    disabled={isUserLoading || isBankLoading}
                    disabledTooltip="دکمه غیرفعال است"
                    onClick={
                        isBankConfirmed || isBankPending
                            ? () => {
                                  scrollToTop(1000, 150);
                                  setActiveIndex((oldIndex) => oldIndex + 1);
                              }
                            : handleSubmit(submitHandler)
                    }
                >
                    {isBankConfirmed || isBankPending ? "بعدی" : "تایید و بعدی"}
                </Button>
            </div>
        </form>
    );
};

export default BasicAuthentication;
