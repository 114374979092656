import { AnimatePresence, motion, Variants } from "framer-motion";
import Cookies from "js-cookie";
import React, { useContext, useEffect, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

import directLinkLogo from "@/assets/images/markets/avalpardakht.png";
import bazaar from "@/assets/images/markets/bazaar.png";
import SidebarItem from "@/components/dashboard/sidebar/SidebarItem";
import IconArrowRightFromBracket from "@/components/svg/arrow-right-from-bracket";
import IconArrowUpRightFromSquareStroke from "@/components/svg/arrow-up-right-from-square-stroke";
import IconBadgeCheck from "@/components/svg/badge-check";
import IconChevronsLeft from "@/components/svg/chevrons-left";
import IconInstagram from "@/components/svg/instagram";
import IconLinkedin from "@/components/svg/linkedin";
import IconLogo from "@/components/svg/logo";
import IconLogoDark from "@/components/svg/logo-dark";
import IconLogoWhite from "@/components/svg/logo-white";
import IconTelegram from "@/components/svg/telegram";
import IconToman from "@/components/svg/toman";
import IconXTwitter from "@/components/svg/x-twitter";
import Avatar from "@/components/ui/Avatar";
import ClubPoints from "@/components/ui/ClubPoints";
import { sidebarItems } from "@/data/SidebarItems";
import useSettings from "@/hooks/queries/useSettings";
import useUser from "@/hooks/queries/useUser";
import useAnimeSetting from "@/hooks/useAnimeSetting";
import useTheme from "@/hooks/useTheme";
import { Context, ContextType } from "@/store/Context-store";
import { formatNumber } from "@/utils";

const DashboardSidebar = () => {
    const { data: user } = useUser();
    const { isThemeDark } = useTheme();
    const { isAnimationEnabled } = useAnimeSetting();
    const navigate = useNavigate();

    const is1200px = useMediaQuery({ maxWidth: 1200 });
    const { isSidebarExpanded, setIsSidebarExpanded } = useContext(Context) as ContextType;

    useEffect(() => {
        const bodyEl = document.querySelector("body") as HTMLBodyElement;
        if (is1200px && isSidebarExpanded) {
            bodyEl.style.overflowY = "hidden";
        } else {
            bodyEl.style.overflowY = "auto";
        }
    }, [is1200px, isSidebarExpanded]);

    const { data: settings } = useSettings();

    const logoVariants: Variants = {
        hide: {
            scale: 0,
            opacity: 1,
            transition: {
                duration: isAnimationEnabled ? 0.2 : 0,
            },
        },
        show: {
            scale: 1,
            opacity: 1,
            transition: {
                duration: isAnimationEnabled ? 0.2 : 0,
            },
        },
    };

    const renderItems = useMemo(() => {
        return sidebarItems.map((item, index) => (
            <SidebarItem
                icon={item.icon}
                iconDisabled={item.iconDisabled}
                link={item.link}
                text={item.text}
                submenus={item.submenu ? item.submenu.map((sub) => ({ ...sub, id: undefined })) : undefined}
                key={index}
            />
        ));
    }, []);

    const toggleSidebar = () => {
        setIsSidebarExpanded(!isSidebarExpanded);
    };

    return (
        <>
            <div
                className={`fixed left-0 top-0 z-[888] h-full w-full cursor-pointer bg-[rgb(0,0,0,0.5)] min-[1200px]:hidden ${
                    isSidebarExpanded ? "" : "hidden"
                }`}
                onClick={() => setIsSidebarExpanded(false)}
            />
            <motion.div
                animate={{
                    width: isSidebarExpanded ? "auto" : 80,
                    x: is1200px ? (isSidebarExpanded ? "0" : "100%") : undefined,
                }}
                transition={{ type: "tween", duration: isAnimationEnabled ? 0.3 : 0 }}
                className="fixed top-0 z-[999] h-screen w-fit flex-shrink-0 bg-secondary-400 shadow-side contrast:border-l min-[1200px]:sticky"
            >
                <button
                    onClick={toggleSidebar}
                    className={`absolute top-10 z-10 flex items-center justify-center rounded-full bg-secondary-200 shadow transition-all contrast:border contrast:bg-transparent ${
                        isSidebarExpanded
                            ? "left-[15px] h-[30px] w-[30px] rotate-180"
                            : "left-[-20px] h-[30px] w-[30px] max-[1200px]:left-0"
                    }`}
                >
                    <IconChevronsLeft className="h-[12px] fill-text-main" />
                </button>
                <div
                    className={`mt-8 flex w-full px-8 ${isSidebarExpanded ? "justify-start pl-24" : "justify-center"}`}
                >
                    <AnimatePresence mode="wait">
                        {isSidebarExpanded && (
                            <motion.div variants={logoVariants} initial="hide" animate="show" exit="hide" key={1}>
                                {isThemeDark ? (
                                    <IconLogoWhite className="h-[40px]" key={1} />
                                ) : (
                                    <IconLogoDark className="h-[40px]" key={2} />
                                )}
                            </motion.div>
                        )}

                        {!isSidebarExpanded && (
                            <motion.div
                                className="flex h-[40px] items-center justify-center"
                                variants={logoVariants}
                                initial="hide"
                                animate="show"
                                exit="hide"
                                key={2}
                            >
                                <IconLogo className="h-[30px]" />
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
                <div
                    className={`mt-10 flex gap-3 overflow-x-hidden px-4 pb-4 transition-all min-[576px]:hidden ${
                        !isSidebarExpanded ? "w-0" : ""
                    }`}
                >
                    <div className="flex items-center justify-center">
                        <Avatar picture={user.data.avatar} className="!w-[52px]" />
                    </div>
                    <div className="flex flex-col gap-2">
                        <div className="flex items-center gap-2">
                            {user.data.user.name}{" "}
                            {user.data.user.confirmed ? <IconBadgeCheck className="h-[14px] fill-primary" /> : null}
                        </div>
                        <div className="flex gap-2">
                            <div className="flex items-center gap-1 rounded-full bg-primary/10 px-[10px] py-1 text-text-paragraph">
                                <span
                                    dir="ltr"
                                    className={`text-[0.71rem] ${user.data.balance < 0 ? "text-error" : ""}`}
                                >
                                    {formatNumber(user.data.balance)}
                                </span>
                                <IconToman className="h-[12px] fill-primary" />
                            </div>

                            <ClubPoints />
                        </div>
                    </div>
                </div>

                <div className="custom-scrollbar sidebar-height mt-4 flex flex-col justify-between gap-[100px] overflow-y-auto min-[576px]:mt-10">
                    <ul className="flex-1 space-y-3">
                        {renderItems}
                        <hr className="mx-auto w-[80%] border-b border-secondary-100/40" />
                        {user.data.user.user_role === "admin" && (
                            <li className="w-full px-4">
                                <a
                                    href={`${import.meta.env.VITE_BASE_URL}/user/old/panel/login?token=${Cookies.get(
                                        "access_token",
                                    )}`}
                                    onClick={() => {
                                        if (is1200px) {
                                            setIsSidebarExpanded(false);
                                        }
                                    }}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={`scale-1 flex w-full gap-4 rounded-[10px] ${
                                        isSidebarExpanded ? "justify-between" : "justify-center"
                                    } px-4 py-2 transition-[background,transform] duration-150 hover:bg-primary/10 active:scale-[0.95]`}
                                >
                                    <div className={"flex items-center justify-start gap-4 "}>
                                        <div className="flex w-[30px] justify-center text-[1.42rem]">
                                            <IconArrowUpRightFromSquareStroke className="h-[18px] fill-black dark:fill-white" />
                                        </div>
                                        {isSidebarExpanded && (
                                            <span className={"whitespace-nowrap"}>رفتن به پنل ادمین</span>
                                        )}
                                    </div>
                                </a>
                            </li>
                        )}
                        <li className="w-full px-4">
                            <button
                                onClick={() => {
                                    if (is1200px) {
                                        setIsSidebarExpanded(false);
                                    }
                                    navigate("/auth/logout");
                                }}
                                className={`scale-1 group flex w-full gap-4 rounded-[10px] ${
                                    isSidebarExpanded ? "justify-between" : "justify-center"
                                } px-4 py-2 transition-[background,transform] duration-150 hover:bg-error/10 active:scale-[0.95] active:bg-error/10`}
                            >
                                <div className={"flex items-center justify-start gap-4 text-error"}>
                                    <div className="flex w-[30px] justify-center text-[1.42rem]">
                                        <IconArrowRightFromBracket className="h-[18px] fill-error" />
                                    </div>
                                    {isSidebarExpanded && (
                                        <span className={"whitespace-nowrap"}>خروج از حساب کاربری</span>
                                    )}
                                </div>
                            </button>
                        </li>
                    </ul>
                </div>

                <div className="flex w-full flex-shrink-0 flex-col items-center gap-8 overflow-hidden whitespace-nowrap py-4 md:mb-5">
                    <div className={`flex-col items-center gap-4 ${isSidebarExpanded ? "flex" : "hidden"}`}>
                        <span className="text-text-main text-[16px]">دانلود اپلیکیشن اول پرداخت</span>
                        <div className="flex gap-4">
                            <a
                                className="scale-90 transition hover:scale-100"
                                href="https://cafebazaar.ir/app/com.avalpardakht.app"
                                target="_blank"
                                rel="nofollow noreferrer"
                            >
                                <img className="w-16" src={bazaar} alt="دانلود از بازار" />
                            </a>
                            <a
                                className="scale-90 transition hover:scale-100"
                                href="https://avalpardakht.com/app/download"
                            >
                                <img className="w-16" src={directLinkLogo} alt="دانلود مستقیم" />
                            </a>
                        </div>
                        <hr className="mx-auto w-[80%] border-b border-secondary-100/40" />
                    </div>
                </div>

                <div className="flex w-full flex-shrink-0 flex-col items-center gap-8 overflow-hidden whitespace-nowrap py-4 md:mb-5">
                    <div className={`flex-col items-center gap-4 ${isSidebarExpanded ? "flex" : "hidden"}`}>
                        <div className="flex gap-6">
                            {settings &&
                                Object.keys(settings).map((settingKey, index) => {
                                    if (settingKey == "isCaptchaEnable" || settingKey == "tfa_authenticator") {
                                        return <React.Fragment key={index}></React.Fragment>;
                                    }

                                    return (
                                        <a
                                            href={settings[settingKey]}
                                            key={index}
                                            target="_blank"
                                            rel="nofollow noreferrer"
                                        >
                                            {settingKey == "telegram" && (
                                                <IconTelegram className="w-5 fill-text-main transition-colors hover:fill-primary" />
                                            )}
                                            {settingKey == "linkedin" && (
                                                <IconLinkedin className="w-5 fill-text-main transition-colors hover:fill-primary" />
                                            )}
                                            {settingKey == "instagram" && (
                                                <IconInstagram className="w-5 fill-text-main transition-colors hover:fill-primary" />
                                            )}
                                            {settingKey == "twitter" && (
                                                <IconXTwitter className="w-5 fill-text-main transition-colors hover:fill-primary" />
                                            )}
                                        </a>
                                    );
                                })}
                        </div>
                        <span className="text-xs">ما را در شبکه‌های اجتماعی دنبال کنید</span>
                    </div>
                </div>
            </motion.div>
        </>
    );
};

export default DashboardSidebar;
