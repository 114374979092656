import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import ToastError from "@/components/error/ToastError";
import gate from "@/gate";
import { SubmitOrderResponse } from "@/pages/dashboard/order/SubmitOrder";

const useSubmitOrder = (serviceId: number) => {
    const navigate = useNavigate();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return useMutation<SubmitOrderResponse, Error, any>({
        mutationFn: gate.submitOrder.bind(null, serviceId),
        onSuccess(data) {
            if (!data.error) {
                toast.success(data.message);

                navigate("/panel/invoice/" + data.transaction_id, { state: "ORDER" });
            }
        },
        onError(error) {
            ToastError(error);
        },
    });
};

export default useSubmitOrder;
