import { forwardRef } from "react";
import AuthCode, { AuthCodeRef } from "react-auth-code-input";
import { FieldError } from "react-hook-form";

export interface CodeInputType {
    error?: FieldError;
    onChange: (value: string) => void;
    length?: number;
    ref?: any;
}

const AuthCodeInput: React.FC<CodeInputType> = forwardRef<AuthCodeRef, CodeInputType>(
    ({ error, length, onChange }, ref) => {
        const handleOnChange = (value: string) => {
            onChange(value);
        };

        return (
            <div className="flex justify-center gap-4" dir="ltr">
                <div className="flex flex-col gap-4">
                    <AuthCode
                        containerClassName="flex gap-2 bg-transparent"
                        inputClassName={`rounded-[8px] border h-[40px] w-[40px] px-3 transition-colors flex justify-center items-center text-text-main placeholder:text-text-paragraph bg-input-bg border-input-border focus-within:border-primary hover:border-input-border-hover border-input-border text-center focus:border-primary focus:outline-none ${
                            error && "border-error"
                        }`}
                        allowedCharacters="numeric"
                        autoFocus
                        length={length ?? 5}
                        onChange={handleOnChange}
                        ref={ref}
                    />
                    <span className="w-full text-right text-sm text-error">{error?.message}</span>
                </div>
            </div>
        );
    },
);
export default AuthCodeInput;
