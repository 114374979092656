import { useMemo } from "react";
import { Link } from "react-router-dom";

import IconEyeDuotone from "@/components/svg/eye-duotone";
import Table from "@/components/ui/Table";
import { TableTdProps } from "@/components/ui/Table/TableTd";
import { Cashincome } from "@/interfaces/dashboard/layout/panel/summary";
import { formatNumber } from "@/utils";
import { cashStatus } from "@/utils/cases";

import EmptyIndicator from "../EmptyIndicator";

interface CashTabContentProps {
    cashIncomes: Cashincome[] | undefined;
    isLoading?: boolean;
}

const CashTabContent: React.FC<CashTabContentProps> = ({ cashIncomes, isLoading }) => {
    const tableRows = useMemo<TableTdProps[][] | undefined>(() => {
        return cashIncomes?.map((cash) => {
            const { icon, color } = cashStatus(cash.status);

            return [
                {
                    header: "شماره نقد",
                    children: "#" + cash.id,
                    link: `/panel/cash-income/list/${cash.id}`,
                },
                {
                    header: "میزان ارز",
                    children: formatNumber(Number(cash.amount)),
                },
                {
                    header: "نوع ارز",
                    children: cash.currency_name,
                },
                {
                    header: "نرخ خرید (تومان)",
                    children: formatNumber(Number(cash.currency_price)),
                },
                {
                    header: "وضعیت",
                    children: cash.status,
                    color: color,
                    icon: icon,
                    iconColor: color,
                },
                {
                    header: "تاریخ",
                    children: (
                        <div>
                            <label className="text-sm text-slate-700 dark:text-white">ایجاد: </label>
                            <span className="text-xs">{cash.created_at}</span>
                            <br />
                            <label className="text-sm text-slate-700 dark:text-white">بروزرسانی: </label>
                            <span className="text-xs">{cash.updated_at}</span>
                        </div>
                    ),
                },
                {
                    header: "عملیات",
                    children: (
                        <Link to={`/panel/cash-income/list/${cash.id}`}>
                            <IconEyeDuotone className="w-6 cursor-pointer fill-black dark:fill-white" />
                        </Link>
                    ),
                },
            ];
        });
    }, [cashIncomes]);

    return (
        <div className="my-6">
            <Table
                rows={tableRows}
                loading={isLoading}
                emptyIndicator={
                    <EmptyIndicator
                        link="/panel/cash-income/sell-paypal"
                        message="لیست نقدها خالی است"
                        linkText="ثبت اولین نقد درآمد"
                    />
                }
            />
            <div className="-mb-6 flex w-full justify-end pl-6 pt-6">
                <Link to="/panel/cash-income/list" className="text-primary transition-colors hover:text-primary">
                    لیست همه نقدها
                </Link>
            </div>
        </div>
    );
};

export default CashTabContent;
