import { useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";

import Modal from "@/components/ui/Modal";
import { GetNotificationsResponse } from "@/gate/panel";
import useMarkAsRead from "@/hooks/queries/useMarkAsRead";

type NotificationsProps = {
    show: boolean;
    setShow: (show: boolean) => void;
    notifications: GetNotificationsResponse["notifications"]["userUnreadNotifications"];
    unreadCount: number;
};

const Notifications = ({ show, setShow, notifications, unreadCount }: NotificationsProps) => {
    const { mutateAsync: markAsRead } = useMarkAsRead();
    const navigate = useNavigate();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const goToNotification = async (notif: any) => {
        setShow(false);
        await markAsRead({ id: notif.data.id });

        const url = new URL(notif.data.url);

        if (window.location.origin == url.origin) {
            navigate(notif.data.url);
        } else {
            location.href = notif.data.url;
        }
    };

    const renderNotifications = useMemo(() => {
        if (notifications && Array.isArray(notifications)) {
            return notifications?.slice(0, 5)?.map((notif, index) => {
                return (
                    <div className="-mx-6 border-b border-secondary-200 py-3 last-of-type:border-none" key={index}>
                        <div className="flex gap-2 px-5">
                            {!notif.read_at && <div className="mt-[6px] h-2 w-2 shrink-0 rounded-full bg-success" />}
                            <div>
                                <button
                                    onClick={() => {
                                        goToNotification(notif);
                                    }}
                                    type="button"
                                    className="text-right text-text-main hover:text-primary"
                                >
                                    {notif.data.msg}
                                </button>
                                <div className="flex gap-4">
                                    <div className="mt-2 text-[0.85rem]">{notif.data.date}</div>
                                    <div className="mt-2 text-[0.85rem] empty:hidden">{notif.read_at}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifications]);

    return (
        <>
            <Modal
                show={show}
                onClose={() => setShow(false)}
                className="max-w-[500px]"
                title={
                    <div>
                        رویدادها <span className="text-primary">({unreadCount ?? ""})</span>
                    </div>
                }
            >
                {renderNotifications && renderNotifications.length > 0 ? (
                    <>
                        <div className="min-h-[300px]">{renderNotifications}</div>
                        <div className="-mx-6">
                            <Link
                                to="/panel/notifications"
                                onClick={() => setShow(false)}
                                className="flex w-full items-center justify-center border-t border-secondary-200 pt-4 text-primary hover:text-primary"
                            >
                                مشاهده همه
                            </Link>
                        </div>
                    </>
                ) : (
                    <div className="flex min-h-[300px] items-center justify-center">هیچ رویدادی ثبت نشده است</div>
                )}
            </Modal>
        </>
    );
};

export default Notifications;
