import classNames from "classnames";
import React, { useMemo } from "react";

type CreditDetailsProps = {
    serial: string;
    iban: string | null;
    name: string;
    account?: string;
};

const CreditDetails: React.FC<CreditDetailsProps> = ({ iban, serial, name, account }) => {
    const renderSerial = useMemo(() => {
        const serialGroups: React.ReactNode[][] = [[], [], [], []];

        const splicedSerial = serial?.split("")?.map((digit, i) => (
            <span className="text-text-main" key={i}>
                {digit}
            </span>
        ));

        Array.from({ length: 16 - (splicedSerial?.length || 0) })
            .fill("")
            .forEach(() => {
                splicedSerial?.push(
                    <span className="px-[2px] text-text-paragraph dark:text-[rgb(230,230,230)]">_</span>,
                );
            });

        splicedSerial?.forEach((digit, index) => {
            const groupIndex = Math.ceil((index + 1) / 4) - 1;

            serialGroups[groupIndex].push(<span className="text-white">{digit}</span>);
        });

        return serialGroups.map((group, index) => (
            <div key={index} className="text-left" dir="ltr">
                {group.map((item, i) => (
                    <React.Fragment key={i}>{item}</React.Fragment>
                ))}
            </div>
        ));
    }, [serial]);

    const renderIban = useMemo(() => {
        if (iban) return iban.length === 0 ? "00000000000000000000000" : iban;
    }, [iban]);

    const serialClasses = classNames(
        "flex flex-row-reverse items-center justify-between text-[1.28rem] @[320px]:text-[1.42rem] @[380px]:text-[1.71rem] leading-6",
        iban ? "" : "pt-6",
    );

    return (
        <div className={`w-full space-y-2 ${account ? "" : "@[320px]:space-y-3"}`}>
            <div className="flex items-center justify-start text-[1rem] text-text-main @[320px]:text-[1.14rem]">
                {name}
            </div>
            {account && (
                <div className="rtl flex w-full items-center justify-end text-[1rem] font-semibold text-text-main @[320px]:text-[1.14rem]">
                    {/*<span>شماره حساب</span>*/}
                    <span>{account}</span>
                </div>
            )}
            {iban && (
                <div className="rtl w-full whitespace-nowrap text-left text-[0.85rem] font-semibold text-text-main @[320px]:text-[1rem] @[380px]:text-[1.28rem]">
                    {renderIban}
                </div>
            )}
            <div className={serialClasses}>{renderSerial}</div>
        </div>
    );
};

export default CreditDetails;
