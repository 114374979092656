import { motion } from "framer-motion";
import { useEffect, useMemo } from "react";
import { Control, useWatch } from "react-hook-form";

import { CashCreateResponse } from "@/interfaces/dashboard/order/order";
import { formatNumber } from "@/utils";

import AlertBox from "../ui/AlertBox";
import CustomRadio from "../ui/CustomRadio";
import Select from "../ui/Select";
import ImmediateWithdraw from "../wallet/ImmediateWithdraw";

type CashRefundOptionsProps = {
    instructions: CashCreateResponse;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: Control<any>;
    cashType: "ADD_TO_ACCOUNT_BALANCE" | "REFUND";
    balance: number;
    onIsImmediateChange: (value: boolean) => void;
};

const CashRefundOptions: React.FC<CashRefundOptionsProps> = ({
    instructions,
    balance,
    control,
    cashType,
    onIsImmediateChange,
}) => {
    const userBanksList = useMemo(() => {
        const mainAccount = {};
        if (instructions?.main_account?.bank_name) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            mainAccount.value = -1;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            mainAccount.label =
                (instructions.main_account.bank_name.startsWith("بانک ") ? "" : "بانک ") +
                instructions.main_account.bank_name +
                " - " +
                instructions.main_account.bank_account_card_number;
        }

        const userBanksList = Object.keys(mainAccount).length > 0 ? [mainAccount] : [];

        userBanksList.push(
            ...(instructions.cards?.map((card) => {
                return {
                    value: card.id,
                    label: card.bank_name_fa + " - " + card.card_number,
                };
            }) || []),
        );
        return userBanksList;
    }, [instructions.cards, instructions.main_account.bank_account_card_number, instructions.main_account.bank_name]);

    const [watchedCashType] = useWatch({
        control,
        name: ["cash_type"],
        defaultValue: {
            cash_type: "ADD_TO_ACCOUNT_BALANCE",
        },
    });

    useEffect(() => {
        if (watchedCashType === "ADD_TO_ACCOUNT_BALANCE") {
            onIsImmediateChange(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchedCashType]);

    return (
        <div className="flex flex-col gap-2">
            <label className="text-text-paragraph">شیوه تسویه حساب: </label>
            <CustomRadio
                label="افزودن اعتبار به حساب کاربری"
                name="cash_type"
                control={control}
                value="ADD_TO_ACCOUNT_BALANCE"
                checked={cashType == "ADD_TO_ACCOUNT_BALANCE"}
            />
            <CustomRadio
                label="واریز به حساب بانکی"
                name="cash_type"
                control={control}
                value="REFUND"
                checked={cashType == "REFUND"}
            />
            <motion.div
                className="overflow-hidden"
                initial={false}
                animate={{
                    height: cashType == "REFUND" ? "auto" : 0,
                }}
                transition={{ duration: 0.15 }}
            >
                {instructions.service.is_working_time ? (
                    <ImmediateWithdraw
                        onIsImmediateChange={(value: boolean) => {
                            onIsImmediateChange(value);
                        }}
                    />
                ) : (
                    <></>
                )}

                <Select
                    control={control}
                    topTitle="تسویه به حساب"
                    name="bank_id"
                    variant="fill"
                    placeholder="کارت بانکی"
                    rules={{
                        required:
                            instructions.service.is_working_time && cashType === "REFUND"
                                ? "لطفا یک بانک را انتخاب کنید"
                                : undefined,
                    }}
                    options={userBanksList}
                />
                {balance < 0 && (
                    <AlertBox
                        type="error"
                        className="mt-4"
                        description={
                            <>
                                مبلغ&nbsp;
                                <span className="text-error">{formatNumber(Math.abs(balance))}</span>
                                &nbsp; تومان بابت بدهی به سایت، از مبلغ نهایی تسویه شده کسر می‌گردد.
                            </>
                        }
                    />
                )}
            </motion.div>
        </div>
    );
};
export default CashRefundOptions;
