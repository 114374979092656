import { RouteObject } from "react-router-dom";

import Order from "@/pages/dashboard/order/Order";
import Orders from "@/pages/dashboard/order/Orders";
import OrdersItem from "@/pages/dashboard/order/OrdersItem";
import SubmitOrder from "@/pages/dashboard/order/SubmitOrder";

const OrderRoutes: RouteObject[] = [
    {
        path: "order",
        element: <Order />,
    },
    {
        path: "order/:serviceName",
        element: <Order />,
    },
    {
        path: "order/new/:serviceType",
        element: <SubmitOrder />,
    },
    {
        path: "order/list",
        element: <Orders />,
    },
    {
        path: "order/list/:orderId",
        element: <OrdersItem />,
    },
];

export default OrderRoutes;
