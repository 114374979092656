import { useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";

import AddComment from "@/components/comments/AddComment";
import Comment from "@/components/comments/Comment";
import PlaceholderLayout from "@/components/placeholder/PlaceholderLayout";
import Pagination from "@/components/ui/Pagination";
import PageTitle from "@/components/utility/PageTitle";
import useComments from "@/hooks/queries/useComments";
import useAfter from "@/hooks/useAfter";

const Comments: React.FC = () => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);

    const { data: commentsData } = useComments(page, perPage);

    const [replyId, setReplyId] = useState(0);

    useAfter(() => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setPerPage(commentsData!.pagination.per_page);
    }, !!commentsData);

    const renderComments = useMemo(() => {
        if (commentsData) {
            return commentsData.comments.map((comment, index) => {
                return <Comment {...comment} setReplyId={setReplyId} key={index} />;
            });
        }

        return Array(10)
            .fill(null)
            .map((_item, index) => (
                <div className="flex w-full gap-3 sm:gap-4" key={index}>
                    <Skeleton className="!h-[30px] !w-[30px] sm:!h-[40px] sm:!w-[40px]" circle />
                    <Skeleton
                        containerClassName="w-[100%]"
                        className="!h-[110px] !rounded-b-[15px] !rounded-l-[15px]"
                    />
                </div>
            ));
    }, [commentsData]);

    return (
        <PlaceholderLayout>
            <PageTitle>لیست نظرات</PageTitle>

            <div className="flex w-full flex-col gap-14">
                <AddComment replyId={replyId} setReplyId={setReplyId} />
                <div className="flex flex-1 flex-col gap-5">
                    <div className="w-full space-y-6">{renderComments}</div>
                    <Pagination
                        onPageChange={(page) => setPage(Number(page))}
                        paginationInfo={{
                            from: commentsData?.pagination.from,
                            to: commentsData?.pagination.to,
                            pages: commentsData?.pagination.last_page,
                            count: commentsData?.pagination.total,
                            page,
                        }}
                        nextPage={() => setPage(page + 1)}
                        prevPage={() => setPage(page - 1)}
                    />
                </div>
            </div>
        </PlaceholderLayout>
    );
};

export default Comments;
