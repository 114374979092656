import { SubmitHandler, useForm } from "react-hook-form";

import IdCardImageFront from "@/assets/images/id-card-1.jpg";
import IdCardImageBack from "@/assets/images/id-card-2.jpg";
import ToastError from "@/components/error/ToastError";
import Button from "@/components/ui/Button";
import UploadStatus from "@/components/ui/UploadStatus";
import useUpdateNationalId from "@/hooks/queries/useUpdateNationalId";
import useUser from "@/hooks/queries/useUser";
import useUploadHandler from "@/hooks/useUploadHandler";
import { scrollToTop, verificationStatus } from "@/utils";

import SlideSection from "../SlideSection";

type IdentificationProps = {
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
};

type IdentificationSchema = {
    front_image: File | undefined;
    back_image: File | undefined;
};

const IdentificationCard: React.FC<IdentificationProps> = ({ setActiveIndex }) => {
    const { data: user, refetch: refetchUserData } = useUser();

    const {
        control,
        trigger,
        setValue,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<IdentificationSchema>({
        defaultValues: {
            front_image: undefined,
            back_image: undefined,
        },
    });

    const nationalIdStatus = verificationStatus(user, "NATIONAL_ID");
    const isNationalIdConfirmed = nationalIdStatus("CONFIRMED");
    const isNationalIdPending = nationalIdStatus("PENDING");

    const { renderTemplate: renderTemplate1 } = useUploadHandler({
        title: "لطفا عکس روی کارت را آپلود کنید",
        name: "front_image",
        accept: "image/*",
        setFormValue: setValue,
        control,
        rules: {
            required: !isNationalIdConfirmed && !isNationalIdPending,
        },
        disabled: isNationalIdConfirmed || isNationalIdPending,
        error: !!errors.front_image,
    });

    const { renderTemplate: renderTemplate2 } = useUploadHandler({
        title: "لطفا عکس پشت کارت را آپلود کنید",
        name: "back_image",
        accept: "image/*",
        setFormValue: setValue,
        control,
        rules: {
            required: !isNationalIdConfirmed && !isNationalIdPending,
        },
        disabled: isNationalIdConfirmed || isNationalIdPending,
        error: !!errors.back_image,
    });

    const { mutateAsync: updateNationalId, isLoading } = useUpdateNationalId();

    const submitHandler: SubmitHandler<IdentificationSchema> = async (data) => {
        await trigger();

        if (isValid) {
            try {
                if (!isNationalIdConfirmed && !isNationalIdPending) {
                    await updateNationalId({
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        front_image: data.front_image!,
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        back_image: data.back_image!,
                    });
                }

                await refetchUserData();

                setActiveIndex((oldIndex) => oldIndex + 1);
            } catch (e) {
                console.log(e);
                ToastError(new Error("خطایی سمت سرور رخ داده است"));
            }
        }
    };

    return (
        <div className="flex h-full w-full flex-col">
            <SlideSection
                title={`ارسال تصویر کارت ملی ${user.data.user.is_business ? "مدیرعامل" : ""}`}
                subTitle={
                    isNationalIdConfirmed
                        ? "کارت ملی شما تایید شده است"
                        : isNationalIdPending
                        ? "کارت ملی شما در حال بررسی است"
                        : ""
                }
                subTitleStatus={isNationalIdConfirmed ? "success" : isNationalIdPending ? "warning" : ""}
                className="h-full"
            >
                <div className="flex items-center justify-center gap-6 max-md:flex-col">
                    <div className="w-full space-y-3">
                        <h4 className="pr-1 text-[1.14rem] text-text-main">
                            عکس روی کارت ملی {user.data.user.is_business ? "مدیرعامل" : ""}
                        </h4>
                        <div className="relative aspect-[3/2] w-full">
                            {renderTemplate1}
                            {(isNationalIdPending || isNationalIdConfirmed) && (
                                <UploadStatus
                                    image={IdCardImageFront}
                                    text={
                                        isNationalIdConfirmed
                                            ? "کارت ملی شما تایید شده است"
                                            : "عکس کارت ملی شما در حال بررسی است"
                                    }
                                    status={isNationalIdConfirmed ? "success" : "pending"}
                                />
                            )}
                        </div>
                    </div>

                    <div className="w-full space-y-3">
                        <h4 className="pr-1 text-[1.14rem] text-text-main">
                            عکس پشت کارت ملی {user.data.user.is_business ? "مدیرعامل" : ""}
                        </h4>
                        <div className="relative aspect-[3/2] w-full">
                            {renderTemplate2}
                            {(isNationalIdPending || isNationalIdConfirmed) && (
                                <UploadStatus
                                    image={IdCardImageBack}
                                    text={
                                        isNationalIdConfirmed
                                            ? "کارت ملی شما تایید شده است"
                                            : "عکس کارت ملی شما در حال بررسی است"
                                    }
                                    status={isNationalIdConfirmed ? "success" : "pending"}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </SlideSection>
            <div className="flex w-full items-center justify-between gap-4 border-t border-secondary-200 px-4 py-6 max-xs:pb-4 xs:px-6">
                <Button
                    type="button"
                    className="w-[90px] flex-shrink-0 bg-zinc-400 xs:w-[150px]"
                    disabled={isLoading}
                    disabledTooltip="دکمه غیرفعال است"
                    onClick={() => {
                        scrollToTop(1000, 150);
                        setActiveIndex((oldIndex) => oldIndex - 1);
                    }}
                >
                    قبلی
                </Button>
                <Button
                    type="button"
                    className="w-full xs:w-[150px]"
                    loading={isLoading}
                    disabled={isLoading || !isValid}
                    disabledTooltip="دکمه غیرفعال است"
                    onClick={
                        isNationalIdConfirmed || isNationalIdPending
                            ? () => {
                                  scrollToTop(1000, 150);
                                  setActiveIndex((oldIndex) => oldIndex + 1);
                              }
                            : handleSubmit(submitHandler)
                    }
                >
                    {isNationalIdConfirmed || isNationalIdPending ? "بعدی" : "تایید و بعدی"}
                </Button>
            </div>
        </div>
    );
};

export default IdentificationCard;
