import toast from "react-hot-toast";

import Button from "@/components/ui/Button";

type ShowQuestionArgs = {
    avatar: string;
    text: string;
    cancelButtonText?: string;
    acceptButtonText?: string;
    duration?: number;
};

const showQuestion = async ({
    cancelButtonText,
    acceptButtonText,
    text,
    avatar,
    duration = 8000,
}: ShowQuestionArgs) => {
    const waitForAnswer = new Promise((resolve) => {
        toast(
            (t) => {
                setTimeout(() => {
                    toast.dismiss(t.id);
                    resolve(false);
                }, duration); 

                return (
                    <span>
                        <div className="flex gap-4">
                            <div className="w-[30px] h-[30px] 2xs:w-[50px] 2xs:h-[50px] relative mt-[6px] shrink-0 border-2 border-primary/50 rounded-[5px] overflow-hidden">
                                <img src={avatar} className="reset-hue-rotate w-full h-full absolute object-cover" alt="image-to-compress" />
                            </div>
                            <p className="text-justify leading-7 text-text-paragraph max-2xs:leading-6 flex-1">
                                {text}
                            </p>
                        </div>
                        <div className="-mx-[20px]">
                            <div className="mt-4 flex w-full items-center gap-4 border-t border-secondary-100 px-[20px] pt-4">
                                <Button
                                    variant="stroke"
                                    className="!h-[2.28rem] flex-1 !text-[1rem]"
                                    onClick={() => {
                                        toast.dismiss(t.id);
                                        resolve(false);
                                    }}
                                    color="error"
                                >
                                    {cancelButtonText}
                                </Button>
                                <Button
                                    className="!h-[2.28rem] flex-1 !text-[1rem]"
                                    onClick={() => {
                                        toast.dismiss(t.id);
                                        resolve(true);
                                    }}
                                >
                                    {acceptButtonText}
                                </Button>
                            </div>
                        </div>
                    </span>
                );
            },
            {
                duration: Infinity,
                className: "!bg-toast",
            },
        );
    });

    return await waitForAnswer;
};

export default showQuestion;
