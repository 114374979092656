import { RouteObject } from "react-router-dom";

import Affiliate from "@/pages/dashboard/affiliate/Affiliate";
import Comments from "@/pages/dashboard/comments/Comments";
import CustomerClub from "@/pages/dashboard/customer-club/CustomerClub";
import Dashboard from "@/pages/dashboard/dashboard/Dashboard";
import Notifications from "@/pages/dashboard/notifications/Notifications";
import Wallet from "@/pages/dashboard/wallet/Main";

const PanelRoutes: RouteObject[] = [
    {
        index: true,
        element: <Dashboard />,
    },
    {
        path: "comments",
        element: <Comments />,
    },
    {
        path: "notifications",
        element: <Notifications />,
    },
    {
        path: "affiliate",
        element: <Affiliate />,
    },
    {
        path: "wallet",
        element: <Wallet />,
    },
    {
        path: "customer-club",
        element: <CustomerClub />,
    },
];

export default PanelRoutes;
