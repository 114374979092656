import { useMutation } from "@tanstack/react-query";

import gate from "@/gate";

const useLoginWithToken = () => {
    return useMutation({
        mutationFn: gate.loginWithToken,
    });
};

export default useLoginWithToken;
