import { useEffect } from "react";

import PageTitle from "@/components/utility/PageTitle";
import useAuth from "@/hooks/useAuth";

const Logout = () => {
    const { logout } = useAuth();

    useEffect(() => {
        logout(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="flex min-h-screen w-full items-center justify-center bg-secondary">
            <PageTitle>خروج از حساب کاربری</PageTitle>
            <h3 className="text-lg">در حال خروج از حساب کاربری...</h3>
        </div>
    );
};

export default Logout;
