import AlertBox from "@/components/ui/AlertBox";
import { transactionStatus } from "@/utils/cases";

type InvoiceAlertProps = {
    status: "در انتظار پرداخت" | "پرداخت شده" | "ناموفق" | "رد شده" | " در انتظار تایید تیم مالی";
    type?: string;
};

const InvoiceAlert: React.FC<InvoiceAlertProps> = ({ status, type }) => {
    const alerts = [
        {
            title: "در انتظار پرداخت",
            description: "",
        },
        {
            title: "پرداخت شده",
            description:
                type == "order" ? "هزینه سفارش پرداخت شده است." : "این صورت حساب افزایش موجودی پرداخت شده است.",
        },
        {
            title: "ناموفق",
            description: "پرداخت شما ناموفق بود.",
        },
        {
            title: "رد شده",
            description: "پرداخت شما رد شده است.",
        },
        {
            title: " در انتظار تایید تیم مالی",
            description: "پرداخت شما پس از تاییدیه تیم مالی انجام خواهد شد, لطفا صبور باشید.",
        },
    ];

    const statusDetails = transactionStatus(status);

    const alert = alerts.find((alert) => {
        return alert.title == status;
    });

    if (alert?.description) {
        return <AlertBox description={alert?.description || ""} customColor={statusDetails.color} />;
    } else {
        return <></>;
    }
};

export default InvoiceAlert;
