import { Cashincome, Order } from "@/interfaces/dashboard/layout/panel/summary";
import {
    CalculationRequestType,
    CalculationResponse,
    CashCalculationResponse,
    CashResponse,
    CashServicesList,
    ShowOrderResponse,
} from "@/interfaces/dashboard/order/order";
import { OrderRequestType } from "@/pages/dashboard/order/SubmitOrder";

import api from "../api";

// type OrdersRequest = {
//     price: number;
//     card_id: string | number;
//     "g-recaptcha-response"?: string;
// };

type OrdersResponse = {
    data: Order[];
    "links": {
        "first": string;
        "last": string;
        "prev": null | string;
        "next": null | string;
    };
    "meta": {
        "current_page": number;
        "from": number;
        "last_page": number;
        "path": string;
        "per_page": string;
        "to": number;
        "total": number;
    };
};

type CashesResponse = {
    data: Cashincome[];
    "links": {
        "first": string;
        "last": string;
        "prev": null | string;
        "next": null | string;
    };
    "meta": {
        "current_page": number;
        "from": number;
        "last_page": number;
        "path": string;
        "per_page": string;
        "to": number;
        "total": number;
    };
};

type OrderAddCardRequest = {
    id: string;
    national_code?: string;
    birthday?: string;
    card_number: string;
};

export default {
    servicesList: () => api.get("panel/api/v1/orders"),
    ordersList: (page: number, perPage: number) =>
        api.get<OrdersResponse>(`panel/api/v1/orders/list?page=${page}&per_page=${perPage}`),
    getCategoryPrices: (slug: string) => api.get("panel/api/v1/orders/prices/" + slug),
    getServiceInstruction: (serviceType: string) => api.get("panel/api/v1/orders/create/" + serviceType),
    orderCalculation: (data: CalculationRequestType) =>
        api.post<CalculationResponse>("/panel/api/v1/orders/calc/" + data.serviceId + "/price", data),
    showOrder: (id: number) => api.get<ShowOrderResponse>(`panel/api/v1/orders/show/${id}`),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    submitOrder: (serviceId: number, data: OrderRequestType) => api.file(`panel/orders/new/order/${serviceId}`, data),

    cashServiesList: () => api.get<CashServicesList>("panel/api/v1/casheincomes/cash-services-list"),
    cashesList: (page: number, perPage: number) =>
        api.get<CashesResponse>(`panel/api/v1/casheincomes/list?page=${page}&per_page=${perPage}`),
    getCashInstruction: (serviceName: string) => api.get(`panel/api/v1/casheincomes/create/${serviceName}`),
    cashCalculation: (serviceName?: string, amount?: number, currency?: number | string) =>
        api.get<CashCalculationResponse>(
            `panel/api/v1/casheincomes/${serviceName || "all"}/calculate?amount=${amount || 0}&currency=${
                currency || ""
            }`,
        ),
    submitCash: (data: unknown) => api.post("panel/cashincomes/create", data),
    showCash: (id: number) => api.get<CashResponse>(`panel/api/v1/casheincomes/${id}`),
    settleCash: (id: number, data: any) => api.file(`panel/cashincomes/status/${id}/settle`, data),
    orderAddCard: (data: OrderAddCardRequest) =>
        api.post(`/panel/orders/show/${data.id}/addCard`, { ...data, id: undefined }),
};
