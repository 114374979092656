import { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useParams, useSearchParams } from "react-router-dom";

import Spinner from "@/components/loader/Spinner";
import Button from "@/components/ui/Button";
import CashTotal from "@/components/ui/CashTotal";
import CustomCheckbox from "@/components/ui/CustomCheckbox";
import CustomRadio from "@/components/ui/CustomRadio";
import Input from "@/components/ui/Input";
import Select from "@/components/ui/Select";
import Textarea from "@/components/ui/Textarea";
import { NUMBER_INPUT_FLOATING_POINT } from "@/constants/common";
import {
    CashCalculationRequestType,
    CashCreateResponse,
    CashCreateService,
    Currency,
} from "@/interfaces/dashboard/order/order";

import CashRefundOptions from "../CashRefundOptions";

type CryptoPerfectCashRequest = {
    service: string;
    amount: number | "";
    fk_currency_id: number;
    meta?: {
        usdt_network?: string;
    };
    cash_type: "ADD_TO_ACCOUNT_BALANCE" | "REFUND";
    bank_id: number;
    user_desc?: string;
    rules: boolean;
};

type ServiceInstruction = {
    isLoading: boolean;
    instructions?: CashCreateResponse;
    isCashPricesLoading: boolean;
    calculatedPrice?: Currency;
    onCalculation: (data: CashCalculationRequestType) => void;
    onFormSubmit: (data: CryptoPerfectCashRequest) => void;
};

type CryptoPerfectCashType = {
    amount: number | "";
    currency: {
        label: string;
        value: number;
    };
    cash_type: "ADD_TO_ACCOUNT_BALANCE" | "REFUND";
    bank_id?: {
        label: string;
        value: number;
    };
    usdt_network?: string;
    user_desc?: string;
    is_immediate: boolean;
};

const CryptoPerfectCashForm: React.FC<ServiceInstruction> = ({
    isLoading,
    instructions,
    isCashPricesLoading,
    calculatedPrice,
    onCalculation,
    onFormSubmit,
}) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const [serviceData, setServiceData] = useState<CashCreateService>(instructions?.service);
    const [cashType, setCashType] = useState<undefined | string>();

    const [isImmediate, setIsImmediate] = useState<boolean>(false);

    const [acceptRules, setAcceptRules] = useState<boolean>(false);
    const [total, setTotal] = useState<number>(0);

    const [selectedCurrency, setSelectedCurrency] = useState<number>(0);

    const [queryString] = useSearchParams();

    const { serviceName } = useParams();
    const serviceNameRef = useRef(serviceName);

    const {
        control,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm<CryptoPerfectCashType>({
        defaultValues: {
            amount: queryString.get("amount") ? Number(queryString.get("amount")) : "",
            currency: {
                value: queryString.get("currency")
                    ? serviceData.currencies.find((currency) => currency.abbreviation == queryString.get("currency"))
                          ?.id
                    : serviceData?.currencies[0]?.id,
                label: queryString.get("currency")
                    ? serviceData.currencies.find((currency) => currency.abbreviation == queryString.get("currency"))
                          ?.name
                    : serviceData?.currencies[0]?.name,
            },
            usdt_network: "",
            user_desc: "",
            cash_type: "ADD_TO_ACCOUNT_BALANCE",
            is_immediate: isImmediate,
        },
    });

    const [watchedAmount, watchedCurrency, watchedCashType] = useWatch({
        control,
        name: ["amount", "currency", "cash_type"],
        defaultValue: {
            amount: "",
            currency: {
                value: serviceData.currencies[0]?.id,
                label: serviceData.currencies[0]?.name,
            },
            cash_type: "ADD_TO_ACCOUNT_BALANCE",
        },
    });

    useEffect(() => {
        if (serviceName != serviceNameRef.current) {
            setTotal(0);
            reset({ amount: "" });
            serviceNameRef.current = serviceName;
        }

        if (instructions) {
            setServiceData(instructions.service);
            const cashType = instructions.service.form_file.split(".")[2];

            setCashType(cashType);

            setValue("currency", {
                value: instructions.service.currencies[0].id,
                label: instructions.service.currencies[0].name,
            });

            // if (instructions.service.currencies[0]) {
            //     setValue("currency", {
            //         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //         //@ts-ignore
            //         value:
            //             cashType == "broker_cashincome"
            //                 ? instructions.service.currencies[1].id
            //                 : instructions.service.currencies[0].id,
            //         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //         //@ts-ignore
            //         label:
            //             cashType == "broker_cashincome"
            //                 ? instructions.service.currencies[1].name
            //                 : instructions.service.currencies[0].name,
            //     });
            // }
        }
    }, [instructions, reset, setValue, serviceName]);

    useEffect(() => {
        setTotal(calculatedPrice?.total || 0);
    }, [calculatedPrice]);

    useEffect(() => {
        if (watchedCurrency && watchedAmount && Number(watchedAmount) >= 0) {
            onCalculation({
                serviceName: serviceName?.replace("sell-", "").toUpperCase() || "",
                amount: +watchedAmount,
                currency: watchedCurrency.value || "",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchedAmount, watchedCurrency]);

    useEffect(() => {
        setSelectedCurrency(watchedCurrency?.value || 0);
    }, [watchedCurrency]);

    const submitHandler: SubmitHandler<CryptoPerfectCashType> = (data) => {
        const submitData = {
            service: serviceName?.replace("sell-", "").toUpperCase() || "",
            amount: data.amount,
            fk_currency_id: data.currency.value,
            user_desc: data.user_desc,
            cash_type: data.cash_type,
            bank_id: data.bank_id?.value || -1,
            rules: acceptRules,
            is_immediate: isImmediate,
        };

        if (cashType == "tether") {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            submitData.meta = {};
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            submitData.meta.usdt_network = data.usdt_network;
        }

        onFormSubmit(submitData);
    };

    return (
        <form id="order-form" onSubmit={handleSubmit(submitHandler)}>
            <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-4 lg:flex-row">
                    <div className="w-full lg:w-7/12">
                        <Input
                            containerClassName="w-full"
                            variant="fill"
                            required
                            ltr
                            name="amount"
                            topTitle={`میزان ${
                                instructions?.service?.form_file?.endsWith("broker_cashincome.user")
                                    ? ""
                                    : instructions?.service?.name?.includes("پایر")
                                    ? instructions?.service?.name
                                    : cashType?.toUpperCase()
                            }`}
                            inputMode="decimal"
                            type="number"
                            min={0}
                            step={NUMBER_INPUT_FLOATING_POINT}
                            placeholder={`میزان ${serviceData.name}`}
                            control={control}
                            rules={{
                                required: "میزان الزامی است",
                            }}
                        />
                    </div>
                    <div className="w-full lg:w-5/12">
                        <Select
                            control={control}
                            topTitle="نوع ارز"
                            required
                            name="currency"
                            variant="fill"
                            placeholder="انتخاب کنید"
                            rules={{
                                required: "انتخاب نوع ارز الزامی است",
                            }}
                            defaultValue={{
                                value: serviceData.currencies[0]?.id,
                                label: serviceData.currencies[0]?.name,
                            }}
                            options={serviceData.currencies.map((currency) => {
                                return {
                                    value: currency.id,
                                    label: currency.name,
                                };
                            })}
                        />
                    </div>
                </div>

                <CashTotal isLoading={isCashPricesLoading} total={total} />

                {(cashType == "tether" || selectedCurrency == 23) && (
                    <div className="mt-4 flex flex-col gap-4">
                        <span className="text-text-main">
                            شبکه انتقال
                            <span className="text-error">*</span>
                        </span>
                        <div className="flex gap-8">
                            <CustomRadio
                                control={control}
                                label="BEP20"
                                font="font-sans"
                                name="usdt_network"
                                value="BEP20"
                                rules={{
                                    required: cashType == "tether" ? "انتخاب شبکه انتقال الزای است" : undefined,
                                }}
                            />
                            <CustomRadio
                                control={control}
                                label="ERC20"
                                font="font-sans"
                                name="usdt_network"
                                value="ERC20"
                                rules={{
                                    required: cashType == "tether" ? "انتخاب شبکه انتقال الزای است" : undefined,
                                }}
                            />
                            <CustomRadio
                                control={control}
                                label="TRC20"
                                font="font-sans"
                                name="usdt_network"
                                value="TRC20"
                                rules={{
                                    required: cashType == "tether" ? "انتخاب شبکه انتقال الزای است" : undefined,
                                }}
                            />
                        </div>
                        {errors && errors.usdt_network && (
                            <div className="-mt-2 text-[0.78rem] text-error">
                                <span>{errors.usdt_network.message}</span>
                            </div>
                        )}
                    </div>
                )}

                {instructions && instructions?.can_choose_payment_method && (
                    <CashRefundOptions
                        control={control}
                        balance={instructions.balance}
                        instructions={instructions}
                        cashType={watchedCashType}
                        onIsImmediateChange={(value: boolean) => setIsImmediate(value)}
                    />
                )}

                <Textarea
                    name="user_desc"
                    control={control}
                    label="توضیحات"
                    placeholder="توضیحات خود را اینجا بنویسید"
                />

                <CustomCheckbox
                    onChange={() => setAcceptRules((prev) => !prev)}
                    label={
                        <span>
                            <a
                                href="https://avalpardakht.com/terms"
                                target="_blank"
                                rel="noreferrer nofollow"
                                className="text-primary hover:text-primary"
                            >
                                قوانین و مقررات
                            </a>{" "}
                            سایت را مطالعه کرده‌ام و آن را قبول دارم.
                        </span>
                    }
                />
                <Button type="submit" disabled={!acceptRules || isLoading} disabledTooltip="دکمه غیرفعال است">
                    {isLoading ? <Spinner /> : "ثبت درخواست"}
                </Button>
            </div>
        </form>
    );
};

export default CryptoPerfectCashForm;
