import classNames from "classnames";
import { useContext, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { NavLink, useLocation, useParams } from "react-router-dom";

import IconCaretDown from "@/components/svg/caret-down";
import { Context, ContextType } from "@/store/Context-store";

import SubMenuItem, { SubMenuItemProps } from "./SubMenuItem";

type SidebarItemProps = {
    icon: any;
    iconDisabled: any;
    link: string[];
    text: string;
    submenus?: SubMenuItemProps[];
};

const SidebarItem: React.FC<SidebarItemProps> = ({ icon: Icon, iconDisabled: IconDisabled, link, text, submenus }) => {
    const { isSidebarExpanded, setIsSidebarExpanded } = useContext(Context) as ContextType;
    const { pathname, hash } = useLocation();
    const params = useParams();
    const isMobile = useMediaQuery({ maxWidth: 1200 });

    const isItemActive = useMemo(() => {
        const paramsString = Object.keys(params).reduce((prevValue, key, index) => {
            if (key === "*") return "";
            if (index !== 1) return prevValue + params[key] + "/";
            return "/" + prevValue + params[key];
        }, "");

        return link.some((path) => {
            return (
                pathname.replace(paramsString, "") + hash === path ||
                pathname.replace(paramsString, "") + hash === path + "/" + paramsString.replace("/", "")
            );
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, hash, link]);

    const isSubMenuActive = useMemo(() => {
        return link.some((path) => path === hash);
    }, [link, hash]);

    const isActive = isItemActive || isSubMenuActive;

    const containerClasses = classNames({
        "flex py-2 px-4 rounded-[10px] transition-[background,transform] scale-1 active:scale-[0.95] duration-150 gap-4": 1,
        "bg-primary contrast:!bg-transparent contrast:border contrast:border-primary": isSidebarExpanded && isActive,
        "hover:bg-primary/10": !isActive,
        "justify-between": isSidebarExpanded,
        "justify-center": !isSidebarExpanded,
    });

    const wrapperClasses = classNames({
        "flex items-center justify-start": 1,
        "gap-4": isSidebarExpanded,
    });

    const iconClasses = classNames({
        "h-[18px]": 1,
        "dark:fill-white fill-black": !isActive,
        "fill-white contrast:!fill-primary": isSidebarExpanded && isActive,
        "fill-primary": !isSidebarExpanded && isActive,
    });

    const textClasses = classNames({
        "whitespace-nowrap": 1,
        "text-white contrast:!text-primary mt-[2px]": isActive,
        "text-text-paragraph": !isItemActive && !isSubMenuActive,
        "hidden": !isSidebarExpanded,
    });

    const expandClasses = classNames({
        "text-[1.42rem] text-white": 1,
        "mt-1": isSubMenuActive,
        "mb-1": !isSubMenuActive,
        "hidden": !isSidebarExpanded,
    });

    const expandIconClasses = classNames({
        "h-[18px] fill-black dark:fill-white transition-transform": 1,
        "!fill-white contrast:!fill-primary rotate-180": isActive,
    });

    const renderSubMenus = useMemo(() => {
        if (submenus) {
            return submenus.map((submenu, index) => {
                return <SubMenuItem text={submenu.text} link={submenu.link + hash} key={index} />;
            });
        }
    }, [submenus, hash]);

    const onItemClick = () => {
        if (submenus) setIsSidebarExpanded(true);
        else if (isMobile) setIsSidebarExpanded(false);
    };

    return (
        <li className="w-full px-4">
            <NavLink to={link[0]} end className={containerClasses} onClick={onItemClick}>
                <div className={wrapperClasses}>
                    <div className="flex w-[30px] justify-center text-[1.42rem]">
                        {isActive ? <Icon className={iconClasses} /> : <IconDisabled className={iconClasses} />}
                    </div>

                    <span className={textClasses}>{text}</span>
                </div>

                <div className={expandClasses}>{submenus && <IconCaretDown className={expandIconClasses} />}</div>
            </NavLink>

            {isSubMenuActive && isSidebarExpanded && (
                <ul className="mr-8 space-y-3 border-r-2 border-secondary-200 px-6 py-2 pt-3">{renderSubMenus}</ul>
            )}
        </li>
    );
};

export default SidebarItem;
