type CreditLogoProps = {
    name: string | undefined;
    logo: string | undefined;
};

const CreditLogo: React.FC<CreditLogoProps> = ({ name, logo }) => {
    return (
        <div className="flex items-center justify-start gap-3">
            <img src={logo} alt={name} className="w-[30px] @[320px]:w-[40px] aspect-square" />
            <span className="text-[1rem] @[320px]:text-[1.14rem] text-text-main">{name}</span>
        </div>
    );
};

export default CreditLogo;
