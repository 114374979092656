import "swiper/css";
import "swiper/css/effect-coverflow";

import { useEffect, useMemo } from "react";
import { EffectCoverflow } from "swiper/modules";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

import IconBriefcase from "@/components/svg/briefcase";
import IconFileContract from "@/components/svg/file-contract";
import IconIdCardSolid from "@/components/svg/id-card-solid";
import IconPhone from "@/components/svg/phone";
import IconUser from "@/components/svg/user";
import IconVideo from "@/components/svg/video";
import useUser from "@/hooks/queries/useUser";
import { VerificationStatus } from "@/interfaces/dashboard/layout/panel/panel";

import ProgressItem from "./ProgressItem";

type WizardProgressProps = {
    activeIndex: number;
    setActiveIndex: (index: number) => void;
    stepsProgress: {
        step0: VerificationStatus;
        step1: VerificationStatus;
        step2: VerificationStatus;
        step3: VerificationStatus;
        step4: VerificationStatus;
        step5: VerificationStatus;
    };
    stepsDisabled: { step1: boolean; step2: boolean; step3: boolean; step4: boolean; step5: boolean };
};

const WizardProgress: React.FC<WizardProgressProps> = ({
    activeIndex,
    setActiveIndex,
    stepsProgress,
    stepsDisabled,
}) => {
    const { data: userData } = useUser();

    const getNotCompletedIndex = useMemo(() => {
        let notCompletedStepIndex = userData.data.user.is_business ? -1 : 0;

        let index = userData.data.user.is_business ? -1 : 0;
        for (const key in stepsProgress) {
            if (!userData.data.user.is_business && key === "step0") continue;

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (stepsProgress[key] === "REJECTED" || stepsProgress[key] === "CANCELED") {
                notCompletedStepIndex = index + 1;
                break;

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
            } else if (stepsProgress[key] === "CONFIRMED" || stepsProgress[key] === "PENDING") {
                notCompletedStepIndex = index + 2;
            }

            index++;
        }
        console.log(notCompletedStepIndex, stepsProgress);
        if (
            notCompletedStepIndex === (userData.data.user.is_business ? -1 : 0) ||
            notCompletedStepIndex > (userData.data.user.is_business ? 6 : 5)
        ) {
            return userData.data.user.is_business ? 0 : 1;
        }

        return notCompletedStepIndex;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setActiveIndex(getNotCompletedIndex);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getNotCompletedIndex]);

    let progressItems = [
        <ProgressItem
            icon={IconBriefcase}
            index={0}
            key={0}
            setActiveIndex={setActiveIndex}
            status={stepsProgress.step0}
            active={activeIndex === 0}
            title="اطلاعات حقوقی"
        />,
        <ProgressItem
            icon={IconUser}
            index={1}
            key={1}
            setActiveIndex={setActiveIndex}
            status={stepsProgress.step1}
            active={activeIndex === 1}
            disabled={userData.data.user.is_business ? stepsDisabled.step1 : false}
            title="احراز هویت پایه"
        />,
        <ProgressItem
            icon={IconIdCardSolid}
            index={2}
            key={2}
            setActiveIndex={setActiveIndex}
            status={stepsProgress.step2}
            active={activeIndex === 2}
            title="کارت ملی"
            disabled={stepsDisabled.step2}
        />,
        <ProgressItem
            icon={IconFileContract}
            index={3}
            key={3}
            setActiveIndex={setActiveIndex}
            status={stepsProgress.step3}
            active={activeIndex === 3}
            title="ارسال تعهد نامه"
            disabled={stepsDisabled.step3 || stepsDisabled.step2}
        />,
        <ProgressItem
            icon={IconPhone}
            index={4}
            key={4}
            setActiveIndex={setActiveIndex}
            status={stepsProgress.step4}
            active={activeIndex === 4}
            title="تلفن و کد پستی"
            disabled={stepsDisabled.step4 || stepsDisabled.step3 || stepsDisabled.step2}
        />,
        <ProgressItem
            icon={IconVideo}
            index={5}
            key={5}
            setActiveIndex={setActiveIndex}
            status={stepsProgress.step5}
            active={activeIndex === 5}
            title="ارسال ویدیو"
            disabled={stepsDisabled.step5 || stepsDisabled.step4 || stepsDisabled.step3 || stepsDisabled.step2}
        />,
    ];

    if (!userData.data.user.is_business) {
        progressItems = progressItems.slice(1);
    }

    return (
        <div className="flex items-center justify-center pt-2">
            <div className="flex items-center justify-center max-xs:hidden">{progressItems}</div>
            <div className="h-full w-full xs:hidden">
                <Swiper
                    effect="coverflow"
                    grabCursor={true}
                    centeredSlides={true}
                    draggable={false}
                    allowTouchMove={false}
                    breakpoints={{
                        200: {
                            slidesPerView: 3,
                        },
                        350: {
                            slidesPerView: 4,
                        },
                    }}
                    coverflowEffect={{
                        rotate: 0,
                        stretch: -20,
                        depth: 250,
                        scale: 0.95,
                        modifier: 1,
                        slideShadows: false,
                    }}
                    modules={[EffectCoverflow]}
                    className="w-[290px]"
                    style={{ padding: "6px 0", overflow: "visible" }}
                    onSwiper={(swiper: SwiperClass) => {
                        if (getNotCompletedIndex === 0) {
                            swiper.slideTo(4);
                        } else {
                            swiper.slideTo(getNotCompletedIndex - 1);
                        }
                    }}
                    onSlideChange={(swiper: SwiperClass) => {
                        setActiveIndex(swiper.activeIndex + 1);
                    }}
                >
                    {progressItems.map((item, index) => {
                        return <SwiperSlide key={index}>{item}</SwiperSlide>;
                    })}
                </Swiper>
            </div>
        </div>
    );
};

export default WizardProgress;
