import { useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import SelfieExampleImage from "@/assets/images/selfie-example-2.png";
import ToastError from "@/components/error/ToastError";
import Button from "@/components/ui/Button";
import Description from "@/components/ui/Description";
import UploadStatus from "@/components/ui/UploadStatus";
import useUpdateSelfie from "@/hooks/queries/useUpdateSelfie";
import useUser from "@/hooks/queries/useUser";
import useUploadHandler from "@/hooks/useUploadHandler";
import { scrollToTop, verificationStatus } from "@/utils";

import SlideSection from "../SlideSection";

type SelfieProps = {
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
};

type SelfieSchema = {
    selfie_image: File | undefined;
};

const SelfieVerification: React.FC<SelfieProps> = ({ setActiveIndex }) => {
    const queryClient = useQueryClient();
    const { data: user , refetch : refetchUser} = useUser();

    const selfieStatus = verificationStatus(user, "CREDIT");
    const isSelfiePending = selfieStatus("PENDING");
    const isSelfieRejected = selfieStatus("REJECTED");
    const isSelfieConfirmed = selfieStatus("CONFIRMED");

    const {
        control,
        trigger,
        setValue,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<SelfieSchema>({
        defaultValues: {
            selfie_image: undefined,
        },
    });

    const { renderTemplate: renderTemplate1 } = useUploadHandler({
        title: "لطفا عکس سلفی را آپلود کنید",
        name: "selfie_image",
        accept: "image/*",
        setFormValue: setValue,
        control,
        disabled: isSelfieConfirmed || isSelfiePending,
        error: !!errors.selfie_image,
        rules: {
            required: !isSelfieConfirmed && !isSelfiePending,
        },
    });

    const { mutateAsync: updateSelfie, isLoading } = useUpdateSelfie();

    const submitHandler: SubmitHandler<SelfieSchema> = async (data) => {
        trigger();

        if (isValid) {
            try {
                if (!isSelfieConfirmed && !isSelfiePending) {
                    await updateSelfie({
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        selfie_image: data.selfie_image!,
                    });
                    await refetchUser();
                }

                setActiveIndex((oldIndex) => oldIndex + 1);
            } catch (e) {
                console.log(e);
                ToastError(new Error("خطایی سمت سرور رخ داده است"));
            }
        }

        queryClient.invalidateQueries(["userData"]);
    };

    const sectionSubTitle = useMemo(() => {
        if (isSelfieConfirmed) return "عکس سلفی شما تایید شده است";
        if (isSelfiePending) return "عکس سلفی شما در حال بررسی است";
        if (isSelfieRejected) return "عکس سلفی شما رد شده است";
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const sectionSubTitleStatus = useMemo(() => {
        if (isSelfieConfirmed) return "success";
        if (isSelfiePending) return "warning";
        if (isSelfieRejected) return "error";
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
        <div className="flex h-full w-full flex-col">
            <div className="flex h-full max-lg:flex-col-reverse">
                <SlideSection
                    title={`ارسال عکس سلفی ${user.data.user.is_business ? "مدیرعامل" : ""}`}
                    subTitle={sectionSubTitle}
                    subTitleStatus={sectionSubTitleStatus}
                    containerClasses="flex-1"
                    className="h-full pt-6"
                >
                    <div className="flex items-center justify-center gap-8 max-2xs:flex-col-reverse">
                        <div className="relative aspect-[9/13] w-full">
                            {renderTemplate1}
                            {(isSelfiePending || isSelfieConfirmed) && (
                                <UploadStatus
                                    image={SelfieExampleImage}
                                    text={
                                        isSelfieConfirmed
                                            ? "عکس سلفی شما تایید شده است"
                                            : "ععکس سلفی شما در حال بررسی است"
                                    }
                                    status={isSelfieConfirmed ? "success" : "pending"}
                                />
                            )}
                        </div>
                        <div className="relative aspect-[9/13] w-full overflow-hidden rounded-[15px]">
                            <img
                                src={SelfieExampleImage}
                                className="absolute h-full w-full object-cover object-top p-4 pb-0"
                                alt="selfie-example"
                            />
                            <div className="absolute bottom-0 h-[80px] w-full bg-gradient-to-t from-secondary-400 from-5% to-transparent dark:h-[50px]" />
                        </div>
                    </div>
                </SlideSection>
                <SlideSection title="توضیحات" containerClasses="flex-[0.8] xl:flex-[0.6]" separator>
                    <div className="flex w-full flex-col gap-4">
                        <Description title="مرحله اول" className="h-full">
                            کاربر گرامی جهت تایید احراز دو مرحله قبل ، یک کاغذ سفید تهیه کرده واطلاعات کارت ملی و کارت
                            بانکی خود را با پوشاندن تاریخ انقضا و CVV2 بر روی آن بچسبانید.
                        </Description>
                        <Description title="مرحله دوم">
                            سپس در قسمت زیرین عبارت{" "}
                            <span className="text-text-main">
                                &quot;جهت تایید کارت بانکی در وبسایت اول پرداخت&quot;
                            </span>{" "}
                            را بنویسید.
                        </Description>
                        <Description title="مرحله سوم">
                            کاغذ را مطابق الگو در دست گرفته و از شخصی کمک بخواهید که از شما عکس بگیرد. تصویر را در
                            جایگاه بارگذاری نمایید.
                        </Description>
                    </div>
                </SlideSection>
            </div>
            <div className="flex w-full items-center justify-between gap-4 border-t border-secondary-200 px-4 py-6 max-xs:pb-4 xs:px-6">
                <Button
                    type="button"
                    className="w-[90px] flex-shrink-0 bg-zinc-400 xs:w-[150px]"
                    disabled={isLoading}
                    disabledTooltip="دکمه غیرفعال است"
                    onClick={() => {
                        scrollToTop(1000, 150);
                        setActiveIndex((oldIndex) => oldIndex - 1);
                    }}
                >
                    قبلی
                </Button>
                <Button
                    type="button"
                    className="w-full xs:w-[150px]"
                    loading={isLoading}
                    disabled={isLoading || !isValid}
                    disabledTooltip="دکمه غیرفعال است"
                    onClick={
                        isSelfieConfirmed || isSelfiePending
                            ? () => {
                                  scrollToTop(1000, 150);
                                  setActiveIndex((oldIndex) => oldIndex + 1);
                              }
                            : handleSubmit(submitHandler)
                    }
                >
                    {isSelfieConfirmed || isSelfiePending ? "بعدی" : "تایید و بعدی"}
                </Button>
            </div>
        </div>
    );
};

export default SelfieVerification;
