import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";

import AlertBox from "@/components/ui/AlertBox";
import Button from "@/components/ui/Button";
import { ONLINE_PAYMENT_LIMIT } from "@/constants/common";
import useInvoice from "@/hooks/queries/useInvoice";
import usePayOnline from "@/hooks/queries/usePayOnline";
import { formatNumber } from "@/utils";

type OnlinePaymentSchema = {
    bank: string;
    fish_number: string;
    date: string;
    time: string;
    image: File;
    description?: string;
};

const OnlinePayment = () => {
    const params = useParams();

    const { data: invoice } = useInvoice(Number(params.invoiceId));
    const { mutateAsync: payOnline, isLoading: isPaymentLoading } = usePayOnline();

    const status = invoice?.transaction.status;

    const {
        handleSubmit,
        formState: { isValid },
    } = useForm<OnlinePaymentSchema>();

    const isPaymentDisabled =
        status !== "INIT" ||
        isPaymentLoading ||
        !isValid ||
        (invoice && invoice.transaction.amount > ONLINE_PAYMENT_LIMIT && !invoice.can_pay_online);

    const onSubmit: SubmitHandler<OnlinePaymentSchema> = async () => {
        try {
            const response = await payOnline({
                id: Number(params.invoiceId),
            });

            window.location.href = response.redirect_url;
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {invoice?.transaction && invoice.transaction.amount > ONLINE_PAYMENT_LIMIT && invoice?.can_pay_online_above_25_message && (
                <AlertBox
                    className="mb-4 mt-8"
                    description={
                        <>
                            برای پرداخت مبالغ بیش از {formatNumber(ONLINE_PAYMENT_LIMIT)} تومان در روز، با پشتیبانی{" "}
                            <Link to="/panel/ticket/new" className="text-primary hover:text-primary-400">
                                تماس
                            </Link>{" "}
                            بگیرید.
                        </>
                    }
                    type="error"
                />
            )}

            {!isPaymentDisabled && (
                <div className="mr-[6px] mt-8 space-y-4 border-r-2 border-gold px-4 pr-4 text-justify leading-loose text-text-paragraph">
                    <div>
                        جهت واریز وجه، حتما از کارت های بانکی که به نام خودتان در اول‌پرداخت ثبت و تایید شده، استفاده
                        نمایید.
                    </div>
                    <div>به آدرس صفحه‌ی بانکی که سایت سامانه شاپرک است دقت کنید سپس اطلاعات کارت خود را ثبت کنید.</div>
                    <div className={ (invoice && invoice.transaction.amount > ONLINE_PAYMENT_LIMIT && invoice?.can_pay_online_above_25_message) ? "" : "hidden"}>
                        سقف میزان واریزی هر حساب از سوی بانک مرکزی {formatNumber(ONLINE_PAYMENT_LIMIT)} تومان می باشد.
                        در صورتی که قصد واریز وجه به میزان بیشتر را دارید؛
                        <Link to="/panel/ticket/new" className="text-blue-500 transition-colors hover:text-blue-400">
                            {" "}
                            نسبت به ارسال تیکت اقدام نمایید
                        </Link>
                    </div>
                    <div>
                        با توجه به ابلاغیه بانک مرکزی در مورد نظام کارمزد، واریز ریالی از درگاه پرداخت به حساب کاربری در
                        اول پرداخت مشمول کارمزد خواهد بود.
                    </div>
                </div>
            )}

            <Button className="!mt-8 w-full" type="submit" loading={isPaymentLoading} disabledTooltip="دکمه غیرفعال است" disabled={isPaymentDisabled}>
                پرداخت آنلاین
            </Button>
        </form>
    );
};

export default OnlinePayment;
