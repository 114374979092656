import { useEffect, useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useParams, useSearchParams } from "react-router-dom";

import Spinner from "@/components/loader/Spinner";
import Button from "@/components/ui/Button";
import CashTotal from "@/components/ui/CashTotal";
import CustomCheckbox from "@/components/ui/CustomCheckbox";
import Input from "@/components/ui/Input";
import Select from "@/components/ui/Select";
import Textarea from "@/components/ui/Textarea";
import { NUMBER_INPUT_FLOATING_POINT } from "@/constants/common";
import { countries } from "@/data/CountriesList";
import {
    CashCalculationRequestType,
    CashCreateResponse,
    CashCreateService,
    Currency,
} from "@/interfaces/dashboard/order/order";

import CashRefundOptions from "../CashRefundOptions";

type SwiftCashRequest = {
    service: string;
    amount: number | "";
    fk_currency_id: number;
    meta: {
        full_name: string;
        bic: string;
        iban: string;
        country: string;
    };
    cash_type: "ADD_TO_ACCOUNT_BALANCE" | "REFUND";
    bank_id: number;
    user_desc?: string;
    rules: boolean;
};

type ServiceInstruction = {
    isLoading: boolean;
    instructions?: CashCreateResponse;
    isCashPricesLoading: boolean;
    calculatedPrice?: Currency;
    onCalculation: (data: CashCalculationRequestType) => void;
    onFormSubmit: (data: SwiftCashRequest) => void;
    onCurrencyChange: (currencyId: number) => void;
};

type SwiftCash = {
    amount: number | "";
    currency: {
        label: string;
        value: number;
    };

    fullName: string;
    bic: string;
    iban: string;
    country: {
        label: string;
        value: string;
    };

    cash_type: "ADD_TO_ACCOUNT_BALANCE" | "REFUND";
    bank_id?: {
        label: string;
        value: number;
    };
    user_desc?: string;
    rules: boolean;
    is_immediate: boolean;
};

const SwiftCashForm: React.FC<ServiceInstruction> = ({
    isLoading,
    instructions,
    isCashPricesLoading,
    calculatedPrice,
    onCurrencyChange,
    onCalculation,
    onFormSubmit,
}) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const [serviceData, setServiceData] = useState<CashCreateService>(instructions?.service);

    const [acceptRules, setAcceptRules] = useState<boolean>(false);
    const [total, setTotal] = useState<number>(0);

    const [isImmediate, setIsImmediate] = useState<boolean>(false);

    const [queryString] = useSearchParams();

    const { serviceName } = useParams();

    useEffect(() => {
        if (instructions) {
            setServiceData(instructions.service);
            setTotal(0);
        }
    }, [instructions]);

    const { control, handleSubmit, setValue } = useForm<SwiftCash>({
        defaultValues: {
            amount: queryString.get("amount") ? Number(queryString.get("amount")) : "",
            currency: {
                value: queryString.get("currency")
                    ? serviceData.currencies.find((currency) => currency.abbreviation == queryString.get("currency"))
                          ?.id
                    : serviceData?.currencies[0]?.id,
                label: queryString.get("currency")
                    ? serviceData.currencies.find((currency) => currency.abbreviation == queryString.get("currency"))
                          ?.name
                    : serviceData?.currencies[0]?.name,
            },

            fullName: "",
            bic: "",
            iban: "",
            country: {
                value: countries[0],
                label: countries[0],
            },

            cash_type: "ADD_TO_ACCOUNT_BALANCE",
            rules: false,
            user_desc: "",
            is_immediate: isImmediate,
        },
    });

    const [watchedAmount, watchedCurrency, watchedCashType] = useWatch({
        control,
        name: ["amount", "currency", "cash_type"],
        defaultValue: {
            amount: "",
            currency: {
                value: serviceData.currencies[0]?.id,
                label: serviceData.currencies[0]?.name,
            },
            cash_type: "ADD_TO_ACCOUNT_BALANCE",
        },
    });

    useEffect(() => {
        if (instructions) {
            if (instructions.service.currencies[0]) {
                setValue("currency", {
                    value:
                        (queryString.get("currency")
                            ? serviceData.currencies.find((currency) => {
                                  return currency.abbreviation == queryString.get("currency");
                              })?.id
                            : serviceData?.currencies[0]?.id) || 0,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    label: queryString.get("currency")
                        ? serviceData.currencies.find(
                              (currency) => currency.abbreviation == queryString.get("currency"),
                          )?.name
                        : serviceData?.currencies[0]?.name,
                });
            }
        }
    }, [instructions, queryString, serviceData.currencies, setValue]);

    useEffect(() => {
        if (watchedCurrency && watchedAmount && Number(watchedAmount) >= 0) {
            onCalculation({
                serviceName: serviceName?.replace("sell-", "").toUpperCase() || "",
                amount: +watchedAmount,
                currency: watchedCurrency.value || "",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchedAmount, watchedCurrency]);

    useEffect(() => {
        if (watchedCurrency && watchedCurrency.value) {
            onCurrencyChange(watchedCurrency.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchedCurrency]);

    useEffect(() => {
        setTotal(calculatedPrice?.total || 0);
    }, [calculatedPrice]);

    const submitHandler: SubmitHandler<SwiftCash> = (data) => {
        const submitData = {
            service: serviceName?.replace("sell-", "").toUpperCase() || "",
            amount: data.amount,
            fk_currency_id: data.currency.value,

            meta: {
                full_name: data.fullName,
                bic: data.bic,
                iban: data.iban,
                country: data.country.value,
            },

            rules: acceptRules,
            cash_type: data.cash_type,
            bank_id: data.bank_id?.value || -1,
            is_immediate: isImmediate,
        };

        onFormSubmit(submitData);
    };

    return (
        <form id="order-form" onSubmit={handleSubmit(submitHandler)}>
            <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-4 lg:flex-row">
                    <div className="w-full lg:w-7/12">
                        <Input
                            containerClassName="w-full"
                            variant="fill"
                            required
                            ltr
                            autoComplete="off"
                            name="amount"
                            topTitle="میزان"
                            inputMode="decimal"
                            type="number"
                            min={0}
                            step={NUMBER_INPUT_FLOATING_POINT}
                            placeholder="میزان"
                            control={control}
                            rules={{
                                required: "میزان الزامی است",
                            }}
                        />
                    </div>
                    <div className="w-full lg:w-5/12">
                        <Select
                            control={control}
                            topTitle="نوع ارز"
                            required
                            name="currency"
                            variant="fill"
                            placeholder="انتخاب کنید"
                            rules={{
                                required: "انتخاب نوع ارز الزامی است",
                            }}
                            defaultValue={{
                                value: serviceData.currencies[0]?.id,
                                label: serviceData.currencies[0]?.name,
                            }}
                            options={serviceData.currencies.map((currency) => {
                                return {
                                    value: currency.id,
                                    label: currency.name,
                                };
                            })}
                        />
                    </div>
                </div>

                <CashTotal isLoading={isCashPricesLoading} total={total} />

                <Input
                    containerClassName="w-full"
                    variant="fill"
                    required
                    name="fullName"
                    topTitle="نام و نام خانوادگی فرستنده"
                    inputMode="text"
                    type="text"
                    placeholder="نام و نام خانوادگی فرستنده"
                    control={control}
                    rules={{
                        required: "نام و نام خانوادگی فرستنده الزامی است",
                    }}
                />

                <Input
                    containerClassName="w-full"
                    variant="fill"
                    required
                    name="bic"
                    topTitle="BIC سوئیفت بانک"
                    inputMode="text"
                    type="text"
                    placeholder="سوئیفت بانک BIC"
                    control={control}
                    ltr
                    rules={{
                        required: "BIC سوئیفت بانک الزامی است",
                    }}
                />

                <Input
                    containerClassName="w-full"
                    variant="fill"
                    name="iban"
                    topTitle="شماره حساب یا IBAN"
                    inputMode="text"
                    type="text"
                    placeholder="IBAN شماره حساب یا "
                    control={control}
                    ltr
                />

                <Select
                    control={control}
                    topTitle="کشور"
                    name="country"
                    variant="fill"
                    placeholder="انتخاب کشور"
                    required
                    rules={{
                        required: "انتخاب کشور الزامی است",
                    }}
                    defaultValue={{
                        value: countries[0],
                        label: countries[0],
                    }}
                    options={countries.map((country) => {
                        return {
                            value: country,
                            label: country,
                        };
                    })}
                />

                {instructions && instructions?.can_choose_payment_method && (
                    <CashRefundOptions
                        control={control}
                        balance={instructions.balance}
                        instructions={instructions}
                        cashType={watchedCashType}
                        onIsImmediateChange={(value: boolean) => setIsImmediate(value)}
                    />
                )}

                <Textarea
                    name="user_desc"
                    control={control}
                    label="توضیحات"
                    placeholder="توضیحات خود را اینجا بنویسید"
                />

                <CustomCheckbox
                    onChange={() => setAcceptRules((prev) => !prev)}
                    label={
                        <span>
                            <a
                                href="https://avalpardakht.com/terms"
                                target="_blank"
                                rel="noreferrer nofollow"
                                className="text-primary hover:text-primary"
                            >
                                قوانین و مقررات
                            </a>{" "}
                            سایت را مطالعه کرده‌ام و آن را قبول دارم.
                        </span>
                    }
                />
                <Button type="submit" disabled={!acceptRules || isLoading} disabledTooltip="دکمه غیرفعال است">
                    {isLoading ? <Spinner /> : "ثبت درخواست"}
                </Button>
            </div>
        </form>
    );
};

export default SwiftCashForm;
