import { FC, useMemo } from "react";
import { Link } from "react-router-dom";

import Table from "@/components/ui/Table";
import { TableTdProps } from "@/components/ui/Table/TableTd";
import { Ticket } from "@/interfaces/dashboard/layout/panel/summary";
import { ticketStatus } from "@/utils/cases";

import EmptyIndicator from "../EmptyIndicator";

interface TicketTabContentProps {
    tickets: Ticket[] | undefined;
    isLoading?: boolean;
}

const TicketTabContent: FC<TicketTabContentProps> = ({ tickets, isLoading }) => {
    const tableRows = useMemo<TableTdProps[][] | undefined>(() => {
        return tickets?.map((ticket) => {
            const { color } = ticketStatus(ticket.status);

            return [
                {
                    header: "شماره تیکت",
                    children: "#" + ticket.id,
                    link: "/panel/ticket/" + ticket.id,
                },
                {
                    header: "موضوع",
                    children: ticket.title.length > 50 ? ticket.title.substring(0, 50) : ticket.title,
                },
                {
                    header: "دپارتمان",
                    children: ticket.department,
                },
                {
                    header: "اولویت",
                    children: (
                        <span
                            className={`${
                                ticket.priority == "بالا"
                                    ? "text-error"
                                    : ticket.priority == "متوسط"
                                    ? "text-yellow-500"
                                    : "text-blue-500"
                            }`}
                        >
                            {ticket.priority}
                        </span>
                    ),
                },
                {
                    header: "تاریخ ثبت",
                    children: ticket.created_at,
                },
                {
                    header: "وضعیت",
                    children: ticket.status,
                    color: color,
                },
            ];
        });
    }, [tickets]);

    return (
        <div className="my-6">
            <Table
                rows={tableRows}
                loading={isLoading}
                emptyIndicator={
                    <EmptyIndicator
                        link="/panel/ticket/new"
                        message="تیکتی برای نمایش وجود ندارد"
                        linkText="ثبت اولین تیکت"
                    />
                }
            />
            <div className="-mb-6 flex w-full justify-end pl-6 pt-6">
                <Link to="/panel/ticket/list" className="text-primary transition-colors hover:text-primary">
                    لیست همه تیکت‌ها
                </Link>
            </div>
        </div>
    );
};

export default TicketTabContent;
