import { useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";

import Categories from "@/components/order/Categories";
import Details from "@/components/order/Details";
import OrderFilterModal from "@/components/order/OrderFilterModal";
import Services from "@/components/order/Services";
import PlaceholderLayout from "@/components/placeholder/PlaceholderLayout";
import ServicesListPlaceholder from "@/components/placeholder/ServicesListPlaceholder";
import Modal from "@/components/ui/Modal";
import PageTitle from "@/components/utility/PageTitle";
import useAllServices from "@/hooks/queries/useAllServices";
import useCategoryPrices from "@/hooks/queries/useCategoryPrices";
import useLocalStorage from "@/hooks/useLocalStorage";
import { ServicesListType, ServiceTypeSummary } from "@/interfaces/dashboard/order/order";
import { isEmpty } from "@/utils";

const Order: React.FC = () => {
    const [selectedService, setSelectedService] = useState<number>();
    const [servicesList, setServicesList] = useState<ServiceTypeSummary[] | undefined>();
    const [description, setDescription] = useState<string | null>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const isMobile = useMediaQuery({ maxWidth: 575.999 });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [servicePrice, setServicePrice] = useState<any>([]);
    const [allServices, setAllServices] = useLocalStorage<ServicesListType | object>("all_services", {});
    const [showSearchModal, setShowSearchModal] = useState(false);

    const { serviceName } = useParams();

    const { data: services, refetch: refetchServices } = useAllServices(allServices);

    const { refetch, isFetching: priceLoading, data: prices } = useCategoryPrices(serviceName);

    useEffect(() => {
        if (serviceName) {
            refetch();
        }
    }, [refetch, serviceName]);

    useEffect(() => {
        if (!isEmpty(services)) {
            setAllServices(services);
        } else {
            refetchServices();
        }
    }, [refetchServices, services, setAllServices]);

    useEffect(() => {
        if (selectedService == 0) {
            setDescription(
                servicesList?.find((a) => {
                    return a.id == 0;
                })?.instruction || "",
            );

            setServicePrice(() => {
                return prices?.cashServices?.currencies
                    ?.flatMap((currency) => {
                        return {
                            title: currency.name,
                            price: currency.total,
                        };
                    })
                    .filter((price) => {
                        return price?.price;
                    });
            });
        } else if (selectedService == -1) {
            setDescription(
                servicesList?.find((a) => {
                    return a.id == -1;
                })?.instruction || "",
            );

            setServicePrice(() => {
                return prices?.cashServices?.currencies
                    ?.flatMap((currency) => {
                        return {
                            title: currency.name,
                            price: currency.total,
                        };
                    })
                    .filter((price) => {
                        return price?.price;
                    });
            });
        } else {
            const selected = services?.services?.filter((service) => {
                return service.id == selectedService;
            });
            setDescription(selected ? selected[0]?.instruction : undefined);

            setServicePrice(() => {
                let servicePrices = prices?.service
                    ?.flatMap((service) => {
                        if (service.id == selectedService) {
                            return service.currency.map((price) => {
                                return {
                                    title: price.name,
                                    price: price.total,
                                };
                            });
                        }
                    })
                    .filter((price) => {
                        return price?.price;
                    });

                if (!servicePrices) {
                    servicePrices = services?.services
                        ?.flatMap((service) => {
                            if (service.id == (servicesList ? servicesList[0]?.id : undefined)) {
                                return service.currency.map((price) => {
                                    return {
                                        title: price.name,
                                        price: price.total,
                                    };
                                });
                            }
                        })
                        .filter((price) => {
                            return price?.price;
                        });
                }

                return servicePrices;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedService, serviceName, services?.services, servicesList]);

    useEffect(() => {
        if (services) {
            setServicesList(() => {
                const list =
                    (serviceName
                        ? services?.services?.filter((service) => {
                              return service.type == serviceName;
                          })
                        : services?.services?.filter((service) => {
                              return service.type == services?.categories[0]?.slug;
                          })) || [];

                const servicesObj = services?.cashServices || {};
                // eslint-disable-next-line no-prototype-builtins
                const hasCash = servicesObj.hasOwnProperty(serviceName || "");

                if (hasCash) {
                    list.push({
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        title: "فروش " + services?.cashServices[serviceName]?.name + " به اول پرداخت",
                        slug: "sell-" + serviceName?.toLowerCase(),
                        type: serviceName + "",
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        form_file: services.cashServices[serviceName]?.form_file,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        instruction: services?.cashServices[serviceName]?.instruction,
                        id: 0,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        currency: services?.cashServices[serviceName]?.currency,
                    });
                }

                if (hasCash && serviceName == "PERFECTMONEY") {
                    list.push({
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        title: "فروش " + services?.cashServices[serviceName + "_VOUCHER"]?.name + " به اول پرداخت",
                        slug: "sell-" + serviceName?.toLowerCase() + "_voucher",
                        type: serviceName + "_VOUCHER",
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        form_file: services.cashServices[serviceName + "_VOUCHER"]?.form_file,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        instruction: services?.cashServices[serviceName + "_VOUCHER"]?.instruction,
                        id: -1,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        currency: services?.cashServices[serviceName + "_VOUCHER"]?.currency,
                    });
                }

                return list;
            });

            setSelectedService(() => {
                const filteredServices = services?.services?.filter((service) => {
                    if (serviceName) {
                        return service.type == serviceName;
                    } else {
                        return service.type == services?.categories[0]?.slug;
                    }
                });

                return filteredServices ? filteredServices[0]?.id : undefined;
            });
        }
    }, [serviceName, services]);

    useEffect(() => {
        if (serviceName && isMobile) {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    }, [isMobile, serviceName]);

    const onModalClose = () => {
        setShowModal(false);
    };

    const changeCategoryHandler = () => {
        if (isMobile) {
            setShowModal(true);
        }
    };

    const activeServiceDetails = useMemo(() => {
        return servicesList?.find((service) => service.id === selectedService);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedService]);

    useEffect(() => {
        const handleShowingSearchModal = function (event: KeyboardEvent) {
            if (event.ctrlKey && (event.key === "/" || event.key === "f" || event.key === "ب")) {
                event.preventDefault();
                
                setShowSearchModal(true);
            } else if (event.key == "Escape") {
                setShowSearchModal(false);
            }
        };

        window.addEventListener("keydown", handleShowingSearchModal);

        return () => {
            window.removeEventListener("keydown", handleShowingSearchModal);
        };
    }, []);

    return (
        <>
            <PageTitle>ثبت سفارش</PageTitle>
            {services && (
                <>
                    <div className="mb-8 rounded-lg bg-secondary-400 p-4">
                        <div
                            onClick={() => {
                                setShowSearchModal(true);
                            }}
                            className="flex w-full items-center justify-between rounded-lg border border-input-border p-4"
                        >
                            <span>جستجو</span>
                            <span dir="ltr">Ctrl+F یا Ctrl+/</span>
                        </div>
                    </div>

                    {showSearchModal && (
                        <OrderFilterModal
                            setShowSearchModal={setShowSearchModal}
                            show={showSearchModal}
                            services={services}
                        />
                    )}
                </>
            )}

            <div className="flex min-h-screen w-full items-start justify-start gap-4 sm:gap-6 md:gap-10">
                <Categories onSetCategory={changeCategoryHandler} categories={services?.categories} />
                {servicesList && servicesList.length > 0 ? (
                    <div className="hidden min-h-[1200px] w-1/2 items-start gap-4 rounded-[10px] bg-secondary-400 p-2 min-[576px]:flex sm:p-4 md:w-2/3 lg:gap-10">
                        <Services services={servicesList} setSelectedService={setSelectedService} />
                        <Details
                            service={activeServiceDetails}
                            prices={servicePrice}
                            isLoading={priceLoading ? "1" : "0"}
                            description={description || ""}
                        />
                    </div>
                ) : (
                    <ServicesListPlaceholder />
                )}

                <Modal onClose={onModalClose} show={showModal} title="" mobileSize={576}>
                    <div className="flex h-full w-full items-start justify-start">
                        {servicesList && servicesList.length > 0 ? (
                            <Services services={servicesList} setSelectedService={setSelectedService} />
                        ) : (
                            <PlaceholderLayout>
                                <div className="flex w-full flex-col gap-2">
                                    <Skeleton className="!h-[50px] !w-full" borderRadius={10} />
                                    <Skeleton className="!h-[50px] !w-full" borderRadius={10} />
                                    <Skeleton className="!h-[50px] !w-full" borderRadius={10} />
                                    <Skeleton className="!h-[50px] !w-full" borderRadius={10} />
                                    <Skeleton className="!h-[50px] !w-full" borderRadius={10} />
                                </div>
                            </PlaceholderLayout>
                        )}
                    </div>
                </Modal>
            </div>
        </>
    );
};
export default Order;
