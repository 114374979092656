import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import Button from "@/components/ui/Button";
import Input from "@/components/ui/Input";
import Textarea from "@/components/ui/Textarea";
import useAddComment from "@/hooks/queries/useAddComment";

import IconMessageLines from "../svg/message-lines";
import Modal from "../ui/Modal";

type AddCommentProps = {
    replyId: number;
    setReplyId: (id: number) => void;
};

type AddCommentSchema = {
    company: string;
    content: string;
};

const AddComment = ({ replyId, setReplyId }: AddCommentProps) => {
    const queryClient = useQueryClient();
    const { mutateAsync: addComment, isLoading } = useAddComment();
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (replyId !== 0) {
            setIsOpen(true);
        }
    }, [replyId]);

    const {
        reset,
        control,
        formState: { isValid },
        handleSubmit,
    } = useForm<AddCommentSchema>({
        defaultValues: {
            company: "",
            content: "",
        },
    });

    const onFormSubmit: SubmitHandler<AddCommentSchema> = async (data) => {
        await addComment({ ...data, parent: replyId });
        reset({
            company: "",
            content: "",
        });
        toast.success("نظر شما با موفقیت ثبت شد");
        queryClient.refetchQueries(["comments"]);
        navigate(-1);
    };

    return (
        <>
            <div className="flex items-center justify-between">
                <span className="text-[1.42rem] font-semibold text-text-main xs:text-[1.71rem]">نظرات کاربران</span>
                <Button
                    onClick={() => {
                        setReplyId(0);
                        setIsOpen(true);
                    }}
                    className="w-[150px] xs:w-[200px]"
                    icon={IconMessageLines}
                >
                    ثبت نظر جدید
                </Button>
            </div>
            <Modal
                show={isOpen}
                onClose={() => {
                    setReplyId(0);
                    setIsOpen(false);
                }}
                className="max-w-[600px]"
                title="نظر جدید"
            >
                <form className="mt-8 space-y-6 max-xs:-mx-2" onSubmit={handleSubmit(onFormSubmit)}>
                    <Input
                        control={control}
                        name="company"
                        variant="fill"
                        topTitle="شرکت و یا شغل"
                        placeholder="( اختیاری )"
                    />
                    <Textarea
                        control={control}
                        name="content"
                        label="متن نظر"
                        placeholder="لطفا نظر خود را بنویسید..."
                        rules={{
                            required: "فیلد محتوا الزامی است",
                        }}
                    />
                    <Button className="!mt-8 w-full" disabledTooltip="دکمه غیرفعال است" disabled={!isValid || isLoading} loading={isLoading}>
                        ثبت نظر
                    </Button>
                </form>
            </Modal>
        </>
    );
};

export default AddComment;
