import { InputHTMLAttributes } from "react";
import { Control, Controller } from "react-hook-form";

interface CustomRadioProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string;
    font?: string;
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rules?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: Control<any>;
    containerClassName?: string;
}

const CustomRadio: React.FC<CustomRadioProps> = ({
    label,
    font = "",
    control,
    name,
    rules,
    containerClassName,
    ...rest
}) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => {
                return (
                    <label className={`text-text-main ${font ?? ""} ${containerClassName} ${rest.disabled ? "grayscale select-none" : "cursor-pointer"}`}>
                        <div className="flex items-center gap-2 select-none">
                            <input type="radio" className="peer invisible absolute opacity-0" {...field} {...rest} />
                            <span
                                className={`relative h-4 w-4 rounded-full border-2 border-gray-400 before:absolute before:left-1/2 before:top-1/2 before:h-3 before:w-3 before:-translate-x-1/2 before:-translate-y-1/2 before:rounded-full peer-checked:border-primary peer-checked:bg-primary peer-checked:before:border-2 peer-checked:before:border-secondary peer-checked:dark:before:border-input-bg`}
                            ></span>
                            {label}
                        </div>
                    </label>
                );
            }}
        />
    );
};

export default CustomRadio;
