import { useEffect } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import FileInput from "@/components/ui/FileInput";
import Input from "@/components/ui/Input";
import Select from "@/components/ui/Select";
import Textarea from "@/components/ui/Textarea";
import { NUMBER_INPUT_FLOATING_POINT } from "@/constants/common";
import { countries } from "@/data/CountriesList";
import { CalculationRequestType, ServiceInstructionType, SubmitOrderType } from "@/interfaces/dashboard/order/order";
import { objectToArray } from "@/utils";

type OrderValidationSchema = {
    amount: number | "";
    currency: {
        value: number;
        label: string;
    };
    user_description: string | null | undefined;
    attachments?: Record<string, File>;
    passportNumber: string;
    passportFullName: string;
    bic: string;
    iban: string;
    country: {
        value: string;
        label: string;
    };
};

type SubmitOrderRequest = SubmitOrderType & {
    amount: number | "";
    fk_currency_id: number;
    meta: {
        passport_number: string;
        passport_famil: string;
        bic: string;
        iban: string;
        country: string;
    };
};

type ServiceInstruction = ServiceInstructionType & {
    onCalculation: (data: CalculationRequestType) => void;
    onFormSubmit: (data: SubmitOrderRequest, serviceId: number) => void;
};

const SwiftForm: React.FC<ServiceInstruction> = ({ instructions, onCalculation, onFormSubmit }) => {
    const [queryString] = useSearchParams();

    const { control, handleSubmit, getValues, resetField } = useForm<OrderValidationSchema>({
        defaultValues: {
            amount: queryString.get("amount") ? Number(queryString.get("amount")) : "",
            currency: {
                value: queryString.get("currency")
                    ? instructions.currency.find(
                          (currency) =>
                              currency.abbreviation.toLowerCase() == queryString.get("currency")?.toLocaleLowerCase(),
                      )?.id
                    : instructions.currency[0]?.id,
                label: queryString.get("currency")
                    ? instructions.currency.find(
                          (currency) =>
                              currency.abbreviation.toLowerCase() == queryString.get("currency")?.toLocaleLowerCase(),
                      )?.name
                    : instructions.currency[0]?.name,
            },
            user_description: "",
            passportNumber: "",
            passportFullName: "",
            bic: "",
            iban: "",
            country: {
                value: countries[0],
                label: countries[0],
            },
        },
    });

    const [watchedAmount, watchedCurrency] = useWatch({
        control: control,
        name: ["amount", "currency"],
        defaultValue: {
            amount: 0,
            currency: {
                value: instructions.currency[0]?.id,
                label: instructions.currency[0]?.name,
            },
        },
    });

    useEffect(() => {
        if (Number(watchedAmount) && +watchedAmount >= 0) {
            onCalculation({
                amount: +watchedAmount,
                fk_currency_id: watchedCurrency.value,
                serviceId: instructions.id,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchedAmount, watchedCurrency]);

    useEffect(() => {
        const amount = queryString.get("amount");
        onCalculation({
            amount: amount ? +amount : 0,
            fk_currency_id: getValues().currency.value,
            serviceId: instructions.id,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submitHandler: SubmitHandler<OrderValidationSchema> = (data) => {
        onFormSubmit(
            {
                amount: data.amount,
                fk_currency_id: data.currency.value || -1,
                user_description: data.user_description,
                meta: {
                    passport_number: data.passportNumber,
                    passport_famil: data.passportFullName,
                    bic: data.bic,
                    iban: data.iban,
                    country: data.country.value,
                },
                attachments: data.attachments ? objectToArray(data.attachments) : undefined,
            },
            instructions.id,
        );
    };

    return (
        <form id="order-form" onSubmit={handleSubmit(submitHandler)}>
            <div className="flex flex-col gap-4">
                <div className="flex max-md:flex-col md:gap-4">
                    <div className="w-full md:w-1/2 lg:w-2/3">
                        <Input
                            ltr
                            containerClassName="w-full"
                            variant="fill"
                            required
                            name="amount"
                            topTitle="میزان ارز"
                            inputMode="decimal"
                            type="number"
                            step={NUMBER_INPUT_FLOATING_POINT}
                            min={0}
                            placeholder="میزان ارز"
                            control={control}
                            rules={{
                                required: "میزان ارز الزامی است",
                            }}
                        />
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/3">
                        <Select
                            control={control}
                            topTitle="&nbsp;"
                            name="currency"
                            variant="fill"
                            placeholder="انتخاب کنید"
                            rules={{
                                required: "انتخاب واحد پول الزامی است",
                            }}
                            defaultValue={{
                                value: instructions.currency[0]?.id,
                                label: instructions.currency[0]?.name,
                            }}
                            options={instructions.currency.map((currency) => {
                                return {
                                    value: currency.id,
                                    label: currency.name,
                                };
                            })}
                        />
                    </div>
                </div>
                <Input
                    containerClassName="w-full"
                    variant="fill"
                    required
                    name="passportNumber"
                    topTitle="شماره پاسپورت"
                    inputMode="text"
                    type="text"
                    placeholder="شماره پاسپورت"
                    control={control}
                    ltr
                    rules={{
                        required: "شماره پاسپورت الزامی است",
                    }}
                />

                <Input
                    containerClassName="w-full"
                    variant="fill"
                    required
                    name="passportFullName"
                    topTitle="نام و نام خانوادگی گیرنده"
                    inputMode="text"
                    type="text"
                    placeholder="نام و نام خانوادگی گیرنده"
                    control={control}
                    ltr
                    rules={{
                        required: "نام و نام خانوادگی گیرنده الزامی است",
                    }}
                />

                <Input
                    containerClassName="w-full"
                    variant="fill"
                    required
                    name="bic"
                    topTitle="BIC سوئیفت بانک گیرنده"
                    inputMode="text"
                    type="text"
                    placeholder="سوئیفت بانک گیرنده BIC"
                    control={control}
                    ltr
                    rules={{
                        required: "BIC سوئیفت بانک گیرنده الزامی است",
                    }}
                />

                <Input
                    containerClassName="w-full"
                    variant="fill"
                    name="iban"
                    topTitle="شماره حساب یا IBAN"
                    inputMode="text"
                    type="text"
                    placeholder="IBAN شماره حساب یا "
                    control={control}
                    ltr
                />

                <Select
                    control={control}
                    topTitle="کشور"
                    name="country"
                    variant="fill"
                    placeholder="انتخاب کشور"
                    required
                    rules={{
                        required: "انتخاب کشور الزامی است",
                    }}
                    defaultValue={{
                        value: countries[0],
                        label: countries[0],
                    }}
                    options={countries.map((country) => {
                        return {
                            value: country,
                            label: country,
                        };
                    })}
                />

                <Textarea
                    name="user_description"
                    control={control}
                    label="توضیحات"
                    placeholder="توضیحات خود را اینجا بنویسید"
                />
                <FileInput control={control} name="attachments" onReset={resetField} repeater />
            </div>
        </form>
    );
};
export default SwiftForm;
