import global from "@/gate/global";
import invoice from "@/gate/invoice";
import wallet from "@/gate/wallet";

import auth from "./auth";
import base from "./base";
import broker from "./broker";
import comment from "./comment";
import error from "./error";
import order from "./order";
import panel from "./panel";
import profile from "./profile";
import ticket from "./ticket";

export default {
    ...auth,
    ...broker,
    ...global,
    ...panel,
    ...profile,
    ...order,
    ...wallet,
    ...invoice,
    ...comment,
    ...ticket,
    ...base,
    ...error,
};
