import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import ToastError from "@/components/error/ToastError";
import gate from "@/gate";

const useCashSubmit = () => {
    const navigate = useNavigate();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return useMutation<any, Error, any>({
        mutationFn: gate.submitCash,
        onSuccess(data) {
            toast.success(data.message);

            if (data && data.redirect_url) {
                navigate("/panel/cash-income/list/" + data.redirect_url?.split("/").pop());
            }
        },
        onError(error) {
            ToastError(error);
        },
    });
}

export default useCashSubmit;