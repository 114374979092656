import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import { random } from "@/utils";

const CashItemPlaceholder = () => {
    const placeholder = useMemo(() => {
        return Array(6)
            .fill(null)
            .map((_, index) => {
                return (
                    <div
                        key={index}
                        className="flex w-full items-center justify-between border-b border-secondary-200 px-6 py-4 last:border-none"
                    >
                        <Skeleton width={random(70, 120)} height={15} />
                        <Skeleton width={random(50, 120)} height={15} />
                    </div>
                );
            });
    }, []);

    return <div className="-mx-5">{placeholder}</div>;
};

export default CashItemPlaceholder;
