import api from "./api";

type GetProvincesResponse = {
    data: {
        "provinces": [
            {
                "name": string;
                "province_id": number;
            },
        ];
    };
};

type GetCitiesResponse = {
    data: {
        "cities": [
            {
                "name": string;
                "city_id": number;
                "fk_province_id": number;
            },
        ];
    };
};

export default {
    getProvinces: () => api.get<GetProvincesResponse>("/panel/api/v1/provinces"),
    getCities: (id: number) => api.get<GetCitiesResponse>(`/panel/api/v1/cities/${id}`),
};
