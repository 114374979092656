import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const useQueryParams = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const urlSearchParams = new URLSearchParams(searchParams.toString());

    const updateQueryParams = (params: object) => {
        Object.entries(params).forEach(([key, value]) => {
            if (value === undefined || value === null) {
                urlSearchParams.delete(key);
            } else {
                urlSearchParams.set(key, String(value));
            }
        });

        const search = urlSearchParams.toString();
        const query = (search ? `?${search}` : "") + location.hash;

        navigate(query);
    };

    return { updateQueryParams, searchParams };
};

export default useQueryParams;
