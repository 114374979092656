import Skeleton from "react-loading-skeleton";

const ChargeFormPlaceholder = () => {
    return (
        <div className="flex flex-col gap-2">
            <Skeleton width={66} />
            <div className="mb-2 flex items-end gap-4">
                <div className="w-7/12 md:w-3/4">
                    <Skeleton height={40} style={{ borderRadius: "10px" }} />
                </div>
                <div className="w-5/12 md:w-1/4">
                    <Skeleton height={40} style={{ borderRadius: "10px" }} />
                </div>
            </div>

            <Skeleton width={52} />
            <Skeleton height={40} style={{ borderRadius: "10px" }} />

            <Skeleton width={50} />
            <Skeleton height={80} style={{ borderRadius: "10px" }} />

            <Skeleton width={110} className="mt-4" />
            <Skeleton width={100} height={40} style={{ borderRadius: "10px" }} />
        </div>
    );
};

export default ChargeFormPlaceholder;
