import { useEffect, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";

import { CategoryType } from "@/interfaces/dashboard/order/order";

import CategoriesPlaceholder from "../placeholder/CategoriesPlaceholder";
import Category from "./Category";

type ServiceCategoriesProps = {
    categories: CategoryType[] | undefined;
    onSetCategory: () => void;
};

const Categories: React.FC<ServiceCategoriesProps> = ({ categories, onSetCategory }) => {
    const navigate = useNavigate();
    const { serviceName } = useParams();
    const isMobile = useMediaQuery({ maxWidth: 575.999 });

    useEffect(() => {
        if (!serviceName && !isMobile && categories) {
            const firstService = categories[0]?.slug;
            navigate(`/panel/order/${firstService}`, { replace: true });
        }
    }, [categories, isMobile, navigate, serviceName]);

    const renderCategories = useMemo(() => {
        return (
            <>
                {(categories &&
                    categories?.map((category) => {
                        return (
                            <Category
                                key={category.id}
                                image={category.icon}
                                link={`/panel/order/${category.slug}`}
                                text={category.title}
                                onSetCategory={onSetCategory}
                                active={serviceName == category.slug}
                            />
                        );
                    })) || <CategoriesPlaceholder />}
            </>
        );
    }, [categories, onSetCategory, serviceName]);

    return (
        <div className="flex w-full flex-shrink-0 flex-col items-start justify-start gap-2 min-[576px]:w-1/2 sm:gap-4 md:w-1/3">
            {renderCategories}
        </div>
    );
};

export default Categories;
