import { useQueryClient } from "@tanstack/react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

import Button from "@/components/ui/Button";
import useInvoice from "@/hooks/queries/useInvoice";
import usePayWallet from "@/hooks/queries/usePayWallet";
import useUser from "@/hooks/queries/useUser";
import { formatNumber } from "@/utils";

type WalletPaymentSchema = {
    bank: string;
    fish_number: string;
    date: string;
    time: string;
    image: File;
    description?: string;
};

const WalletPayment = () => {
    const queryClient = useQueryClient();
    const params = useParams();

    const navigate = useNavigate();

    const { data: user } = useUser();
    const { data: invoice } = useInvoice(Number(params.invoiceId));
    const { mutateAsync: payWallet, isLoading: isPaymentLoading } = usePayWallet();

    const status = invoice?.transaction.status;
    const isPaymentDisabled = status !== "INIT" || user.data.balance < (invoice?.transaction.amount ?? 0);

    const {
        handleSubmit,
        formState: { isValid },
    } = useForm<WalletPaymentSchema>();

    const onSubmit: SubmitHandler<WalletPaymentSchema> = async () => {
        try {
            const response = await payWallet({
                id: Number(params.invoiceId),
            });

            await queryClient.invalidateQueries(["userData"]);
            await queryClient.invalidateQueries(["invoice", Number(params.invoiceId)]);

            if (response.error == false) {
                toast.success(response.message);
                navigate("/panel/order/list/" + response.redirect_url.split("/").pop());
            } else {
                toast.error("خطا در روند پرداخت صورت حساب");
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {/*{invoice?.transaction.type !== "addfund" && !isPaymentDisabled ? (*/}
            <div className="flex items-center gap-4 px-4 pt-8">
                <div className="text-[1.14rem] text-text-main">موجودی کیف پول</div>
                <div
                    className={`rounded-[10px] bg-secondary-200 px-4 py-2 text-[1.42rem] ${
                        user.data.balance > 0 ? "text-success" : "text-error"
                    }`}
                >
                    <span className="ms-2 brightness-125" dir="ltr">
                        {user && formatNumber(user.data.balance)}{" "}
                    </span>
                    تومان
                </div>
            </div>

            <Button
                className="!mt-8 w-full"
                type="submit"
                loading={isPaymentLoading}
                disabled={isPaymentLoading || !isValid || isPaymentDisabled}
                disabledTooltip="دکمه غیرفعال است"
            >
                پرداخت با کیف پول
            </Button>
        </form>
    );
};

export default WalletPayment;
