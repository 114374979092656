import { useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import IconHeadset from "@/components/svg/headset";
import IconPhone from "@/components/svg/phone";
import BoxContainer from "@/components/ui/BoxContainer";
import Button from "@/components/ui/Button";
import FileInput from "@/components/ui/FileInput";
import Input from "@/components/ui/Input";
import Select from "@/components/ui/Select";
import Textarea from "@/components/ui/Textarea";
import Title from "@/components/ui/Title";
import useAddTicket from "@/hooks/queries/useAddTicket";
import useTicketInfo from "@/hooks/queries/useTicketInfo";
import useQueryParams from "@/hooks/useQueryParams";
import useGoogleReCaptchaV3 from "@/hooks/useRecaptcha";
import { objectToArray } from "@/utils";

type AddTicketSchema = {
    title: string;
    priority: {
        value: string;
        label: string;
    };
    department: {
        value: string;
        label: string;
    };
    order: {
        value: string;
        label: string;
    };
    content: string;
    attachment: Record<string, File>;
};

const AddTicket = () => {
    const navigate = useNavigate();
    const { data: ticketInfo } = useTicketInfo();
    const [isRecaptchaLoading, setIsRecaptchaLoading] = useState(false);
    const { executeReCaptcha } = useGoogleReCaptchaV3({ action: "AddTicket" });

    const { searchParams } = useQueryParams();
    const order = searchParams.get("order");

    const { mutateAsync: addTicket, isLoading: isAddTicketLoading } = useAddTicket();
    const { control, handleSubmit, reset, resetField } = useForm<AddTicketSchema>({
        defaultValues: {
            attachment: {},
            order: {
                value: order ?? undefined,
                label: order ? ` سفارش شماره ${order}#` : "",
            },
            priority: {
                value: "DOWN",
                label: "پایین",
            },
            department: {
                value: "1",
                label: "مدیریت",
            },
        },
    });

    const getCaptchaToken = async () => {
        let captchaToken: string | undefined = undefined;

        if (ticketInfo?.is_captcha_enable) {
            setIsRecaptchaLoading(true);
            captchaToken = await executeReCaptcha("Login");
            setIsRecaptchaLoading(false);
        }

        return captchaToken;
    };

    const onAddTicket: SubmitHandler<AddTicketSchema> = async (data) => {
        const token = await getCaptchaToken();

        const response = await addTicket({
            content: data.content,
            attachment: objectToArray<File>(data.attachment),
            priority: data.priority.value,
            title: data.title,
            fk_department_id: data.department.value,
            fk_order_id: data.order.value,
            "g-recaptcha-response": token,
        });

        reset({
            title: "",
            order: {
                value: "",
                label: "",
            },
            content: "",
            attachment: {},
        });

        navigate(`/panel/ticket/${response?.ticket_id}`);
    };

    const ordersList = useMemo(() => {
        if (ticketInfo?.orders) {
            return ticketInfo.orders.map((order) => {
                const title = order?.service ? order.service.title : null;
                return {
                    value: order.id,
                    label: (title ? `${title} - ` : "سفارش شماره ") + order.id + "#",
                };
            });
        }
        return [];
    }, [ticketInfo?.orders]);

    const openChat = () => {
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            Goftino.open();
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div className="flex min-h-screen w-full gap-8 max-lg:flex-col">
            <div className="flex-1">
                <BoxContainer colorBox={false} className="">
                    <Title title="افزودن تیکت جدید" containerClasses="-mx-5 -mt-5" />
                    <form onSubmit={handleSubmit(onAddTicket)} className="mt-4 flex flex-col gap-8">
                        <div className="flex w-full gap-8 max-xs:flex-col">
                            <Input
                                containerClassName="flex-1"
                                topTitle="موضوع تیکت"
                                variant="fill"
                                control={control}
                                name="title"
                                rules={{ required: "موضوع تیکت الزامی است" }}
                            />
                            <Select
                                variant="fill"
                                className="flex-1"
                                control={control}
                                name="order"
                                isSearchable
                                topTitle="شماره سفارش (اختیاری)"
                                options={ordersList}
                                rules={{}}
                            />
                        </div>
                        <div className="flex w-full gap-8 max-xs:flex-col">
                            <Select
                                variant="fill"
                                className="flex-1"
                                control={control}
                                name="priority"
                                placeholder="اولویت"
                                options={[
                                    {
                                        value: "UP",
                                        label: "بالا",
                                    },
                                    {
                                        value: "MEDIUM",
                                        label: "متوسط",
                                    },
                                    {
                                        value: "DOWN",
                                        label: "پایین",
                                    },
                                ]}
                                topTitle="اولویت تیکت را انتخاب کنید"
                                rules={{ required: "لطفا اولویت را انتخاب کنید" }}
                            />
                            <Select
                                className="flex-1"
                                control={control}
                                name="department"
                                placeholder="دپارتمان"
                                variant="fill"
                                options={[
                                    {
                                        value: "1",
                                        label: "مدیریت",
                                    },
                                    {
                                        value: "2",
                                        label: "پشتیبانی",
                                    },
                                    {
                                        value: "3",
                                        label: "فروش",
                                    },
                                    {
                                        value: "4",
                                        label: "مالی",
                                    },
                                    {
                                        value: "5",
                                        label: "پردازش",
                                    },
                                    {
                                        value: "6",
                                        label: "توسعه",
                                    },
                                ]}
                                topTitle="دپارتمان را انتخاب کنید"
                                rules={{ required: "لطفا دپارتمان را انتخاب کنید" }}
                            />
                        </div>
                        <Textarea
                            name="content"
                            control={control}
                            label="متن تیکت"
                            placeholder="متن تیکت خود را اینجا بنویسید"
                            rules={{
                                required: "متن تیکت الزامی است",
                                minLength: {
                                    value: 10,
                                    message: "متن تیکت نباید کمتر از 10 کاراکتر باشد",
                                },
                            }}
                        />
                        <div>
                            <FileInput
                                control={control}
                                name="attachment"
                                onReset={resetField}
                                disabled={isAddTicketLoading}
                                repeater
                            />
                        </div>
                        <Button
                            type="submit"
                            className="mt-4"
                            disabled={isAddTicketLoading || isRecaptchaLoading}
                            disabledTooltip="دکمه غیرفعال است"
                            loading={isAddTicketLoading || isRecaptchaLoading}
                        >
                            ثبت تیکت
                        </Button>
                    </form>
                </BoxContainer>
            </div>
            <div className="h-min w-[350px] space-y-8 max-lg:hidden">
                <BoxContainer staticColorBox staticColorBoxPosition="top-left">
                    <div className="flex items-center">
                        <div className="ml-4 flex h-[60px] w-[60px] items-center justify-center rounded-full bg-secondary-100/50">
                            <IconHeadset className="h-[60%] fill-primary" />
                        </div>
                        <span className="mt-2 block text-[1.4rem] font-semibold text-text-main">
                            در هر قدم کنار شما هستیم
                        </span>
                    </div>

                    <p className="mt-6 block w-full text-justify indent-4 text-[1rem] font-light leading-8">
                        واحدهای فروش و پردازش مجموعه اول پرداخت همگام با واحد پشتیبانی در کمترین زمان ممکن سفارش‌ها و
                        تیکت‌های شما را مورد بررسی قرار می‌دهند و به اجرا می‌رسانند.
                    </p>
                    <p className="mt-2 block w-full text-justify indent-4 text-[1rem] font-light leading-8">
                        لازم به ذکر است که تمامی واحدهای مجموعه اول پرداخت همه روزه از
                        <span className="font-black"> ساعت ۸:۳۰ صبح تا ۱۱:۳۰ شب </span>و روزهای تعطیل از
                        <span className="font-black"> ساعت ۱۰ صبح الی ۱۱:۳۰ شب </span>
                        پاسخگوی درخواست‌ها و سوالات مشتریان عزیز هستند.
                    </p>
                    <div className="mt-[77px] space-y-6">
                        <div className="flex w-full items-center justify-end gap-4">
                            <a href="tel:07138330714" className="text-[1.14rem] font-semibold">
                                071-38330714
                            </a>
                            <div>
                                <IconPhone className="h-[16px] fill-primary" />
                            </div>
                        </div>

                        <div className="flex w-full items-center justify-end gap-4">
                            <a href="tel:02191015998" className="text-[1.14rem] font-semibold">
                                021-91015998
                            </a>
                            <div>
                                <IconPhone className="h-[16px] fill-primary" />
                            </div>
                        </div>
                    </div>
                    <Button className="mt-6 w-full" variant="stroke" onClick={openChat}>
                        تماس با پشتیبانی
                    </Button>
                </BoxContainer>
            </div>
        </div>
    );
};

export default AddTicket;
