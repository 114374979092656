import { ClubTask } from "@/interfaces/dashboard/club/club";

import api from "../api";

export type GetNotificationsResponse = {
    "notifications": {
        "userUnreadNotificationsCount": number;
        "userUnreadNotifications": {
            "id": string;
            "type": string;
            "notifiable_type": string;
            "notifiable_id": number;
            "data": {
                id: string;
                "msg": string;
                "url": string;
                "date": string;
                "content": string;
            };
            "read_at": string | null;
            "created_at": string;
            "updated_at": string;
        }[];
    };
    "notices": {
        "userNotifyCount": number;
        "notifications_users_items": {
            "id": number;
            "title": string;
            "content": string;
            "created_at": string;
        }[];
    };
};

export type GetNotificationsListResponse = {
    "data": [
        {
            "type": string;
            "type_original": string;
            "content": string;
            "created_at": string;
            data: {
                id: string;
                date: string;
                url: string;
                msg: string;
                vcount: number;
            };
        },
    ];
    "links": {
        "first": string;
        "last": string;
        "prev": string | null;
        "next": string | null;
    };
    "meta": {
        "current_page": number;
        "from": number;
        "last_page": number;
        "path": string;
        "per_page": string;
        "to": number;
        "total": number;
    };
};

export type GetClubTasksResponse = ClubTask[];

export type GetClubGiftsResponse = ClubTask[];

export type GetClubHistoryResponse = {
    "data": {
        "description": string;
        "point": string;
        "after_point": string;
        "before_point": string;
        "is_gain": boolean;
        "created_at": string;
    }[];
    "links": {
        "first": string;
        "last": string;
        "prev": null | string;
        "next": null | string;
    };
    "meta": {
        "current_page": number;
        "from": number;
        "last_page": number;
        "path": string;
        "per_page": string;
        "to": number;
        "total": number;
    };
};

export type EarnPointResponse = {
    "meta": null;
    "meta_type": string;
    "isModified": boolean;
    "message": string;
    "newPoint": number;
    "point": number;
};

export type EarnRewardResponse = {
    "meta": {
        "code": string;
        "discount": number;
        "is_percent": boolean;
        "expiration_day": number;
        "expire_time": string;
    };
    "meta_type": string;
    "isModified": boolean;
    "message": string;
    "newPoint": number;
    "point": number;
};

export default {
    user: () => api.get("/panel/api/v1/profile?size=150"),
    panelSummary: () => api.get("/panel/api/v1"),
    getNotification: () => api.get<GetNotificationsResponse>("/panel/api/v1/notifications"),
    getNotificationList: (page: number, perPage: number) =>
        api.get<GetNotificationsListResponse>(`/panel/api/v1/notifications/list?per_page=${perPage}&page=${page}`),
    markAsRead: (data: { id: string }) => api.get<GetNotificationsResponse>(`/panel/api/v1/notifications/${data.id}`),
    getClubTasks: () => api.get<GetClubTasksResponse>("/panel/api/v1/avalClub/tasks"),
    getClubGifts: () => api.get<GetClubGiftsResponse>("/panel/api/v1/avalClub/rewards"),
    getClubHistory: (page: number, perPage: number) =>
        api.get<GetClubHistoryResponse>(`/panel/api/v1/avalClub/point/histories?page=${page}&per_page=${perPage}`),
    earnPoint: (slug: string) => api.post<EarnPointResponse>(`/panel/api/v1/avalClub/point/gain/`, { slug }),
    earnReward: (slug: string) => api.post<EarnRewardResponse>(`/panel/api/v1/avalClub/reward/get`, { slug }),
};
