import classNames from "classnames";
import { Link } from "react-router-dom";

import { StatusTypes } from "@/interfaces/dashboard/layout/panel/panel";

const Status: React.FC<StatusTypes> = ({ status, tooltip, icon: Icon, needVideoVerification, type }) => {
    const containerClasses = classNames({
        "w-[30px] min-[930px]:w-[35px] min-[1350px]:w-[40px] h-[30px] min-[930px]:h-[35px] min-[1350px]:h-[40px] border-2 rounded-full flex justify-center items-center": 1,
        "border-primary":
            (type == "VIDEO" && needVideoVerification === 0) || status === "CONFIRMED" || status === "PENDING",
        "border-error": status === "REJECTED",
        "border-secondary-200": type == "VIDEO" ? needVideoVerification === 1 && status === "INIT" : status === "INIT",
        "border-dashed": status === "PENDING",
        "border-dotted border-primary": type == "VIDEO" && needVideoVerification === 0,
    });

    const iconClasses = classNames({
        "h-[12px] min-[930px]:h-[15px] min-[1350px]:h-[18px]": 1,
        "fill-primary": (type == "VIDEO" && needVideoVerification === 0) || status === "CONFIRMED",
        "fill-icon-disabled": status === "INIT" || status === "PENDING",
        "fill-error": status === "REJECTED",
    });

    return (
        <Link
            to={type === "EMAIL" ? "/panel/profile#profile" : "/panel/authentication"}
            className={containerClasses}
            data-tooltip-id="main-tooltip"
            data-tooltip-content={tooltip}
            data-tooltip-place="bottom"
        >
            <Icon className={iconClasses} />
        </Link>
    );
};

export default Status;
