import { useMutation } from "@tanstack/react-query";

import ToastError from "@/components/error/ToastError";
import gate from "@/gate";

type ForgotPasswordRequest = {
    email: string;
};

const useForgotPassword = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return useMutation<any, Error, ForgotPasswordRequest>({
        mutationFn: gate.forgotPassword,
        onError(error) {
            ToastError(error);
        },
    });
};

export default useForgotPassword;
