import { ReactNode } from "react";

import IconCircleCheckDuotone from "@/components/svg/circle-check-duotone";
import IconCircleExclamation from "@/components/svg/circle-exclamation";
import IconCircleXmarkDuotone from "@/components/svg/circle-xmark-duotone";

type AlertBoxProps = {
    title?: string;
    description: string | ReactNode;
    type?: "info" | "success" | "error" | "warning" | "danger";
    className?: string;
    customColor?: string;
};

const AlertBox: React.FC<AlertBoxProps> = ({ description, title, type = "info", className, customColor }) => {
    const colorClasses = {
        info: "bg-blue-400 border-blue-400 bg-opacity-5",
        error: "bg-error border-error bg-opacity-5",
        danger: "bg-error border-error bg-opacity-5",
        warning: "bg-warning border-warning bg-opacity-5",
        success: "bg-success border-success bg-opacity-5",
    };

    const iconTypes = {
        info: <IconCircleExclamation className="h-[20px] shrink-0 fill-blue-400" style={{ fill: customColor }} />,
        error: <IconCircleXmarkDuotone className="h-[20px] shrink-0 fill-error" style={{ fill: customColor }} />,
        danger: <IconCircleXmarkDuotone className="h-[20px] shrink-0 fill-error" style={{ fill: customColor }} />,
        warning: <IconCircleExclamation className="h-[20px] shrink-0 fill-warning" style={{ fill: customColor }} />,
        success: <IconCircleCheckDuotone className="h-[20px] shrink-0 fill-success" style={{ fill: customColor }} />,
    };

    return (
        <div
            className={`flex w-full gap-[10px] rounded-[10px] border p-3 ${colorClasses[type]} ${className}`}
            style={{ backgroundColor: `${customColor}10`, borderColor: customColor }}
        >
            {iconTypes[type]}
            <div className="flex flex-col">
                {title && <h3 className="mt-px h-[18px] text-text-main">{title}</h3>}
                <div className={`${title ? "mt-2" : ""} w-full text-[0.85rem] leading-loose text-text-paragraph`}>
                    {description}
                </div>
            </div>
        </div>
    );
};

export default AlertBox;
