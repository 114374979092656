import { useQuery } from "@tanstack/react-query";

import gate from "@/gate";

const useCities = (city = 1, enabled = true) => {
    return useQuery(["cities-list", city], () => gate.getCities(city), {
        select: (data) => {
            return data?.data?.cities?.map((city) => {
                return {
                    value: city.city_id,
                    label: city.name,
                };
            });
        },
        enabled: enabled,
    });
};
export default useCities;
