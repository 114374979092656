import classNames from "classnames";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";

type SliderItem = {
    children: React.ReactNode;
    index: number;
    activeIndex: number;
    completed?: boolean;
};

const SliderItem: React.FC<SliderItem> = ({ children, index, activeIndex, completed }) => {
    const timer = useRef<number | null>(null);
    const [windowWidth, setWindowWidth] = useState(2000);

    useEffect(() => {
        setWindowWidth(window!.innerWidth);

        const calcWindowWidth = () => {
            if (timer.current) clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                setWindowWidth(window!.innerWidth);
            }, 50);
        };

        window.addEventListener("resize", calcWindowWidth);
        return () => {
            window.removeEventListener("resize", calcWindowWidth);
        };
    }, []);

    const diffFromActiveSlide = activeIndex - index;
    const isPrevSlide = index < activeIndex;

    const times = Math.abs(diffFromActiveSlide);

    const scaleRatio = 0.12;
    const translateRatio = 85;

    const containerClasses = classNames({
        "flex w-full items-start justify-center border-secondary-200 bg-secondary-400 shadow-main rounded-[15px] dark:border-2 border lg:w-[90%]": 1,
        "h-auto relative": activeIndex === index,
        "h-[600px] absolute overflow-hidden": activeIndex !== index,
    });

    return (
        <motion.div
            className={containerClasses}
            initial={false}
            animate={{
                scale:
                    windowWidth < 1024 || diffFromActiveSlide === 0
                        ? 1
                        : isPrevSlide
                        ? Math.abs(times * scaleRatio - 1)
                        : 1,
                translateX: isPrevSlide
                    ? windowWidth < 1024
                        ? times * windowWidth
                        : times * translateRatio
                    : times * -windowWidth,
                opacity: diffFromActiveSlide === 0 ? 1 : Math.abs(diffFromActiveSlide) > 1 ? 0 : 1,
            }}
            transition={{
                type: "spring",
                damping: 35,
                stiffness: 300,
            }}
        >
            <div
                className={`h-full w-full bg-secondary-400 transition-opacity ${
                    activeIndex !== index ? "z-50 opacity-1 absolute" : "-z-10 opacity-0 absolute"
                } left-0`}
            />
            {children}
        </motion.div>
    );
};

export default SliderItem;
