import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import DailyPoints from "@/components/customer-club/DailyPoints";
import Task from "@/components/customer-club/Task";
import useClubTasks from "@/hooks/queries/useClubTasks";

const Tasks = () => {
    const { data: clubTasks } = useClubTasks();

    const tasksPlaceholder = Array.from({ length: 4 }).map((_, i) => (
        <div className="flex gap-4 rounded-[10px] border border-secondary-200 p-4 max-xs:flex-col" key={i}>
            <Skeleton className="aspect-square h-min shrink-0 xs:!w-[100px] sm:!w-[130px]" borderRadius={10} />
            <div className="flex w-full flex-col justify-between px-2 py-4">
                <div className="flex flex-col gap-2">
                    <Skeleton width="20%" />
                    <Skeleton width="60%" />
                </div>
                <div className="mt-4 flex justify-between gap-2">
                    <Skeleton width={100} height={20} />
                    <Skeleton width={60} height={20} />
                </div>
            </div>
        </div>
    ));

    const renderTasks = useMemo(() => {
        if (clubTasks) {
            const tasks = clubTasks.tasks.filter((task) => task.can_earn);
            return tasks.map((task) => <Task {...task} key={task.slug} />);
        }
        return tasksPlaceholder;
    }, [clubTasks]);

    const renderEarnedTasks = useMemo(() => {
        if (clubTasks) {
            const tasks = clubTasks.tasks.filter((task) => !task.can_earn);
            return tasks.map((task) => <Task {...task} key={task.slug} />);
        }
        return tasksPlaceholder;
    }, [clubTasks]);

    return (
        <>
            {clubTasks?.dailyTask && <DailyPoints />}

            {renderTasks.length > 0 && (
                <>
                    <div className="-mx-5 pt-10">
                        <div className="border-b border-secondary-200 px-10 pb-3 text-[1.42rem]">ماموریت‌ها</div>
                    </div>
                    <div className="mt-8 grid grid-cols-1 gap-8 min-[950px]:grid-cols-2">{renderTasks}</div>
                </>
            )}
            {renderEarnedTasks.length > 0 && (
                <>
                    <div className="-mx-5 pt-10">
                        <div className="border-b border-secondary-200 px-10 pb-3 text-[1.42rem]">
                            ماموریت‌های تکمیل شده
                        </div>
                    </div>
                    <div className="mt-8 grid grid-cols-1 gap-8 min-[950px]:grid-cols-2">{renderEarnedTasks}</div>
                </>
            )}
        </>
    );
};

export default Tasks;
