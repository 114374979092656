import { useEffect, useMemo } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import FileInput from "@/components/ui/FileInput";
import Input from "@/components/ui/Input";
import Select from "@/components/ui/Select";
import Textarea from "@/components/ui/Textarea";
import { NUMBER_INPUT_FLOATING_POINT } from "@/constants/common";
import { CalculationRequestType, ServiceInstructionType, SubmitOrderType } from "@/interfaces/dashboard/order/order";
import { objectToArray } from "@/utils";

type OrderValidationSchema = {
    serviceId: number;
    amount: number | "";
    currency: {
        value: number;
        label: string;
    };
    email: string;
    wallet: string;
    user_description: string | null | undefined;
    attachments?: Record<string, File>;
};

type SubmitOrderRequest = SubmitOrderType & {
    amount: number | "";
    fk_currency_id: number;
    meta: {
        email?: string;
        wallet?: string;
    };
};

type ServiceInstruction = ServiceInstructionType & {
    onCalculation: (data: CalculationRequestType) => void;
    onFormSubmit: (data: SubmitOrderRequest, serviceId: number) => void;
};

const ChargeForm: React.FC<ServiceInstruction> = ({ instructions, onCalculation, onFormSubmit }) => {
    const [queryString] = useSearchParams();

    const walletConstant = useMemo(() => {
        return instructions.constants?.find((item) => {
            return item.key == "meta.wallet";
        });
    }, [instructions.constants]);

    const { control, handleSubmit, getValues, resetField } = useForm<OrderValidationSchema>({
        defaultValues: {
            amount: queryString.get("amount") ? Number(queryString.get("amount")) : "",
            currency: {
                value: queryString.get("currency")
                    ? instructions.currency.find(
                          (currency) =>
                              currency.abbreviation.toLowerCase() == queryString.get("currency")?.toLocaleLowerCase(),
                      )?.id
                    : instructions.currency[0]?.id,
                label: queryString.get("currency")
                    ? instructions.currency.find(
                          (currency) =>
                              currency.abbreviation.toLowerCase() == queryString.get("currency")?.toLocaleLowerCase(),
                      )?.name
                    : instructions.currency[0]?.name,
            },
            email: "",
            wallet: walletConstant ? walletConstant.value : "",
            user_description: "",
        },
    });

    const [watchedAmount, watchedCurrency] = useWatch({
        control: control,
        name: ["amount", "currency"],
        defaultValue: {
            amount: 0,
            currency: {
                value: instructions.currency[0]?.id,
                label: instructions.currency[0]?.name,
            },
        },
    });

    useEffect(() => {
        if (Number(watchedAmount) && +watchedAmount >= 0) {
            onCalculation({
                amount: +watchedAmount,
                fk_currency_id: watchedCurrency.value,
                serviceId: instructions.id,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchedAmount, watchedCurrency]);

    useEffect(() => {
        const amount = queryString.get("amount");
        onCalculation({
            amount: amount ? +amount : 0,
            fk_currency_id: getValues().currency.value,
            serviceId: instructions.id,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submitHandler: SubmitHandler<OrderValidationSchema> = (data) => {
        onFormSubmit(
            {
                amount: data.amount,
                fk_currency_id: data.currency.value,
                meta: {
                    email: data.email,
                    wallet: data.wallet,
                },
                user_description: data.user_description,
                attachments: data.attachments ? objectToArray(data.attachments) : undefined,
            },
            instructions.id,
        );
    };

    return (
        <form id="order-form" onSubmit={handleSubmit(submitHandler)}>
            <div className="flex flex-col gap-4">
                <div className="flex flex-col md:flex-row md:gap-4">
                    <div className="w-full md:w-1/2 lg:w-2/3">
                        <Input
                            ltr
                            containerClassName="w-full"
                            variant="fill"
                            topTitle="مبلغ شارژ"
                            required
                            inputMode="decimal"
                            min={0}
                            step={NUMBER_INPUT_FLOATING_POINT}
                            type="number"
                            placeholder="مبلغ شارژ"
                            control={control}
                            name="amount"
                            rules={{
                                required: "مبلغ شارژ الزامی است",
                            }}
                        />
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/3">
                        <Select
                            control={control}
                            name="currency"
                            topTitle="&nbsp;"
                            variant="fill"
                            placeholder="انتخاب کنید"
                            rules={{
                                required: "انتخاب واحد پول الزامی است",
                            }}
                            defaultValue={{
                                value: instructions.currency[0]?.id,
                                label: instructions.currency[0]?.name,
                            }}
                            options={instructions.currency.map((currency) => {
                                return {
                                    value: currency.id,
                                    label: currency.name,
                                };
                            })}
                        />
                    </div>
                </div>
                {instructions.form_file.endsWith("perfectmoney.charge") ? (
                    <Input
                        containerClassName="w-full"
                        variant="fill"
                        topTitle="کیف پول پرفکت مانی"
                        required
                        inputMode="text"
                        placeholder="کیف پول پرفکت مانی"
                        ltr
                        type={walletConstant ? "hidden" : "text"}
                        readOnly={walletConstant ? true : false}
                        control={control}
                        name="wallet"
                        rules={{
                            required: "کیف پول پرفکت مانی الزامی است",
                        }}
                    />
                ) : (
                    <Input
                        containerClassName="w-full"
                        variant="fill"
                        type={walletConstant ? "hidden" : "text"}
                        topTitle={
                            instructions.form_file.includes("wise")
                                ? "ایمیل وایز، Wisetag یا Account Number وایز"
                                : "ایمیل پی پال"
                        }
                        required
                        inputMode="text"
                        placeholder={
                            instructions.form_file.includes("wise")
                                ? "ایمیل وایز، وایز تگ یا شماره حساب وایز"
                                : "ایمیل پی پال"
                        }
                        ltr
                        control={control}
                        name="email"
                        rules={{
                            required: `${
                                instructions.form_file.includes("wise") ? "وارد کردن این فیلد" : "ایمیل پی پال"
                            } الزامی است`,
                        }}
                    />
                )}

                <Textarea
                    name="user_description"
                    control={control}
                    label="توضیحات"
                    placeholder="توضیحات خود را اینجا بنویسید"
                />
                <FileInput control={control} name="attachments" onReset={resetField} repeater />
            </div>
        </form>
    );
};
export default ChargeForm;
