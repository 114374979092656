import { useQuery } from "@tanstack/react-query";

import gate from "@/gate";
import { PanelSummaryProps } from "@/interfaces/dashboard/layout/panel/summary";

const usePanelSummary = () => {
    return useQuery<unknown, Error, PanelSummaryProps>({
        queryFn: gate.panelSummary,
        queryKey: ["panelSummary"],
    });
};

export default usePanelSummary;
