import { Link } from "react-router-dom";

import EmptySVG from "@/components/ui/EmptySVG";

// import EmptyImage from "@/assets/images/illustration/transactions.svg";

type EmptyIndicatorProps = {
    message?: string;
    link?: string;
    linkText?: string;
    className?: string;
};

const EmptyIndicator: React.FC<EmptyIndicatorProps> = ({
    message = "رکوردی برای نمایش وجود ندارد",
    link,
    linkText,
    className,
}) => {
    return (
        <div className="mt-14 flex w-full flex-col items-center justify-center gap-1">
            <div className={className || "w-1/3 max-w-[50%]"}>
                <EmptySVG />
            </div>
            <p className="text-text-main">{message}</p>
            {link && (
                <Link
                    className="mt-4 flex h-[40px] w-full max-w-[200px] items-center justify-center gap-3 rounded-[8px] border-2 border-primary bg-primary contrast:bg-transparent contrast:border contrast:border-primary contrast:text-primary px-3 text-[1.14rem] text-white transition-all hover:bg-primary hover:brightness-110 active:translate-y-1"
                    to={link}
                >
                    {linkText || "کلیک کنید"}
                </Link>
            )}
        </div>
    );
};
export default EmptyIndicator;
