import { RouteObject } from "react-router-dom";

import Logout from "@/pages/auth/Logout";
import DashboardLayout from "@/pages/dashboard/layout/DashboardLayout";
import LoginWithToken from "@/pages/dashboard/loginWithToken/LoginWithToken";
import Index from "@/pages/Index";
import OrderRoutes from "@/routes/OrderRoutes";
import UserRoutes from "@/routes/UserRoutes";

import AuthRoutes from "./AuthRoutes";
import BrokerRoutes from "./BrokerRoutes";
import CashIncomeRoutes from "./CashIncomeRoutes";
import InvoiceRoutes from "./InvoiceRoutes";
import PanelRoutes from "./PanelRoutes";
import TicketRoutes from "./TicketRoutes";

const routes: RouteObject[] = [
    {
        path: "/",
        element: <Index />,
    },
    {
        path: "/panel/",
        element: <DashboardLayout />,
        children: [
            ...PanelRoutes,
            ...UserRoutes,
            ...OrderRoutes,
            ...InvoiceRoutes,
            ...CashIncomeRoutes,
            ...TicketRoutes,
        ],
    },
    {
        path: "/auth/logout",
        element: <Logout />,
    },
    {
        path: "/panel/login-with-token",
        element: <LoginWithToken />,
    },
    ...AuthRoutes,
    ...BrokerRoutes,
];

export default routes;
