import { useContext } from "react";

import { Context, ContextType } from "@/store/Context-store";

const GrayscaleSetting = () => {
    const { setGrayScaleValue, grayScaleValue, setIsSettingsChanged } = useContext(Context) as ContextType;

    return (
        <div className="relative mb-8 mt-12 flex items-center justify-center">
            <input
                onChange={(e) => {
                    const value = Number(e.target.value);
                    setGrayScaleValue(value);
                    setIsSettingsChanged(true);
                }}
                dir="ltr"
                type="range"
                className="custom-range-slider-without-thumb my-4"
                step={1}
                value={grayScaleValue}
            />
            <div className="absolute -z-10 flex h-[14px] w-full items-center justify-evenly rounded-full bg-red-500 bg-gradient-to-r from-primary to-gray-400"></div>
        </div>
    );
};

export default GrayscaleSetting;
