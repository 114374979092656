import { useMemo } from "react";
import { Link } from "react-router-dom";

import IconFileZipper from "@/components/svg/file-zipper";
import { fileExtension } from "@/utils";

type TicketReplyProps = {
    avatar: string;
    name: string;
    attachments: { name: string; url: string }[];
    private?: 0 | 1;
    content: string;
    date: string;
    reverse?: boolean;
};

const TicketReply = ({ avatar, name, content, date, reverse, attachments }: TicketReplyProps) => {
    const renderAttachments = useMemo(() => {
        return attachments?.map((attachment, index) => {
            const fileType = fileExtension(attachment?.name);

            const isFileImage =
                fileType === "png" ||
                fileType === "jpeg" ||
                fileType === "jpg" ||
                fileType === "gif" ||
                fileType === "svg";

            return (
                <Link
                    key={index}
                    to={attachment.url}
                    className="flex w-min items-center gap-2 rounded-full border border-primary/50 bg-primary/10 py-[5px] pl-3 pr-5"
                    target="_blank"
                    data-tooltip-id="main-tooltip"
                    data-tooltip-content={attachment.name}
                    data-tooltip-place="top"
                >
                    {isFileImage ? (
                        <div className="relative aspect-square w-[25px] overflow-hidden rounded-[5px]">
                            <img src={attachment.url} className="absolute top-0 object-cover" alt="alt" />
                        </div>
                    ) : (
                        <IconFileZipper className="mx-[2px] h-[25px] fill-primary py-px" />
                    )}
                    <div className="mt-px max-w-[70px] overflow-hidden text-ellipsis whitespace-nowrap text-[0.85rem] text-text-main xs:max-w-[100px]">
                        {attachment.name}
                    </div>
                </Link>
            );
        });
    }, [attachments]);

    return (
        <section
            className={`min-w-[250px] max-w-full xs:max-w-[80%] ${
                reverse ? "fade-in-right self-end" : "fade-in-left self-start"
            }`}
            dir={reverse ? "ltr" : "rtl"}
        >
            <div className="flex justify-start gap-3 sm:gap-4">
                <div className="relative h-[30px] w-[30px] shrink-0 overflow-hidden rounded-full shadow-main sm:h-[40px] sm:w-[40px]">
                    <img className="absolute z-20 h-full w-full object-cover" src={avatar} alt={name} />
                    <div className="absolute z-10 h-full w-full bg-white dark:bg-secondary-200" />
                </div>
                <div
                    className={`w-full rounded-b-[15px] contrast:border ${
                        reverse ? "rounded-r-[15px]" : "rounded-l-[15px]"
                    } bg-secondary-400 p-4 shadow-main`}
                >
                    <h3 className={`text-[1rem] font-semibold ${reverse ? "text-success" : "text-primary"}`}>{name}</h3>
                    <div className="pt-1 text-[0.85rem] text-text-paragraph">{date}</div>
                    <p
                        dir={reverse ? "rtl" : "rtl"}
                        dangerouslySetInnerHTML={{ __html: content }}
                        className="w-full pl-4 pt-3 text-text-main ![overflow-wrap:anywhere]"
                    />
                    {attachments.length > 0 && <div className="mt-4 flex flex-wrap gap-3">{renderAttachments}</div>}
                </div>
            </div>
        </section>
    );
};

export default TicketReply;
