import "./assets/css/vazirmatn.css";
import "./assets/css/credc.css";
import "./index.scss";

import * as Sentry from "@sentry/react";
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AxiosError } from "axios";
import { MotionConfig } from "framer-motion";
import React, { PropsWithChildren, useContext } from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import ScrollToTop from "@/components/ScrollToTop";
import useAnimeSetting from "@/hooks/useAnimeSetting";

import App from "./App";
import { store } from "./redux/store";
import ContextProvider, { Context, ContextType } from "./store/Context-store";

if (import.meta.env.VITE_MODE_ENV === "production") {
    Sentry.init({
        dsn: "https://ee4d5fc836f0f9cb1cc55997cb9b05b5@sentry.tejaratyashar.ir/3",
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: [/^https:\/\/panel\.avalpardakht\.com/],
            }),
            new Sentry.Replay({
                maskAllInputs: false,
                maskAllText: false,
                networkDetailAllowUrls: [/^https:\/\/oldpanel\.avalpardakht\.com/],
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

declare global {
    interface Window {
        isMobile: boolean;
    }
}

window.isMobile = window.outerWidth <= 600; // Mobile width

const ReactQueryProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { setError } = useContext(Context) as ContextType;

    const queryClient = new QueryClient({
        mutationCache: new MutationCache({
            onError: (error) => {
                setError(error as AxiosError);
            },
        }),
        queryCache: new QueryCache({
            onError: (error) => {
                setError(error as AxiosError);
            },
        }),
    });

    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

const FramerMotionProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { isAnimationEnabled } = useAnimeSetting();

    return <MotionConfig reducedMotion={isAnimationEnabled ? "never" : "always"}>{children}</MotionConfig>;
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <ContextProvider>
        <ReactQueryProvider>
            <HelmetProvider>
                <Provider store={store}>
                    <BrowserRouter>
                        <ScrollToTop />
                        <FramerMotionProvider>
                            <React.StrictMode>
                                <App />
                                <Tooltip id="main-tooltip" className="z-[1000]" />
                                <Toaster
                                    position={window.isMobile ? "top-center" : "bottom-right"}
                                    reverseOrder={false}
                                    gutter={8}
                                    containerClassName=""
                                    containerStyle={{}}
                                    toastOptions={{
                                        className: "",
                                        duration: 5000,
                                        success: {
                                            duration: 3000,
                                        },
                                    }}
                                />
                                <ReactQueryDevtools />
                            </React.StrictMode>
                        </FramerMotionProvider>
                    </BrowserRouter>
                </Provider>
            </HelmetProvider>
        </ReactQueryProvider>
    </ContextProvider>,
);
