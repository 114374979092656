import api from "../api";

type GetCommentsResponse = {
    "current_page": number;
    "data": UserComment[];
    "first_page_url": string;
    "from": number;
    "last_page": number;
    "last_page_url": string;
    "next_page_url": null | string;
    "path": string;
    "per_page": number;
    "prev_page_url": null | string;
    "to": number;
    "total": number;
};

type AddCommentRequest = {
    content: string;
    company: string;
    parent: number;
};

export default {
    getComments: (page: number, perPage: number) =>
        api.get<GetCommentsResponse>(`panel/api/v1/comment/list?per_page=${[perPage]}&page=${page}`),
    addComment: (data: AddCommentRequest) => api.post("panel/api/v1/comment/insert", data),
};
