import { useMemo, useState } from "react";

import EmptyIndicator from "@/components/dashboard/main/EmptyIndicator";
import BoxContainer from "@/components/ui/BoxContainer";
import Pagination from "@/components/ui/Pagination";
import Table from "@/components/ui/Table";
import { TableTdProps } from "@/components/ui/Table/TableTd";
import useClubHistory from "@/hooks/queries/useClubHistory";
import useAfter from "@/hooks/useAfter";
import useQueryParams from "@/hooks/useQueryParams";

const History = () => {
    const { updateQueryParams, searchParams } = useQueryParams();

    const page = Number(searchParams.get("page") ?? 1);
    const perPageValue = Number(searchParams.get("perPage") ?? 10);
    const perPage = { value: perPageValue, label: perPageValue };

    const { data: histories, isLoading } = useClubHistory(page, perPage.value);

    const forcePerPage = histories?.per_page ? Number(histories?.per_page) : 1;

    // set force per_page once after data is loaded
    useAfter(() => {
        updateQueryParams({ perPage: forcePerPage });
    }, !!histories);

    const tableRows = useMemo<TableTdProps[][] | undefined>(() => {
        return histories?.list.map((history) => {
            return [
                {
                    header: "امتیاز",
                    children: (
                        <span className={history.is_gain ? "text-success" : "text-error"}>
                            {history.point}
                            {history.is_gain ? "+" : "-"}
                        </span>
                    ),
                },
                {
                    header: "توضیحات",
                    children: <div className="text-justify">{history.description}</div>,
                },
                {
                    header: "امتیاز بعد دریافت",
                    children: <div className="text-gold">{history.after_point}</div>,
                },
                {
                    header: "تاریخ دریافت",
                    children: history.created_at,
                },
            ];
        });
    }, [histories]);

    return (
        <>
            <BoxContainer colorBox={false} className="mt-10">
                <div className="-mx-5 pb-14">
                    {tableRows?.length !== 0 ? (
                        <Table placeholderRows={12} loading={isLoading} rows={tableRows} className="max-sm:-mt-5"/>
                    ) : (
                        <EmptyIndicator
                            message="تاریخچه ای برای نمایش وجود ندارد"
                            link="/panel/order"
                            linkText="ثبت اولین سفارش"
                        />
                    )}
                </div>
            </BoxContainer>
            {!isLoading && tableRows?.length !== 0 && (
                <Pagination
                    onPerPageChange={(perPage) => updateQueryParams({ perPage })}
                    onPageChange={(page) => updateQueryParams({ page })}
                    nextPage={() => updateQueryParams({ page: page + 1 })}
                    prevPage={() => updateQueryParams({ page: page - 1 })}
                    paginationInfo={{
                        from: histories?.from,
                        to: histories?.to,
                        pages: histories?.last_page,
                        count: histories?.total,
                        perPage: perPage.value,
                        forcePerPage,
                        page,
                    }}
                />
            )}
        </>
    );
};

export default History;
