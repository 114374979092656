import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { InferType, object, string } from "yup";

import Spinner from "@/components/loader/Spinner";
import IconEnvelope from "@/components/svg/envelope";
import IconLock from "@/components/svg/lock";
import IconShieldCheck from "@/components/svg/shield-check";
import Button from "@/components/ui/Button";
import Input from "@/components/ui/Input";
import PageTitle from "@/components/utility/PageTitle";
import { EMAIL_REQUIRED_MESSAGE, INVALID_EMAIL_MESSAGE, PASSWORD_REQUIRED_MESSAGE } from "@/constants/InavlidMessages";
import useLogin from "@/hooks/queries/useLogin";
import useSettings from "@/hooks/queries/useSettings";
import useGoogleReCaptchaV3 from "@/hooks/useRecaptcha";

const loginSchema = object({
    email: string().required(EMAIL_REQUIRED_MESSAGE).email(INVALID_EMAIL_MESSAGE),
    password: string().required(PASSWORD_REQUIRED_MESSAGE),
}).required();

type LoginValidationSchema = InferType<typeof loginSchema>;

const Login = () => {
    const { data: settings } = useSettings();
    const { executeReCaptcha } = useGoogleReCaptchaV3({ action: "Login" });
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginValidationSchema>({
        resolver: yupResolver(loginSchema),
        defaultValues: {
            email: "",
            password: "",
        },
    });

    const { mutate: login, isLoading: loginLoading } = useLogin(setIsLoading);

    const getCaptchaToken = async () => {
        let captchaToken: string | undefined = undefined;

        if (settings?.isCaptchaEnable && settings.isCaptchaEnable === "1") {
            setIsLoading(true);
            captchaToken = await executeReCaptcha("Login");
            setIsLoading(false);
        }

        return captchaToken;
    };

    const submitHandler: SubmitHandler<LoginValidationSchema> = async (data) => {
        const token = await getCaptchaToken();

        login({
            email: data.email,
            password: data.password,
            recaptcha: token,
        });
    };

    return (
        <>
            <PageTitle>ورود به حساب کاربری</PageTitle>
            <form onSubmit={handleSubmit(submitHandler)} className="w-full">
                <h2 className="flex items-center gap-2 text-lg font-bold text-text-main">
                    ✔️ اول‌پرداخت، انتخاب اول در پرداخت‌های بین‌المللی
                </h2>
                <div className="my-12 flex flex-col gap-6">
                    <Input
                        name="email"
                        control={control}
                        ltr
                        topTitle="ایمیل"
                        placeholder="ایمیل"
                        type="email"
                        variant="fill"
                        autoFocus
                        autoComplete="email"
                        className={`${errors.email && "border-error"} text-text-main`}
                        addon={<IconEnvelope className="h-6 fill-gray-400" />}
                    />
                    <div className="flex flex-col gap-2">
                        <Input
                            name="password"
                            control={control}
                            ltr
                            topTitle="رمز عبور"
                            placeholder="رمز عبور"
                            type="password"
                            variant="fill"
                            className={`${errors.password && "border-error"} text-text-main`}
                            addon={<IconLock className="h-6 fill-gray-400" />}
                        />
                        <Link
                            to={"/auth/forgot-password"}
                            className="mx-3 block text-text-paragraph hover:text-primary"
                        >
                            رمز عبور را فراموش کرده اید؟
                        </Link>
                    </div>
                </div>

                <Button disabledTooltip="دکمه غیرفعال است" disabled={isLoading || loginLoading} className="w-full">
                    {isLoading || loginLoading ? (
                        <Spinner />
                    ) : (
                        <>
                            <IconShieldCheck className="h-6 fill-current" />
                            ورود امن به حساب کاربری
                        </>
                    )}
                </Button>
            </form>
        </>
    );
};

export default Login;
