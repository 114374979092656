import { useState } from "react";

import Gifts from "@/components/customer-club/Gifts";
import History from "@/components/customer-club/History";
import Tasks from "@/components/customer-club/Tasks";
import IconStarSolid from "@/components/svg/star-solid";
import BoxContainer from "@/components/ui/BoxContainer";
import useUser from "@/hooks/queries/useUser";
import { formatNumber } from "@/utils";

const Tabs = () => {
    const { data: userDate } = useUser();
    const [activeTab, setActiveTab] = useState<"tasks" | "gifts" | "history">("tasks");

    return (
        <BoxContainer colorBox={false}>
            <div className="-mx-5">
                <div className="flex w-full items-center justify-center gap-2 border-secondary-100/30 pb-8 pt-2 xs:gap-[10px] sm:gap-4">
                    <IconStarSolid className="h-[10px] scale-[0.75] fill-gold/25 xs:h-[12px] sm:h-[16px] lg:h-[22px]" />
                    <IconStarSolid className="h-[10px] scale-[0.85] fill-gold/50 xs:h-[12px] sm:h-[16px] lg:h-[22px]" />
                    <IconStarSolid className="h-[10px] fill-gold xs:h-[12px] sm:h-[16px] lg:h-[22px]" />
                    <span className="text-[1.14rem] text-text-main xs:text-[1.42rem] sm:text-[1.71rem] lg:text-[2.28rem]">
                        اول کلاب
                    </span>
                    <IconStarSolid className="h-[10px] fill-gold xs:h-[12px] sm:h-[16px] lg:h-[22px]" />
                    <IconStarSolid className="h-[10px] scale-[0.85] fill-gold/50 xs:h-[12px] sm:h-[16px] lg:h-[22px]" />
                    <IconStarSolid className="h-[10px] scale-[0.75] fill-gold/25 xs:h-[12px] sm:h-[16px] lg:h-[22px]" />
                </div>
            </div>
            <div className="-mx-5">
                <div className="flex w-full items-center justify-between px-3 pb-5 sm:px-5 sm:pt-4">
                    <div className="flex gap-[6px] xs:gap-3 sm:gap-6">
                        <div
                            onClick={() => setActiveTab("tasks")}
                            className={`cursor-pointer whitespace-nowrap rounded-full px-3 py-1 text-[0.78rem] transition-colors sm:px-5 sm:py-[6px] sm:text-[1rem] ${
                                activeTab === "tasks"
                                    ? "bg-primary text-white"
                                    : "text-text-main hover:bg-primary/[0.15]"
                            }`}
                        >
                            ماموریت‌ها
                        </div>
                        <div
                            onClick={() => setActiveTab("gifts")}
                            className={`cursor-pointer whitespace-nowrap rounded-full px-3 py-1 text-[0.78rem] transition-colors sm:px-5 sm:py-[6px] sm:text-[1rem] ${
                                activeTab === "gifts"
                                    ? "bg-primary text-white"
                                    : "text-text-main hover:bg-primary/[0.15]"
                            }`}
                        >
                            جوایز
                        </div>
                        <div
                            onClick={() => setActiveTab("history")}
                            className={`cursor-pointer whitespace-nowrap rounded-full px-3 py-1 text-[0.78rem] transition-colors sm:px-5 sm:py-[6px] sm:text-[1rem] ${
                                activeTab === "history"
                                    ? "bg-primary text-white"
                                    : "text-text-main hover:bg-primary/[0.15]"
                            }`}
                        >
                            تاریخچه
                        </div>
                    </div>
                    <div className="flex w-min items-center justify-center gap-1 rounded-full bg-gold/10 px-3 py-1 pt-2 sm:gap-2 sm:px-4">
                        <span className="whitespace-nowrap text-[0.78rem] max-xs:hidden sm:text-[1rem]">
                            امتیاز فعلی شما:
                        </span>
                        <span className="text-[0.78rem] sm:text-[1rem]">
                            {formatNumber(userDate.data.user.avalClubPoint ?? 0)}
                        </span>
                        <IconStarSolid className="h-[12px] -translate-y-[2px] fill-gold sm:h-[14px]" />
                    </div>
                </div>
            </div>
            {activeTab === "tasks" && <Tasks />}
            {activeTab === "gifts" && <Gifts />}
            {activeTab === "history" && <History />}
        </BoxContainer>
    );
};

export default Tabs;
