import { RouteObject } from "react-router-dom";

import Profile from "@/pages/dashboard/account/Profile";
import AppearancePage from "@/pages/dashboard/profile/Appearance";
import Authentication from "@/pages/dashboard/profile/Authentication";
import CreditCards from "@/pages/dashboard/profile/CreditCards";

const UserRoutes: RouteObject[] = [
    {
        path: "credit",
        element: <CreditCards />,
    },
    {
        path: "authentication",
        element: <Authentication />,
    },
    {
        path: "profile",
        element: <Profile />,
    },
    {
        path: "appearance",
        element: <AppearancePage />,
    },
];

export default UserRoutes;
