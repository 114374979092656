import { useMemo } from "react";
import { Link } from "react-router-dom";

import EmptyIndicator from "@/components/dashboard/main/EmptyIndicator";
import IconEyeDuotone from "@/components/svg/eye-duotone";
import IconHouse from "@/components/svg/house";
import BoxContainer from "@/components/ui/BoxContainer";
import Breadcrumb from "@/components/ui/Breadcrumb";
import Pagination from "@/components/ui/Pagination";
import Table from "@/components/ui/Table";
import { TableTdProps } from "@/components/ui/Table/TableTd";
import PageTitle from "@/components/utility/PageTitle";
import useCashes from "@/hooks/queries/useCashes";
import useAfter from "@/hooks/useAfter";
import useQueryParams from "@/hooks/useQueryParams";
import { formatNumber } from "@/utils";
import { cashStatus } from "@/utils/cases";

const CashIncomeList = () => {
    const { updateQueryParams, searchParams } = useQueryParams();

    const page = Number(searchParams.get("page") ?? 1);
    const perPageValue = Number(searchParams.get("perPage") ?? 10);
    const perPage = { value: perPageValue, label: perPageValue };

    const { data: cashes, isLoading } = useCashes(page, perPage.value);

    const forcePerPage = cashes?.per_page ? Number(cashes?.per_page) : 1;

    // set force per_page once after data is loaded
    useAfter(() => {
        updateQueryParams({ perPage: forcePerPage });
    }, !!cashes);

    const tableRows = useMemo<TableTdProps[][] | undefined>(() => {
        return cashes?.list.map((cash) => {
            const { icon, color } = cashStatus(cash.status);

            return [
                {
                    header: "شماره نقد",
                    children: "#" + cash.id,
                    link: "" + cash.id,
                },
                {
                    header: "میزان ارز",
                    children: formatNumber(+cash.amount),
                },
                {
                    header: "نوع ارز",
                    children: cash.currency_name,
                },
                {
                    header: "نرخ خرید (تومان)",
                    children: formatNumber(+cash.currency_price),
                },
                {
                    header: "وضعیت",
                    children: cash.status,
                    color: color,
                    icon: icon,
                    iconColor: color,
                },
                {
                    header: "تاریخ",
                    children: (
                        <div>
                            <label className="text-sm text-slate-700 dark:text-white">ایجاد: </label>
                            <span className="text-xs">{cash.created_at}</span>
                            <br />
                            <label className="text-sm text-slate-700 dark:text-white">بروزرسانی: </label>
                            <span className="text-xs">{cash.updated_at}</span>
                        </div>
                    ),
                },
                {
                    header: "عملیات",
                    children: (
                        <Link to={"/panel/cash-income/list/" + cash.id}>
                            <IconEyeDuotone className="w-6 cursor-pointer fill-primary hover:fill-primary" />
                        </Link>
                    ),
                },
            ];
        });
    }, [cashes]);

    return (
        <>
            <PageTitle>لیست نقدها</PageTitle>

            <Breadcrumb
                sections={[
                    { link: "/panel", text: "صفحه اصلی", icon: IconHouse },
                    { link: "#", text: "لیست نقدها", active: true },
                ]}
            />
            <BoxContainer colorBox={false}>
                <div className="-mx-5 pb-14">
                    {tableRows?.length !== 0 ? (
                        <Table placeholderRows={12} loading={isLoading} rows={tableRows} className="max-sm:-mt-5" />
                    ) : (
                        <EmptyIndicator
                            message="نقدی برای نمایش وجود ندارد"
                            link="/panel/cash-income"
                            linkText="ثبت اولین نقد"
                        />
                    )}
                </div>
            </BoxContainer>
            {!isLoading && tableRows?.length !== 0 && (
                <Pagination
                    onPerPageChange={(perPage) => updateQueryParams({ perPage })}
                    onPageChange={(page) => updateQueryParams({ page })}
                    nextPage={() => updateQueryParams({ page: page + 1 })}
                    prevPage={() => updateQueryParams({ page: page - 1 })}
                    paginationInfo={{
                        from: cashes?.from,
                        to: cashes?.to,
                        pages: cashes?.last_page,
                        count: cashes?.total,
                        perPage: perPage.value,
                        forcePerPage,
                        page,
                    }}
                />
            )}
        </>
    );
};

export default CashIncomeList;
