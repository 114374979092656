import { useState } from "react";
import Skeleton from "react-loading-skeleton";

import OrderFormFile from "@/components/order/forms/OrderFormFile";
import BoxContainer from "@/components/ui/BoxContainer";
import Title from "@/components/ui/Title";
import PageTitle from "@/components/utility/PageTitle";
import { CalculationRequestType, ServiceType } from "@/interfaces/dashboard/order/order";

type OrderFormProps = {
    onCalculation: (data: CalculationRequestType) => void;
    onFormSubmit: (data: object, serviceId: number) => void;
    onDataAvailable: (service: ServiceType) => void;
};

const OrderForm: React.FC<OrderFormProps> = ({ onCalculation, onFormSubmit, onDataAvailable }) => {
    const [data, setData] = useState<ServiceType | null>();

    const dataAvailableHandler = (data: ServiceType) => {
        setData(data);
        onDataAvailable(data);
    };

    return (
        <>
            <PageTitle>ثبت سفارش</PageTitle>
            <BoxContainer colorBox={false} className="z-40 xs:overflow-visible">
                <Title
                    title={!data ? <Skeleton width={250} /> : `ثبت سفارش ${data?.title ?? ""}`}
                    containerClasses="-m-5"
                    className="flex h-full flex-col gap-6 p-0"
                >
                    <OrderFormFile
                        onCalculation={onCalculation}
                        onFormSubmit={onFormSubmit}
                        onDataAvailable={dataAvailableHandler}
                    />
                </Title>
            </BoxContainer>
        </>
    );
};

export default OrderForm;
