import { QueryClient } from "@tanstack/react-query";
import Cookies from "js-cookie";

import { UserData } from "@/interfaces/dashboard/layout/panel/panel";
import { IProjectWindow, isBrowser } from "@/interfaces/recaptchaTypes";

export const isEmpty = (obj: any) => {
    if (obj === null || obj === "" || obj === undefined) return true;
    if (!Object.entries(obj).length) return true;
    return false;
};

export const asyncLocalStorage = {
    setItem: function (key: string, value: any) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    getItem: function (key: any) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    },
};

export function blobToFile(theBlob: any, fileName: any) {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
}

export const commafy = (num: any = 0, delimiterChar = ","): any => {
    try {
        let number = num?.toString()?.replace(".*", "");
        number = number?.split(delimiterChar)?.join("");
        if (isNaN(Number(number))) {
            return number;
        }
        const str = number?.toString()?.split(".");
        if (str[0]?.length >= 4) {
            str[0] = str[0]?.replace(/(\d)(?=(\d{3})+$)/g, "$1" + delimiterChar);
        }
        if (str[1] && str[1]?.length >= 4) {
            str[1] = str[1]?.replace(/(\d{3})/g, "$1 ");
        }

        return str.join(".");
    } catch (error) {
        console.error(error);
    }
};

export const onlyNumber = (e: any) => {
    return (e.target.value = e?.target?.value?.replace(/[^0-9.]/g, "")?.replace(/(\..*?)\..*/g, "$1"));
};

export const getActiveClassFilterBtn = (arg: boolean) =>
    arg ? "bg-blue text-white p-1 rounded" : "bg-white text-blue";

export const convertNumToStringJalaliMonth = (arr: []) => {
    const months = [
        "فروردين",
        "ارديبهشت",
        "خرداد",
        "تير",
        "مرداد",
        "شهريور",
        "مهر",
        "آبان",
        "آذر",
        "دي",
        "بهمن",
        "اسفند",
    ];
    const newMonth = arr?.map((a: number) => months[a - 1]);
    return newMonth;
};

export const addMinutes = (min: number) => {
    return new Date(new Date().getTime() + min * 60 * 1000);
};

export const getOperatingSystem = () => {
    let operatingSystem = "Unknown";
    if (window.navigator.appVersion.indexOf("Win") !== -1) {
        operatingSystem = "Windows";
    }
    if (window.navigator.appVersion.indexOf("Mac") !== -1) {
        operatingSystem = "MacOS";
    }
    if (window.navigator.appVersion.indexOf("X11") !== -1) {
        operatingSystem = "UNIX";
    }
    if (window.navigator.appVersion.indexOf("Linux") !== -1) {
        operatingSystem = "Linux";
    }

    return operatingSystem;
};

export const getBrowser = () => {
    let currentBrowser = "Unknown";
    if (window.navigator.userAgent.indexOf("YaBrowser") !== -1) {
        currentBrowser = "Yandex";
    } else if (window.navigator.userAgent.indexOf("Chrome") !== -1) {
        currentBrowser = "Google Chrome";
    } else if (window.navigator.userAgent.indexOf("Firefox") !== -1) {
        currentBrowser = "Mozilla Firefox";
    } else if (window.navigator.userAgent.indexOf("MSIE") !== -1) {
        currentBrowser = "Internet Exployer";
    } else if (window.navigator.userAgent.indexOf("Edge") !== -1) {
        currentBrowser = "Edge";
    } else if (window.navigator.userAgent.indexOf("Vivaldi") !== -1) {
        currentBrowser = "Vivaldi";
    } else if (window.navigator.userAgent.indexOf("Safari") !== -1) {
        currentBrowser = "Safari";
    } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
        currentBrowser = "Opera";
    }

    return currentBrowser;
};

export const getProjectWindow = () => (isBrowser ? (window as unknown as IProjectWindow) : undefined);

export const getHeadTag = () => {
    const window = getProjectWindow();
    if (window) {
        return window.document.getElementsByTagName("head")[0];
    }
    return undefined;
};

export const injectScript = (id: string, src: string, onload?: () => void) => {
    const window = getProjectWindow();
    const headTag = getHeadTag();
    if (headTag && window) {
        const scriptTag = window.document.createElement("script");
        scriptTag.id = id;
        scriptTag.src = src;

        if (onload) {
            scriptTag.onload = onload;
        }

        if ("async" in scriptTag) {
            scriptTag.async = true;
        }

        if ("defer" in scriptTag) {
            scriptTag.defer = true;
        }
        headTag.appendChild(scriptTag);
        return true;
    }
    return false;
};

export const removeScript = (id: string) => {
    const window = getProjectWindow();
    if (window) {
        const scriptTag = window.document.getElementById(id);
        if (scriptTag) {
            scriptTag.remove();
            return true;
        }
    }
    return false;
};

export const formatNumber = (num: number) => {
    return new Intl.NumberFormat("en-US", { style: "decimal", maximumFractionDigits: 10 }).format(num);
};

export const getFieldError = (field: string, errors: any) => {
    const fieldErrors = errors[field];

    if (fieldErrors) {
        // eslint-disable-next-line no-prototype-builtins
        if (fieldErrors.hasOwnProperty("message")) return fieldErrors.message;
        return fieldErrors[0].message;
    }

    return false;
};

export const random = (min = 1, max = 100) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const objectToArray = <T = any>(obj: Record<string, T>) => {
    return Object.keys(obj).map((key) => obj[key]);
};

export const verificationStatus = (
    user: UserData,
    type: "EMAIL" | "NATIONAL_ID" | "MOBILE" | "CREDIT" | "PHONE" | "VIDEO" | "POSTAL_CODE" | "BUSINESS",
) => {
    const itemStatus = user.data.verifications.find((item) => item.verification_type === type)?.resultStatus;
    return (status: "INIT" | "PENDING" | "CONFIRMED" | "REJECTED" | "CANCELED") => itemStatus === status;
};

export const clearOnLogout = () => {
    Cookies.remove("access_token");
    const queryClient = new QueryClient();
    queryClient.clear();
    localStorage.removeItem("user_data");

    document.querySelector("#goftino_w")?.remove();
    document.querySelector("#goftino-header-link")?.remove();
};

export const loadGoftinoScript = () => {
    const script = document.createElement("script");
    const src = "https://www.goftino.com/widget/qSKw2F";
    const localGoftino = localStorage.getItem("goftino");

    script.type = "text/javascript";
    script.async = !0;
    script.src = localGoftino ? src + "?o=" + localGoftino : src;
    script.id = "goftino-header-link";

    if (!document.querySelector("#goftino-header-link")) {
        document.getElementsByTagName("head")[0].appendChild(script);
    }
};

export const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export const scrollToTop = (delay = 0, top = 0) => {
    setTimeout(() => {
        window.scrollTo({ top, behavior: "smooth" });
    }, delay);
};

export const fileExtension = (fileName?: string) => {
    const ext = fileName ? fileName.split(".") : undefined;

    if (ext) return ext[ext.length - 1];
    return ext;
};

export const nl2br = (str: string | undefined, is_xhtml = false) => {
    if (typeof str === "undefined" || str === null) {
        return "";
    }
    const breakTag = is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
    return (str + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1" + breakTag + "$2");
};
