import { PropsWithChildren } from "react";
import { SkeletonTheme } from "react-loading-skeleton";

import useTheme from "@/hooks/useTheme";

const PlaceholderLayout: React.FC<PropsWithChildren> = ({ children }) => {
    const { isThemeDark } = useTheme();

    return (
        <SkeletonTheme
            direction="rtl"
            enableAnimation={true}
            highlightColor={isThemeDark ? "#454a59" : "#f5f5f5"}
            baseColor={isThemeDark ? "#323848" : "#ebebeb"}
            inline={true}
        >
            {children}
        </SkeletonTheme>
    );
};
export default PlaceholderLayout;
