import { useContext } from "react";

import SettingItem from "@/components/profile/appereance/SettingItem";
import SliderTool from "@/components/profile/appereance/SliderTool";
import BoxContainer from "@/components/ui/BoxContainer";
import Title from "@/components/ui/Title";
import { ThemeFontSizes } from "@/hooks/useTheme";
import { Context, ContextType } from "@/store/Context-store";

const FontSettingBox = () => {
    const {
        fontSizeValue,
        setFontSizeValue,
        fontWeightValue,
        setFontWeightValue,
        lineHeightValue,
        setLineHeightValue,
        wordSpacingValue,
        setWordSpacingValue,
        setIsSettingsChanged,
    } = useContext(Context) as ContextType;

    const setFontSizeFunction = (value: ThemeFontSizes) => {
        setFontSizeValue(value);
        setIsSettingsChanged(true);
    };

    const setFontWeightFunction = (value: number) => {
        setFontWeightValue(value);
        setIsSettingsChanged(true);
    };

    return (
        <>
            <BoxContainer>
                <Title title="سایز متن ها" containerClasses="-mx-6 -mt-4" />
                <div className="mt-6 flex flex-wrap items-center justify-evenly gap-2 xs:gap-4 sm:gap-8">
                    <SettingItem
                        id="xl"
                        name="font-size"
                        value={fontSizeValue}
                        setValue={setFontSizeFunction}
                        className="text-[20px] !font-normal lg:text-[24px]"
                        subtitle="خیلی بزرگ"
                    >
                        الف
                    </SettingItem>
                    <SettingItem
                        id="lg"
                        name="font-size"
                        value={fontSizeValue}
                        setValue={setFontSizeFunction}
                        className="text-[16px] !font-normal lg:text-[19px]"
                        subtitle="بزرگ"
                    >
                        الف
                    </SettingItem>
                    <SettingItem
                        id="md"
                        name="font-size"
                        value={fontSizeValue}
                        setValue={setFontSizeFunction}
                        className="text-[14px] !font-normal"
                        subtitle="متوسط"
                    >
                        الف
                    </SettingItem>
                    <SettingItem
                        id="sm"
                        name="font-size"
                        value={fontSizeValue}
                        setValue={setFontSizeFunction}
                        className="text-[12px] !font-normal"
                        subtitle="کوچک"
                    >
                        الف
                    </SettingItem>
                    <SettingItem
                        id="xs"
                        name="font-size"
                        value={fontSizeValue}
                        setValue={setFontSizeFunction}
                        className="text-[10px] !font-normal"
                        subtitle="خیلی کوچک"
                    >
                        الف
                    </SettingItem>
                </div>
            </BoxContainer>
            <BoxContainer>
                <Title title="ضخامت متن ها" containerClasses="-mx-6 -mt-4" />
                <div className="mt-6 flex flex-wrap items-center justify-evenly gap-2 xs:gap-4 sm:gap-8">
                    <SettingItem
                        id={900}
                        name="font-weight"
                        value={fontWeightValue}
                        setValue={setFontWeightFunction}
                        className="text-[16px] font-black xs:text-[18px] sm:text-[24px]"
                        subtitle="بسیار ضخیم"
                    >
                        الف
                    </SettingItem>
                    <SettingItem
                        id={700}
                        name="font-weight"
                        value={fontWeightValue}
                        setValue={setFontWeightFunction}
                        className="text-[16px] font-semibold xs:text-[18px] sm:text-[24px]"
                        subtitle="ضخیم"
                    >
                        الف
                    </SettingItem>
                    <SettingItem
                        id={500}
                        name="font-weight"
                        value={fontWeightValue}
                        setValue={setFontWeightFunction}
                        className="text-[16px] font-bold xs:text-[18px] sm:text-[24px]"
                        subtitle="متوسط"
                    >
                        الف
                    </SettingItem>
                    <SettingItem
                        id={300}
                        name="font-weight"
                        value={fontWeightValue}
                        setValue={setFontWeightFunction}
                        className="text-[16px] font-medium xs:text-[18px] sm:text-[24px]"
                        subtitle="نازک"
                    >
                        الف
                    </SettingItem>
                    <SettingItem
                        id={200}
                        name="font-weight"
                        value={fontWeightValue}
                        setValue={setFontWeightFunction}
                        className="text-[16px] font-light xs:text-[18px] sm:text-[24px]"
                        subtitle="بسیار نازک"
                    >
                        الف
                    </SettingItem>
                </div>
            </BoxContainer>
            <BoxContainer>
                <Title title="تنظیم ارتفاع بین خطوط متن ها" containerClasses="-mx-6 -mt-4" />
                <SliderTool
                    steps={[
                        { value: 0, title: "28px" },
                        { value: 50, title: "35px" },
                        { value: 100, title: "42px" },
                    ]}
                    value={lineHeightValue}
                    onChange={(value) => {
                        setLineHeightValue(value);
                        setIsSettingsChanged(true);
                    }}
                    autoStep={false}
                />
            </BoxContainer>
            <BoxContainer>
                <Title title="تنظیم فاصله بین کلمات" containerClasses="-mx-6 -mt-4" />
                <SliderTool
                    steps={[
                        {
                            value: 0,
                            title: "0px",
                        },
                        {
                            value: 20,
                            title: "2px",
                        },
                        {
                            value: 40,
                            title: "4px",
                        },
                        {
                            value: 60,
                            title: "6px",
                        },
                        {
                            value: 80,
                            title: "8px",
                        },
                        {
                            value: 100,
                            title: "10px",
                        },
                    ]}
                    value={wordSpacingValue}
                    onChange={(value) => {
                        setWordSpacingValue(value);
                        setIsSettingsChanged(true);
                    }}
                />
            </BoxContainer>
        </>
    );
};

export default FontSettingBox;
