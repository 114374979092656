import { useEffect, useMemo } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import FileInput from "@/components/ui/FileInput";
import Input from "@/components/ui/Input";
import Select from "@/components/ui/Select";
import Textarea from "@/components/ui/Textarea";
import { NUMBER_INPUT_FLOATING_POINT } from "@/constants/common";
import { CalculationRequestType, ServiceInstructionType, SubmitOrderType } from "@/interfaces/dashboard/order/order";
import { objectToArray } from "@/utils";

import BuyFormAccountRequirement from "../BuyFormAccountRequirement";

export type OrderValidationSchema = {
    amount: number | "";
    currency: {
        value: number;
        label: string;
    };
    user_description: string | null | undefined;
    attachments?: Record<string, File>;
    user_account_username?: string | null | undefined;
    user_account_password?: string | null | undefined;
    link: string;
    account: "بله" | "خیر";
};

type SubmitOrderRequest = SubmitOrderType & {
    amount: number | "";
    fk_currency_id: number;
    meta: {
        user_account_username?: string | null | undefined;
        user_account_password?: string | null | undefined;
        link: string;
        account: "بله" | "خیر";
    };
};

type ServiceInstruction = ServiceInstructionType & {
    onCalculation: (data: CalculationRequestType) => void;
    onFormSubmit: (data: SubmitOrderRequest, serviceId: number) => void;
};

const BuyForm: React.FC<ServiceInstruction> = ({ instructions, onCalculation, onFormSubmit }) => {
    const [queryString] = useSearchParams();

    const linkConstant = useMemo(() => {
        return instructions.constants?.find((item) => {
            return item.key == "meta.link";
        });
    }, [instructions.constants]);

    const { control, handleSubmit, getValues, resetField } = useForm<OrderValidationSchema>({
        defaultValues: {
            amount: queryString.get("amount") ? Number(queryString.get("amount")) : "",
            currency: {
                value: queryString.get("currency")
                    ? instructions.currency.find(
                          (currency) =>
                              currency.abbreviation.toLowerCase() == queryString.get("currency")?.toLocaleLowerCase(),
                      )?.id
                    : instructions.currency[0]?.id,
                label: queryString.get("currency")
                    ? instructions.currency.find(
                          (currency) =>
                              currency.abbreviation.toLowerCase() == queryString.get("currency")?.toLocaleLowerCase(),
                      )?.name
                    : instructions.currency[0]?.name,
            },
            user_description: "",
            user_account_username: "",
            user_account_password: "",
            link: linkConstant ? linkConstant.value : "",
            account: instructions.slug ? "بله" : "خیر",
        },
    });

    const [watchedAmount, watchedCurrency] = useWatch({
        control: control,
        name: ["amount", "currency", "account"],
        defaultValue: {
            amount: 0,
            currency: {
                value: instructions.currency[0]?.id,
                label: instructions.currency[0]?.name,
            },
        },
    });

    useEffect(() => {
        if (Number(watchedAmount) && +watchedAmount >= 0) {
            onCalculation({
                amount: +watchedAmount,
                fk_currency_id: watchedCurrency.value,
                serviceId: instructions.id,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchedAmount, watchedCurrency]);

    useEffect(() => {
        const amount = queryString.get("amount");
        onCalculation({
            amount: amount ? +amount : 0,
            fk_currency_id: getValues().currency.value,
            serviceId: instructions.id,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submitHandler: SubmitHandler<OrderValidationSchema> = (data) => {
        onFormSubmit(
            {
                amount: data.amount,
                fk_currency_id: data.currency.value || -1,
                user_description: data.user_description,
                meta: {
                    link: data.link,
                    account: data.account,
                    user_account_username: data.user_account_username,
                    user_account_password: data.user_account_password,
                },
                attachments: data.attachments ? objectToArray(data.attachments) : undefined,
            },
            instructions.id,
        );
    };

    return (
        <form id="order-form" onSubmit={handleSubmit(submitHandler)}>
            <div className="flex flex-col gap-4">
                <div className="flex max-md:flex-col md:gap-4">
                    <div className="w-full md:w-1/2 lg:w-2/3">
                        <Input
                            ltr
                            containerClassName="w-full"
                            variant="fill"
                            required
                            name="amount"
                            topTitle="مبلغ سرویس/محصول"
                            inputMode="decimal"
                            type="number"
                            step={NUMBER_INPUT_FLOATING_POINT}
                            min={0}
                            placeholder="مبلغ سرویس/محصول"
                            control={control}
                            rules={{
                                required: "مبلغ سرویس/محصول الزامی است",
                            }}
                        />
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/3">
                        <Select
                            control={control}
                            topTitle="&nbsp;"
                            name="currency"
                            variant="fill"
                            placeholder="انتخاب کنید"
                            rules={{
                                required: "انتخاب واحد پول الزامی است",
                            }}
                            defaultValue={{
                                value: instructions.currency[0]?.id,
                                label: instructions.currency[0]?.name,
                            }}
                            options={instructions.currency.map((currency) => {
                                return {
                                    value: currency.id,
                                    label: currency.name,
                                };
                            })}
                        />
                    </div>
                </div>
                <Input
                    containerClassName="w-full"
                    variant="fill"
                    required
                    name="link"
                    readOnly={linkConstant ? true : false}
                    topTitle="لینک سرویس/محصول"
                    inputMode="text"
                    type="text"
                    placeholder="لینک سرویس/محصول"
                    control={control}
                    ltr
                    rules={{
                        required: "لینک سرویس/محصول الزامی است",
                    }}
                />

                <BuyFormAccountRequirement control={control} slug={instructions.slug} />

                <Textarea
                    name="user_description"
                    control={control}
                    label="توضیحات"
                    placeholder="توضیحات خود را اینجا بنویسید"
                />
                <FileInput control={control} name="attachments" onReset={resetField} repeater />
            </div>
        </form>
    );
};
export default BuyForm;
