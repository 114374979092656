import { useEffect, useState } from "react";

import classes from "./PaymentAnnouncementCoundwon.module.scss";

type PaymentAnnouncementCountdownProps = {
    time?: number;
    date?: string;
};

const PaymentAnnouncementCountdown: React.FC<PaymentAnnouncementCountdownProps> = ({ time, date }) => {
    const [remaining, setRemaining] = useState<{
        days: string;
        hours: string;
        minutes: string;
        seconds: string;
    }>({ days: "0", hours: "0", minutes: "0", seconds: "0" });

    const getTimeRemaining = (countdown: number) => {
        const days = Math.floor(countdown / (60 * 60 * 24))
            .toString()
            .padStart(2, "0");
        const hours = Math.floor((countdown / (60 * 60)) % 24)
            .toString()
            .padStart(2, "0");
        const minutes = Math.floor((countdown / 60) % 60)
            .toString()
            .padStart(2, "0");
        const seconds = Math.floor(countdown % 60)
            .toString()
            .padStart(2, "0");

        return {
            days,
            hours,
            minutes,
            seconds,
        };
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (time && time >= 0) {
                setRemaining(getTimeRemaining(time--));
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="flex w-full flex-col items-center gap-4 pt-2">
            <span className="text-text-paragraph">اعتبار تا:</span>
            <main className={`${classes.main}`}>
                <div className={`${classes.cards}`}>
                    <div className={classes.card}>
                        <p className="text-text-paragraph">ثانیه</p>
                        <div className={`text-text-main ${classes["flip-card"]}`}>
                            <div className={`${classes.counter}`}>{remaining.seconds}</div>
                        </div>
                    </div>

                    <div className={classes.card}>
                        <p className="text-text-paragraph">دقیقه</p>
                        <div className={`text-text-main ${classes["flip-card"]}`}>
                            <div className={`${classes.counter}`}>{remaining.minutes}</div>
                        </div>
                    </div>

                    <div className={classes.card}>
                        <p className="text-text-paragraph">ساعت</p>
                        <div className={`text-text-main ${classes["flip-card"]}`}>
                            <div className={`${classes.counter}`}>{remaining.hours}</div>
                        </div>
                    </div>

                    {+remaining.days > 0 && (
                        <div className={classes.card}>
                            <p className="text-text-paragraph">روز</p>
                            <div className={`text-text-main ${classes["flip-card"]}`}>
                                <div className={`${classes.counter}`}>{remaining.days}</div>
                            </div>
                        </div>
                    )}
                </div>
            </main>
            <div className="flex w-full items-center justify-center gap-4">
                <span className="text-sm text-text-paragraph">
                    <span className="hidden sm:inline">تاریخ</span> پایان اعتبار:{" "}
                </span>
                <span className="font-bold text-sm sm:text-base text-text-main whitespace-nowrap">{date}</span>
            </div>
        </div>
    );
};
export default PaymentAnnouncementCountdown;
