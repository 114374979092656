import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import PlaceholderLayout from "./PlaceholderLayout";

type CategoriesPlaceholderProps = {
    count?: number;
};

const CategoriesPlaceholder: React.FC<CategoriesPlaceholderProps> = ({ count = 20 }) => {
    const renderSkeleton = useMemo(() => {
        return Array(count)
            .fill(null)
            .map((_, index) => {
                return (
                    <Skeleton
                        key={index}
                        style={{ borderRadius: "10px" }}
                        containerClassName="w-full h-[51px]"
                        height={"100%"}
                    />
                );
            });
    }, [count]);

    return <PlaceholderLayout>{renderSkeleton}</PlaceholderLayout>;
};
export default CategoriesPlaceholder;
