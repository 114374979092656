import { ChangeEvent, useMemo, useState } from "react";

import { IMMEDIATE_WITHDRAW_BANKS, IMMEDIATE_WITHDRAW_COST } from "@/constants/common";
import { banksList } from "@/data/Banks";
import { formatNumber } from "@/utils";

import AlertBox from "../ui/AlertBox";
import CustomCheckbox from "../ui/CustomCheckbox";

type ImmediateWithdrawType = {
    onIsImmediateChange: (value: boolean) => void;
};

const ImmediateWithdraw: React.FC<ImmediateWithdrawType> = ({ onIsImmediateChange }) => {
    const [isImmediate, setIsImmediate] = useState<boolean>(false);

    const immediateChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setIsImmediate(event.target.checked);
        onIsImmediateChange(event.target.checked);
    };

    const bankDetails = useMemo(() => {
        return IMMEDIATE_WITHDRAW_BANKS.map((bankName) => {
            return banksList.find((bank) => bank.name === bankName);
        });
    }, []);

    return (
        <div className="my-4">
            <CustomCheckbox
                onChange={immediateChangeHandler}
                label={`تسویه آنی (هزینه ${formatNumber(IMMEDIATE_WITHDRAW_COST)} تومان)`}
            />

            {isImmediate && (
                <AlertBox
                    type="warning"
                    className="mt-4"
                    title="کاربر گرامی!"
                    description={
                        <>
                            بانک‌های پشتیبانی شده:
                            <br />
                            <div className="my-4 grid grid-cols-7 gap-4">
                                {bankDetails.map((bank, index) => {
                                    return (
                                        <img
                                            data-tooltip-id="main-tooltip"
                                            data-tooltip-html={bank?.name}
                                            data-tooltip-place={index <= 6 ? "top" : "bottom"}
                                            key={index}
                                            className="w-10"
                                            src={`/images/banks/${bank?.logo}`}
                                            alt={bank?.name}
                                        />
                                    );
                                })}
                            </div>
                            تسویه‌ی آنی فقط در روزهای کاری قابل انجام است. شما می‌توانید درخواست تسویه‌ی آنی خود را از{" "}
                            <span className="font-extrabold text-text-main">
                                شنبه تا چهارشنبه از ساعت ۹ صبح تا ۱۶ و روز پنجشنبه از ساعت ۹ صبح تا ۱۳
                            </span>{" "}
                            ثبت کنید.
                            <br />
                            ضمنا توجه داشته باشید که مبلغ{" "}
                            <span className="font-extrabold text-text-main">
                                {formatNumber(IMMEDIATE_WITHDRAW_COST)} تومان
                            </span>{" "}
                            بابت تسویه آنی از مبلغ نهایی کسر خواهد شد.
                        </>
                    }
                />
            )}
        </div>
    );
};
export default ImmediateWithdraw;
