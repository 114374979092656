import { useQuery } from "@tanstack/react-query";

import gate from "@/gate";

const useClubTasks = () => {
    return useQuery(["club-tasks"], gate.getClubTasks, {
        select: (data) => {
            return {
                dailyTask: data.find((item) => item.slug === "daily"),
                tasks: data.filter((item) => item.slug !== "daily"),
            };
        },
    });
};

export default useClubTasks;
