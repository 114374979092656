import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ServiceTypeSummary } from "@/interfaces/dashboard/order/order";

import Service from "./Service";

type ServicesProps = {
    setSelectedService: (service: number) => void;
    services: ServiceTypeSummary[] | undefined;
};

const Services: React.FC<ServicesProps> = ({ services, setSelectedService }) => {
    const params = useParams();
    const [active, setActive] = useState<number | undefined>();

    useEffect(() => {
        if (services && window.innerWidth > 575) {
            setActive(services![0].id);
        }
    }, [params, services]);

    const selectedServicehandler = (id: number) => {
        setActive(id);
        setSelectedService(id);
    };

    return (
        <div className="flex w-full flex-col items-start justify-start gap-2 sm:gap-4 md:w-1/2">
            {services?.map((service, index) => {
                return (
                    <Service
                        key={index}
                        id={service.id}
                        image={service.icon || ""}
                        link={
                            service.id == 0 || service.id == -1
                                ? `/panel/cash-income/${service.slug}`
                                : `/panel/order/new/${service.slug}`
                        }
                        text={service.title}
                        active={active == service.id}
                        setSelectedService={selectedServicehandler}
                    />
                );
            })}
        </div>
    );
};

export default Services;
