import IconChevronLeft from "@/components/svg/chevron-left";
import Button from "@/components/ui/Button";
import PerPage from "@/components/ui/Pagination/PerPage";

export type PaginationProps = {
    nextPage?: () => void;
    prevPage?: () => void;
    onPageChange: (page: string) => void;
    onPerPageChange?: (perPage: string) => void;
    paginationInfo: {
        from?: number;
        to?: number;
        perPage?: number;
        page: number;
        pages?: number;
        count?: number;
        forcePerPage?: number;
    };
};

const Pagination: React.FC<PaginationProps> = ({
    nextPage,
    prevPage,
    onPageChange,
    onPerPageChange,
    paginationInfo: { pages = 1, page, count = 1, perPage, to = 1, from = 1, forcePerPage = 1 },
}) => {
    nextPage = page !== pages ? nextPage : undefined;
    prevPage = page !== 1 ? prevPage : undefined;

    const isHavePerPage = perPage && onPerPageChange;

    const onPageClicked = (page: number) => {
        onPageChange(String(page));
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const pageButtonClasses = "px-2 text-text-main hover:text-primary transition-colors duration-150";

    return (
        <div
            className={`mt-4 flex w-full items-center justify-center px-4 ${
                isHavePerPage && "sm:justify-between"
            } md:px-8`}
        >
            {isHavePerPage && (
                <div className="flex items-center justify-start gap-2 max-sm:hidden">
                    <div className="flex h-[40px] items-center justify-center rounded-[8px] bg-secondary-200 contrast:bg-transparent  px-4 pt-[2px] text-text-main">
                        نمایش {from} تا {to} از {count} رکورد
                    </div>
                    <PerPage
                        pages={pages}
                        activePerPage={perPage}
                        onPerPageChange={onPerPageChange}
                        forcePerPage={forcePerPage}
                        onPageChange={onPageChange}
                        count={count}
                    />
                </div>
            )}
            <div className="flex items-center justify-center gap-4">
                <Button
                    onClick={prevPage}
                    disabled={!prevPage}
                    disabledTooltip="دکمه غیرفعال است"
                    className="w-[30px]"
                    icon={IconChevronLeft}
                    iconClassName="rotate-180"
                    circle
                />

                {/* first page */}
                <div className="flex w-[160px] items-center justify-center md:w-[180px]">
                    {!(page - 3 <= 0) && (
                        <>
                            <button onClick={() => onPageClicked(1)} className={pageButtonClasses}>
                                1
                            </button>
                            <span className="text-text-paragraph">...</span>
                        </>
                    )}

                    {/* 2 prev page */}
                    {!(page - 2 <= 0) && page - 3 <= 0 && (
                        <button onClick={() => onPageClicked(page - 2)} className={pageButtonClasses}>
                            {page - 2}
                        </button>
                    )}

                    {/* prev page */}
                    {!(page - 1 <= 0) && (
                        <button onClick={() => onPageClicked(page - 1)} className={pageButtonClasses}>
                            {page - 1}
                        </button>
                    )}

                    {/* current page */}
                    <div className="rounded-full px-2 text-primary">{page}</div>

                    {/* next page */}
                    {pages !== page && pages - page > 0 && (
                        <button onClick={() => onPageClicked(page + 1)} className={pageButtonClasses}>
                            {page + 1}
                        </button>
                    )}

                    {/* 2 next page */}
                    {pages !== page && pages - page > 1 && !(pages - page > 2) && (
                        <button onClick={() => onPageClicked(page + 2)} className={pageButtonClasses}>
                            {page + 2}
                        </button>
                    )}

                    {/* last page */}
                    {pages - page > 2 && (
                        <>
                            <span className="text-text-paragraph">...</span>
                            <button onClick={() => onPageClicked(pages)} className={pageButtonClasses}>
                                {pages}
                            </button>
                        </>
                    )}
                </div>

                <Button onClick={nextPage} disabledTooltip="دکمه غیرفعال است" disabled={!nextPage} className="w-[30px]" icon={IconChevronLeft} circle />
            </div>
        </div>
    );
};

export default Pagination;
