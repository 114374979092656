import { useMemo } from "react";

import TablePlaceholder from "@/components/placeholder/TablePlaceholder";
import TableTd, { TableTdProps } from "@/components/ui/Table/TableTd";

type TableProps = {
    rows: TableTdProps[][] | undefined;
    loading?: boolean;
    placeholderRows?: number;
    placeholderCols?: number;
    emptyIndicator?: React.ReactNode;
    className?: string;
};

const Table: React.FC<TableProps> = ({
    rows = [[]],
    loading,
    placeholderRows,
    placeholderCols,
    emptyIndicator,
    className,
}) => {
    const renderHeaders = useMemo(() => {
        return rows[0]?.map((column, index) => {
            return (
                <th className="py-5 text-right text-text-main first:ps-12 last:pe-12 " key={index}>
                    {column.header}
                </th>
            );
        });
    }, [rows]);

    const renderRows = useMemo(() => {
        return rows.map((row, index) => {
            return (
                <tr key={index} className="group flex w-full flex-col sm:table-row relative">
                    {row.map((column, index) => (
                        <TableTd {...column} key={index}>
                            {column.children}
                        </TableTd>
                    ))}
                </tr>
            );
        });
    }, [rows]);

    return loading ? (
        <TablePlaceholder placeholderRows={placeholderRows} placeholderCols={placeholderCols} />
    ) : (
        <>
            {renderRows.length > 0 ? (
                <table className={`w-full table-auto ${className}`}>
                    <thead className="hidden sm:table-header-group">
                        <tr>{renderHeaders}</tr>
                    </thead>
                    <tbody className="flex flex-col sm:table-row-group">{renderRows}</tbody>
                </table>
            ) : (
                emptyIndicator
            )}
        </>
    );
};

export default Table;
