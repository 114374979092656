import { useState } from "react";

import { Currency, PlanType } from "@/interfaces/dashboard/order/order";

import Plan from "./Plan";

type PlansProps = {
    plans: PlanType[];
    currency: Currency | undefined;
    onPlanChange: (id: number) => void;
};

const Plans: React.FC<PlansProps> = ({ plans, currency, onPlanChange }) => {
    const [checked, setChecked] = useState<number>(plans[0]?.id || -1);

    const handleChange = (id: number) => {
        setChecked(id);
        onPlanChange(id);
    };

    return (
        <div className="flex flex-col gap-4">
            <span>یکی از پلن‌های زیر را انتخاب کنید</span>
            {plans.map((plan) => {
                return (
                    <Plan
                        key={plan.id}
                        currency={currency}
                        isChecked={checked == plan.id}
                        {...plan}
                        onChange={handleChange}
                    />
                );
            })}
        </div>
    );
};

export default Plans;
