import Compressor from "compressorjs";

import { sleep } from ".";

type CompressImageArgs = {
    file: File;
    reduceToUnder?: number;
    progress?: (p: number) => void;
};

export const compressImage = async ({ file, progress, reduceToUnder = 2 * 1024 * 1024 }: CompressImageArgs) => {
    let currentFileSize = file.size;
    let currentCompressionQuality = 0.8;
    let latestCompressedFile: File = file;

    const compress = (fileToCompress: File) => {
        return new Promise<File>((resolve, reject) => {
            new Compressor(fileToCompress, {
                quality: currentCompressionQuality,
                mimeType: "image/jpeg",
                success(result) {
                    resolve(new File([result], file.name, { type: "image/jpeg" }));
                },
                error(err) {
                    console.log(err.message);
                    reject(0);
                },
            });
        });
    };

    const calculateProgression = () => {
        const beforeSize = (file.size / 1024 / 1024).toFixed(2);
        const currentSize = (currentFileSize / 1024 / 1024).toFixed(2);
        return Number((100 - (Number(currentSize) / Number(beforeSize)) * 100).toFixed(0));
    };

    if (progress) progress(calculateProgression());

    while (currentFileSize > reduceToUnder) {
        latestCompressedFile = await compress(file);
        currentFileSize = latestCompressedFile.size;
        currentCompressionQuality = currentCompressionQuality - 0.1;
        if (progress) progress(calculateProgression());
    }

    if (progress) progress(100);

    await sleep(250);

    return latestCompressedFile;
};
