import { PropsWithChildren, useEffect } from "react";

type FactorBoxProps = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon: any;
    title: React.ReactNode;
    color?: string;
    withoutBottom?: boolean;
};

const FactorBox: React.FC<PropsWithChildren<FactorBoxProps>> = ({
    color = "hsl(192,97%,56%)",
    icon: Icon,
    title,
    children,
    withoutBottom,
}) => {
    const time = new Date().getTime();

    const setCssVariable = (scopeEl: string, varName: string, varContent: string) => {
        const factorBoxEl = document.querySelector(scopeEl) as HTMLDivElement;
        factorBoxEl.style.setProperty(varName, varContent);
    };

    useEffect(() => {
        if (color) {
            setCssVariable(`#factor-box-${time}`, "--factor-box-color", color);
        }
    }, [color, time]);

    return (
        <div
            id={`factor-box-${time}`}
            className={`relative mt-11 flex flex-col gap-4 rounded-[10px] border border-secondary-200 bg-secondary-transparent p-5 ${
                withoutBottom
                    ? ""
                    : "mb-8 before:absolute before:-bottom-4 before:left-1/2 before:-z-10 before:h-4 before:w-10/12 before:-translate-x-1/2 before:rounded-b-[10px] before:bg-[var(--factor-box-color)]"
            } sm:p-8 md:p-9 lg:p-10`}
        >
            <div className="relative flex flex-col items-center justify-center">
                <div
                    className={`absolute -top-[calc(44px+1.25rem)] flex aspect-square w-[80px] items-center justify-center rounded-full border-[4px] border-[var(--factor-box-color)] bg-white dark:bg-secondary sm:-top-[calc(44px+2.5rem)]`}
                >
                    <Icon className="h-[40px] fill-[var(--factor-box-color)]" />
                </div>
            </div>
            <span className="mt-5 w-full text-center text-lg text-text-main sm:mt-0">{title}</span>

            <div className="relative -mx-5 my-2 border-t-[2px] border-dashed border-secondary contrast:border-white before:absolute before:-right-[20px] before:-top-[21px] before:h-[40px] before:w-[40px] before:rounded-full contrast:before:border-l before:bg-background after:absolute after:-left-[20px] after:-top-[21px] after:h-[40px] after:w-[40px] after:rounded-full contrast:after:border-r after:bg-background dark:before:border-l-0 dark:before:bg-secondary dark:after:border-r-0 dark:after:bg-secondary max-xs:overflow-x-hidden sm:-mx-10 sm:my-5 contrast:before:!bg-white contrast:after:!bg-white"></div>
            {children}
        </div>
    );
};
export default FactorBox;
