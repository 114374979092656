import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import gate from "@/gate";
import { ServiceTypeSummary } from "@/interfaces/dashboard/order/order";

import Category from "../order/Category";
import CategoriesPlaceholder from "../placeholder/CategoriesPlaceholder";

type ServiceCategoriesProps = {
    categories: ServiceTypeSummary[] | undefined;
    activeCategory?: string;
    activeCurreny?: number;
    isLoading: boolean;
};

const CashCategories: React.FC<ServiceCategoriesProps> = ({ categories, activeCategory, activeCurreny, isLoading }) => {
    const { data: allPrices } = useQuery({
        queryFn: gate.cashCalculation.bind(null, "all", 0, ""),
        queryKey: ["all_cash_prices"],
        refetchOnWindowFocus: false,
    });

    const renderCategories = useMemo(() => {
        return (
            (!isLoading &&
                allPrices &&
                categories?.map((category, index) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    const currencyPrice = allPrices[category.type];

                    return (
                        <Category
                            key={index}
                            image={category.icon || ""}
                            link={`/panel/cash-income/${category.slug}`}
                            text={category.title}
                            onSetCategory={() => undefined}
                            currencyPrice={currencyPrice || 0}
                            active={
                                activeCategory
                                    ? category.type == activeCategory.replace("sell-", "").toUpperCase()
                                    : false
                            }
                        />
                    );
                })) || <CategoriesPlaceholder count={7} />
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeCategory, activeCurreny, allPrices, categories]);

    return (
        <div className="flex w-full flex-shrink-0 flex-col items-start justify-start gap-2 sm:gap-4 md:w-1/2 lg:w-1/3">
            {renderCategories}
        </div>
    );
};

export default CashCategories;
