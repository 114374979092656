import { useMutation } from "@tanstack/react-query";
import { useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { PATTERNS, THEMES } from "@/constants/common";
import gate from "@/gate";
import useUser from "@/hooks/queries/useUser";
import useAfter from "@/hooks/useAfter";
import useAnimeSetting from "@/hooks/useAnimeSetting";
import useTheme, { Theme, ThemeFontSizes, ThemePatterns } from "@/hooks/useTheme";
import { Context, ContextType } from "@/store/Context-store";

const useSyncThemeSettings = () => {
    const location = useLocation();
    const { changeThemeTo, isThemeDark, theme } = useTheme();
    const { setAnimationStatus, isAnimationEnabled } = useAnimeSetting();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const prevSettings = useRef<Record<string, any>>({});

    const {
        setSystemTheme,
        setLineHeightValue,
        setShowThemeButton,
        setPattern,
        setHueRotationValue,
        setFontSizeValue,
        setWordSpacingValue,
        setFontWeightValue,
        setLinkUnderline,
        setGrayScaleValue,
        hueRotationValue,
        fontWeightValue,
        wordSpacingValue,
        grayScaleValue,
        fontSizeValue,
        showThemeButton,
        systemTheme,
        linkUnderline,
        lineHeightValue,
        pattern,
        setIsSettingsChanged,
        isSettingsChanged,
    } = useContext(Context) as ContextType;

    const { data: userData, refetch: refetchUser } = useUser();

    const { mutateAsync: syncTheme, isLoading } = useMutation({
        mutationFn: gate.updateThemeSettings,
    });

    useEffect(() => {
        if (location.pathname !== "/panel/appearance" && isSettingsChanged) {
            setIsSettingsChanged(false);

            changeThemeTo(prevSettings.current.theme);
            setAnimationStatus(prevSettings.current.isAnimationEnabled ?? true);
            setSystemTheme(prevSettings.current.systemTheme ?? false);
            setLineHeightValue(prevSettings.current.lineHeightValue ?? 1);
            setShowThemeButton(prevSettings.current.showThemeButton ?? false);
            setPattern(prevSettings.current.pattern ?? PATTERNS.PATTERN_NONE);
            setHueRotationValue(prevSettings.current.hueRotationValue ?? 0);
            setFontSizeValue(prevSettings.current.fontSizeValue ?? "md");
            setWordSpacingValue(prevSettings.current.wordSpacingValue ?? 0);
            setFontWeightValue(prevSettings.current.fontWeightValue ?? 500);
            setLinkUnderline(prevSettings.current.linkUnderline ?? false);
            setGrayScaleValue(prevSettings.current.grayScaleValue ?? 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSettingsChanged, location.pathname]);

    const syncThemeFunction = async (setting: Record<string, string | number | boolean>) => {
        if (userData.data.themeDetails) {
            const isMobile = window.matchMedia("(max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2)");

            const response = await syncTheme({
                device_size: isMobile.matches ? "web_mobile" : "web_desktop",
                hueRotationValue,
                fontWeightValue,
                wordSpacingValue,
                grayScaleValue,
                fontSizeValue,
                showThemeButton,
                systemTheme,
                linkUnderline,
                lineHeightValue,
                pattern,
                isThemeDark,
                theme,
                isAnimationEnabled,
                ...setting,
            });
            setIsSettingsChanged(false);
            await refetchUser();

            return response;
        }
    };

    useEffect(() => {
        if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
            changeThemeTo(THEMES.DEFAULT_DARK);
        } else {
            changeThemeTo(THEMES.DEFAULT_LIGHT);
        }

        setAnimationStatus(true);
        setSystemTheme(false);
        setLineHeightValue(1);
        setShowThemeButton(false);
        setPattern(PATTERNS.PATTERN_NONE);
        setHueRotationValue(0);
        setFontSizeValue("md");
        setWordSpacingValue(0);
        setFontWeightValue(500);
        setLinkUnderline(false);
        setGrayScaleValue(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const applySettings = () => {
        if (userData.data.themeDetails) {
            const { web_mobile, web_desktop } = userData.data.themeDetails;
            const isMobile = window.matchMedia("(max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2)");
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const settings = isMobile.matches ? web_mobile! : web_desktop!;

            if (settings?.theme) changeThemeTo(settings?.theme as Theme);
            if (settings?.isAnimationEnabled) setAnimationStatus(settings.isAnimationEnabled === "1");
            if (settings?.systemTheme) setSystemTheme(settings.systemTheme === "1");
            if (settings?.lineHeightValue) setLineHeightValue(Number(settings.lineHeightValue));
            if (settings?.showThemeButton) setShowThemeButton(settings.showThemeButton === "1");
            if (settings?.pattern) setPattern(settings.pattern as ThemePatterns);
            if (settings?.hueRotationValue) setHueRotationValue(Number(settings.hueRotationValue));
            if (settings?.fontSizeValue) setFontSizeValue(settings.fontSizeValue as ThemeFontSizes);
            if (settings?.wordSpacingValue) setWordSpacingValue(Number(settings.wordSpacingValue));
            if (settings?.fontWeightValue) setFontWeightValue(Number(settings.fontWeightValue));
            if (settings?.linkUnderline) setLinkUnderline(settings.linkUnderline === "1");
            if (settings?.grayScaleValue) setGrayScaleValue(Number(settings.grayScaleValue));
        }
    };

    useAfter(() => {
        setTimeout(() => {
            const { web_mobile, web_desktop } = userData.data.themeDetails;
            const isMobile = window.matchMedia("(max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2)");
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const settings = isMobile.matches ? web_mobile! : web_desktop!;

            prevSettings.current.theme = settings?.theme ?? THEMES.DEFAULT_DARK;
            if (settings?.isAnimationEnabled)
                prevSettings.current.isAnimationEnabled = settings.isAnimationEnabled === "1";
            if (settings?.systemTheme) prevSettings.current.systemTheme = settings.systemTheme === "1";
            if (settings?.lineHeightValue) prevSettings.current.lineHeightValue = Number(settings.lineHeightValue);
            if (settings?.showThemeButton) prevSettings.current.showThemeButton = settings.showThemeButton === "1";
            if (settings?.pattern) prevSettings.current.pattern = settings.pattern as ThemePatterns;
            if (settings?.hueRotationValue) prevSettings.current.hueRotationValue = Number(settings.hueRotationValue);
            if (settings?.fontSizeValue) prevSettings.current.fontSizeValue = settings?.fontSizeValue as ThemeFontSizes;
            if (settings?.wordSpacingValue) prevSettings.current.wordSpacingValue = Number(settings.wordSpacingValue);
            if (settings?.fontWeightValue) prevSettings.current.fontWeightValue = Number(settings.fontWeightValue);
            if (settings?.linkUnderline) prevSettings.current.linkUnderline = settings.linkUnderline === "1";
            if (settings?.grayScaleValue) prevSettings.current.grayScaleValue = Number(settings.grayScaleValue);

            applySettings();
        }, 10);
    }, !!userData.data.themeDetails);

    return { syncThemeFunction, isLoading };
};

export default useSyncThemeSettings;
