export const defaultUser = {
    "data": {
        "user": {
            "id": 12345,
            "name": "دریافت اطلاعات",
            "username": "username_1234",
            "email": "example@test.com",
            "national_code": "1234567890",
            "mobile": "09123456789",
            "phone": "07112345678",
            "status": 1,
            "user_role": "user",
            "fk_city_id": 123,
            "fk_province_id": 12,
            "postal_code": "1234567890",
            "address": "",
            "birthday": "2001-01-01",
            "created_at": "",
            "updated_at": "",
            "deleted_at": null,
            "we_trust": 0,
            "block": 0,
            "last_login_at": "",
            "last_login_ip": "",
            "notify_login": "no",
            "tow_factor_auth": 0,
            "ip": "",
            "admin_text": "",
            "tfa_type": "MOBILE",
            "referred_by": null,
            "affiliate_id": "abcdefg",
            "reagent": null,
            "limit": 0,
            "level": "A",
            "user_profit": 0,
            "can_withdraw": 1,
            "can_addfund": 1,
            "need_video_verification": 1,
            "cooperation": 0,
            "fk_seconder_id": null,
            "confirmed": 0,
            "online_chat_token": "",
            "enable_auto_order": 1,
            "avalClubPoint": 0,
            "aval_club_point_object": {
                "id": 1,
                "pointable_type": "Modules\\User\\Entities\\User",
                "pointable_id": 1,
                "point": 1,
                "created_at": "",
                "updated_at": "",
            },
            "birthday_fa": "1300-01-01",
            "province": {
                "province_id": 1,
                "name": "",
            },
            "city": {
                "city_id": 1,
                "fk_province_id": 1,
                "name": "",
            },
        },
        "balance": 0,
        "verifications": [
            {
                "verification_type": "EMAIL",
                "resultStatus": "INIT",
            },
            {
                "verification_type": "MOBILE",
                "resultStatus": "INIT",
            },
            {
                "verification_type": "NATIONAL_ID",
                "resultStatus": "INIT",
            },
            {
                "verification_type": "CREDIT",
                "resultStatus": "INIT",
            },
            {
                "verification_type": "PHONE",
                "resultStatus": "INIT",
            },
            {
                "verification_type": "VIDEO",
                "resultStatus": "INIT",
            },
            {
                "verification_type": "POSTAL_CODE",
                "resultStatus": "INIT",
            },
        ],
        "verificationTypes": {
            "EMAIL": "\u0627\u06cc\u0645\u06cc\u0644",
            "MOBILE": "\u0645\u0648\u0628\u0627\u06cc\u0644",
            "NATIONAL_ID": "\u06a9\u062f \u0645\u0644\u06cc",
            "CREDIT": " \u06a9\u0627\u0631\u062a \u0628\u0627\u0646\u06a9\u06cc ",
            "PHONE": "\u062a\u0644\u0641\u0646 \u062b\u0627\u0628\u062a",
            "VIDEO": "\u0648\u06cc\u062f\u06cc\u0648\u06cc\u06cc",
            "POSTAL_CODE": "\u06a9\u062f\u067e\u0633\u062a\u06cc",
        },
        "verificationSettings": {
            "can_verify_POSTAL_CODE": false,
            "can_verify_CREDIT": false,
            "bank_card_verification_status": "PENDING",
            "auto_address_verification": false,
            "auto_get_bank_data": false,
            "video_verification_text": "",
        },
        "avatar": "",
    },
};
