import api from "../api";

type LastBrokerOrderResponse = {
    order_id: number;
    invoice_id: number;
    link: string;
};

export default {
    getLastBrokerOrder: () => api.get<LastBrokerOrderResponse>("/panel/api/v1/orders/last-broker-order"),
};
