import gregorian from "react-date-object/calendars/gregorian";
import { Control } from "react-hook-form";
import { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

import Input from "../ui/Input";

type PaymentAnnouncementCryptoInputsProps = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: Control<any>;
    service?: string;
};

const PaymentAnnouncementCryptoInputs: React.FC<PaymentAnnouncementCryptoInputsProps> = ({ control, service }) => {
    return (
        <div className="mb-4 flex flex-col gap-4">
            <Input
                name="pay_date"
                control={control}
                ltr
                containerClassName="w-full"
                variant="fill"
                topTitle="تاریخ واریز"
                placeholder="تاریخ واریز"
                autoComplete="off"
                datePicker
                datePickerProps={{
                    calendar: gregorian,
                    maxDate: new DateObject({ calendar: gregorian }),
                    format: "YYYY-MM-DD HH:mm",
                    plugins: [<TimePicker position="bottom" hideSeconds key={1} />],
                }}
                rules={{
                    required: "تاریخ واریز الزامی است",
                }}
            />
            <Input
                containerClassName="w-full"
                name="wallet"
                variant="fill"
                topTitle={
                    <>
                        کیف پول واریزی <span className="text-xs">(کیف پول شما)</span>
                    </>
                }
                inputMode="text"
                type="text"
                placeholder="کیف پول واریزی"
                ltr
                autoComplete="off"
                control={control}
                rules={{
                    required: "کیف پول واریزی الزامی است",
                }}
            />
            {service != "WEBMONEY" && (
                <Input
                    containerClassName="w-full"
                    name="tx_id"
                    variant="fill"
                    topTitle="TX ID"
                    inputMode="text"
                    type="text"
                    placeholder="شماره تراکنش"
                    ltr
                    autoComplete="off"
                    control={control}
                    rules={{
                        required: "شماره تراکنش واریزی الزامی است",
                    }}
                />
            )}
        </div>
    );
};
export default PaymentAnnouncementCryptoInputs;
