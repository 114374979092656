import { AxiosProgressEvent } from "axios/index";

import api from "../api";

type BankCardsResponse = {
    "national_id_verified": boolean;
    "insertManual": boolean;
    "main_account": {
        "value": string;
        "status": CreditCard["status"];
        "verification_id": number;
        "fk_user_id": number;
        "verification_type": string;
        "code": string;
        "verified_at": string;
        "created_at": string;
        "updated_at": string;
        "admin_id": number;
        "description": string | null;
    };
    "cards": CreditCard[];
};

type AddBankCardRequest = {
    iban: string | null;
    account_number: string | null;
    birthday: string | null;
    national_code: string | undefined;
    card_number: string | null;
    attachments?: File;
    name: string;
};

type UpdateUserRequest = {
    name?: string;
    email?: string;
    birthday?: string;
    national_code?: string;
    phone?: string;
};

type UpdateBankRequest = {
    card_number: string;
    account_number: string;
    iban: string;
    card_image: File;
};

type UpdateNationalIdRequest = {
    front_image: File;
    back_image: File;
};

type UpdateSelfieRequest = {
    selfie_image: File;
};

type GetAddressInfoRequest = {
    postalCode: string;
};

type GetAddressInfoResponse = {
    postalCode: string;
    address: string;
    province: string;
    district: string;
};

type UpdatePostalCodeRequest = {
    postal_code: string;
    fk_province_id?: string | number | null;
    address?: string | null;
    fk_city_id?: string | number | null;
};

type VerifyRequest = {
    verifyType: "EMAIL" | "MOBILE" | "NATIONAL_ID" | "PHONE" | "BUSINESS";
};

type VerifyResponse = {
    message: string;
    error: boolean;
};

type GetAffiliateinfoResponse = {
    "data": {
        "referred_count": number;
        "commission": string;
        "earn_commission": number;
        "link": string;
        "ads_240_240": string;
        "ads_120_240": string;
        "ads_728_90": string;
        "ads_300_250": string;
        "ads_468_60": string;
        "ads_300_100": string;
        "ads_600_300": string;
        "ads_160_600": string;
    };
};

type SendVideoVerificationRequest = {
    face_id: Blob;
};

type LoginWithTokenRequest = {
    uuid: string;
};

type ChangePasswordRequest = {
    current_password: string;
    password: string;
    password_confirmation: string;
};

type Change2FARequest = {
    tfa_type: "MOBILE" | "GOOGLE";
};

type UpdateAvatarRequest = {
    avatar: Blob | File;
};

type UpdateBusinessRequest = {
    business_name: string;
    business_financial_code: string;
    business_register_number: string;
    business_national_index: string;
};

type UpdateThemeSettingsRequest = {
    device_size: "android" | "ios" | "web_desktop" | "web_mobile";
} & Record<string, string | number | boolean>;

type UpdateThemeSettingsResponse = {
    message : string;
}

export default {
    bankCards: () => api.get<BankCardsResponse>("/panel/api/v1/bank-credits"),
    addBankCard: (data: AddBankCardRequest) => api.file("/panel/bank-credits/create", data),
    updateUser: (data: UpdateUserRequest) => api.post("/panel/profile/update", data),
    updateBank: async (data: UpdateBankRequest) =>
        api.file("/panel/bank-credits/create", {
            iban: data.iban,
            account_number: data.account_number,
            card_number: data.card_number,
            attachments: data.card_image,
            name: "ناشناس",
        }),
    updateBusiness: async (data: UpdateBusinessRequest) => api.post("/panel/profile/update", data),
    updateNationalId: async (data: UpdateNationalIdRequest) => {
        const formData = new FormData();
        formData.append("national_id_card_front", data.front_image);
        formData.append("national_id_card_back", data.back_image);
        formData.append("tyn", "national_id");

        await api.file("/panel/profile/upload-document", formData, (event) => {
            console.log("uploading", event.progress);
        });
    },
    updateSelfie: async (data: UpdateSelfieRequest) => {
        const formData = new FormData();
        formData.append("credit", data.selfie_image);
        formData.append("tyc", "credit");

        await api.file("/panel/profile/upload-document", formData, (event) => {
            console.log("uploading", event.progress);
        });
    },
    getAddressInfo: async (data: GetAddressInfoRequest) => {
        return await api.post<GetAddressInfoResponse>("/panel/profile/address/get", data);
    },
    updatePostalCode: async (data: UpdatePostalCodeRequest) => api.post("/panel/profile/address/update", data),
    verifyRequest: (data: VerifyRequest) => api.post<VerifyResponse>("/panel/profile/verify-ajax", data),
    getAffiliateInfo: () => api.get<GetAffiliateinfoResponse>("/panel/api/v1/profile/referrals"),
    sendVideoVerification: (data: SendVideoVerificationRequest, onProgress: (e: AxiosProgressEvent) => void) =>
        api.file("/panel/api/v1/profile/video/record", data, onProgress),
    loginWithToken: (data: LoginWithTokenRequest) => api.get(`/api/v1/user/access/token/${data.uuid}`),
    changePassword: (data: ChangePasswordRequest) => api.post("/panel/profile/change-password", data),
    change2FA: (data: Change2FARequest) => api.post("/panel/profile/2fa/enable/1", data),
    updateAvatar: (data: UpdateAvatarRequest) => api.file("/panel/profile/avatar", data),
    updateThemeSettings: (data: UpdateThemeSettingsRequest) => api.post<UpdateThemeSettingsResponse>("/panel/api/v1/profile/edit/theme", data),
};
