import api from "../api";

export type TransactionsResponse = {
    transactions: {
        current_page: number;
        data: Transaction[];
        first_page_url: string;
        from: number;
        last_page: number;
        last_page_url: string | null;
        next_page_url: string;
        path: string;
        per_page: string;
        prev_page_url: string | null;
        to: number;
        total: number;
    };
    user: {
        balance: number;
        can_withdraw: 0 | 1;
        can_addfund: 0 | 1;
        bank_cards: { id: number | string; name: string; card_number: string; account_number: string; iban: string }[];
    };
    add_fund_min: string;
    add_fund_max: string;
    tfa_authenticator: "1" | "0";
    is_captcha_enable: boolean;
    is_working_time: boolean;
    server_time?: number;
};

type WithdrawOtpRequest = {
    price: number;
    card_id: string | number;
    "g-recaptcha-response"?: string;
};

type WithdrawOtpResponse = {
    hash: string;
    message: string;
};

type WithdrawRequest = {
    price: number;
    card_id: string | number;
    hash?: string;
    code?: string;
    "g-recaptcha-response"?: string;
    is_immediate?: boolean;
};

type AddFundRequest = {
    price: number;
};

type AddFundResponse = {
    "transaction": {
        "id": number;
        "type": string;
    };
};

export default {
    transactions: (page: number, perPage: number) =>
        api.get<TransactionsResponse>(`/panel/api/v1/transactions?page=${page}&per_page=${perPage}`),
    withdrawOtp: (data: WithdrawOtpRequest) =>
        api.post<WithdrawOtpResponse>("/panel/api/v1/transactions/withdraw/otp", data),
    withdraw: (data: WithdrawRequest) => api.post("/panel/wallet/withdraw", data),
    addFund: (data: AddFundRequest) => api.post<AddFundResponse>("/panel/wallet/addfund", data),
};
