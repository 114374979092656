// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Num2persian from "num2persian";
import { useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import ToastError from "@/components/error/ToastError";
import Button from "@/components/ui/Button";
import Input from "@/components/ui/Input";
import Modal from "@/components/ui/Modal";
import Select from "@/components/ui/Select";
import { IMMEDIATE_WITHDRAW_COST } from "@/constants/common";
import { banksList } from "@/data/Banks";
import useTransactions from "@/hooks/queries/useTransactions";
import { formatNumber } from "@/utils";

import ImmediateWithdraw from "../ImmediateWithdraw";

type WithdrawAmountProps = {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    setIsConfirmOpen: (isOpen: boolean) => void;
};

const WithdrawAmount: React.FC<WithdrawAmountProps> = ({ isOpen, setIsOpen, setIsConfirmOpen }) => {
    const [isImmediate, setIsImmediate] = useState<boolean>(false);

    const { data: walletData } = useTransactions();

    const walletBalance = walletData ? walletData.user.balance : 1;

    const {
        control,
        setValue,
        trigger,
        reset,
        formState: { isValid, isDirty },
    } = useFormContext();

    const withdrawAmount = useWatch({ control, name: "amount" });

    const cards = walletData?.user.bank_cards;

    const feeAmount = withdrawAmount > 1_000_000 ? 10_000 : 5_000;

    const userBanks = useMemo(() => {
        if (cards && cards.length !== 0) {
            return cards.map((bank) => ({
                value: bank.card_number,
                label: bank.name,
            }));
        }

        return [
            {
                isDisabled: true,
                value: "",
                label: "حسابی جهت تسیوه حساب معرفی نشده است!",
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletData]);

    const getBankIcon = (bankCode: string) => {
        const bank = banksList.find((bank) => {
            return bankCode.includes(String(bank.code));
        });

        return bank?.logo;
    };

    const setAllBalance = () => {
        setValue("amount", walletBalance);
        trigger();
    };

    const submitWithdraw = async () => {
        await trigger();

        if (isImmediate && withdrawAmount <= IMMEDIATE_WITHDRAW_COST + 1000) {
            ToastError({
                message: `حداقل میزان برای تسویه آنی ${formatNumber(IMMEDIATE_WITHDRAW_COST + 1000)} تومان است`,
            });
            return;
        }

        if (isValid) {
            setIsOpen(false);
            setIsConfirmOpen(true);
        }
    };

    return (
        <Modal
            show={isOpen}
            onClose={() => {
                reset();
                setIsImmediate(false);
                setIsOpen(false);
            }}
            title="برداشت وجه"
            className="max-w-[500px]"
        >
            <div>
                <div className="flex items-center justify-between rounded-[10px] bg-secondary-200 p-4 text-text-main">
                    <span>موجودی کیف پول</span>
                    <button
                        type="button"
                        className="flex items-center justify-end gap-2 transition-all hover:brightness-125"
                        onClick={setAllBalance}
                    >
                        <span className="text-primary">{formatNumber(walletBalance)}</span>
                        <span>تومان</span>
                    </button>
                </div>

                <div className="w-full pt-10">
                    <Input
                        ltr
                        autoFocus
                        control={control}
                        name="amount"
                        variant="fill"
                        topTitle="مقدار برداشت را وارد کنید"
                        step="1"
                        rules={{
                            required: "لطفا مقدار برداشت را وارد کنید",
                            validate: (value: string) => {
                                const parsedValue = Number(value);
                                if (parsedValue >= 1000 && parsedValue <= walletBalance) return true;
                                return `مقدار برداشت باید بین ${1000} تا ${walletBalance} تومان باشد`;
                            },
                        }}
                        addon={
                            <button
                                className="whitespace-nowrap text-primary transition-all hover:brightness-125"
                                onClick={setAllBalance}
                            >
                                تمام موجودی
                            </button>
                        }
                    />
                    <div className="px-4 pt-3 text-[0.85rem] text-text-paragraph">
                        {Num2persian(withdrawAmount)} تومان
                    </div>
                    <Select
                        className="pt-6"
                        control={control}
                        name="bank"
                        placeholder="کارت بانکی"
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        formatOptionLabel={(data: any) => {
                            if (data.value.length !== 0) {
                                return (
                                    <div className="flex items-center justify-start gap-2">
                                        <img
                                            src={`/images/banks/${getBankIcon(data.value)}`}
                                            className="h-[25px] w-[25px]"
                                            alt="selected-bank"
                                        />
                                        <span className="mt-1">
                                            {data.label} - {data.value}
                                        </span>
                                    </div>
                                );
                            }

                            return data.label;
                        }}
                        options={userBanks}
                        topTitle="بانک خود را انتخاب کنید"
                        rules={{ required: "لطفا یک بانک را انتخاب کنید" }}
                    />

                    {walletData?.is_working_time && (
                        <ImmediateWithdraw
                            onIsImmediateChange={(value: boolean) => {
                                setIsImmediate(value);
                                setValue("isImmediate", value);
                            }}
                        />
                    )}

                    <Button
                        disabled={(!isValid && isDirty) || (cards && cards.length === 0)}
                        disabledTooltip="دکمه غیرفعال است"
                        type="button"
                        className="mt-12 w-full"
                        onClick={submitWithdraw}
                    >
                        برداشت موجودی
                    </Button>
                    <div className="mt-1 flex flex-wrap items-center justify-between">
                        <div className="whitespace-nowrap px-3 pt-3 text-text-paragraph">
                            موجودی بعد از برداشت :
                            <span className="text-primary">
                                {formatNumber(walletBalance - withdrawAmount < 0 ? 0 : walletBalance - withdrawAmount)}
                            </span>
                        </div>
                        <div className="whitespace-nowrap px-3 pt-3 text-text-paragraph">
                            کارمزد :{" "}
                            <span className="text-primary">
                                {formatNumber(isImmediate ? IMMEDIATE_WITHDRAW_COST : feeAmount)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default WithdrawAmount;
