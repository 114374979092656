import { ReactNode } from "react";
import Skeleton from "react-loading-skeleton";

import { cashStatus } from "@/utils/cases";

import Badge from "./Badge";

type CashItemDetailProps = {
    title: string;
    value?: string | number | ReactNode;
};

const CashItemDetail: React.FC<CashItemDetailProps> = ({ title, value }) => {
    const status = cashStatus(String(value));

    return (
        <div className="flex w-full items-center justify-between border-b border-secondary-200 px-6 py-4 last:border-none">
            <div className="text-text-paragraph">{title}:</div>
            {title == "وضعیت" ? (
                value ? (
                    <Badge customColor={status.color}>{value}</Badge>
                ) : (
                    <Skeleton width={120} height={30} style={{ borderRadius: "999px" }} />
                )
            ) : (
                <div className="text-text-main">{value ?? <Skeleton width={120} height={15} />}</div>
            )}
        </div>
    );
};

export default CashItemDetail;
