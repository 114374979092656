import { FormEvent, useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import toast from "react-hot-toast";

import IconCircleUser from "@/components/svg/circle-user";
import IconHouse from "@/components/svg/house";
import IconLockKeyhole from "@/components/svg/lock-keyhole";
import AlertBox from "@/components/ui/AlertBox";
import BoxContainer from "@/components/ui/BoxContainer";
import Breadcrumb from "@/components/ui/Breadcrumb";
import Button from "@/components/ui/Button";
import CustomRadio from "@/components/ui/CustomRadio";
import Input from "@/components/ui/Input";
import Level from "@/components/ui/Level";
import Title from "@/components/ui/Title";
import PageTitle from "@/components/utility/PageTitle";
import useChange2FA from "@/hooks/queries/useChange2FA";
import useChangePassword from "@/hooks/queries/useChangePassword";
import useUpdateUser from "@/hooks/queries/useUpdateUser";
import useUser from "@/hooks/queries/useUser";
import useVerifyRequest from "@/hooks/queries/useVerifyRequest";
import useAuth from "@/hooks/useAuth";
import { EMAIL_VALIDATION } from "@/lib/validations";
import Detail from "@/pages/dashboard/account/Detail";
import SelectAvatar from "@/pages/dashboard/account/SelectAvatar";

type UpdatePasswordSchema = {
    current_password: string;
    password: string;
    password_confirmation: string;
};

type UpdateTwoStepLoginSchema = {
    tfa_type: "GOOGLE" | "MOBILE";
};

const Profile = () => {
    const { logout } = useAuth();

    const { data: user, refetch: refetchUser } = useUser();
    const { mutateAsync: changePassword, isLoading: isChangePasswordLoading } = useChangePassword();
    const { mutateAsync: change2FA, isSuccess: isChange2FASuccess } = useChange2FA();

    const { mutateAsync: updateUser, isLoading: isUserUpdating } = useUpdateUser();
    const { mutateAsync: verifyRequest } = useVerifyRequest();
    const [showChangeEmailForm, setShowChangeEmailForm] = useState(false);
    const [newEmail, setNewEmail] = useState(user.data.user.email);

    const isEmailVerified =
        user.data.verifications.find((item) => item.verification_type === "EMAIL")?.resultStatus === "CONFIRMED";

    const {
        control: updatePasswordControl,
        handleSubmit: updatePasswordSubmitHandler,
        watch: updatePassWatch,
    } = useForm<UpdatePasswordSchema>();

    const newPassword = useRef({});
    newPassword.current = updatePassWatch("password", "");

    const {
        control: updateTwoStepControl,
        handleSubmit: updateTwoStepSubmitHandler,
        watch,
    } = useForm<UpdateTwoStepLoginSchema>({
        defaultValues: {
            "tfa_type": "MOBILE",
        },
    });

    const {
        isLoading: verificationLoading,
        data: verificationRequestData,
        mutateAsync: emailVerification,
    } = useVerifyRequest();

    const twoStepLoginValue = useWatch({ control: updateTwoStepControl, name: "tfa_type" });

    useEffect(() => {
        const subscription = watch(() => updateTwoStepSubmitHandler(onUpdateTwoStepLoginSubmit)());
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateTwoStepSubmitHandler, watch]);

    const onUpdatePasswordSubmit: SubmitHandler<UpdatePasswordSchema> = async (data) => {
        await changePassword(data);
        toast.success("رمز عبور شما با موفقیت تغییر کرد");

        setTimeout(() => logout(true), 2000);
    };

    const onUpdateTwoStepLoginSubmit: SubmitHandler<UpdateTwoStepLoginSchema> = async (data) => {
        await change2FA(data);
        toast.success("ورود دو مرحله‌ای پیامک بر روی حساب کاربری شما فعال شد");
    };

    const sendVerificationEmail = async () => {
        const response = await emailVerification({
            verifyType: "EMAIL",
        });

        if (response.error == false) {
            toast.success(response.message);
        }
    };

    const updateEmail = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const emailRegExp = new RegExp(EMAIL_VALIDATION);

        if (newEmail.length !== 0 && emailRegExp.exec(newEmail)) {
            if (user.data.user.email !== newEmail) {
                await updateUser({ email: newEmail });
                await verifyRequest({ verifyType: "EMAIL" });
                await refetchUser();
                toast.success("ایمیل شما تغییر کرد");
            } else {
                toast.error("لطفا یک ایمیل جدید وارد کنید");
            }
        } else {
            toast.error("لطفا یک ایمیل صحیح وارد کنید");
        }
    };

    return (
        <div className="flex min-h-screen w-full flex-col gap-2">
            <PageTitle>پروفایل کاربری</PageTitle>
            <div className="w-full">
                <Breadcrumb
                    sections={[
                        { text: "خانه", icon: IconHouse, link: "/panel" },
                        { text: "ویرایش حساب کاربری", icon: IconCircleUser, active: true },
                    ]}
                />
            </div>
            <div className="flex gap-8 max-lg:flex-col">
                <BoxContainer colorBox={false} className="flex-1">
                    <Title title="اطلاعات حساب" containerClasses="-mx-5 -mt-5" />
                    <div className="pt-4">
                        <div className="flex items-center gap-3 max-[420px]:flex-col min-[420px]:justify-between">
                            <div className="flex items-center gap-4 max-[420px]:flex-col max-[420px]:justify-center">
                                <div className="relative aspect-square w-[60px] overflow-hidden rounded-full bg-secondary-200 xs:w-[65px]">
                                    <img
                                        src={user.data.avatar}
                                        className="absolute top-0 h-full w-full object-contain"
                                        alt={user.data.user.username}
                                    />
                                </div>
                                <div className="flex flex-col gap-2 max-[420px]:items-center">
                                    <div className="text-[1.28rem] text-text-main">{user.data.user.name}</div>
                                    <div className="text-[0.85rem] text-text-paragraph">
                                        {user.data.user.is_business ? "تاریخ عضویت مدیرعامل" : "تاریخ عضویت "}
                                        {user.data.user.created_at}
                                    </div>
                                </div>
                            </div>
                            <div className="flex h-full items-center justify-center min-[420px]:pl-3 xs:pl-6">
                                <Level />
                            </div>
                        </div>
                        <SelectAvatar />

                        <div className="-mx-5 grid grid-cols-1 gap-x-2 gap-y-4 border-b border-secondary-200 px-6 pb-4 pt-8 text-text-paragraph min-[420px]:grid-cols-2">
                            {user.data.user.is_business && <Detail title="نام" value={user.data.user.originalName} />}
                            <Detail title="ایمیل" value={user.data.user.email} detailFor="place" />
                            <Detail title="موبایل" value={user.data.user.mobile} />
                            <Detail title="تاریخ تولد" value={user.data.user.birthday_fa?.replace(/-/g, "/")} />
                            <Detail title="کد ملی" value={user.data.user.national_code} />
                        </div>

                        {user.data.user.is_business && user.data.user.info.business_information && (
                            <div className="-mx-5 grid grid-cols-1 gap-x-2 gap-y-4 border-b border-secondary-200 px-6 py-4  text-text-paragraph min-[420px]:grid-cols-2">
                                <Detail title="نام شرکت" value={user.data.user.name} detailFor={false} />
                                <Detail
                                    title="کد اقتصادی"
                                    value={user.data.user.info.business_information.financial_code}
                                    detailFor={false}
                                />
                                <Detail
                                    title="شناسه ملی"
                                    value={user.data.user.info.business_information.national_index}
                                    detailFor={false}
                                />
                                <Detail
                                    title="شماره ثبت"
                                    value={user.data.user.info.business_information.register_number}
                                    detailFor={false}
                                />
                            </div>
                        )}

                        <div className="-mx-5 grid grid-cols-1 gap-2 px-6 py-4 text-text-paragraph min-[420px]:grid-cols-2">
                            <Detail title="کد پستی" value={user.data.user.postal_code} detailFor="place" />
                            <Detail title="تلفن ثابت" value={user.data.user.phone} detailFor="place" />
                        </div>
                        <div className="-mx-5 border-b border-secondary-200 px-6 pb-4">
                            <Detail
                                title="آدرس"
                                value={user.data.user.address}
                                className="!flex-col"
                                detailFor="place"
                            />
                        </div>
                        <div className="-mx-5 grid grid-cols-1 gap-2 px-6 py-4 text-text-paragraph min-[420px]:grid-cols-2">
                            <Detail title="تاریخ آخرین ورود" value={user.data.user.last_login_at} detailFor={false} />
                            <Detail title="آخرین IP وارد شده" value={user.data.user.last_login_ip} detailFor={false} />
                        </div>
                    </div>
                </BoxContainer>
                <div className="flex w-full flex-1 flex-col gap-8">
                    <BoxContainer colorBox={false} className="h-min w-full">
                        <Title title="تغییر رمز عبور" containerClasses="-mx-5 -mt-5" />
                        <form
                            onSubmit={updatePasswordSubmitHandler(onUpdatePasswordSubmit)}
                            className="mt-4 flex flex-col gap-5"
                        >
                            <Input
                                ltr
                                type="password"
                                topTitle="رمز عبور فعلی"
                                variant="fill"
                                control={updatePasswordControl}
                                name="current_password"
                                rules={{ required: "رمز عبور فعلی الزامی است" }}
                            />
                            <Input
                                icon={IconLockKeyhole}
                                ltr
                                type="password"
                                topTitle="رمز عبور جدید"
                                variant="fill"
                                control={updatePasswordControl}
                                name="password"
                                rules={{
                                    required: "رمز عبور جدید الزامی است",
                                    minLength: {
                                        value: 8,
                                        message: "رمز عبور باید 8  کاراکتر و یا بیشتر باشد",
                                    },
                                }}
                            />
                            <Input
                                icon={IconLockKeyhole}
                                ltr
                                type="password"
                                topTitle="تکرار رمز عبور جدید"
                                variant="fill"
                                control={updatePasswordControl}
                                name="password_confirmation"
                                rules={{
                                    required: "تکرار رمز عبور جدید الزامی است",
                                    validate: (value: string) =>
                                        value === newPassword.current || "رمز عبور ها یکسان نیستند",
                                }}
                            />
                            <Button
                                type="submit"
                                className="mt-4"
                                disabled={isChangePasswordLoading}
                                disabledTooltip="دکمه غیرفعال است"
                                loading={isChangePasswordLoading}
                            >
                                تغییر رمز عبور
                            </Button>
                        </form>
                    </BoxContainer>

                    <div className="w-full">
                        {!isEmailVerified && (
                            <AlertBox
                                className="mb-8"
                                type="error"
                                title="فعال سازی ایمیل"
                                description={
                                    <div className="pl-2 text-justify xs:pl-4">
                                        یک ایمیل حاوی کد فعال سازی به آدرس ایمیل شما ارسال شده است، لطفا نسبت به تایید
                                        ایمیل خود اقدام نمایید.
                                        <br />
                                        اگر ایمیل فعال سازی برای شما ارسال نشده و یا تاریخ استفاده از آن گذشته، دکمه
                                        ارسال مجدد کد فعال سازی را فشار دهید تا مجددا کدد تاییده ارسال شود.
                                        <br />
                                        {!showChangeEmailForm ? (
                                            <div className="flex w-full flex-wrap justify-center gap-4 pb-3 pt-5 xs:justify-end">
                                                {/*<Button*/}
                                                {/*    variant="stroke"*/}
                                                {/*    onClick={() => setShowChangeEmailForm(true)}*/}
                                                {/*    className="max-xs:w-full xs:px-8"*/}
                                                {/*    disabled={verificationLoading}*/}
                                                {/*>*/}
                                                {/*    ویرایش ایمیل*/}
                                                {/*</Button>*/}
                                                <Button
                                                    variant="stroke"
                                                    color="error"
                                                    onClick={sendVerificationEmail}
                                                    className="max-xs:w-full xs:px-8"
                                                    disabled={verificationLoading}
                                                    disabledTooltip="دکمه غیرفعال است"
                                                >
                                                    ارسال مجدد ایمیل فعال‌سازی
                                                </Button>
                                            </div>
                                        ) : (
                                            <form className="mt-6 flex w-full gap-4" onSubmit={updateEmail}>
                                                <Button
                                                    disabledTooltip="دکمه غیرفعال است"
                                                    disabled={isUserUpdating}
                                                    loading={isUserUpdating}
                                                >
                                                    ویرایش
                                                </Button>
                                                <Input
                                                    ltr
                                                    variant="fill"
                                                    containerClassName="flex-1"
                                                    value={newEmail}
                                                    onChange={(e) => setNewEmail(e.target.value)}
                                                />
                                            </form>
                                        )}
                                    </div>
                                }
                            />
                        )}
                        <BoxContainer colorBox={false} className="h-min w-full">
                            <div className="py-1">
                                <div className="flex w-full flex-col">
                                    <div
                                        className="-mx-5 -mt-6 space-y-3 border-b border-secondary-200 px-5 py-5 transition-colors data-[tfa-type=sms]:bg-primary data-[tfa-type=sms]:bg-opacity-[0.06]"
                                        data-tfa-type={twoStepLoginValue}
                                    >
                                        <CustomRadio
                                            checked={user.data.user.tfa_type === "MOBILE"}
                                            label="SMS"
                                            name="tfa_type"
                                            control={updateTwoStepControl}
                                            value="MOBILE"
                                        />
                                        <p className="mr-4 text-text-paragraph">ارسال کد احراز هویت به صورت پیامک</p>
                                    </div>
                                    <div
                                        className="-mx-5 -mb-6 space-y-3 border-secondary-200 bg-zinc-600 bg-opacity-30 px-5 py-5 pt-5 transition-colors data-[tfa-type=google]:bg-primary data-[tfa-type=google]:bg-opacity-[0.06]"
                                        data-tfa-type={twoStepLoginValue}
                                    >
                                        <CustomRadio
                                            checked={user.data.user.tfa_type === "GOOGLE"}
                                            disabled
                                            label="Google Authenticator"
                                            name="tfa_type"
                                            control={updateTwoStepControl}
                                            value="GOOGLE"
                                        />
                                        <p className="mr-4 text-text-paragraph">
                                            ارسال کد احراز هویت به برنامه Google Authenticator
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </BoxContainer>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
