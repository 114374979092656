import classNames from "classnames";
import { ButtonHTMLAttributes, forwardRef, useId } from "react";
import { Tooltip } from "react-tooltip";

import IconSpinnerThirdDuotone from "../svg/spinner-third-duotone";

interface ButtonBaseProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: "stroke" | "fill";
    loading?: boolean;
    icon?: any;
    iconClassName?: string;
    circle?: boolean;
    color?: "default" | "warning" | "error" | "success";
    disabledTooltip?: string;
}

type ButtonLabelProps = ButtonBaseProps & {
    as: "label";
    htmlFor: string;
};

type ButtonWithoutLabelProps = ButtonBaseProps & {
    as?: "button";
    htmlFor?: never;
};

type ButtonProps = ButtonLabelProps | ButtonWithoutLabelProps;

const Button: React.FC<ButtonProps> = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            variant = "fill",
            loading,
            disabled,
            disabledTooltip,
            className,
            children,
            icon: Icon,
            iconClassName,
            as = "button",
            htmlFor,
            circle,
            color = "default",
            ...rest
        },
        ref,
    ) => {
        const id = useId();

        const colorClasses = {
            default: {
                base: "focus-within:outline-primary/35",
                icon: "fill-primary",
                fill: "bg-primary contrast:bg-transparent contrast:border-primary contrast:text-primary",
                stroke: "text-primary !border-primary hover:bg-primary",
            },
            warning: {
                base: "focus-within:outline-warning-transparent",
                icon: "fill-warning",
                fill: "bg-warning contrast:bg-transparent contrast:border-warning contrast:text-warning",
                stroke: "text-warning !border-warning hover:bg-warning",
            },
            error: {
                base: "focus-within:outline-error-transparent",
                icon: "fill-error",
                fill: "bg-error contrast:bg-transparent contrast:border-error contrast:text-error",
                stroke: "text-error !border-error hover:bg-error",
            },
            success: {
                base: "focus-within:outline-success-transparent",
                icon: "fill-success",
                fill: "bg-success contrast:bg-transparent contrast:border-success contrast:text-success",
                stroke: "text-success !border-success hover:bg-success",
            },
        };

        const classes = classNames(
            {
                "border-2 transition-all duration-100 select-none text-[1.14rem] flex items-center justify-center gap-3 outline outline-4 outline-transparent": 1,
                "aspect-square rounded-full": circle,
                "h-[2.85rem] px-3 rounded-[8px]": !circle,
                "grayscale": disabled,
                [`${colorClasses[color].fill} text-white border-transparent`]: variant === "fill",
                [`${colorClasses[color].stroke} bg-transparent hover:bg-opacity-5`]: variant === "stroke",
                [`${colorClasses[color].base} active:translate-y-1 hover:brightness-110`]: !disabled,
            },
            className,
        );

        const iconClasses = `h-[50%] aspect-square contrast:fill-primary ${
            variant === "fill" ? "fill-white" : colorClasses[color].icon
        } ${iconClassName}`;

        const content = loading ? (
            <IconSpinnerThirdDuotone className={iconClasses + " animate-spin"} />
        ) : (
            <>
                {children}
                {Icon && <Icon className={iconClasses} />}
            </>
        );

        return (
            <>
                <button
                    {...rest}
                    ref={ref}
                    disabled={disabled || loading}
                    data-tooltip-id={id}
                    data-tooltip-place="bottom"
                    data-tip-disable={false}
                    className={classes}
                >
                    {as === "button" ? (
                        content
                    ) : (
                        <label
                            htmlFor={htmlFor}
                            className="flex h-full w-full cursor-pointer items-center justify-center"
                        >
                            {content}
                        </label>
                    )}
                </button>

                <Tooltip id={id} content={disabled ? disabledTooltip : undefined} className="z-[100]" />
            </>
        );
    },
);

export default Button;
