import { useQueryClient } from "@tanstack/react-query";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { SubmitHandler, useForm } from "react-hook-form";
import { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import { useNavigate, useParams } from "react-router-dom";

import Button from "@/components/ui/Button";
import Input from "@/components/ui/Input";
import Textarea from "@/components/ui/Textarea";
import useInvoice from "@/hooks/queries/useInvoice";
import usePayWithDraft from "@/hooks/queries/usePayWithDraft";
import useUploadHandler from "@/hooks/useUploadHandler";
import { GetTransactionResponse } from "@/interfaces/dashboard/invoice/invoice";
import ShowDraftBankCards from "@/pages/dashboard/invoice/ShowDraftBankCards";

type DraftPaymentSchema = {
    bank: string;
    fish_number: string;
    date: DateObject;
    transaction_attachment: File;
    description?: string;
};

type DraftPaymentProps = {
    draftCard: GetTransactionResponse["draft_card"];
};

const DraftPayment = ({ draftCard }: DraftPaymentProps) => {
    const queryClient = useQueryClient();
    const params = useParams();

    const navigate = useNavigate();

    const { data: invoice } = useInvoice(Number(params.invoiceId));
    const { mutateAsync: payWithDraft, isLoading } = usePayWithDraft();

    const status = invoice?.transaction.status;
    const isPaymentDisabled = status !== "INIT";

    const {
        control,
        setValue,
        handleSubmit,
        formState: { isValid, errors },
    } = useForm<DraftPaymentSchema>({
        defaultValues: {
            fish_number: "",
            date: "",
        },
    });

    const { renderTemplate } = useUploadHandler({
        name: "transaction_attachment",
        control,
        setFormValue: setValue,
        title: "رسید پرداخت را آپلود کنید",
        className: "pt-6 pb-4",
        error: !!errors.transaction_attachment,
        rules: { required: "رسید پرداخت الزامی است" },
        disabled: isPaymentDisabled,
    });

    const onSubmit: SubmitHandler<DraftPaymentSchema> = async (data) => {
        try {
            const response = await payWithDraft({
                id: Number(params.invoiceId),
                data: {
                    meta: {
                        bank_fish_number: data.fish_number,
                        bank_pay_date: data.date.toDate().toISOString(),
                        bank_pay_description: data.description,
                        bank_name: data.bank,
                    },
                    transaction_attachment: data.transaction_attachment,
                },
            });

            if (response.error === false) {
                await queryClient.refetchQueries(["invoice", Number(params.invoiceId)]);

                if (invoice?.transaction.type == "order") {
                    navigate("/panel/order/list/" + response.redirect_url.split("/").pop());
                } else {
                    navigate("/");
                }
            }
        } catch (error) {
            //
        }
    };

    return (
        <form className="space-y-5" onSubmit={handleSubmit(onSubmit)}>
            <ShowDraftBankCards draftCard={draftCard} amount={invoice?.transaction.amount} />
            <div className="mt-8 flex flex-1 gap-5 max-xs:flex-col">
                <div className="w-full flex-1 space-y-5">
                    <Input
                        ltr
                        type="number"
                        control={control}
                        topTitle="شماره پیگیری"
                        name="fish_number"
                        variant="fill"
                        containerClassName="flex-1"
                        inputMode="numeric"
                        autoComplete="off"
                        rules={{
                            required: "شماره پیگیری الزامی است",
                        }}
                        disabled={isPaymentDisabled}
                    />
                    <Input
                        ltr
                        control={control}
                        topTitle="تاریخ واریز"
                        name="date"
                        variant="fill"
                        containerClassName="flex-1"
                        datePicker
                        autoComplete="off"
                        datePickerProps={{
                            calendar: persian,
                            locale: persian_fa,
                            format: "MM/DD/YYYY HH:mm",
                            plugins: [<TimePicker position="bottom" hideSeconds key={1} />],
                        }}
                        rules={{
                            required: "تاریخ واریز الزامی است",
                        }}
                        disabled={isPaymentDisabled}
                    />
                </div>
                <div className="mt-8 flex-1">{renderTemplate}</div>
            </div>

            <Textarea
                disabled={isPaymentDisabled}
                control={control}
                label="توضیحات"
                name="description"
                containerClass="!mt-5"
            />

            <Button
                className="!mt-8 w-full"
                type="submit"
                loading={isLoading}
                disabled={isLoading || !isValid || isPaymentDisabled}
                disabledTooltip="دکمه غیرفعال است"
            >
                ثبت
            </Button>
        </form>
    );
};

export default DraftPayment;
