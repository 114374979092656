import { Link } from "react-router-dom";

type QuickAccessItemProps = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon: any;
    className?: string;
    iconClasses?: string;
    text: string;
    link: string;
    size?: number;
};

const QuickAccessItem: React.FC<QuickAccessItemProps> = ({
    icon: Icon,
    link,
    text,
    className,
    iconClasses = "h-[32px] sm:h-[35px]",
}) => {
    return (
        <div className={"group w-[80px] md:w-[95px] lg:w-[105px] " + className}>
            <Link
                to={link}
                className="flex flex-col items-center justify-center gap-3 whitespace-nowrap text-center text-[1rem] text-text-main [perspective:800px] lg:gap-4 lg:text-[1.14rem]"
            >
                <div className="flex h-[35px] items-center justify-center">
                    <Icon
                        className={
                            "fill-primary transition-all group-hover:-translate-y-2 group-hover:drop-shadow-[0_8px_10px_hsl(192,47%,56%,0.5)] " +
                            iconClasses
                        }
                    />
                </div>
                {text}
            </Link>
        </div>
    );
};

export default QuickAccessItem;
