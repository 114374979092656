import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { InferType, object, string } from "yup";

import AuthCodeInput from "@/components/auth/AuthCodeInput";
import Spinner from "@/components/loader/Spinner";
import AlertBox from "@/components/ui/AlertBox";
import Button from "@/components/ui/Button";
import PageTitle from "@/components/utility/PageTitle";
import { TWO_FA } from "@/constants/common";
import { CODE_NOT_MATCH_MESSAGE, CODE_REQUIRED_MESSAGE } from "@/constants/InavlidMessages";
import useResendTwoFaValidate from "@/hooks/queries/useResendTwoFaValidate";
import useTwoFaValidate from "@/hooks/queries/useTwoFaValidate";

const codeSchema = object({
    code: string()
        .required(CODE_REQUIRED_MESSAGE)
        .matches(/^(\d{5}|\d{6})$/, CODE_NOT_MATCH_MESSAGE),
}).required();

type ValidationSchema = InferType<typeof codeSchema>;

const TwoFaValidate = () => {
    const location = useLocation();

    const [resendingCode, setResendingCode] = useState<boolean>(true);
    const [isCodeInserted, setIsCodeInserted] = useState(false);

    const [countdown, setCountdown] = useState<number>(120);

    const startCountdown = () => {
        const timer = setInterval(() => {
            setCountdown((prev) => {
                if (prev == 0) {
                    setResendingCode(false);
                    clearInterval(timer);
                    return 120;
                }

                return prev > 0 ? prev - 1 : 0;
            });
        }, 1000);
    };

    const navigate = useNavigate();

    useEffect(() => {
        startCountdown();
        setTimeout(() => {
            setResendingCode(false);
        }, 120000);
    }, []);

    // REDIRECT TO LOGIN IF SOMEONE OPENED THIS LINK DIRECTLY
    useEffect(() => {
        if (!location.state?.twoFAType) {
            navigate("/auth/login");
        }
    }, [location, navigate]);

    const {
        control,
        handleSubmit,
        formState: { errors },
        resetField,
        setFocus,
        setValue,
    } = useForm<ValidationSchema>({
        resolver: yupResolver(codeSchema),
        defaultValues: {
            code: "",
        },
    });
    const [watchedCode] = useWatch({
        control,
        name: ["code"],
        defaultValue: {
            code: "",
        },
    });

    const { mutate: twoFaValidate, isLoading } = useTwoFaValidate();

    const authenticateHandler: SubmitHandler<ValidationSchema> = (data) => {
        twoFaValidate({
            isGoogleTwoFA: location.state?.twoFAType == TWO_FA.GOOGLE,
            email: location.state?.email,
            token: data.code,
        });
    };

    const { mutate: resendTwoFaValidate, isLoading: resendLoading } = useResendTwoFaValidate(
        startCountdown,
        setResendingCode,
        setFocus,
    );

    const resendHandler = () => {
        setResendingCode(true);
        resetField("code");

        resendTwoFaValidate({
            email: location.state?.email,
        });
    };

    useEffect(() => {
        setIsCodeInserted(() => {
            return location.state?.twoFAType === TWO_FA.GOOGLE ? watchedCode.length === 6 : watchedCode.length === 5;
        });

        if (location.state?.twoFAType === TWO_FA.GOOGLE ? watchedCode.length === 6 : watchedCode.length === 5) {
            handleSubmit(authenticateHandler)();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchedCode, location.state?.twoFAType]);

    return (
        <>
            <PageTitle>ورود کد اعتبارسنجی</PageTitle>
            <form onSubmit={handleSubmit(authenticateHandler)} className="mx-auto">
                <div className="mb-8 flex items-center justify-between ">
                    <h2 className="text-text-main">
                        {location.state?.twoFAType == TWO_FA.MOBILE
                            ? "کد پیامک شده را وارد کنید."
                            : "کد امنیتی Google Authenticator را وارد کنید"}
                    </h2>
                    {location.state?.twoFAType == TWO_FA.MOBILE && (
                        <div className="flex justify-between">
                            <button
                                type="button"
                                disabled={resendingCode}
                                onClick={resendHandler}
                                className="flex whitespace-nowrap text-sm text-text-paragraph"
                            >
                                {resendingCode ? (
                                    resendLoading ? (
                                        <Spinner size="xs" />
                                    ) : (
                                        countdown + " ثانیه"
                                    )
                                ) : (
                                    "ارسال مجدد"
                                )}
                            </button>
                        </div>
                    )}
                </div>
                <AlertBox
                    className="mt-8 max-w-[250px]"
                    type="warning"
                    description="در صورت عدم دریافت پیامک لطفا قسمت اسپم اپلیکیشن پیامک خود را چک کنید"
                />

                <div className="relative my-12 flex flex-col gap-6">
                    <AuthCodeInput
                        error={errors.code}
                        onChange={(value: string) => {
                            setValue("code", value);
                        }}
                        length={location.state?.twoFAType === TWO_FA.GOOGLE ? 6 : 5}
                    />
                </div>
                <Button id="submit" disabled={isLoading || !isCodeInserted} className="w-full">
                    {isLoading ? <Spinner /> : "ورود"}
                </Button>
            </form>
        </>
    );
};

export default TwoFaValidate;
