import "react-loading-skeleton/dist/skeleton.css";

import React from "react";
import Skeleton from "react-loading-skeleton";

interface AvatarProps {
    progress: number;
    imageSrc: string;
    name: string;
    r?: number;
}

const Avatar: React.FC<AvatarProps> = ({ progress, imageSrc, name, r = 40 }) => {
    const radius = r;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (progress / 100) * circumference;

    return (
        <div
            style={{ width: `${radius * 2 + 10}px`, height: `${radius * 2 + 10}px` }}
            className="flex items-center justify-center rounded-full bg-secondary-200"
        >
            <svg
                className="progress-ring"
                width={radius * 2 + 10}
                height={radius * 2 + 10}
                style={{ rotate: "-90deg" }}
            >
                <circle
                    className="progress-ring-circle stroke-secondary-300"
                    strokeWidth="4"
                    fill="transparent"
                    r={radius}
                    cx={radius + 5}
                    cy={radius + 5}
                />
                <circle
                    className="progress-ring-circle stroke-primary"
                    strokeWidth="4"
                    strokeLinecap="round"
                    fill="transparent"
                    r={radius}
                    cx={radius + 5}
                    cy={radius + 5}
                    style={{
                        strokeDasharray: `${circumference} ${circumference}`,
                        strokeDashoffset: offset,
                    }}
                />
            </svg>
            <div className="absolute">
                {(imageSrc && (
                    <img
                        src={imageSrc}
                        alt={name}
                        style={{ width: `${radius * 2 - 8}px` }}
                        className={`rounded-full`}
                    />
                )) || (
                    <Skeleton circle={true} style={{ width: `${radius * 2 - 8}px`, height: `${radius * 2 - 8}px` }} />
                )}
            </div>
        </div>
    );
};

export default Avatar;
