import React from "react";

type DescriptionProps = {
    title?: string;
    children: React.ReactNode;
    className?: string;
};

const Description: React.FC<DescriptionProps> = ({ className, children, title }) => {
    return (
        <div className={className}>
            {title &&
                <h3 className="mb-1 flex items-center justify-start gap-3 text-[1rem] text-text-main xs:text-[1.14rem]">
                    <span className="inline-block h-2 w-2 rounded-full bg-amber-500" />
                    {title}
                </h3>
            }
            <p className="leading-8 text-text-paragraph max-xs:text-[0.85rem]">{children}</p>
        </div>
    );
};
export default Description;
