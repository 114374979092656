import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
    user: {
        name: string;
        email: string;
        mobile: string;
    } | null;
    isLoggedIn: boolean;
}

const initialState: UserState = {
    user: null,
    isLoggedIn: false,
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        logout: () => {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            
            return initialState;
        },
        login: (state, action: PayloadAction<UserState>) => {
            state.user = action.payload.user;
            state.isLoggedIn = true;
        },
    },
});

export const { logout, login } = userSlice.actions;
export default userSlice.reducer;
