import { useQuery } from "@tanstack/react-query";

import gate from "@/gate";

const useTicket = (id: number) => {
    return useQuery(["ticket", id], () => gate.getTicket(id), {
        select: (data) => {
            return {
                ...data.ticket,
                is_captcha_enable: data.is_captcha_enable,
                has_order: data.has_order,
                order: data.order,
                replies: data.replies,
            };
        },
    });
};

export default useTicket;
