import { useQuery } from "@tanstack/react-query";

import gate from "@/gate";

const useNotifications = () => {
    return useQuery(["notifications"], gate.getNotification, {
        refetchInterval: 1000 * 60 * 2,
        refetchOnMount: false,
        select: (data) => {
            if (data) {
                return {
                    notifications: data.notifications.userUnreadNotifications,
                    notices: data.notices.notifications_users_items,
                    notificationsUnreadCount: data.notifications.userUnreadNotificationsCount,
                    noticesUnreadCount: data.notices.userNotifyCount,
                };
            }

            return data;
        },
    });
};

export default useNotifications;
