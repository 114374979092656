export const INVALID_EMAIL_MESSAGE = "ایمیل نامعتبر است.";
export const INVALID_CONFIRM_PASSWORD_MESSAGE = "رمز عبور و تکرار آن همخوانی ندارد.";
export const PASSWORD_VALIDATION_MESSAGE =
    "رمز عبور باید حداقل 8 کاراکتر و متشکل از حروف کوچک، بزرگ، اعداد و یکی از علائم @#$%^&* باشد.";
export const MOBILE_VALIDATION_MESSAGE = "شماره همراه نامعتبر است.";
export const CODE_NOT_MATCH_MESSAGE = "کد وارد شده نامعتبر است";
export const RULES_VALIDATION_MESSAGE = "برای ثبت نام حتما شرایط و قوانین را پذیرفته باشید";

export const RULES_REQUIRED_MESSAGE = "شرایط و قوانین الزامی است";
export const CODE_REQUIRED_MESSAGE = "کد را وارد کنید";
export const EMAIL_REQUIRED_MESSAGE = "ایمیل الزامی است";
export const MOBILE_REQUIRED_MESSAGE = "شماره همراه الزامی است";
export const PASSWORD_REQUIRED_MESSAGE = "رمز عبور الزامی است";
export const CONFIRM_PASSWORD_REQUIRED_MESSAGE = "تکرار رمز عبور الزامی است";
