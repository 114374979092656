import { useQueryClient } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import Skeleton from "react-loading-skeleton";

import ExampleImage from "@/assets/images/test.webp";
import IconCopy from "@/components/svg/copy";
import IconStarSolid from "@/components/svg/star-solid";
import Button from "@/components/ui/Button";
import Modal from "@/components/ui/Modal";
import useClubGifts from "@/hooks/queries/useClubGifts";
import useEarnReward from "@/hooks/queries/useEarnReward";

const Gifts = () => {
    const queryClient = useQueryClient();
    const { data: clubGifts } = useClubGifts();
    const [giftSlug, setGiftSlug] = useState<string | null>(null);
    const { mutateAsync: earnReward, isLoading, data: rewardResponse, reset: resetRewardResponse } = useEarnReward();

    const selectedGiftInfo = useMemo(() => {
        return clubGifts?.find((gift) => gift.slug === giftSlug);
    }, [giftSlug, clubGifts]);

    const earnRewardHandler = async () => {
        await earnReward(selectedGiftInfo!.slug);
        await queryClient.refetchQueries(["userData"]);
    };

    const giftsPlaceholder = Array.from({ length: 8 }).map((_, i) => (
        <div className="flex flex-col gap-4 rounded-[10px] border border-secondary-200 p-4" key={i}>
            <Skeleton className="aspect-square h-[200px] shrink-0" borderRadius={10} />
            <div className="flex w-full flex-col justify-between px-2 py-4">
                <div className="flex flex-col gap-2">
                    <Skeleton width="20%" />
                    <Skeleton width="60%" />
                </div>
                <Skeleton width="100%" height={30} className="mt-6"/>
            </div>
        </div>
    ));

    const renderGifts = useMemo(() => {
        if (clubGifts) {
            return clubGifts.map((gift) => (
                <div
                    className="flex flex-col rounded-[10px] border border-secondary-200 p-4 dark:bg-secondary-250"
                    key={gift.slug}
                >
                    <div className="relative aspect-square h-[200px] rounded-[10px]">
                        <img
                            src={gift.information.image ?? ExampleImage}
                            className="absolute inset-0 h-full w-full rounded-[10px] object-cover"
                            alt={gift.information.title}
                        />
                    </div>
                    <div className="px-2">
                        <div className="pt-4 font-semibold text-text-main">{gift.information.title}</div>
                        <p className="pt-2 text-justify text-text-paragraph">{gift.information.short_description}</p>
                    </div>
                    <div className="mr-2 mt-2 flex w-min items-center gap-2 whitespace-nowrap rounded-full bg-gold/10 px-3 py-1">
                        <span className="text-[0.85rem]">{gift.information.point} امتیاز </span>
                        <IconStarSolid className="h-[13px] fill-gold" />
                    </div>
                    <Button className="mt-4 w-full" disabledTooltip="دکمه غیرفعال است" disabled={!gift.can_earn} onClick={() => setGiftSlug(gift.slug)}>
                        دریافت جایزه
                    </Button>
                </div>
            ));
        }
        return giftsPlaceholder;
    }, [clubGifts]);

    return (
        <>
            <div className="mt-10 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 ">
                {renderGifts}
            </div>
            <Modal
                show={!!selectedGiftInfo}
                onClose={() => {
                    setGiftSlug(null);
                    resetRewardResponse();
                }}
                className="max-w-[500px]"
                title="جزییات جایزه"
            >
                {selectedGiftInfo && (
                    <div className="-mx-6">
                        <div className="px-6 py-4">
                            <div className="text-[1.28rem] text-text-main">{selectedGiftInfo.information.title}</div>
                            <p className="mt-3 text-text-paragraph">{selectedGiftInfo.information.description}</p>
                        </div>
                        {!rewardResponse ? (
                            <div className="mt-6 flex flex-col items-center justify-center gap-4 border-t border-secondary-200 px-6 pt-6">
                                <span className="text-text-paragraph">
                                    با دریافت جایزه، امتیاز کسر شده قابل بازگشت نیست.
                                </span>
                                <Button
                                    className="w-full"
                                    onClick={earnRewardHandler}
                                    disabled={isLoading}
                                    disabledTooltip="دکمه غیرفعال است"
                                    loading={isLoading}
                                >
                                    دریافت جایزه
                                </Button>
                            </div>
                        ) : (
                            <div className="flex flex-col items-center justify-center gap-4 border-t border-secondary-200 pt-6">
                                <div className="flex w-min items-center gap-4 rounded-[10px] border-2 border-dashed border-primary bg-primary/20 px-4 py-3 text-[1.28rem] text-text-main">
                                    <span className="mt-1">{rewardResponse.meta.code}</span>
                                    <Button
                                        className="!h-[32px] !w-[32px] !p-0"
                                        icon={IconCopy}
                                        onClick={() => {
                                            navigator.clipboard.writeText(rewardResponse.meta.code);
                                            toast.success("کد تخفیف کپی شد");
                                        }}
                                    />
                                </div>
                                <div className="flex items-center gap-2">
                                    اعتبار تا:
                                    <span className="text-text-main">{rewardResponse.meta.expire_time}</span>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </Modal>
        </>
    );
};

export default Gifts;
