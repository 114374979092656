import { useMutation } from "@tanstack/react-query";

import ToastError from "@/components/error/ToastError";
import gate from "@/gate";

const useAddTicket = () => {
    return useMutation({
        mutationFn: gate.addTicket,
        onError: (error: Error) => {
            ToastError(error);
        },
    });
};

export default useAddTicket;
