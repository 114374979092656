import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import IconWifiSlash from "@/components/svg/wifi-slash";
import Button from "@/components/ui/Button";

const OfflineOverlay = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isOffline, setIsOffline] = useState(false);

    useEffect(() => {
        const bodyEl = document.querySelector("body") as HTMLBodyElement;

        if (isOffline && location.pathname !== "/panel") {
            bodyEl.style.overflowY = "hidden";
        } else {
            bodyEl.style.overflowY = "auto";
        }
    }, [isOffline, location.pathname]);


    // useEffect(() => { 
    //     const condition = navigator.onLine ? "online" : "offline";
    //     if (condition === "online") {
    //         console.log("ONLINE");
    //         fetch("https://www.google.com/", {
    //             // Check for internet connectivity
    //             mode: "no-cors",
    //         })
    //             .then(() => {
    //                 console.log("CONNECTED TO INTERNET");
    //             })
    //             .catch(() => {
    //                 console.log("INTERNET CONNECTIVITY ISSUE");
    //             });
    //     } else {
    //         console.log("OFFLINE");
    //     }
    // }, [location.pathname]);

    useEffect(() => {
        const isInitialOffline = !window.navigator.onLine;

        const onNetworkOnline = () => {
            setIsOffline(false);
        };

        const onNetworkOffline = () => {
            setIsOffline(true);
        };

        if (isInitialOffline && location.pathname !== "/panel") {
            onNetworkOffline();
        }

        window.addEventListener("online", onNetworkOnline);
        window.addEventListener("offline", onNetworkOffline);

        return () => {
            window.removeEventListener("online", onNetworkOnline);
            window.removeEventListener("offline", onNetworkOffline);
        };
    }, [location.pathname]);

    return (
        <div
            className={`fixed inset-0 z-[100000] flex h-full w-full flex-col items-center justify-center gap-8 bg-secondary/20 backdrop-blur-3xl 2xs:gap-10 ${
                isOffline && location.pathname !== "/panel" ? "" : "hidden"
            }`}
        >
            <IconWifiSlash className="h-[55px] fill-warning sm:h-[80px]" />
            <p className="px-8 text-center text-[1rem] text-text-main xs:text-[1.14rem] sm:text-[1.42rem]">
                شما آفلاین هستید! <br className="hidden max-2xs:block" />
                لطفا اینترنت خود را چک کنید.
            </p>
            <Button onClick={() => navigate("/panel")}>بازگشت به پنل</Button>
        </div>
    );
};

export default OfflineOverlay;
