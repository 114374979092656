import { useContext } from "react";

import SwitchBox from "@/components/ui/SwitchBox";
import { Context, ContextType } from "@/store/Context-store";

const HeaderSetting = () => {
    const { systemTheme, setSystemTheme, setIsSettingsChanged } = useContext(Context) as ContextType;

    return (
        <div className="mt-6 flex flex-col items-center justify-evenly gap-8">
            <SwitchBox
                active={systemTheme}
                setActive={(state) => {
                    setSystemTheme(state);
                    setIsSettingsChanged(true);
                }}
                label="تنظیم تم بر اساس سیستم عامل"
            />
        </div>
    );
};

export default HeaderSetting;
