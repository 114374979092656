import { useQuery } from "@tanstack/react-query";

import gate from "@/gate";

const useCash = (id: number) => {
    return useQuery(["cash", id], () => gate.showCash(id), {
        refetchOnWindowFocus: false,
    });
};

export default useCash;
