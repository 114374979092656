import { useQuery } from "@tanstack/react-query";

import gate from "@/gate";

const useGetAddressInfo = (postalCode: string) => {
    return useQuery({
        queryFn: () => gate.getAddressInfo({ postalCode }),
        queryKey: ["postal-code-info"],
        initialData: null,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        enabled: false,
        retry: false,
    });
};

export default useGetAddressInfo;
