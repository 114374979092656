import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import IconNotFound from "@/components/svg/not-found";
import Button from "@/components/ui/Button";
import PageTitle from "@/components/utility/PageTitle";
import { Context, ContextType } from "@/store/Context-store";

const ServerError = () => {
    const navigate = useNavigate();
    const { error, setError } = useContext(Context) as ContextType;

    const gotoPanel = () => {
        setError(undefined);
        navigate("/panel");
    };

    return (
        <div className="flex min-h-[100svh] flex-col items-center justify-center gap-8 bg-background dark:bg-secondary">
            <PageTitle>خطای سرور</PageTitle>

            <div className="relative flex flex-col items-center justify-center">
                <div className="text-[80px] font-[150] text-primary [line-height:0.5] sm:text-[230px]">
                    {error?.response?.status || 500}
                </div>
                <IconNotFound className="h-auto max-w-[90%] md:max-w-full" />
            </div>

            <span className="text-center text-lg text-text-main sm:text-2xl">خطای سرور</span>
            <Button onClick={gotoPanel} type="button" className="mb-4">
                بازگشت به پنل
            </Button>
        </div>
    );
};

export default ServerError;
