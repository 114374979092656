import { useState } from "react";
import { useLocation } from "react-router-dom";

import SliderItem from "@/components/profile/authentication/SliderItem";
import BasicAuthentication from "@/components/profile/authentication/steps/BasicAuthentication";
import IdentificationCard from "@/components/profile/authentication/steps/IdentificationCard";
import PhoneAndPostalCode from "@/components/profile/authentication/steps/PhoneAndPostalCode";
import SelfieVerification from "@/components/profile/authentication/steps/SelfieVerification";
import VideoVerification from "@/components/profile/authentication/steps/VideoVerification";
import WizardProgress from "@/components/profile/authentication/WizardProgress";
import useUser from "@/hooks/queries/useUser";
import { VerificationStatus } from "@/interfaces/dashboard/layout/panel/panel";

import BusinessDetails from "./steps/BusinessDetails";

type WizardSliderProps = {
    stepsProgress: {
        step0: VerificationStatus;
        step1: VerificationStatus;
        step2: VerificationStatus;
        step3: VerificationStatus;
        step4: VerificationStatus;
        step5: VerificationStatus;
    };
    stepsDisabled: { step1: boolean; step2: boolean; step3: boolean; step4: boolean; step5: boolean };
};

const WizardSlider = ({ stepsDisabled, stepsProgress }: WizardSliderProps) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const { data: userData } = useUser();

    const { state } = useLocation();

    return (
        <>
            {state && state?.label && state.label === "draft-verification-redirect" ? (
                ""
            ) : (
                <WizardProgress
                    stepsDisabled={stepsDisabled}
                    stepsProgress={stepsProgress}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                />
            )}

            <div className="relative mt-[45px] flex w-full items-start justify-center gap-4 overflow-hidden xs:mt-[65px]">
                {userData.data.user.is_business && (
                    <SliderItem activeIndex={activeIndex} index={0}>
                        <BusinessDetails setActiveIndex={setActiveIndex} />
                    </SliderItem>
                )}

                <SliderItem activeIndex={activeIndex} index={1}>
                    <BasicAuthentication setActiveIndex={setActiveIndex} />
                </SliderItem>

                <SliderItem activeIndex={activeIndex} index={2}>
                    <IdentificationCard setActiveIndex={setActiveIndex} />
                </SliderItem>

                <SliderItem activeIndex={activeIndex} index={3}>
                    <SelfieVerification setActiveIndex={setActiveIndex} />
                </SliderItem>

                <SliderItem activeIndex={activeIndex} index={4}>
                    <PhoneAndPostalCode setActiveIndex={setActiveIndex} />
                </SliderItem>

                <SliderItem activeIndex={activeIndex} index={5}>
                    <VideoVerification setActiveIndex={setActiveIndex} isActive={activeIndex === 5} />
                </SliderItem>
            </div>
        </>
    );
};

export default WizardSlider;
