import { Fragment, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

import PanelSummary from "@/components/dashboard/main/panel/PanelSummary";
import Profile from "@/components/dashboard/main/Profile";
import QuickAccess from "@/components/dashboard/main/QuickAccess";
import ToastError from "@/components/error/ToastError";
import PlaceholderLayout from "@/components/placeholder/PlaceholderLayout";
import AlertBox from "@/components/ui/AlertBox";
import PageTitle from "@/components/utility/PageTitle";
import usePanelSummary from "@/hooks/queries/usePanelSummary";
import useQueryParams from "@/hooks/useQueryParams";

const Dashboard: React.FC = () => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const { searchParams } = useQueryParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (searchParams && searchParams.get("message")) {
            setTimeout(() => {
                toast.success(searchParams.get("message"));
            }, 500);

            setTimeout(() => {
                navigate("/panel", { replace: true });
            }, 3000);
        }
    }, [navigate, searchParams]);

    const { data, isLoading, error } = usePanelSummary();

    if (error) {
        ToastError(error);
    }

    const renderAlerts = useMemo(() => {
        return data?.data?.alerts?.map((alert) => {
            return (
                <AlertBox
                    key={alert.message}
                    description={alert.message.split("\n").map((line, i) => (
                        <Fragment key={i}>
                            {line}
                            <br />
                        </Fragment>
                    ))}
                    type={alert.type}
                    className="pl-4 text-justify"
                />
            );
        });
    }, [data]);

    return (
        <>
            <PlaceholderLayout>
                <PageTitle>پنل کاربری</PageTitle>
                <div className="min-h-screen w-full max-xs:mt-12">
                    <div className="flex items-end gap-6 max-[930px]:justify-center">
                        <Profile />
                        {/*<Slider />*/}

                        <a
                            href={data?.data?.slider[0]?.link}
                            target="_blank"
                            rel="noreferrer"
                            className="h-[345px] flex-[0.85] translate-y-px max-[930px]:hidden"
                        >
                            <img
                                src={data?.data?.slider[0]?.mediaLink}
                                alt={data?.data?.slider[0]?.title}
                                className={`h-full w-full object-contain object-bottom reset-hue-rotate ${
                                    imageLoaded ? "" : "hidden"
                                }`}
                                onLoad={() => setImageLoaded(true)}
                            />
                            {!imageLoaded && (
                                <Skeleton
                                    containerClassName="flex h-full items-end"
                                    style={{ width: "100%", height: "250px", borderRadius: "10px" }}
                                />
                            )}
                        </a>

                        {/*<div className="max-[930px]:hidden flex-[0.85] shadow-main h-[345px] translate-y-px">*/}
                        {/*    <img src={SlideImage} alt="slider" className="w-full object-contain h-full object-bottom"/>*/}
                        {/*</div>*/}
                    </div>
                    <div className="mb-4 mt-12 space-y-6">{renderAlerts}</div>
                    <QuickAccess />
                    <PanelSummary isLoading={isLoading} />
                </div>
            </PlaceholderLayout>
        </>
    );
};
export default Dashboard;
