import { Fragment, useMemo } from "react";

import IconReply from "../svg/reply";

type CommentProps = UserComment & {
    setReplyId: (id: number) => void;
    isReply ?: boolean;
};

const Comment = ({
    id,
    replay,
    name,
    can_replay,
    human_created_at,
    company,
    content,
    avatar,
    setReplyId,
    isReply,
}: CommentProps) => {
    const renderReplies = useMemo(() => {
        return replay.map((comment, index) => {
            return <Comment {...comment} setReplyId={setReplyId} isReply key={index} />;
        });
    }, [replay]);

    return (
        <Fragment>
            <section className="w-full">
                <div className="flex justify-start gap-3 sm:gap-4">
                    <div className="relative h-[30px] w-[30px] shrink-0 overflow-hidden rounded-full shadow-main sm:h-[40px] sm:w-[40px]">
                        <img className="absolute z-20 h-full w-full object-cover" src={avatar} alt={name} />
                        <div className="absolute z-10 h-full w-full bg-white dark:bg-secondary-200" />
                    </div>
                    <div className={`w-full rounded-b-[15px] rounded-l-[15px] ${isReply ? 'bg-secondary-250' : 'bg-secondary-400'} contrast:bg-transparent contrast:border p-4 shadow-main`}>
                        <div className="flex items-center justify-between">
                            <div className="flex gap-2">
                                <h3 className="text-[1rem] font-semibold text-primary">{name}</h3>
                                {company && company.length !== 0 && (
                                    <div className="text-[0.85rem] text-gold">( {company} )</div>
                                )}
                            </div>
                            {can_replay && (
                                <button
                                    className="group flex items-center gap-3 text-primary hover:text-primary"
                                    onClick={() => setReplyId(id)}
                                >
                                    <span>پاسخ</span>
                                    <IconReply className="h-[16px] fill-primary group-hover:fill-primary" />
                                </button>
                            )}
                        </div>
                        <div className="pt-1 text-[0.85rem] text-text-paragraph">{human_created_at}</div>
                        <p dangerouslySetInnerHTML={{ __html: content }} className="pl-4 pt-3 text-text-main" />
                    </div>
                </div>
                <div className="mr-4 mt-6 sm:mr-6 md:mr-24 ">{renderReplies}</div>
            </section>
        </Fragment>
    );
};

export default Comment;
