import classNames from "classnames";
import { useRef, useState } from "react";

type Positions = "top-left" | "bottom-left" | "bottom-center";

type BoxContainerProps = {
    className?: string;
    colorBoxColor?: string;
    staticColorBoxColor?: string;
    colorBox?: boolean;
    staticColorBox?: boolean;
    children: React.ReactNode;
    staticColorBoxPosition?: Positions;
    onClick?: () => void;
};

const BoxContainer: React.FC<BoxContainerProps> = ({
    children,
    className,
    colorBox,
    colorBoxColor = "bg-primary",
    staticColorBox,
    staticColorBoxColor = "bg-primary",
    staticColorBoxPosition = "top-left",
    onClick,
}) => {
    const [showColorBox, setShowColorBox] = useState(false);
    const colorBoxRef = useRef<HTMLDivElement>(null);

    const colorBoxClasses = classNames(
        {
            "absolute z-20 h-[80px] w-[80px] transition-opacity duration-150 max-xs:hidden blur-2xl contrast:hidden": 1,
            "opacity-100": showColorBox,
            "opacity-0": !showColorBox,
        },
        colorBoxColor,
    );

    const onMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (colorBoxRef.current) {
            const rect = e.currentTarget.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;

            if (colorBoxRef.current) {
                colorBoxRef.current.style.left = `${x - 40}px`;
                colorBoxRef.current.style.top = `${y - 40}px`;
            }
        }
    };

    const onMouseLeave = () => setShowColorBox(false);

    const onMouseEnter = () => setShowColorBox(true);

    const staticColorBoxClasses = classNames(
        {
            "absolute z-20 max-xs:hidden transition-transform duration-300 blur-2xl contrast:hidden": 1,
            "left-1/2 translate- -bottom-[50px] -translate-x-1/2 h-[100px] w-1/3": staticColorBoxPosition === "bottom-center",
            "left-0 bottom-0 h-[100px] w-[100px]": staticColorBoxPosition === "bottom-left",
            "left-0 top-0 h-[100px] w-[100px]": staticColorBoxPosition === "top-left",
            "group-hover:scale-150": true,
        },
        staticColorBoxColor,
    );

    return (
        <div className={"relative rounded-[10px] border border-secondary-200 shadow-main xs:overflow-hidden " + className} onClick={onClick}>
            {colorBox && <div className={colorBoxClasses} ref={colorBoxRef} />}
            {staticColorBox && <div className={staticColorBoxClasses} />}
            <div
                className="relative z-30 h-full w-full bg-container p-5"
                onMouseMove={onMouseMove}
                onMouseLeave={onMouseLeave}
                onMouseEnter={onMouseEnter}
            >
                {children}
            </div>
        </div>
    );
};

export default BoxContainer;
