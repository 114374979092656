import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

import ProfileImage from "@/components/dashboard/main/panel/ProfileImage";
import ToastError from "@/components/error/ToastError";
import Spinner from "@/components/loader/Spinner";
import IconBadgeCheck from "@/components/svg/badge-check";
import BoxContainer from "@/components/ui/BoxContainer";
import Level from "@/components/ui/Level";
import useUser from "@/hooks/queries/useUser";
import useLocalStorage from "@/hooks/useLocalStorage";
import { User, UserData } from "@/interfaces/dashboard/layout/panel/panel";
import { formatNumber } from "@/utils";

import AccountStatus from "./AccountStatus";

const Profile = () => {
    const [, setUserData] = useLocalStorage<UserData | object>("user_data", {});
    const [user, setUser] = useState<User | null>();
    const [balance, setBalance] = useState<number | null>();
    const [progress, setProgress] = useState<number>(0);

    const { data, isLoading, error } = useUser();

    useEffect(() => {
        if (data && data?.data) {
            setUserData(data);

            const user = data.data.user;

            setUser(user);
            setBalance(data.data.balance);

            const verificationTypes = Object.keys(data.data.verificationTypes);
            const count = verificationTypes.reduce((prev, current) => {
                const found = data.data.verifications.find((verification) => {
                    return verification.verification_type == current && verification.resultStatus == "CONFIRMED";
                });

                return found ? prev + 1 : prev;
            }, 0);

            const videoVerification = data.data.verifications.find((verification) => {
                return verification.verification_type == "VIDEO";
            });

            let verificationsCount = user.need_video_verification
                ? verificationTypes.length
                : videoVerification?.resultStatus == "CONFIRMED"
                ? verificationTypes.length
                : verificationTypes.length - 1;

            verificationsCount += user.is_business ? 0 : -1;

            setProgress(Math.floor((count / verificationsCount) * 100));
        }
    }, [data, setUserData]);

    if (error) {
        ToastError(error);
    }

    return (
        <BoxContainer
            className="relative flex-1 max-[930px]:max-w-[480px] xs:h-[230px] min-[930px]:h-[240px] min-[1350px]:h-[250px]"
            colorBoxColor={user?.level == "A" ? "bg-gold" : user?.level == "B" ? "bg-silver" : "bg-bronze"}
            staticColorBox={!!user?.level}
            staticColorBoxColor={user?.level == "A" ? "bg-gold" : user?.level == "B" ? "bg-silver" : "bg-bronze"}
        >
            <div className="flex h-full w-full items-stretch justify-center">
                <div className="w-full h-full flex flex-col justify-between">
                    {isLoading && (
                        <div className="absolute inset-0 z-10 flex items-center justify-center before:absolute before:inset-0 before:bg-black before:bg-opacity-25 dark:before:bg-white dark:before:bg-opacity-25">
                            <Spinner size="xl" />
                        </div>
                    )}
                    <div className="flex justify-between max-xs:flex-col max-xs:pt-8 xs:items-start">
                        <div className="flex w-full items-center justify-start gap-2 max-xs:flex-col xs:items-start">
                            <Link to="/panel/profile#profile" className="top-[-50px] max-xs:absolute">
                                <ProfileImage
                                    imageSrc={data?.data?.avatar || ""}
                                    progress={progress}
                                    name={user?.name || ""}
                                />
                            </Link>
                            <div className="mt-1 flex w-full flex-col justify-center max-sm:items-center xs:mr-4 xs:justify-start">
                                <div className="flex w-full items-center justify-between max-xs:flex-col">
                                    <Link
                                        to="/panel/profile#profile"
                                        className="flex w-fit items-center justify-center gap-2 whitespace-nowrap text-[1.42rem] max-[930px]:text-[1.28rem] xs:justify-start"
                                    >
                                        {(user?.name && (
                                            <>
                                                <span className="text-text-main">{user?.name}</span>
                                                {user?.confirmed ? (
                                                    <IconBadgeCheck className="h-[16px] fill-primary" />
                                                ) : (
                                                    ""
                                                )}
                                            </>
                                        )) || <Skeleton containerClassName="flex-1" height={30} />}
                                    </Link>
                                    <div className="max-xs:pt-3">
                                        <Level className="mt-1 justify-end" />
                                    </div>
                                </div>
                                <div className="mt-4 flex w-full justify-evenly gap-3 text-text-paragraph xs:flex-col">
                                    <Link
                                        to="/panel/wallet"
                                        className="flex w-fit items-center justify-start gap-2 whitespace-nowrap max-xs:flex-col"
                                    >
                                        <span className="text-[0.85rem] text-text-paragraph min-[930px]:text-[1rem]">
                                            موجودی اصلی
                                        </span>
                                        <div className="rounded-[8px] bg-secondary-250 px-2 py-1 max-[930px]:text-[0.85rem] min-[1350px]:py-[6px]">
                                            {(balance != null && (
                                                <>
                                                    <span
                                                        dir="ltr"
                                                        className={`inline-block translate-y-px pl-2 text-[1.14rem] ${
                                                            balance >= 0 ? "text-primary" : "text-error"
                                                        }`}
                                                    >
                                                        {formatNumber((balance as number) || 0)}
                                                    </span>
                                                    تومان
                                                </>
                                            )) || <Skeleton width={100} />}
                                        </div>
                                    </Link>
                                    {data.data.user.avalClubIsActive ? (
                                        <Link
                                            to="/panel/customer-club"
                                            className="flex w-fit items-center justify-start gap-2 whitespace-nowrap max-xs:flex-col"
                                        >
                                            <span className="text-[0.85rem] text-text-paragraph min-[930px]:text-[1rem]">
                                                امتیاز باشگاه مشتریان
                                            </span>
                                            <div className="rounded-[8px] bg-secondary-250 px-2 py-1 max-[930px]:text-[0.85rem] min-[1350px]:py-[6px]">
                                                {(balance != null && (
                                                    <>
                                                        <span
                                                            dir="ltr"
                                                            className={`inline-block translate-y-px pl-2 text-[1.14rem] text-gold`}
                                                        >
                                                            {formatNumber(data.data.user.avalClubPoint ?? 0)}
                                                        </span>
                                                        امتیاز
                                                    </>
                                                )) || <Skeleton width={100} />}
                                            </div>
                                        </Link>
                                    ) : (
                                        <div className="py-4 max-xs:hidden" />
                                    )}
                                </div>
                            </div>
                        </div>
                        {/*<div className="max-xs:hidden">*/}
                        {/*    {(user?.level && <Level className="mt-1 justify-end" />) || (*/}
                        {/*        <Skeleton width={100} height={24} />*/}
                        {/*    )}*/}
                        {/*</div>*/}
                    </div>

                    <AccountStatus
                        verifications={data?.data?.verifications}
                        needVideoVerification={user?.need_video_verification}
                    />
                </div>
            </div>
        </BoxContainer>
    );
};

export default Profile;
