import { useMemo } from "react";

type SliderToolProps = {
    title?: string;
    onChange: (value: number) => void;
    value: number;
    steps?: { title: string; value: number }[];
    autoStep?: boolean;
};

const SliderTool = ({ onChange, title, value, steps = [], autoStep = true }: SliderToolProps) => {
    const onRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.target.value);
        onChange(value);
    };

    const renderSteps = useMemo(() => {
        return steps.map((step) => (
            <button onClick={() => onChange(step.value)} className="text-[1rem] text-text-paragraph" key={step.title}>
                {step.title}
            </button>
        ));
    }, [steps]);

    return (
        <div className="mt-4 flex flex-1 flex-col gap-4 px-2 xs:gap-6">
            {title && <div className="-mx-2 text-[1rem] text-text-main sm:text-[1.25rem]">{title}</div>}
            <div className="">
                <input
                    onChange={onRangeChange}
                    dir="ltr"
                    type="range"
                    className="custom-range-slider my-4"
                    step={autoStep && steps ? Math.floor(100 / (steps.length - 1)) : 1}
                    value={value}
                />
                <div className="flex flex-row-reverse items-center justify-between">{renderSteps}</div>
            </div>
        </div>
    );
};

export default SliderTool;
