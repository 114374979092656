import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import IconArrowUpFromBracket from "@/components/svg/arrow-up-from-bracket";
import WalletActionButton from "@/components/wallet/WalletActionButton";
import WithdrawAmount from "@/components/wallet/Withdraw/WithdrawAmount";
import WithdrawConfirm from "@/components/wallet/Withdraw/WithdrawConfirm";
import useTransactions from "@/hooks/queries/useTransactions";

type WithdrawProps = {
    disabled: boolean;
    disabledMessage?: string;
};

type WithdrawAmountSchema = {
    amount: string;
    bank: {
        value: string;
        label: string;
    };
};

const Withdraw: React.FC<WithdrawProps> = ({ disabled, disabledMessage }) => {
    const { data: walletData } = useTransactions();

    const [isOpen, setIsOpen] = useState(false);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);

    const formContext = useForm<WithdrawAmountSchema>({
        shouldUnregister: false,
        reValidateMode: "onChange",
        mode: "onChange",
        defaultValues: {
            amount: "",
        },
    });

    return (
        <>
            <WalletActionButton
                onClick={() => setIsOpen(true)}
                disabled={disabled}
                disabledMessage={disabledMessage}
                color="primary"
                icon={IconArrowUpFromBracket}
                text="برداشت وجه"
            />

            <FormProvider {...formContext}>
                <WithdrawAmount isOpen={isOpen} setIsOpen={setIsOpen} setIsConfirmOpen={setIsConfirmOpen} />
                <WithdrawConfirm isOpen={isConfirmOpen} setIsOpen={setIsConfirmOpen} walletData={walletData!} />
            </FormProvider>
        </>
    );
};

export default Withdraw;
