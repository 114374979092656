import { useQueryClient } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import { Link, useParams } from "react-router-dom";

import PlaceholderLayout from "@/components/placeholder/PlaceholderLayout";
import IconHouse from "@/components/svg/house";
import IconPaperPlaneTop from "@/components/svg/paper-plane-top";
import TicketReply from "@/components/tickets/TicketReply";
import BoxContainer from "@/components/ui/BoxContainer";
import Breadcrumb from "@/components/ui/Breadcrumb";
import Button from "@/components/ui/Button";
import FileInput from "@/components/ui/FileInput";
import Textarea from "@/components/ui/Textarea";
import Title from "@/components/ui/Title";
import PageTitle from "@/components/utility/PageTitle";
import useReplyTicket from "@/hooks/queries/useReplyTicket";
import useTicket from "@/hooks/queries/useTicket";
import useGoogleReCaptchaV3 from "@/hooks/useRecaptcha";
import { objectToArray } from "@/utils";
import { ticketStatus } from "@/utils/cases";

type AddReplySchema = {
    content: string;
    attachment: Record<string, File>;
};

const Ticket = () => {
    const queryClient = useQueryClient();
    const params = useParams();

    const [isRecaptchaLoading, setIsRecaptchaLoading] = useState(false);
    const { executeReCaptcha } = useGoogleReCaptchaV3({ action: "ReplyTicket" });

    const { data: ticket, isLoading: isTicketLoading } = useTicket(Number(params.ticketId));
    const { mutateAsync: replyTicket, isLoading: isReplyLoading } = useReplyTicket();

    const { control, handleSubmit, reset, resetField } = useForm<AddReplySchema>({
        defaultValues: {
            attachment: {},
        },
    });

    // const isSenderExist = ticket && ticket.sender && !Array.isArray(ticket.sender)

    const renderReplies = useMemo(() => {
        if (ticket) {
            return ticket.replies
                .map((reply) => {
                    return (
                        <TicketReply
                            key={reply.id}
                            avatar={reply.sender.avatar}
                            content={reply.content}
                            name={reply.sender.name}
                            date={reply.created_at}
                            reverse={reply.sender.is_admin}
                            attachments={reply.attachments}
                        />
                    );
                })
                .reverse();
        }

        return (
            <>
                <div className="flex w-full gap-3 sm:gap-4">
                    <Skeleton className="!h-[30px] !w-[30px] sm:!h-[40px] sm:!w-[40px]" circle />
                    <Skeleton className="!h-[100px] !w-[170px] !rounded-b-[15px] !rounded-l-[15px] 2xs:!h-[120px] 2xs:!w-[250px] sm:!h-[150px] lg:!w-[300px]" />
                </div>
                <div className="flex w-full gap-3 sm:gap-4" dir="ltr">
                    <Skeleton className="!h-[30px] !w-[30px] sm:!h-[40px] sm:!w-[40px]" circle />
                    <Skeleton className="!h-[100px] !w-[170px] !rounded-b-[15px] !rounded-r-[15px] 2xs:!h-[120px] 2xs:!w-[250px] sm:!h-[150px] lg:!w-[300px]" />
                </div>
                <div className="flex w-full gap-3 sm:gap-4">
                    <Skeleton className="!h-[30px] !w-[30px] sm:!h-[40px] sm:!w-[40px]" circle />
                    <Skeleton className="!h-[100px] !w-[170px] !rounded-b-[15px] !rounded-l-[15px] 2xs:!h-[120px] 2xs:!w-[250px] sm:!h-[150px] lg:!w-[300px]" />
                </div>
                <div className="flex w-full gap-3 sm:gap-4">
                    <Skeleton className="!h-[30px] !w-[30px] sm:!h-[40px] sm:!w-[40px]" circle />
                    <Skeleton className="!h-[100px] !w-[170px] !rounded-b-[15px] !rounded-l-[15px] 2xs:!h-[120px] 2xs:!w-[250px] sm:!h-[150px] lg:!w-[300px]" />
                </div>
            </>
        );
    }, [ticket]);

    const { color } = ticketStatus(ticket?.locale_status);

    const getCaptchaToken = async () => {
        let captchaToken: string | undefined = undefined;

        if (ticket?.is_captcha_enable) {
            setIsRecaptchaLoading(true);
            captchaToken = await executeReCaptcha("ReplyTicket");
            setIsRecaptchaLoading(false);
        }

        return captchaToken;
    };

    const onReplyTicket: SubmitHandler<AddReplySchema> = async (data) => {
        const token = await getCaptchaToken();

        await replyTicket({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            id: params.ticketId!,
            content: data.content,
            attachment: objectToArray<File>(data.attachment),
            "g-recaptcha-response": token,
        });

        reset({
            content: "",
            attachment: undefined,
        });

        queryClient.invalidateQueries(["ticket", Number(params.ticketId)]);
    };

    return (
        <PlaceholderLayout>
            <PageTitle>نمایش تیکت</PageTitle>

            <Breadcrumb
                sections={[
                    { link: "/panel", text: "صفحه اصلی", icon: IconHouse },
                    { link: "/panel/ticket/list", text: "تیکت‌ها" },
                    { link: "#", text: `تیکت ${ticket ? ticket.id : "------"}#`, active: true },
                ]}
            />
            <div className="mt-2 flex gap-8 max-lg:flex-col-reverse">
                <div className="flex-1 space-y-8">
                    {ticket?.status !== "CLOSED" && (
                        <BoxContainer colorBox={false}>
                            <Title title="ارسال پیام جدید" containerClasses="-mx-5 -mt-5" />
                            <form className="space-y-4 pt-5" onSubmit={handleSubmit(onReplyTicket)}>
                                <div className="-mx-5 space-y-4 border-b border-secondary-200 px-5 pb-4">
                                    <Textarea
                                        name="content"
                                        control={control}
                                        rules={{
                                            required: "متن پیام الزامی است",
                                            minLength: {
                                                value: 10,
                                                message: "متن پیام نباید کمتر از 10 کاراکتر باشد",
                                            },
                                        }}
                                        placeholder="متن پیام خود را بنویسید"
                                        containerClass="w-full"
                                    />
                                    <div>
                                        <Button
                                            className="min-w-[100px]"
                                            loading={isRecaptchaLoading || isReplyLoading}
                                            disabled={isRecaptchaLoading || isReplyLoading || !ticket}
                                            disabledTooltip="دکمه غیرفعال است"
                                        >
                                            <IconPaperPlaneTop className="h-[16px] fill-white" /> ارسال
                                        </Button>
                                    </div>
                                </div>
                                <div>
                                    <FileInput
                                        control={control}
                                        name="attachment"
                                        onReset={resetField}
                                        disabled={isReplyLoading}
                                        direction="row"
                                        repeater
                                    />
                                </div>
                            </form>
                        </BoxContainer>
                    )}
                    <div className="flex w-full flex-col gap-4 pt-5">
                        {renderReplies}
                        {ticket && (
                            <TicketReply
                                avatar={ticket.sender.avatar}
                                content={ticket.content}
                                name={ticket.sender.name}
                                date={ticket.created_at}
                                reverse={ticket.sender.is_admin}
                                attachments={ticket.attachments}
                            />
                        )}
                    </div>
                </div>
                <div className="flex-[0.6] space-y-8">
                    <BoxContainer colorBox={false}>
                        <Title title="اطلاعات تیکت" containerClasses="-mx-5 -mt-5" />
                        <div className="-mx-5 space-y-4">
                            <div className="flex w-full justify-between border-b border-secondary-200 px-5 pb-4 pt-4">
                                <span>موضوع</span>
                                {ticket ? (
                                    <span className="text-text-main">{ticket.title}</span>
                                ) : (
                                    <Skeleton width={80} />
                                )}
                            </div>
                            <div className="flex w-full justify-between border-b border-secondary-200 px-5 pb-4">
                                <span>اولویت</span>
                                {ticket ? (
                                    <span
                                        className={`${
                                            ticket.priority == "UP"
                                                ? "text-error"
                                                : ticket.priority == "MEDIUM"
                                                ? "text-yellow-500"
                                                : "text-blue-500"
                                        }`}
                                    >
                                        {ticket.locale_priority}
                                    </span>
                                ) : (
                                    <Skeleton width={80} />
                                )}
                            </div>
                            <div className="flex w-full justify-between border-b border-secondary-200 px-5 pb-4">
                                <span>دپارتمان</span>
                                {ticket ? (
                                    <span className="text-text-main">{ticket.department}</span>
                                ) : (
                                    <Skeleton width={80} />
                                )}
                            </div>
                            <div className="flex w-full justify-between border-b border-secondary-200 px-5 pb-4">
                                <span>تاریخ ایجاد</span>
                                {ticket ? (
                                    <span className="text-text-main">{ticket.created_at}</span>
                                ) : (
                                    <Skeleton width={80} />
                                )}
                            </div>
                            <div className="flex w-full justify-between px-5">
                                <span>وضعیت</span>
                                {ticket ? (
                                    <span style={{ color }}>{ticket.locale_status}</span>
                                ) : (
                                    <Skeleton width={80} />
                                )}
                            </div>
                        </div>
                    </BoxContainer>
                    {(isTicketLoading || ticket?.has_order) && (
                        <BoxContainer>
                            <Title title="اطلاعات سفارش" containerClasses="-mx-5 -mt-5" />
                            <div className="-mx-5 space-y-4">
                                <div className="flex w-full justify-between border-b border-secondary-200 px-5 pb-4 pt-4">
                                    <span>شماره سفارش</span>
                                    {ticket ? (
                                        <Link to={`/panel/order/list/${ticket?.order?.id}`} className="text-blue-400">
                                            #{ticket?.order?.id}
                                        </Link>
                                    ) : (
                                        <Skeleton width={80} />
                                    )}
                                </div>
                                <div className="flex w-full justify-between px-5">
                                    <span>عنوان</span>
                                    {ticket ? (
                                        <span className="text-text-main">{ticket?.order?.service.title}</span>
                                    ) : (
                                        <Skeleton width={80} />
                                    )}
                                </div>
                            </div>
                        </BoxContainer>
                    )}
                </div>
            </div>
        </PlaceholderLayout>
    );
};

export default Ticket;
