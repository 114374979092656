import { RouteObject } from "react-router-dom";

import AddTicket from "@/pages/dashboard/tickets/add-ticket/AddTicket";
import Ticket from "@/pages/dashboard/tickets/ticket/Ticket";
import Tickets from "@/pages/dashboard/tickets/Tickets";

const TicketRoutes: RouteObject[] = [
    {
        path: "ticket",
        element: <Tickets />,
    },
    {
        path: "ticket/list",
        element: <Tickets />,
    },
    {
        path: "ticket/new",
        element: <AddTicket />,
    },
    {
        path: "ticket/:ticketId",
        element: <Ticket />,
    },
];

export default TicketRoutes;
