type BadgeProps = {
    onClick?: () => void;
    children: React.ReactNode;
    customColor?: string;
    color?: "success" | "warning" | "error" | "info" | "pink";
};

const Badge = ({ children, color = "info", customColor, onClick }: BadgeProps) => {
    const colors = {
        success: {
            container: "border-success",
            text: "text-success",
        },
        warning: {
            container: "border-warning",
            text: "text-warning",
        },
        error: {
            container: "border-error",
            text: "text-error",
        },
        info: {
            container: "border-blue-400",
            text: "text-blue-400",
        },
        pink: {
            container: "border-pink-400",
            text: "text-pink-400",
        },
    };

    return (
        <div
            className={`rounded-full border px-3 py-1 transition-all ${colors[color].container} ${
                onClick ? "active:translate-y-1 active:brightness-125" : ""
            }`}
            onClick={onClick}
            style={{ borderColor: customColor }}
        >
            <span className={`${colors[color].text} text-[0.85rem] font-bold`} style={{ color: customColor }}>
                {children}
            </span>
        </div>
    );
};

export default Badge;
