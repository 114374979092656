import { useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";

import Spinner from "@/components/loader/Spinner";
import OrderForm from "@/components/order/forms/OrderForm";
import ServiceInstruction from "@/components/order/ServiceInstruction";
import PlaceholderLayout from "@/components/placeholder/PlaceholderLayout";
import IconHouse from "@/components/svg/house";
import IconMoneyBillWaveDuotone from "@/components/svg/money-bill-wave-duotone";
import AlertBox from "@/components/ui/AlertBox";
import BoxContainer from "@/components/ui/BoxContainer";
import Breadcrumb from "@/components/ui/Breadcrumb";
import Button from "@/components/ui/Button";
import CustomCheckbox from "@/components/ui/CustomCheckbox";
import FactorBox from "@/components/ui/FactorBox";
import Title from "@/components/ui/Title";
import useOrderCalculation from "@/hooks/queries/useOrderCalculation";
import useSubmitOrder from "@/hooks/queries/useSubmitOrder";
import { CalculationRequestType, ServiceType } from "@/interfaces/dashboard/order/order";
import { formatNumber } from "@/utils";

export interface OrderRequestType {
    amount: number;
    fk_currency_id: number;
    user_description: string | null | undefined;
    attachments?: File[] | null;
    meta: {
        user_account_username?: string | null | undefined;
        user_account_password?: string | null | undefined;
        link?: string;
        account?: string;
    };
    rules: boolean;
}

export interface SubmitOrderResponse {
    redirect_url: string;
    error: boolean;
    message: string;
    transaction_id: number;
}

const SubmitOrder: React.FC = () => {
    const [isFirstLoading, setIsFirstLoading] = useState(true);
    const [acceptRules, setAcceptRules] = useState(false);
    const [serviceId, setServiceId] = useState<number>(0);
    const [serviceInstruction, setServiceInstruction] = useState<string>("");
    const [service, setService] = useState<ServiceType | undefined>();

    const dataHandler = (data: ServiceType) => {
        setService(data);
        setServiceInstruction(data.instruction);
    };

    const { mutate: calculate, isLoading: isCalculationLoading, data: calculationData } = useOrderCalculation();

    const delayTimer = useRef<number | null>(null);

    const calculateOrder = (data: CalculationRequestType) => {
        if (delayTimer.current) {
            clearTimeout(delayTimer.current);
        }

        delayTimer.current = setTimeout(() => {
            setIsFirstLoading(false);
            calculate(data);
        }, 500);
    };

    const { mutate: submitOrder, isLoading: isSubmitting } = useSubmitOrder(serviceId);

    const onFormSubmit = (data: object, serviceId: number) => {
        setServiceId(serviceId);

        const submitData = {
            ...data,
            rules: acceptRules,
        };

        submitOrder(submitData);
    };

    return (
        <PlaceholderLayout>
            <Breadcrumb
                sections={[
                    { link: "/panel", text: "صفحه اصلی", icon: IconHouse },
                    { link: `/panel/order/${service?.type}`, text: "لیست سرویس‌ها" },
                    { link: "#", text: `ثبت سفارش ${service?.title ?? "------"}`, active: true },
                ]}
            />
            {service?.night_time_msg && (
                <div className="mb-6">
                    <AlertBox type="danger" description={service.night_time_msg} />
                </div>
            )}
            <div className="flex flex-col gap-8 sm:flex-row">
                <div className="sm:w-1/2 md:w-7/12">
                    <OrderForm
                        onDataAvailable={dataHandler}
                        onFormSubmit={onFormSubmit}
                        onCalculation={calculateOrder}
                    />
                </div>
                <div className="sm:w-1/2 md:w-5/12">
                    <FactorBox withoutBottom={true} title="محاسبه قیمت" icon={IconMoneyBillWaveDuotone}>
                        <div className="flex flex-col gap-4">
                            <div className="-mx-3 mt-3 flex justify-between rounded-md bg-secondary p-3 contrast:border contrast:bg-transparent sm:mt-6">
                                <label className="font-bold text-text-main">
                                    {isFirstLoading || isCalculationLoading ? (
                                        <Skeleton width={80} />
                                    ) : (
                                        <>
                                            نرخ{" "}
                                            {
                                                calculationData?.data.prices?.find((price) => price.type == "AMOUNT")
                                                    ?.currency.name
                                            }
                                            :
                                        </>
                                    )}
                                </label>
                                <span className="font-light text-text-main dark:text-text-paragraph">
                                    {isFirstLoading || isCalculationLoading ? (
                                        <Skeleton width={80} />
                                    ) : (
                                        <>
                                            {formatNumber(
                                                calculationData?.data.prices?.find((price) => price.type == "AMOUNT")
                                                    ?.currency.total || 0,
                                            )}{" "}
                                            تومان
                                        </>
                                    )}
                                </span>
                            </div>
                            {isFirstLoading || isCalculationLoading
                                ? Array(
                                      service &&
                                          (service.form_file.endsWith("webmoney.charge") ||
                                              service.form_file.endsWith("perfectmoney.voucher"))
                                          ? 3
                                          : 2,
                                  )
                                      .fill(null)
                                      .map((_, index) => {
                                          return (
                                              <div key={index} className="flex justify-between">
                                                  <label className="font-bold text-text-main">
                                                      <Skeleton width={80} />
                                                  </label>
                                                  <span className="font-light text-text-paragraph">
                                                      <Skeleton width={80} />
                                                  </span>
                                              </div>
                                          );
                                      })
                                : calculationData?.data.prices.map((price, index) => {
                                      return (
                                          <div key={index} className="flex justify-between">
                                              <label className="font-bold text-text-main">{price.title}</label>
                                              <span className="font-light text-text-paragraph">
                                                  {price.type == "AMOUNT"
                                                      ? formatNumber(price.total) + " تومان"
                                                      : `${formatNumber(+price.amount)} ${price.currency.name}`}
                                              </span>
                                          </div>
                                      );
                                  })}

                            <div className="flex justify-between">
                                {isFirstLoading || isCalculationLoading ? (
                                    <Skeleton width={80} />
                                ) : (
                                    <label className="font-bold text-text-main">هزینه نهایی</label>
                                )}
                                <span className="text-lg font-bold text-yellow-500 md:text-xl">
                                    {isFirstLoading || isCalculationLoading ? (
                                        <Skeleton width={80} />
                                    ) : (
                                        <>
                                            {formatNumber(calculationData?.data.total || 0)}
                                            <span className="text-sm">تومان</span>
                                        </>
                                    )}
                                </span>
                            </div>
                        </div>

                        <CustomCheckbox
                            onChange={() => setAcceptRules((prev) => !prev)}
                            label={
                                <span>
                                    <a
                                        href="https://avalpardakht.com/terms"
                                        target="_blank"
                                        rel="noreferrer nofollow"
                                        className="text-primary hover:text-primary"
                                    >
                                        قوانین و مقررات
                                    </a>{" "}
                                    سایت را مطالعه کرده‌ام و آن را قبول دارم.
                                </span>
                            }
                        />
                        <Button
                            type="submit"
                            disabled={!acceptRules || isCalculationLoading || isFirstLoading || isSubmitting}
                            disabledTooltip="دکمه غیرفعال است"
                            form="order-form"
                        >
                            {isSubmitting ? <Spinner /> : "ثبت سفارش"}
                        </Button>
                    </FactorBox>
                </div>
            </div>
            <div className="mt-6 w-full">
                <BoxContainer>
                    <Title
                        title="نکات و توضیحات"
                        containerClasses="-m-5"
                        className="flex h-full flex-col gap-6 p-0"
                        expandebale
                        expanded={0}
                    >
                        <ServiceInstruction>
                            <div dangerouslySetInnerHTML={{ __html: serviceInstruction }} />
                        </ServiceInstruction>
                    </Title>
                </BoxContainer>
            </div>
        </PlaceholderLayout>
    );
};
export default SubmitOrder;
