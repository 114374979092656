import classNames from "classnames";
import { AnimatePresence, motion, PanInfo, Variants } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";

import useAnimeSetting from "@/hooks/useAnimeSetting";

type ModalProps = {
    title?: React.ReactNode;
    show: boolean;
    onClose: () => void;
    onCloseEnd?: () => void;
    children: React.ReactNode;
    className?: string;
    mobileSize?: number;
};

const Modal: React.FC<ModalProps> = ({ title, show, onClose, children, className, onCloseEnd, mobileSize = 520 }) => {
    const [fullSize, setFullSize] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: mobileSize });
    const [contentHeight, setContentHeight] = useState(500);
    const childContainerRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const { isAnimationEnabled } = useAnimeSetting();

    const navigationType = useNavigationType();
    const navigate = useNavigate();
    const { hash, pathname, search } = useLocation();

    useEffect(() => {
        const bodyEl = document.querySelector("body") as HTMLBodyElement;

        if (show) {
            bodyEl.style.overflowY = "hidden";

            if (location.hash !== "#modal") {
                navigate(`${pathname}${search}#modal`);
            }
        } else {
            bodyEl.style.overflowY = "auto";
        }

        return () => {
            bodyEl.style.overflowY = "auto";
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    useEffect(() => {
        if (show && navigationType === "POP") {
            closeModal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigationType, hash]);

    useEffect(() => {
        const closeModalOnESC = (event: KeyboardEvent) => {
            if (show && event.key === "Escape" && location.hash === "#modal") {
                closeModal();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        };

        window.addEventListener("keyup", closeModalOnESC);

        return () => {
            window.removeEventListener("keyup", closeModalOnESC);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    useEffect(() => {
        const setHeight = () => {
            const height = childContainerRef.current?.clientHeight;

            if (height && show) {
                if (height >= window.innerHeight - 60) {
                    // containerRef.current!.style.overflowY = "scroll";
                    if (isMobile) {
                        setFullSize(true);
                    } else {
                        setFullSize(false);
                        setContentHeight(window.innerHeight - 100);
                    }
                } else {
                    setFullSize(false);
                    // containerRef.current!.style.overflowY = "hidden";
                    setContentHeight(height + 50);
                }
            }
        };

        setHeight();

        window.addEventListener("resize", setHeight);
        return () => {
            window.removeEventListener("resize", setHeight);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children]);

    useEffect(() => {
        if (!isMobile) {
            setFullSize(false);
        }
    }, [isMobile]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onDragEnd = (e: any, info: PanInfo) => {
        if (info.offset.y > 40) {
            closeModal();
        }
        // if (info.offset.y < -10) setFullSize(true);
    };

    const closeModal = () => {
        if (location.hash === "#modal" && show) {
            window.history.replaceState("", "", pathname + search);
        }

        onClose();
        setFullSize(false);
        setContentHeight(0);
    };

    const containerClasses = classNames(
        {
            "no-flicker absolute z-[1200] min-h-[300px] w-full origin-bottom transition-[border-radius]": 1,
            "p-0 bottom-0": isMobile,
            "p-4": !isMobile,
        },
        className,
    );

    const wrapperClasses = classNames({
        "rounded-b-none bg-secondary rounded-t-[15px]": 1,
        "overflow-visible": isMobile,
        "overflow-hidden !rounded-[10px]": !isMobile,
    });

    const overlayVariants: Variants = {
        hide: {
            opacity: 0,
            transition: {
                duration: isAnimationEnabled ? 0.2 : 0,
            },
        },
        show: {
            opacity: 1,
            transition: {
                duration: isAnimationEnabled ? 0.2 : 0,
            },
        },
    };

    const contentDesktopVariants: Variants = {
        initial: {
            scale: 1,
            opacity: 0,
            rotateX: 10,
        },
        hide: {
            opacity: 0,
            rotateX: 15,
            scale: 0.95,
            transition: {
                duration: isAnimationEnabled ? 0.1 : 0,
                opacity: {
                    duration: isAnimationEnabled ? 0.15 : 0,
                },
            },
        },
        show: {
            opacity: 1,
            rotateX: 0,
            scale: 1,
            transition: {
                duration: isAnimationEnabled ? 0.3 : 0,
            },
        },
    };

    const contentMobileVariants: Variants = {
        initial: { y: "100%", height: contentHeight },
        hide: {
            y: "100%",
            height: contentHeight,
            transition: {
                ease: "easeInOut",
                duration: isAnimationEnabled ? 0.25 : 0,
            },
        },
        show: {
            height: fullSize ? "80%" : contentHeight,
            y: 0,
            transition: {
                ease: "easeInOut",
                duration: isAnimationEnabled ? 0.35 : 0,
            },
        },
    };

    return createPortal(
        <AnimatePresence onExitComplete={onCloseEnd}>
            {show && (
                <div className="fixed top-0 z-[999] flex h-full w-full items-center justify-center [perspective:1000px]">
                    <motion.div
                        className={containerClasses}
                        dragConstraints={{ bottom: 0, top: 0 }}
                        dragElastic={{ bottom: 0.4, top: 0.4 }}
                        variants={isMobile ? contentMobileVariants : contentDesktopVariants}
                        drag={isMobile ? "y" : undefined}
                        onDragEnd={onDragEnd}
                        initial="initial"
                        animate="show"
                        exit="hide"
                    >
                        <div className={wrapperClasses}>
                            <div
                                className={`hidden w-full cursor-grab items-center justify-center py-3 ${
                                    isMobile ? "!flex" : ""
                                }`}
                            >
                                <div className="mr-[6px] h-[6px] w-[60px] rounded-[5px] bg-secondary-200" />
                            </div>
                            <div className="relative">
                                {/* <div className="absolute left-0 top-0 z-[1200] h-[20px] w-full bg-gradient-to-b from-secondary to-transparent" /> */}
                                <motion.div
                                    animate={{ height: fullSize ? "80vh" : contentHeight }}
                                    className={`${
                                        !isMobile ? "custom-scrollbar" : ""
                                    } relative z-[1180] overflow-y-auto px-4 pb-16 pt-2 ${
                                        !isMobile && "max-h-[80vh] !px-6 !py-6"
                                    }`}
                                    id="modal-scroll-container"
                                    ref={containerRef}
                                >
                                    <div className="w-full" ref={childContainerRef}>
                                        {title && (
                                            <div className="-mx-6 mb-6 flex items-center justify-center border-b-2 border-secondary-200 pb-3">
                                                <span className="text-[1.42rem] text-text-main">{title}</span>
                                            </div>
                                        )}
                                        {children}
                                    </div>
                                </motion.div>
                                <div className="absolute bottom-0 left-0 z-[1200] h-[20px] w-full bg-gradient-to-t from-secondary to-transparent" />

                                {isMobile && (
                                    <div className="absolute top-[40%] z-[1150] h-[100vh] w-full bg-secondary transition-[top]" />
                                )}
                            </div>
                        </div>
                    </motion.div>

                    <motion.div
                        variants={overlayVariants}
                        initial="hide"
                        animate="show"
                        exit="hide"
                        className="absolute z-[1100] h-full w-full cursor-pointer bg-[rgba(0,0,0,0.8)]"
                        onClick={closeModal}
                    ></motion.div>
                </div>
            )}
        </AnimatePresence>,
        document.querySelector("#portal") as HTMLDivElement,
    );
};

export default Modal;
