import { forwardRef } from "react";

import { Currency, PlanType } from "@/interfaces/dashboard/order/order";
import { formatNumber } from "@/utils";

interface PlanProps extends PlanType {
    onChange: (id: number) => void;
    isChecked: boolean;
    currency: Currency | undefined;
}

const Plan: React.FC<PlanProps> = forwardRef<HTMLInputElement, PlanProps>(({ amount, details, fa_name, id, isChecked, currency, onChange }, ref) => {
    return (
        <label
            className={`flex w-full cursor-pointer resize-none flex-col gap-3 rounded-[8px] border-2 border-input-border bg-input-bg p-4 text-text-main outline-none transition-colors placeholder:text-text-paragraph hover:border-input-border-hover focus:border-primary ${
                isChecked ? "border-primary hover:border-primary" : ""
            }`}
        >
            <div className="flex w-full justify-between">
                <div className="flex items-center gap-2">
                    <input
                        type="radio"
                        checked={isChecked}
                        onChange={onChange.bind(null, id)}
                        className="invisible absolute opacity-0"
                        ref={ref}
                        name="fk_plan_id"
                        value={id}
                    />
                    <span
                        className={`relative h-4 w-4 rounded-full border-2 before:absolute before:left-1/2 before:top-1/2 before:h-3 before:w-3 before:-translate-x-1/2 before:-translate-y-1/2 before:rounded-full ${
                            isChecked ? "border-primary bg-primary before:border-2 before:border-secondary dark:before:border-input-bg" : "border-gray-400"
                        }`}
                    ></span>
                    <span className="text-lg font-bold text-text-main">{fa_name}</span>
                </div>
                <span className="text-yellow-500">
                    {formatNumber(+amount || 0)} {currency?.name}
                </span>
            </div>
            <span className="px-6 py-4 text-right text-sm text-text-paragraph empty:hidden">{details}</span>
        </label>
    );
});

export default Plan;
