import { useQuery } from "@tanstack/react-query";

import gate from "@/gate";
import { ServicesListType } from "@/interfaces/dashboard/order/order";

const useAllServices = (allServices: object | ServicesListType) => {
    return useQuery<unknown, Error, ServicesListType>({
        queryFn: gate.servicesList,
        queryKey: ["allServices"],
        initialData: allServices,
        refetchOnWindowFocus: false,
        // cacheTime: 60 * 60 * 1000,
        // staleTime: 0,
    });
};

export default useAllServices;
