import useUser from "@/hooks/queries/useUser";

type DetailProps = {
    title: string;
    value: React.ReactNode;
    className?: string;
    detailFor?: "place" | "person" | false;
};

const Detail = ({ title, value, className, detailFor = "person" }: DetailProps) => {
    const { data: user } = useUser();

    return (
        <div className={`flex w-full justify-between gap-2 min-[420px]:flex-col ${className}`}>
            <span className="text-[0.85rem] text-text-main">{title} {user.data.user.is_business && detailFor ? (detailFor === "person" ? "مدیرعامل" : "شرکت") : ""}: </span>
            <span className="text-text-paragraph">
                {value}
            </span>
        </div>
    );
};

export default Detail;
