import { useQuery } from "@tanstack/react-query";

import gate from "@/gate";
import { CashServicesList, ServicesListType } from "@/interfaces/dashboard/order/order";

const useCashServices = (cashServices: object | ServicesListType["cashServices"]) => {
    return useQuery<unknown, Error, CashServicesList>({
        queryFn: gate.cashServiesList,
        queryKey: ["cash-services"],
        initialData: cashServices,
    });
};

export default useCashServices;
