import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import OrderAlert from "@/components/order/OrderAlert";
import OrderTimeline from "@/components/order/OrderTimeline";
import PlaceholderLayout from "@/components/placeholder/PlaceholderLayout";
import IconDollarSign from "@/components/svg/dollar-sign";
import IconHouse from "@/components/svg/house";
import IconPaperclipSolid from "@/components/svg/paperclip-solid";
import AlertBox from "@/components/ui/AlertBox";
import Badge from "@/components/ui/Badge";
import BoxContainer from "@/components/ui/BoxContainer";
import Breadcrumb from "@/components/ui/Breadcrumb";
import Button from "@/components/ui/Button";
import FactorBox from "@/components/ui/FactorBox";
import NeedSupport from "@/components/ui/NeedSupport";
import Table from "@/components/ui/Table";
import Title from "@/components/ui/Title";
import PageTitle from "@/components/utility/PageTitle";
import useOrder from "@/hooks/queries/useOrder";
import CompletePayment from "@/pages/dashboard/order/CompletePayment";
import OrderItemDetail from "@/pages/dashboard/order/OrderItemDetail";
import { formatNumber } from "@/utils";
import { orderStatus } from "@/utils/cases";
import IconArrowDownToBracket from "@/components/svg/arrow-down-to-bracket";

const OrdersItem: React.FC = () => {
    const param = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const message = searchParams.get("message");
    const hasError = searchParams.get("error");

    const { data: order, refetch: refetchOrder } = useOrder(Number(param.orderId));

    const status = orderStatus(order?.status);

    const transactions = useMemo(() => {
        return order?.transactions.map((transaction) => {
            return [
                {
                    header: "شماره تراکنش",
                    children: transaction.transaction_id,
                },
                {
                    header: "مبلغ",
                    children: formatNumber(transaction.price),
                },
                {
                    header: "نوع پرداخت",
                    children: transaction.pay_type_locale,
                },
                {
                    header: "وضعیت",
                    children: (
                        <div
                            className={`rounded-md border px-2 py-1 ${
                                order.is_paid ? "border-success text-success" : "border-gold text-gold"
                            }`}
                        >
                            {order.is_paid == 1 ? "پرداخت شده" : "در انتظار پرداخت"}
                        </div>
                    ),
                },
            ];
        });
    }, [order]);

    return (
        <PlaceholderLayout>
            <PageTitle>مشاهده سفارش</PageTitle>

            <Breadcrumb
                sections={[
                    { link: "/panel", text: "صفحه اصلی", icon: IconHouse },
                    { link: "/panel/order/list", text: "لیست سفارش‌ها" },
                    { link: "#", text: `سفارش ${order ? order.id : "------"}#`, active: true },
                ]}
            />
            <div className="flex w-full gap-8 max-lg:flex-col">
                <BoxContainer colorBox={false} className="flex-1">
                    <Title
                        title={`اطلاعات سفارش ${order?.currency_name ?? "------"}`}
                        containerClasses="pb-4 -mx-5 -mt-5"
                    />

                    {!order?.settings.is_cart_verified &&
                        (order?.status ? (
                            <OrderAlert status={order.status} />
                        ) : (
                            <Skeleton height={70} style={{ borderRadius: "10px" }} />
                        ))}

                    {order?.settings.is_cart_verified && <CompletePayment order={order} onRefetch={refetchOrder} />}

                    <div className="space-y-6 py-10 xs:px-4">
                        <OrderItemDetail
                            title="وضعیت سفارش"
                            value={
                                order?.status ? (
                                    <Badge customColor={status.color}>{order?.status}</Badge>
                                ) : (
                                    <Skeleton height={30} width={120} style={{ borderRadius: "9999px" }} />
                                )
                            }
                        />

                        <OrderItemDetail title="میزان سفارش" value={order?.amount} />
                        <OrderItemDetail
                            title="نرخ"
                            value={
                                order?.rate ? formatNumber(Number(order?.rate)) + " تومان" : <Skeleton width={120} />
                            }
                        />
                        <OrderItemDetail title="نوع ارز" value={order?.currency_name} />
                        <OrderItemDetail title="تاریخ سفارش" value={order?.created_at} />
                        {order?.meta?.link && (
                            <OrderItemDetail
                                title="لینک خرید محصول"
                                value={order?.meta.link}
                                isLink={order?.meta.link.startsWith("https")}
                            />
                        )}
                        {order?.meta?.account && (
                            <OrderItemDetail title="اکانت کاربری نیاز دارد" value={order?.meta.account} />
                        )}
                        {order?.cart_transaction && (
                            <OrderItemDetail
                                title="شماره تراکنش پرداخت سفارش"
                                value={order?.cart_transaction}
                                isLink={order?.cart_transaction?.startsWith("https")}
                            />
                        )}
                    </div>
                    <div className="xs:px-4">
                        <NeedSupport
                            title="آیا برای این سفارش به پشتیبانی نیاز دارید؟"
                            ticketLink={`/panel/ticket/new?order=${order?.id}#support`}
                            supportText={`با سلام وقت بخیر</br>هر گونه راهنمایی در رابطه با سفارش ${order?.id} نیاز داشته باشید، در خدمتتون هستم.`}
                        />
                    </div>

                    {order && <OrderTimeline userLogs={order.user_logs} adminDescription={order.admin_description} />}

                    {order?.admin_attachments && order?.admin_attachments.length ? (
                        <BoxContainer className="mt-8" colorBox={false}>
                            <Title title="فایل‌های پیوست شده" containerClasses="pb-4 -mx-5 -my-5" />

                            <div className="flex flex-col gap-4 pt-4">
                                {order?.admin_attachments.map((attachment, index) => {
                                    return (
                                        <a
                                            target="_blank"
                                            rel="nofollow noreferrer"
                                            key={index}
                                            className="group flex items-center gap-2 text-primary hover:text-primary"
                                            href={attachment.link}
                                        >
                                            <IconPaperclipSolid className="-mt-1 w-4 fill-primary group-hover:fill-primary" />
                                            {attachment.file_name}
                                        </a>
                                    );
                                })}
                            </div>
                        </BoxContainer>
                    ) : (
                        ""
                    )}
                </BoxContainer>

                <div className="flex-1">
                    <FactorBox
                        withoutBottom={order?.is_paid === 0}
                        icon={IconDollarSign}
                        title={
                            <div className="flex items-center justify-center gap-3">
                                <span>فاکتور سفارش</span>
                                {order?.factor_generated && (
                                    <a href={order.factor_link} className="flex justify-center items-center gap-1 text-primary">
                                      (
                                        <IconArrowDownToBracket className="w-[16px] fill-primary"/>
                                        دانلود
                                      )
                                    </a>
                                )}
                            </div>
                        }
                        color={status.color}
                    >
                        {message && (
                            <AlertBox
                                description={message}
                                type={hasError == "0" ? "success" : "error"}
                                className="mb-4"
                            />
                        )}

                        {order?.prices.map((price, index) => {
                            return (
                                <div key={index} className="flex justify-between">
                                    <label className="font-bold text-text-main">{price.title}</label>
                                    <span className="font-light text-text-paragraph">
                                        {price.type == "AMOUNT"
                                            ? formatNumber(price.total) + " تومان"
                                            : `${formatNumber(+price.amount)} ${price?.currency_model?.name}`}
                                    </span>
                                </div>
                            );
                        })}

                        <div className="space-y-4 py-4">
                            <div className="flex w-full justify-between">
                                <div className="font-semibold text-text-main">هزینه نهایی:</div>
                                <div className="text-[1.42rem] text-gold">
                                    {order?.total ? (
                                        formatNumber(Number(order?.total))
                                    ) : (
                                        <Skeleton width={120} height={15} />
                                    )}{" "}
                                    <span className="text-[1rem]">تومان</span>
                                </div>
                            </div>
                        </div>
                        <BoxContainer colorBox={false}>
                            <div className="-mx-5 -my-5">
                                <Table rows={transactions} loading={!order} placeholderRows={2} placeholderCols={3} />
                            </div>
                        </BoxContainer>
                        {order?.is_paid === 0 && (
                            <Button
                                type="button"
                                onClick={() =>
                                    navigate("/panel/invoice/" + order?.transactions[0]?.transaction_id, {
                                        state: "ORDER",
                                    })
                                }
                            >
                                پرداخت
                            </Button>
                        )}
                    </FactorBox>
                </div>
            </div>
        </PlaceholderLayout>
    );
};

export default OrdersItem;
