import Skeleton from "react-loading-skeleton";

const WebmoneyPlaceholder = () => {
    return (
        <div className="flex flex-col gap-2">
            <Skeleton width={125} />
            <Skeleton height={40} style={{ borderRadius: "10px" }} />

            <Skeleton width={125} />
            <Skeleton height={40} style={{ borderRadius: "10px" }} />

            <Skeleton width={50} />
            <Skeleton height={80} style={{ borderRadius: "10px" }} />

            <Skeleton width={110} className="mt-4" />
            <Skeleton width={100} height={40} style={{ borderRadius: "10px" }} />
        </div>
    );
};

export default WebmoneyPlaceholder;
