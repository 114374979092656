import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import toast from "react-hot-toast";

import ToastError from "@/components/error/ToastError";
import SlideSection from "@/components/profile/authentication/SlideSection";
import Button from "@/components/ui/Button";
import Input from "@/components/ui/Input";
import Select from "@/components/ui/Select";
import useCities from "@/hooks/queries/useCities";
import useGetAddressInfo from "@/hooks/queries/useGetAddressInfo";
import useProvinces from "@/hooks/queries/useProvinces";
import useUpdatePostalCode from "@/hooks/queries/useUpdatePostalCode";
import useUpdateUser from "@/hooks/queries/useUpdateUser";
import useUser from "@/hooks/queries/useUser";
import useVerifyRequest from "@/hooks/queries/useVerifyRequest";
import { scrollToTop } from "@/utils";

type PhoneAndPostalCodeProps = {
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
};

type PhoneAndPostalCodeSchema = {
    phone: string | null;
    postalCode: string | null;
    city: { label: string; value: number };
    province: { label: string; value: number };
    address: string | null;
};

const PhoneAndPostalCode: React.FC<PhoneAndPostalCodeProps> = ({ setActiveIndex }) => {
    const queryClient = useQueryClient();
    const { data: user, refetch: refetchUser } = useUser();
    const { mutateAsync: verifyRequest } = useVerifyRequest();

    const isPostalCodeAuto = user.data.verificationSettings.auto_address_verification;

    const postalCodeStatus = user.data.verifications.find(
        (verification) => verification.verification_type === "POSTAL_CODE",
    )?.resultStatus;

    const isPostalCodeConfirmed = postalCodeStatus === "CONFIRMED";
    const isPostalCodePending = postalCodeStatus === "PENDING";
    const isPostalCodeRejected = postalCodeStatus === "REJECTED";

    const phoneStatus = user.data.verifications.find(
        (verification) => verification.verification_type === "PHONE",
    )?.resultStatus;

    const isPhoneConfirmed = phoneStatus === "CONFIRMED";
    const isPhonePending = phoneStatus === "PENDING";
    const isPhoneRejected = phoneStatus === "REJECTED";

    const {
        control,
        handleSubmit,
        formState: { isValid },
    } = useForm<PhoneAndPostalCodeSchema>({
        defaultValues: {
            phone: user.data.user.phone ?? "",
            province: {
                label: user.data.user.province ? user.data.user.province.name : "استان خود را انتخاب کنید",
                value: user.data.user.province?.province_id ?? 1,
            },
            city: {
                label: user.data.user?.city ? user.data.user.city.name : "شهرستان خود را انتخاب کنید",
                value: user.data.user.city?.city_id ?? 1,
            },
            postalCode: user.data.user.postal_code ?? "",
            address: user.data.user.address ?? "",
        },
        mode: "all",
    });

    const [watchedPostalCode, watchedProvince] = useWatch({
        control: control,
        name: ["postalCode", "province"],
        defaultValue: {
            postalCode: "",
            province: {
                value: 1,
                label: "انتخاب استان",
            },
            phone: "",
        },
    });

    useEffect(() => {
        setSelectedProvince(watchedProvince.value);
    }, [watchedProvince]);

    const { mutateAsync: updateUser, isLoading: isUserLoading } = useUpdateUser();
    const { mutateAsync: updatePostalCode, isLoading: isPostalCodeLoading } = useUpdatePostalCode();

    const [selectedProvince, setSelectedProvince] = useState(1);

    const { data: provinces, isLoading: isProvincesLoading } = useProvinces();
    const { data: cities, isLoading: isCitiesLoading } = useCities(selectedProvince);
    const {
        data: addressInfo,
        isFetching: isAddressInfoLoading,
        refetch: fetchAddressInfo,
        error: fetchAddressError,
        isError: fetchAddressIsError,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    } = useGetAddressInfo(watchedPostalCode!);

    useEffect(() => {
        if (fetchAddressIsError) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            ToastError(fetchAddressError);
        }
    }, [fetchAddressError, fetchAddressIsError]);

    const getPostalCodeInfo = async () => {
        if (watchedPostalCode && watchedPostalCode.length === 10) {
            try {
                const response = await fetchAddressInfo();
                if (response.status == "success") {
                    toast.success("آدرس با موفقیت دریافت و ذخیره شد");
                }
            } catch (e) {
                console.log(e);
            }
        }
    };

    const submitHandler: SubmitHandler<PhoneAndPostalCodeSchema> = async (data) => {
        if (isValid) {
            try {
                if (!isPostalCodeConfirmed && !isPostalCodePending && data.postalCode) {
                    if (!isPostalCodeAuto) {
                        await updatePostalCode({
                            postal_code: data.postalCode,
                            fk_city_id: data.city.value,
                            address: data.address,
                            fk_province_id: data.province.value,
                        });
                        await refetchUser();
                    } else {
                        await updatePostalCode({
                            postal_code: data.postalCode,
                        });
                    }
                }

                if (!isPhoneConfirmed && !isPhonePending && data.phone) {
                    await updateUser({
                        phone: data.phone.replace(/[-\s]/g, ""),
                    });

                    await verifyRequest({ verifyType: "PHONE" });
                }

                setActiveIndex((oldIndex) => oldIndex + 1);
            } catch (e) {
                console.log(e);
                ToastError(new Error("خطایی سمت سرور رخ داده است"));
            }
        }

        queryClient.invalidateQueries(["userData"]);
    };

    const phoneSectionSubTitle = useMemo(() => {
        if (isPhoneConfirmed) return "تلفن شما تایید شده است";
        if (isPhonePending) return "تلفن شما در حال بررسی است";
        if (isPhoneRejected) return "تلفن شما رد شده است";
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const phoneSectionSubTitleStatus = useMemo(() => {
        if (isPhoneConfirmed) return "success";
        if (isPhonePending) return "warning";
        if (isPhoneRejected) return "error";
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const postalSectionSubTitle = useMemo(() => {
        if (isPostalCodeConfirmed) return "کد پستی شما تایید شده است";
        if (isPostalCodePending) return "کد پستی شما در حال بررسی است";
        if (isPostalCodeRejected) return "کد پستی شما رد شده است";
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const postalSectionSubTitleStatus = useMemo(() => {
        if (isPostalCodeConfirmed) return "success";
        if (isPostalCodePending) return "warning";
        if (isPostalCodeRejected) return "error";
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    // <form className="flex w-full h-full flex-col-reverse">
    //     <div className="flex w-full items-center justify-center px-4 max-xs:pb-4 pt-6 border-t border-secondary-200">

    return (
        <form className="flex h-full w-full flex-col">
            <div className="flex h-full max-lg:flex-col">
                <SlideSection
                    title={`تایید تلفن ثابت ${user.data.user.is_business ? "شرکت" : ""}`}
                    subTitle={phoneSectionSubTitle}
                    subTitleStatus={phoneSectionSubTitleStatus}
                    className="h-full flex-1 pt-6"
                >
                    <Input
                        ltr
                        name="phone"
                        control={control}
                        rules={{
                            required: "تلفن ثابت الزامی است",
                        }}
                        containerClassName="w-full"
                        variant="fill"
                        topTitle={`تلفن ثابت ${user.data.user.is_business ? "شرکت" : ""}`}
                        inputMode="numeric"
                        readOnly={isPhoneConfirmed}
                        disabled={isPhoneConfirmed}
                        showConfirmedStatus={isPhoneConfirmed}
                        placeholder="071-32145678"
                        maskProps={{
                            mask: "999-99999999",
                            maskChar: "",
                        }}
                    />
                </SlideSection>
                <SlideSection
                    title={`تایید کد پستی ${user.data.user.is_business ? "شرکت" : ""}`}
                    subTitle={postalSectionSubTitle}
                    subTitleStatus={postalSectionSubTitleStatus}
                    className="h-full space-y-6 pt-6"
                    separator
                >
                    <Input
                        ltr
                        topTitle={`کد پستی ${user.data.user.is_business ? "شرکت" : ""}`}
                        name="postalCode"
                        control={control}
                        variant="fill"
                        placeholder="کد پستی"
                        inputMode="numeric"
                        readOnly={isPostalCodeConfirmed}
                        disabled={isPostalCodeConfirmed}
                        showConfirmedStatus={isPostalCodeConfirmed}
                        rules={{
                            required: "کد پستی الزامی است",
                        }}
                        maskProps={{
                            mask: "9999999999",
                            maskChar: "",
                        }}
                    />

                    {!isPostalCodeConfirmed && isPostalCodeAuto && (
                        <Button
                            type="button"
                            className="min-w-[180px] px-4"
                            onClick={getPostalCodeInfo}
                            loading={isAddressInfoLoading}
                            disabled={isAddressInfoLoading}
                            disabledTooltip="دکمه غیرفعال است"
                        >
                            دریافت اطلاعات کد پستی
                        </Button>
                    )}

                    {addressInfo && (
                        <div className="flex flex-col gap-4 text-text-main">
                            <div className="flex flex-col gap-2">
                                <div className="text-[1rem] text-text-main">آدرس محل سکونت</div>
                                <div className="text-text-paragraph">{addressInfo.address}</div>
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="text-[1rem] text-text-main">استان</div>
                                <div className="text-text-paragraph">{addressInfo.province}</div>
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="text-[1rem] text-text-main">شهرستان</div>
                                <div className="text-text-paragraph">{addressInfo.district}</div>
                            </div>
                        </div>
                    )}

                    {!isPostalCodeAuto && (
                        <>
                            <Select
                                topTitle="استان"
                                name="province"
                                control={control}
                                variant="fill"
                                placeholder="انتخاب استان"
                                isDisabled={isPostalCodeConfirmed || isProvincesLoading}
                                rules={{
                                    required: "انتخاب استان الزامی است",
                                }}
                                options={isProvincesLoading ? [] : provinces}
                                isLoading={isProvincesLoading}
                            />
                            <Select
                                topTitle="شهرستان"
                                name="city"
                                control={control}
                                variant="fill"
                                placeholder="انتخاب شهرستان"
                                isDisabled={isPostalCodeConfirmed || isCitiesLoading}
                                rules={{
                                    required: "انتخاب شهرستان الزامی است",
                                }}
                                options={isCitiesLoading ? [] : cities}
                                isLoading={isCitiesLoading}
                            />
                            <Input
                                name="address"
                                control={control}
                                rules={{
                                    required: "آدرس الزامی است",
                                }}
                                containerClassName="w-full"
                                variant="fill"
                                topTitle="آدرس"
                                readOnly={isPostalCodeConfirmed}
                                disabled={isPostalCodeConfirmed}
                                placeholder="آدرس دقیق محل سکونت"
                            />
                        </>
                    )}
                </SlideSection>
            </div>
            <div className="flex w-full items-center justify-between gap-4 border-t border-secondary-200 px-4 py-6 max-xs:pb-4 xs:px-6">
                <Button
                    type="button"
                    className="w-[90px] flex-shrink-0 bg-zinc-400 xs:w-[150px]"
                    disabled={isPostalCodeLoading || isUserLoading}
                    disabledTooltip="دکمه غیرفعال است"
                    onClick={() => {
                        scrollToTop(1000, 150);
                        setActiveIndex((oldIndex) => oldIndex - 1);
                    }}
                >
                    قبلی
                </Button>
                <Button
                    type="button"
                    className="w-full xs:w-[150px]"
                    loading={isPostalCodeLoading || isUserLoading}
                    disabled={isPostalCodeLoading || isUserLoading}
                    disabledTooltip="دکمه غیرفعال است"
                    onClick={
                        (isPostalCodeConfirmed || isPostalCodePending) && (isPhoneConfirmed || isPhonePending)
                            ? () => {
                                  scrollToTop(1000, 150);
                                  setActiveIndex((oldIndex) => oldIndex + 1);
                              }
                            : handleSubmit(submitHandler)
                    }
                >
                    {(isPostalCodeConfirmed || isPostalCodePending) && (isPhoneConfirmed || isPhonePending)
                        ? "بعدی"
                        : "تایید و بعدی"}
                </Button>
            </div>
        </form>
    );
};

export default PhoneAndPostalCode;
