export type BankInfo = {
    "code": number;
    "name": string;
    "logo": string;
    "rgb": string;
    "en_alias": string;
};

export const banksList: BankInfo[] = [
    {
        "code": 603799,
        "name": "بانک ملی ایران",
        "logo": "melli-22.svg",
        "rgb": "rgb(223,200,171)",
        "en_alias": "MELLI",
    },
    {
        "code": 589210,
        "name": "بانک سپه",
        "logo": "sepah-24.svg",
        "rgb": "rgb(26,94,192)",
        "en_alias": "SEPAH",
    },
    {
        "code": 636949,
        "name": "بانک سپه",
        "logo": "sepah-24.svg",
        "rgb": "rgb(26,94,192)",
        "en_alias": "SEPAH",
    },
    {
        "code": 639370,
        "name": "بانک سپه",
        "logo": "sepah-24.svg",
        "rgb": "rgb(26,94,192)",
        "en_alias": "SEPAH",
    },
    {
        "code": 505801,
        "name": "بانک سپه",
        "logo": "sepah-24.svg",
        "rgb": "rgb(26,94,192)",
        "en_alias": "SEPAH",
    },
    {
        "code": 639599,
        "name": "بانک سپه",
        "logo": "sepah-24.svg",
        "rgb": "rgb(26,94,192)",
        "en_alias": "SEPAH",
    },
    {
        "code": 627381,
        "name": "بانک سپه",
        "logo": "sepah-24.svg",
        "rgb": "rgb(26,94,192)",
        "en_alias": "SEPAH",
    },
    {
        "code": 603769,
        "name": "بانک صادرات ایران",
        "logo": "saderat-29.svg",
        "rgb": "rgb(45,42,104)",
        "en_alias": "SADERAT",
    },
    {
        "code": 627648,
        "name": "بانک توسعه صادرات",
        "logo": "tosesaderat-18.svg",
        "rgb": "rgb(0,109,8)",
        "en_alias": "TOSESADERAT",
    },
    {
        "code": 207177,
        "name": "بانک توسعه صادرات",
        "logo": "tosesaderat-18.svg",
        "rgb": "rgb(0,109,8)",
        "en_alias": "TOSESADERAT",
    },
    {
        "code": 603770,
        "name": "بانک کشاورزی",
        "logo": "keshavarzi-07.svg",
        "rgb": "rgb(123,114,62)",
        "en_alias": "KESHAVARZI",
    },
    {
        "code": 639217,
        "name": "بانک کشاورزی",
        "logo": "keshavarzi-07.svg",
        "rgb": "rgb(123,114,62)",
        "en_alias": "KESHAVARZI",
    },
    {
        "code": 627961,
        "name": "بانک صنعت و معدن",
        "logo": "sanatmadan-16.svg",
        "rgb": "rgb(155,129,76)",
        "en_alias": "SANATMADAN",
    },
    {
        "code": 628023,
        "name": "بانک مسکن",
        "logo": "maskan-26.svg",
        "rgb": "rgb(236,82,13)",
        "en_alias": "MASKAN",
    },
    {
        "code": 627760,
        "name": "پست بانک ایران",
        "logo": "post-09.svg",
        "rgb": "rgb(20,118,62)",
        "en_alias": "POSTBANK",
    },
    {
        "code": 502908,
        "name": "بانک توسعه تعاون",
        "logo": "tosetaavon-19.svg",
        "rgb": "rgb(104,183,207)",
        "en_alias": "TAAVON",
    },
    {
        "code": 622106,
        "name": "بانک پارسیان",
        "logo": "parsian-21.svg",
        "rgb": "rgb(132,93,62)",
        "en_alias": "PARSIAN",
    },
    {
        "code": 639194,
        "name": "بانک پارسیان",
        "logo": "parsian-21.svg",
        "rgb": "rgb(132,93,62)",
        "en_alias": "PARSIAN",
    },
    {
        "code": 627884,
        "name": "بانک پارسیان",
        "logo": "parsian-21.svg",
        "rgb": "rgb(132,93,62)",
        "en_alias": "PARSIAN",
    },
    {
        "code": 502229,
        "name": "بانک پاسارگاد",
        "logo": "pasargad-10.svg",
        "rgb": "rgb(254,204,9)",
        "en_alias": "PASARGAD",
    },
    {
        "code": 639347,
        "name": "بانک پاسارگاد",
        "logo": "pasargad-10.svg",
        "rgb": "rgb(254,204,9)",
        "en_alias": "PASARGAD",
    },
    {
        "code": 627488,
        "name": "بانک کارآفرین",
        "logo": "karafarin-06.svg",
        "rgb": "rgb(98,115,38)",
        "en_alias": "KARAFARIN",
    },
    {
        "code": 502910,
        "name": "بانک کارآفرین",
        "logo": "karafarin-06.svg",
        "rgb": "rgb(98,115,38)",
        "en_alias": "KARAFARIN",
    },
    {
        "code": 621986,
        "name": "بانک سامان",
        "logo": "saman-01.svg",
        "rgb": "rgb(100,186,231)",
        "en_alias": "SAMAN",
    },
    {
        "code": 639346,
        "name": "بانک سینا",
        "logo": "sina-23.svg",
        "rgb": "rgb(83,132,171)",
        "en_alias": "SINA",
    },
    {
        "code": 639607,
        "name": "بانک سرمایه",
        "logo": "sarmaye-17.svg",
        "rgb": "rgb(128,130,135)",
        "en_alias": "SARMAYEH",
    },
    {
        "code": 502806,
        "name": "بانک شهر",
        "logo": "shahr-25.svg",
        "rgb": "rgb(216,38,44)",
        "en_alias": "SHAHR",
    },
    {
        "code": 504706,
        "name": "بانک شهر",
        "logo": "shahr-25.svg",
        "rgb": "rgb(216,38,44)",
        "en_alias": "SHAHR",
    },
    {
        "code": 502938,
        "name": "بانک دی",
        "logo": "day-12.svg",
        "rgb": "rgb(0,139,160)",
        "en_alias": "DAY",
    },
    {
        "code": 610433,
        "name": "بانک ملت",
        "logo": "mellat-02.svg",
        "rgb": "rgb(195,67,36)",
        "en_alias": "MELLAT",
    },
    {
        "code": 991975,
        "name": "بانک ملت",
        "logo": "mellat-02.svg",
        "rgb": "rgb(195,67,36)",
        "en_alias": "MELLAT",
    },
    {
        "code": 585983,
        "name": "بانک تجارت",
        "logo": "tejarat-13.svg",
        "rgb": "rgb(45,74,152)",
        "en_alias": "TEJARAT",
    },
    {
        "code": 627353,
        "name": "بانک تجارت",
        "logo": "tejarat-13.svg",
        "rgb": "rgb(45,74,152)",
        "en_alias": "TEJARAT",
    },
    {
        "code": 589463,
        "name": "بانک رفاه کارگران",
        "logo": "refahkargaran-28.svg",
        "rgb": "rgb(23,53,118)",
        "en_alias": "REFAH",
    },
    {
        "code": 505785,
        "name": "بانک ایران زمین",
        "logo": "Iranzamin-logo.svg",
        "rgb": "rgb(138,42,118)",
        "en_alias": "IRANZAMIN",
    },
    {
        "code": 636795,
        "name": "بانک مرکزی",
        "logo": "bankmarkazi.svg",
        "rgb": "rgb(46,50,147)",
        "en_alias": "",
    },
    {
        "code": 628157,
        "name": "موسسه اعتباری توسعه",
        "logo": "tose-11.svg",
        "rgb": "rgb(143,36,38)",
        "en_alias": "TOSE",
    },
    {
        "code": 505416,
        "name": "بانک گردشگری",
        "logo": "gardeshgari-20.svg",
        "rgb": "rgb(167,53,63)",
        "en_alias": "GARDESHGARI",
    },
    {
        "code": 606373,
        "name": "بانک قرض الحسنه مهر ایرانیان",
        "logo": "mehriran-15.svg",
        "rgb": "rgb(32,155,28)",
        "en_alias": "MEHRIRAN",
    },
    {
        "code": 636214,
        "name": "بانک آینده",
        "logo": "ayande-03.svg",
        "rgb": "rgb(162,98,31)",
        "en_alias": "AYANDEH",
    },
    {
        "code": 627412,
        "name": "بانک اقتصاد نوین",
        "logo": "eghtesad-04.svg",
        "rgb": "rgb(102,40,145)",
        "en_alias": "EGHTESADNOVIN",
    },
    {
        "code": 504172,
        "name": "بانک قرض الحسنه رسالت",
        "logo": "resalat.png",
        "rgb": "rgb(233,242,244)",
        "en_alias": "RESALAT",
    },
    {
        "code": 507677,
        "name": "موسسه اعتباری نور",
        "logo": "noor.png",
        "rgb": "rgb(132,178,216)",
        "en_alias": "NOOR",
    },
    {
        "code": 611828,
        "name": "بلو بانک",
        "logo": "blubank.png",
        "rgb": "rgb(73,136,216)",
        "en_alias": "BLUEBANK",
    },
    {
        "code": 585947,
        "name": "بانک خاورمیانه",
        "logo": "khavarmiane.svg",
        "rgb": "rgb(242, 141, 25)",
        "en_alias": "MIDDLEEAST",
    },

    // { "code": 611828, "name": "مؤسسه ثامن الائمه", "logo": "../src/assets/images/banks/blubank.png", "rgb": "rgb(73,136,216)" },
];
