import { useQuery } from "@tanstack/react-query";

import gate from "@/gate";

const useTickets = (page = 1, perPage = 10) => {
    return useQuery(["tickets", page, perPage], () => gate.getTickets(page, perPage), {
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        select: (data) => {
            return {
                list: data.data,
                ...data.meta,
            };
        },
    });
};

export default useTickets;
