import { motion } from "framer-motion";
import { ReactNode, useMemo, useState } from "react";

import IconCircleCheckDuotone from "@/components/svg/circle-check-duotone";
import IconCircleExclamation from "@/components/svg/circle-exclamation";
import IconCircleXmarkDuotone from "@/components/svg/circle-xmark-duotone";
import useAnimeSetting from "@/hooks/useAnimeSetting";

import IconCaretDown from "../svg/caret-down";

type TitleBaseProps = {
    title: string | ReactNode;
    subTitle?: string;
    className?: string;
    containerClasses?: string;
    separator?: boolean;
    subTitleStatus?: "info" | "success" | "error";
};

type TitleWithChildren = {
    children: React.ReactNode;
    expanded?: 0 | 1;
    expandebale?: boolean;
};

type TitleWithoutChildren = {
    children?: undefined;
    expanded?: never;
    expandebale?: never;
};

type TitleProps = (TitleWithChildren & TitleBaseProps) | (TitleWithoutChildren & TitleBaseProps);

const Title: React.FC<TitleProps> = ({
    title,
    subTitle,
    children,
    className,
    separator,
    containerClasses = "w-full",
    subTitleStatus = "info",
    expanded = 1,
    expandebale = false
}) => {
    const { isAnimationEnabled } = useAnimeSetting();
    const [isExpanded, setIsExpanded] = useState(expanded);

    const status = useMemo(() => {
        if (subTitleStatus === "info")
            return <IconCircleExclamation className="h-[16px] -translate-y-px fill-primary" />;
        if (subTitleStatus === "success")
            return <IconCircleCheckDuotone className="h-[16px] -translate-y-px fill-success" />;
        if (subTitleStatus === "error")
            return <IconCircleXmarkDuotone className="h-[16px] -translate-y-px fill-error" />;
    }, [subTitleStatus]);

    return (
        <div className={"group flex flex-col items-start justify-start " + containerClasses}>
            <div
                onClick={expandebale ? () => setIsExpanded((prev) => (prev == 1 ? 0 : 1)) : undefined}
                className={`w-full ${separator && "xs:border-r"} flex ${
                    expandebale && "cursor-pointer"
                } items-start justify-between gap-2 ${
                    expandebale ? isExpanded && "border-b" : "border-b"
                } items-center border-secondary-200 px-4 pb-3 pt-5 md:px-6`}
            >
                <div>
                    <h2 className="text-[1.14rem] font-semibold text-text-main xs:text-[1.28rem]">{title}</h2>
                    {subTitle && (
                        <span className="flex items-center justify-start gap-[6px] text-[0.85rem] text-text-paragraph xs:text-[1rem]">
                            ( {status} {subTitle} )
                        </span>
                    )}
                </div>
                {expandebale && (
                    <div>
                        <IconCaretDown className={`h-5 w-5 fill-current transition ${isExpanded && "rotate-180"}`} />
                    </div>
                )}
            </div>
            {children && (
                <motion.div
                    className={
                        `${separator && "xs:border-r"} ${
                            !isExpanded && "h-0 overflow-hidden"
                        } w-full border-secondary-200 px-4 md:px-6 ` + className
                    }
                    initial={{
                        height: isExpanded ? "auto" : "0",
                        paddingBlock: isExpanded ? "1rem" : "0",
                    }}
                    animate={{
                        height: isExpanded ? "auto" : "0",
                        paddingBlock: isExpanded ? "1rem" : "0",
                    }}
                    transition={{ duration: isAnimationEnabled ? 0.15 : 0 }}
                >
                    {children}
                </motion.div>
            )}
        </div>
    );
};

export default Title;
