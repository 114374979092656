import { useMemo } from "react";

import IconCreditCardSim from "@/components/svg/credit-card-sim";
import { banksList } from "@/data/Banks";

import CreditCardBoxes from "./CreditCardBoxes";
import CreditDetails from "./CreditDetails";
import CreditLogo from "./CreditLogo";

type CreditCardProps = {
    serial: string;
    iban: string | null;
    account?: string;
    status: CreditCard["status"];
    name: string;
};

const CreditCard: React.FC<CreditCardProps> = ({ serial, iban, status, name, account }) => {
    const bankDetails = useMemo(() => {
        const serialFirstSixDigits = serial?.slice(0, 6);
        const bank = banksList?.find((bank) => String(bank.code) === serialFirstSixDigits);
        if (bank) {
            return bank;
        }

        return {
            code: "123412",
            rgb: "hsl(192,97%,56%)",
            logo: "logo.svg",
            name: "شرکت پردازش کاران تجارت یاشار",
        };
    }, [serial]);

    return (
        <div className="relative aspect-[16/9.5] w-full max-w-[420px] overflow-hidden rounded-[15px] border border-secondary-200 shadow-main @container min-[420px]:rounded-[20px]">
            <CreditCardBoxes rgb={bankDetails.rgb} />
            <div className="relative z-30 flex h-full flex-col items-center justify-between p-[6%] @[320px]:p-[7%]">
                <div className="flex w-full items-center justify-between">
                    <CreditLogo logo={`/images/banks/${bankDetails.logo}`} name={bankDetails.name} />
                    <IconCreditCardSim className="h-[22px] @[320px]:h-[28px]" />
                </div>
                <CreditDetails iban={iban} serial={serial || ""} name={name} account={account} />
            </div>
            {status === "REJECTED" && (
                <div className="absolute left-0 top-0 z-40 flex h-full w-full flex-col items-center justify-center gap-4 overflow-hidden rounded-[15px] bg-secondary-transparent-2 backdrop-blur-sm @[340px]:gap-8 min-[420px]:rounded-[20px]">
                    <p className="w-[80%] text-center text-[1.28rem] text-error drop-shadow-[0_0_6px_rgba(0,0,0,0.7)] @[340px]:w-[70%] @[340px]:text-[1.57rem]">
                        این کارت مورد تایید مجموعه اول پرداخت نیست
                    </p>
                    <span className="whitespace-nowrap text-[1.14rem] text-text-main drop-shadow-[0_0_6px_rgba(0,0,0,0.7)] @[340px]:text-[1.28rem]">
                        {serial || ""}
                    </span>
                </div>
            )}
        </div>
    );
};

export default CreditCard;
