import { useMemo, useState } from "react";
import { Link } from "react-router-dom";

import IconCaretDown from "@/components/svg/caret-down";

export type TableTdProps = {
    link?: string;
    children: React.ReactNode;
    header: string;
    color?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon?: any;
    detail?: React.ReactNode;
};

const TableTd: React.FC<TableTdProps> = ({ color, header, children, link, icon: Icon, detail }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleDetailView = () => {
        setIsExpanded((prev) => !prev);
    };

    const detailNode = useMemo(() => {
        if (detail) {
            const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/gm;
            const detailHtmlNode = detail.toString().replace(urlRegex, function (url) {
                return '<a class="text-primary" target="_blank" href="' + url + '">' + url + "</a>";
            });

            return detailHtmlNode;
        } else {
            return <></>;
        }
    }, [detail]);

    return (
        <>
            <td
                onClick={detail ? toggleDetailView : undefined}
                className={`line-clamp-1 flex px-5 py-3 text-text-paragraph first:pt-8 last:pb-8 group-odd:bg-secondary-250/[0.45] sm:table-cell sm:py-5 sm:ps-0 first:sm:ps-12 ${
                    detail && !isExpanded ? "cursor-pointer" : ""
                }`}
                style={{ color }}
            >
                <span className="w-[120px] flex-shrink-0 font-bold text-text-main sm:hidden">{header}</span>
                <span className="flex gap-2">
                    {link ? (
                        <Link to={link} className="text-primary transition-colors hover:text-primary">
                            {children} {Icon && <Icon className="w-5" style={{ fill: color }} />}
                        </Link>
                    ) : detail ? (
                        <span className={`${isExpanded ? "" : "line-clamp-1"}`}>
                            {isExpanded ? (
                                <div
                                    onClick={(event) => event.stopPropagation()}
                                    dangerouslySetInnerHTML={{ __html: detailNode }}
                                ></div>
                            ) : (
                                children
                            )}
                        </span>
                    ) : (
                        <div>{children}</div>
                    )}
                    {!link && Icon && <Icon className="w-5" style={{ fill: color }} />}
                </span>
                {detail && (
                    <IconCaretDown
                        className={`absolute right-4 top-1/2 flex w-4 flex-shrink-0 -translate-y-1/2 fill-text-paragraph transition ${
                            isExpanded ? "rotate-180" : ""
                        }`}
                    />
                )}
            </td>
        </>
    );
};

export default TableTd;
