import { useEffect, useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";

import ToastError from "@/components/error/ToastError";
import FileInput from "@/components/ui/FileInput";
import Select from "@/components/ui/Select";
import Textarea from "@/components/ui/Textarea";
import { CalculationRequestType, ServiceInstructionType, SubmitOrderType } from "@/interfaces/dashboard/order/order";
import { objectToArray } from "@/utils";

import Plans from "../Plans";

type OrderValidationSchema = {
    serviceId: number;
    qty: {
        value: number;
        label: number;
    };
    fk_plan_id: number;
    user_description: string | null | undefined;
    attachments?: Record<string, File>;
};

type SubmitOrderRequest = SubmitOrderType & {
    qty: number | "";
    fk_plan_id: number;
};

type ServiceInstruction = ServiceInstructionType & {
    onCalculation: (data: CalculationRequestType) => void;
    onFormSubmit: (data: SubmitOrderRequest, serviceId: number) => void;
};

const GiftForm: React.FC<ServiceInstruction> = ({ instructions, onCalculation, onFormSubmit }) => {
    const [selectedPlan, setSelectedPlan] = useState<number | undefined>(instructions.plans[0]?.id);

    const { control, handleSubmit, resetField } = useForm<OrderValidationSchema>({
        defaultValues: {
            serviceId: instructions.id,
            user_description: "",
            fk_plan_id: instructions.plans[0]?.id,
            qty: {
                value: 1,
                label: 1,
            },
        },
    });

    const [watchedQty] = useWatch({
        control: control,
        name: ["qty"],
        defaultValue: {
            qty: {
                value: 1,
                label: 1,
            },
        },
    });

    useEffect(() => {
        if (selectedPlan && selectedPlan > 0) {
            onCalculation({
                qty: watchedQty.value,
                fk_plan_id: selectedPlan || -1,
                serviceId: instructions.id,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchedQty, selectedPlan]);

    useEffect(() => {
        onCalculation({
            qty: 1,
            fk_plan_id: instructions.plans[0]?.id || -1,
            serviceId: instructions.id,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submitHandler: SubmitHandler<OrderValidationSchema> = (data) => {
        if (selectedPlan) {
            onFormSubmit(
                {
                    qty: data.qty.value,
                    fk_plan_id: selectedPlan,
                    user_description: data.user_description,
                    attachments: data.attachments ? objectToArray<File>(data.attachments) : undefined,
                },
                instructions.id,
            );
        } else {
            ToastError(new Error("برای ثبت سفارش ابتدا باید یک پلن انتخاب کنید."));
        }
    };

    return (
        <form id="order-form" onSubmit={handleSubmit(submitHandler)}>
            <div className="flex flex-col gap-4">
                <Plans
                    currency={instructions.currency? instructions.currency[0] : undefined}
                    plans={instructions.plans}
                    onPlanChange={(id: number) => setSelectedPlan(id)}
                />
                <Select
                    control={control}
                    name="qty"
                    topTitle="تعداد"
                    required
                    variant="fill"
                    placeholder="انتخاب کنید"
                    rules={{
                        required: "انتخاب تعداد الزامی است",
                    }}
                    defaultValue={{
                        value: 1,
                        label: 1,
                    }}
                    options={Array(+instructions.giftCardsQty)
                        .fill(0)
                        .map((_, index) => {
                            return {
                                value: index + 1,
                                label: index + 1,
                            };
                        })}
                />

                <Textarea
                    name="user_description"
                    control={control}
                    label="توضیحات"
                    placeholder="توضیحات خود را اینجا بنویسید"
                />
                <FileInput control={control} name="attachments" onReset={resetField} repeater />
            </div>
        </form>
    );
};
export default GiftForm;
