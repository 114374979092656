import { PropsWithChildren, TextareaHTMLAttributes, useId } from "react";
import { Control, Controller } from "react-hook-form";

import { getFieldError } from "@/utils";

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    label?: string;
    labelClass?: string;
    placeholder?: string;
    name: string;
    id?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: Control<any>;
    rules?: object;
    required?: boolean;
    containerClass?: string;
    className?: string;
}

const Textarea: React.FC<PropsWithChildren<TextareaProps>> = ({
    label,
    labelClass,
    placeholder,
    name,
    id,
    containerClass,
    className,
    children,
    control,
    rules,
    required,
    ...rest
}) => {
    const randomId = useId();

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field, formState: { errors } }) => {
                const error = getFieldError(field.name, errors) as string;

                return (
                    <div className={`flex flex-col gap-2 ${containerClass}`}>
                        {label && (
                            <label htmlFor={id ?? randomId} className={`px-4 text-[0.85rem] text-text-main ${labelClass}`}>
                                {label}
                                {required ? <span className="text-error"> *</span> : ""}
                            </label>
                        )}
                        <textarea
                            id={id ?? randomId}
                            {...rest}
                            {...field}
                            className={`flex h-[80px] w-full resize-none items-center justify-center gap-3 rounded-[8px] border bg-input-bg p-3 text-text-main outline-none transition-colors placeholder:text-text-paragraph  ${
                                error
                                    ? "border-error"
                                    : "border-input-border hover:border-input-border-hover focus:border-primary"
                            } ${className ?? ""}`}
                            placeholder={placeholder ?? ""}
                            data-clarity-unmask="true"
                        >
                            {children}
                        </textarea>
                        {error && (
                            <div className="px-3 text-[0.78rem] text-error">
                                <span>{error}</span>
                            </div>
                        )}
                    </div>
                );
            }}
        />
    );
};

export default Textarea;
