import { useQuery } from "@tanstack/react-query";

import gate from "@/gate";

const useSettings = () => {
    return useQuery({
        queryFn: gate.generalSettings,
        queryKey: ["generalSettings"],
        refetchOnWindowFocus: false,
        select: (data) => {
            const settingObj: Record<string, string> = {};

            data.forEach((setting) => {
                settingObj[setting.key] = setting.value;
            });

            return settingObj;
        },
    });
};

export default useSettings;
