import classNames from "classnames";
import { NavLink } from "react-router-dom";

import { formatNumber } from "@/utils";

type CategoryProps = {
    image: string;
    text: string;
    link: string;
    active?: boolean;
    currencyPrice?: number;
    onSetCategory: () => void;
};

const Category: React.FC<CategoryProps> = ({ image, link, text, active, currencyPrice, onSetCategory }) => {
    const iconClasses = classNames({
        "h-[32px] w-[32px] bg-transparent": 1,
        "fill-primary": !active,
        "fill-white": active,
    });

    return (
        <NavLink
            className={classNames({
                "relative flex w-full items-center justify-between gap-2 overflow-hidden rounded-[10px] px-4 py-3 transition-colors contrast:border sm:gap-4": 1,
                "bg-secondary-400 hover:bg-primary/20": !active,
                "bg-primary contrast:border-primary contrast:bg-transparent": active,
            })}
            to={link}
            onClick={onSetCategory}
        >
            <div className="flex items-center justify-start gap-2">
                <img src={image} className={iconClasses} />
                <span className={`text-text-main ${active ? "contrast:text-primary" : ""}`}>{text}</span>
            </div>
            {currencyPrice && (
                <div className={`flex items-center ${active ? "text-white" : "text-text-paragraph"}`}>
                    <span className={`text-sm ${active ? "font-bold text-white" : "text-primary"}`}>
                        {formatNumber(currencyPrice)}
                    </span>
                    &nbsp;تومان
                </div>
            )}
        </NavLink>
    );
};

export default Category;
