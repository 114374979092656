import { useQuery } from "@tanstack/react-query";

import gate from "@/gate";

const useCashes = (page = 1, perPage = 10) => {
    return useQuery(["cashes", page, perPage], () => gate.cashesList(page, perPage), {
        select: (data) => {
            return {
                list: data.data,
                ...data.meta,
            };
        },
    });
};

export default useCashes;
