import classNames from "classnames";
import { ReactNode } from "react";
import { Control, Controller } from "react-hook-form";
import SelectComponent, {
    DropdownIndicatorProps,
    GroupBase,
    LoadingIndicatorProps,
    Props as SelectComponentProps,
} from "react-select";

import IconChevronLeft from "@/components/svg/chevron-left";
import IconSpinnerThirdDuotone from "@/components/svg/spinner-third-duotone";

interface SelectBaseProps extends SelectComponentProps {
    variant?: "stroke" | "fill";
    topTitle?: string | ReactNode;
    required?: boolean;
}

type SelectWithFormProps = SelectBaseProps & {
    name: string;
    control: Control<any>;
    rules?: any;
};

type SelectWithoutFormProps = SelectBaseProps & {
    name?: never;
    control?: never;
    rules?: never;
};

type SelectProps = SelectWithFormProps | SelectWithoutFormProps;

const LoadingIndicator = (_props: LoadingIndicatorProps<any, boolean, GroupBase<unknown>>) => {
    return (
        <div className="flex items-center justify-center gap-2">
            <span className="text-[0.85rem] text-text-paragraph">دریافت اطلاعات</span>
            <IconSpinnerThirdDuotone className="h-[14px] animate-spin fill-text-paragraph" />
        </div>
    );
};

const DropdownIndicator = ({
    selectProps: { menuIsOpen },
}: DropdownIndicatorProps<any, boolean, GroupBase<unknown>>) => {
    return (
        <IconChevronLeft
            className={`h-[14px] px-4 transition-all duration-[250ms] ease-in-out ${
                menuIsOpen ? "rotate-90 fill-primary" : "-rotate-90 fill-text-paragraph"
            }`}
        />
    );
};

const NoOptionsMessage = () => {
    return <div className="text w-full text-center text-text-paragraph">موردی یافت نشد</div>;
};

const Select: React.FC<SelectProps> = ({
    topTitle,
    variant = "stroke",
    name,
    rules,
    control,
    isDisabled,
    className,
    options,
    required,
    ...rest
}) => {
    return (
        <div className={"relative mt-1 space-y-3 " + className}>
            {topTitle && (
                <div className="px-3 text-[0.85rem] text-text-main">
                    {topTitle}
                    {required ? <span className="text-error">*</span> : ""}
                </div>
            )}

            {control && (
                <Controller
                    name={name}
                    control={control}
                    rules={rules}
                    render={({ field, formState: { errors } }) => {
                        const isError = errors && errors[field.name] && errors[field.name]?.type === "required";

                        return (
                            <SelectComponent
                                {...rest}
                                {...field}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: "hsl(var(--color-primary))",
                                        primary25: "transparent",
                                        neutral0: "var(--color-secondary-250)",
                                        // neutral20: "white",
                                        primary50: "transparent",
                                    },
                                })}
                                placeholder={rest.placeholder ?? "انتخاب"}
                                isDisabled={isDisabled}
                                menuPortalTarget={document.body}
                                components={{ LoadingIndicator, DropdownIndicator, NoOptionsMessage }}
                                classNames={{
                                    menuPortal: () => "!z-[9999] !text-text-main",
                                    menu: () => "!outline-none !border-none !shadow-none",
                                    option: () => "hover:!bg-primary/20 rounded-[6px] !cursor-pointer",
                                    // placeholder :() =>  "bg-red-400",
                                    container: () => "!w-full !rounded-[12px] !text-text-main ",
                                    singleValue: () => "!text-text-main",
                                    menuList: () =>
                                        "custom-scrollbar !bg-white contrast:!bg-transparent contrast:!text-black contrast:!border-white non-contrast:dark:border-none !border !border-[rgb(220,220,220)] !shadow-[0_15px_30px_-20px_rgb(0_0_0_/_0.25)] dark:!bg-secondary-200 !rounded-[12px] !p-[10px] !space-y-2",
                                    // dropdownIndicator : () => "!bg-secondary-100/20 !rounded-full !scale-[0.8] !fill-white",
                                    // indicatorsContainer : () => "!bg-secondary-100 !rounded-full",
                                    indicatorSeparator: () => "!hidden non-contrast:!bg-white",
                                    input: () => "!text-text-main",
                                    control: (state) => {
                                        return classNames({
                                            "!p-0 !h-[40px] !text-text-main !rounded-[8px]": 1,
                                            "!bg-input-bg": variant === "fill",
                                            "!border-input-border focus-within:!border-primary":
                                                !isDisabled && !isError,
                                            "!border-error": isError,
                                            "hover:!border-input-border-hover !border-input-border":
                                                !state.isFocused && !isError,
                                        });
                                    },
                                }}
                                options={options}
                                data-clarity-unmask="true"
                            />
                        );
                    }}
                />
            )}

            {!control && (
                <SelectComponent
                    {...rest}
                    isDisabled={isDisabled}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary: "hsl(var(--color-primary))",
                            primary25: "transparent",
                            neutral0: "var(--color-secondary-250)",
                            // neutral20: "white",
                            primary50: "transparent",
                        },
                    })}
                    menuPortalTarget={document.body}
                    classNames={{
                        menuPortal: () => "!z-[9999] !text-text-main",
                        menu: () => "!outline-none !border-none !shadow-none",
                        option: () => "hover:!bg-primary/20 rounded-[6px] !cursor-pointer",
                        // placeholder :() =>  "bg-red-400",
                        container: () => "!w-full !rounded-[12px] !text-text-main ",
                        singleValue: () => "!text-text-main",
                        menuList: () =>
                            "custom-scrollbar !bg-white contrast:!bg-transparent contrast:!text-black contrast:!border-white non-contrast:dark:border-none !border !border-[rgb(220,220,220)] !shadow-[0_15px_30px_-20px_rgb(0_0_0_/_0.25)] dark:!bg-secondary-200 !rounded-[12px] !p-[10px] !space-y-2",
                        // dropdownIndicator : () => "!bg-secondary-100/20 !rounded-full !scale-[0.8] !fill-white",
                        // indicatorsContainer : () => "!bg-secondary-100 !rounded-full",
                        indicatorSeparator: () => "!hidden",
                        input: () => "!text-text-main",
                        control: (state) => {
                            return classNames({
                                "!p-0 !h-[40px] !text-text-main !rounded-[8px]": 1,
                                "!bg-input-bg": variant === "fill",
                                "!border-input-border focus-within:!border-primary": !isDisabled,
                                "hover:!border-input-border-hover !border-input-border": !state.isFocused,
                            });
                        },
                    }}
                    options={options}
                    placeholder={rest.placeholder ?? "انتخاب"}
                    components={{ LoadingIndicator, DropdownIndicator }}
                    data-clarity-unmask="true"
                />
            )}
        </div>
    );
};

export default Select;
