import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { AnimatePresence, motion, Variants } from "framer-motion";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { InferType, object, string } from "yup";

import ToastError from "@/components/error/ToastError";
import Spinner from "@/components/loader/Spinner";
import IconEnvelope from "@/components/svg/envelope";
import IconLock from "@/components/svg/lock";
import IconMobileSolid from "@/components/svg/mobile-solid";
import AlertBox from "@/components/ui/AlertBox";
import Button from "@/components/ui/Button";
import CustomCheckbox from "@/components/ui/CustomCheckbox";
import Input from "@/components/ui/Input";
import Tabs from "@/components/ui/Tabs";
import PageTitle from "@/components/utility/PageTitle";
import { TWO_FA } from "@/constants/common";
import {
    EMAIL_REQUIRED_MESSAGE,
    INVALID_EMAIL_MESSAGE,
    MOBILE_REQUIRED_MESSAGE,
    MOBILE_VALIDATION_MESSAGE,
    PASSWORD_REQUIRED_MESSAGE,
    PASSWORD_VALIDATION_MESSAGE,
} from "@/constants/InavlidMessages";
import gate from "@/gate";
import useSettings from "@/hooks/queries/useSettings";
import useGoogleReCaptchaV3 from "@/hooks/useRecaptcha";
import { MOBILE_VALIDATION, PASSWORD_VALIDATION } from "@/lib/validations";

const RegisterSchema = object({
    email: string().required(EMAIL_REQUIRED_MESSAGE).email(INVALID_EMAIL_MESSAGE),
    mobile: string().required(MOBILE_REQUIRED_MESSAGE).matches(MOBILE_VALIDATION, MOBILE_VALIDATION_MESSAGE),
    password: string().required(PASSWORD_REQUIRED_MESSAGE).matches(PASSWORD_VALIDATION, PASSWORD_VALIDATION_MESSAGE),
}).required();

type RegisterValidationSchema = InferType<typeof RegisterSchema>;

const Register = () => {
    const navigate = useNavigate();
    const { data: settings } = useSettings();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [goftinoToken, setGoftinoToken] = useState<undefined | string>();
    const [acceptRules, setAcceptRules] = useState<boolean>(false);

    const [accountType, setAccountType] = useState<"business" | "personal">("personal");

    useEffect(() => {
        window.addEventListener("goftino_ready", async function () {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            setGoftinoToken(Goftino.getUserId());
        });
    }, []);

    const { executeReCaptcha } = useGoogleReCaptchaV3({ action: "Register" });

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<RegisterValidationSchema>({
        resolver: yupResolver(RegisterSchema),
        defaultValues: {
            email: "",
            mobile: "",
            password: "",
        },
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { mutate, isLoading: registerLoading } = useMutation<any, Error, any>({
        mutationFn: gate.register,
        onSuccess(data) {
            setIsLoading(false);

            toast.success("ثبت نام شما با موفقیت انجام شد.");

            navigate("/auth/2fa-validate", {
                state: {
                    twoFAType: TWO_FA.MOBILE,
                    email: data.email,
                },
            });
        },
        onError(error) {
            setIsLoading(false);
            ToastError(error);
        },
    });

    const getCaptchaToken = async () => {
        let captchaToken: string | undefined = undefined;

        if (settings?.isCaptchaEnable && settings.isCaptchaEnable === "1") {
            setIsLoading(true);
            captchaToken = await executeReCaptcha("Register");
            setIsLoading(false);
        }

        return captchaToken;
    };

    const submitHandler: SubmitHandler<RegisterValidationSchema> = async (data) => {
        const token = await getCaptchaToken();

        mutate({
            email: data.email,
            mobile: data.mobile,
            password: data.password,
            rules: acceptRules,
            recaptcha: token,
            online_chat_token: goftinoToken || "",
            referral: Cookies.get("referral"),
            is_business: accountType === "business" ? 1 : 0,
        });
    };

    const variants: Variants = {
        hide: {
            opacity: 0,
            scale: 0.9,
            transition: {
                opacity: {
                    duration: 0.1,
                },
            },
        },
        show: {
            opacity: 1,
            scale: 1,
        },
    };

    return (
        <>
            <PageTitle>ایجاد حساب کاربری</PageTitle>
            <form onSubmit={handleSubmit(submitHandler)} className="w-full">
                <h2 className="text-lg font-bold text-text-main">ایجاد حساب کاربری در اول پرداخت</h2>
                <div className="mt-8">
                    <Tabs
                        active={accountType}
                        setActive={(id: any) => setAccountType(id)}
                        tabs={[
                            {
                                id: "personal",
                                text: "حساب حقیقی",
                                disabled: false,
                                hide: false,
                            },
                            {
                                id: "business",
                                text: "حساب حقوقی",
                                disabled: false,
                            },
                        ]}
                    />
                </div>

                <AnimatePresence initial={false} mode="wait">
                    {accountType === "personal" ? (
                        <motion.div
                            key={1}
                            variants={variants}
                            animate="show"
                            initial="hide"
                            exit="hide"
                            className="my-12 flex flex-col gap-6"
                        >
                            <Input
                                name="mobile"
                                control={control}
                                ltr
                                topTitle="شماره همراه"
                                placeholder="شماره همراه"
                                type="text"
                                variant="fill"
                                autoComplete="new-password"
                                className={`${errors.mobile && "border-error"}`}
                                addon={<IconMobileSolid className="h-6 fill-gray-400" />}
                            />
                            <Input
                                name="email"
                                control={control}
                                ltr
                                topTitle="ایمیل"
                                placeholder="ایمیل"
                                type="email"
                                variant="fill"
                                autoFocus
                                autoComplete="new-password"
                                className={`${errors.email && "border-error"}`}
                                addon={<IconEnvelope className="h-6 fill-gray-400" />}
                            />
                            <Input
                                name="password"
                                control={control}
                                ltr
                                topTitle="رمز عبور"
                                placeholder="رمز عبور"
                                type="password"
                                variant="fill"
                                autoComplete="new-password"
                                className={`${errors.password && "border-error"}`}
                                addon={<IconLock className="h-6 fill-gray-400" />}
                            />
                        </motion.div>
                    ) : (
                        <motion.div
                            key={2}
                            variants={variants}
                            animate="show"
                            initial="hide"
                            exit="hide"
                            className="my-12 flex flex-col gap-6"
                        >
                            <AlertBox
                                type="info"
                                title="توجه"
                                description="در صورتی که دارای شرکت / موسسه ثبت شده هستید از این بخش ثبت نام کنید. (بعد از ایجاد حساب کاربری حقوقی امکان تغییر آن به حساب کاربری حقیقی وجود ندارد.)"
                            />
                            <Input
                                name="mobile"
                                control={control}
                                ltr
                                topTitle="شماره همراه مدیرعامل"
                                placeholder="شماره همراه مدیرعامل"
                                type="text"
                                variant="fill"
                                autoComplete="new-password"
                                className={`${errors.mobile && "border-error"}`}
                                addon={<IconMobileSolid className="h-6 fill-gray-400" />}
                            />
                            <Input
                                name="email"
                                control={control}
                                ltr
                                topTitle="ایمیل شرکت"
                                placeholder="ایمیل شرکت"
                                type="email"
                                variant="fill"
                                autoFocus
                                autoComplete="new-password"
                                className={`${errors.email && "border-error"}`}
                                addon={<IconEnvelope className="h-6 fill-gray-400" />}
                            />
                            <Input
                                name="password"
                                control={control}
                                ltr
                                topTitle="رمز عبور"
                                placeholder="رمز عبور"
                                type="password"
                                variant="fill"
                                autoComplete="new-password"
                                className={`${errors.password && "border-error"}`}
                                addon={<IconLock className="h-6 fill-gray-400" />}
                            />
                        </motion.div>
                    )}
                </AnimatePresence>

                <div className="mx-3 mb-6">
                    <CustomCheckbox
                        onChange={() => setAcceptRules((prev) => !prev)}
                        label={
                            <span>
                                <a
                                    href="https://avalpardakht.com/terms"
                                    target="_blank"
                                    rel="noreferrer nofollow"
                                    className="text-primary hover:text-primary"
                                >
                                    قوانین و مقررات
                                </a>{" "}
                                سایت را مطالعه کرده‌ام و آن را قبول دارم.
                            </span>
                        }
                    />
                </div>
                <Button disabledTooltip="دکمه غیرفعال است" type="submit" disabled={isLoading || registerLoading || !acceptRules} className="w-full">
                    {isLoading || registerLoading ? <Spinner /> : "ثبت نام"}
                </Button>
            </form>
        </>
    );
};

export default Register;
