import { useQueryClient } from "@tanstack/react-query";
import { ChangeEvent, useMemo, useState } from "react";

import ToastError from "@/components/error/ToastError";
import IconCamera from "@/components/svg/camera";
import IconSpinnerThirdDuotone from "@/components/svg/spinner-third-duotone";
import Avatar from "@/components/ui/Avatar";
import useUpdateAvatar from "@/hooks/queries/useUpdateAvatar";
import useUser from "@/hooks/queries/useUser";

const SelectAvatar = () => {
    const queryClient = useQueryClient();
    const { mutateAsync: updateAvatar, isLoading } = useUpdateAvatar();
    const { data: user ,refetch : refetchUser} = useUser();
    const [selectedAvatar, setSelectedAvatar] = useState<number | null>(null);

    const avatars = [
        "/images/avatars/avatar-1.png",
        "/images/avatars/avatar-2.png",
        "/images/avatars/avatar-3.png",
        "/images/avatars/avatar-4.png",
        // "/images/avatars/avatar-5.png",
        "/images/avatars/avatar-6.png",
        "/images/avatars/avatar-7.png",
        "/images/avatars/avatar-8.png",
    ];

    const businessAvatars = [
        "/images/business-avatars/avatar-1.png",
        "/images/business-avatars/avatar-2.png",
        "/images/business-avatars/avatar-3.png",
        "/images/business-avatars/avatar-4.png",
        "/images/business-avatars/avatar-5.png",
        "/images/business-avatars/avatar-6.png",
        "/images/business-avatars/avatar-7.png",
    ];

    const uploadAvatar = async (id: string) => {
        try {
            const avatarImgTag = document.querySelector(id) as HTMLImageElement;
            const response = await fetch(avatarImgTag.src);
            const avatarBlob = await response.blob();
            await updateAvatar({ avatar: avatarBlob });
            await refetchUser();
            setSelectedAvatar(null);
        } catch (e) {
            ToastError(new Error("مشکلی در آپلود تصویر شما پیش‌ آمده است"));
        }
    };

    const uploadCustomAvatar = async (e: ChangeEvent<HTMLInputElement>) => {
        const selectedAvatar = e.target.files![0];
        if (selectedAvatar.type === "image/jpeg" || selectedAvatar.type === "image/png") {
            await updateAvatar({ avatar: selectedAvatar });
            await queryClient.refetchQueries(["userData"]);
        }
    };

    const spinnerBox = (
        <div className="absolute top-0 z-10 flex h-full w-[50px] items-center justify-center rounded-full border-4 border-secondary-100 bg-secondary-transparent-2 min-[360px]:w-[55px] xs:w-[70px]">
            <IconSpinnerThirdDuotone className="h-[16px] animate-spin fill-primary" />
        </div>
    );

    const renderAvatars = useMemo(() => {
        return (user.data.user.is_business ? businessAvatars : avatars).map((avatar, index) => {
            return (
                <button
                    key={index}
                    onClick={() => {
                        setSelectedAvatar(index);
                        uploadAvatar(`#avatar-${index}`);
                    }}
                    className="relative flex items-center justify-center transition-all hover:scale-110"
                >
                    {isLoading && selectedAvatar === index && spinnerBox}
                    <Avatar
                        id={index}
                        picture={avatar}
                        className="!w-[50px] shrink-0 min-[360px]:!w-[55px] xs:!w-[70px]"
                    />
                </button>
            );
        });
    }, [isLoading, selectedAvatar]);

    return (
        <div className="mt-6 grid grid-cols-4 gap-8 rounded-[15px] border border-secondary-200 p-4">
            {renderAvatars}
            <label
                htmlFor="custom-avatar-input"
                className="relative flex aspect-square !w-[50px] cursor-pointer items-center justify-center justify-self-center overflow-hidden rounded-full bg-secondary-250 transition-all hover:scale-110 dark:bg-secondary-200 min-[360px]:!w-[55px] xs:!w-[70px]"
            >
                {isLoading && selectedAvatar === null && spinnerBox}
                <IconCamera className="h-[15px] shrink-0 fill-icon-disabled dark:fill-white xs:h-[20px]" />
            </label>
            <input id="custom-avatar-input" onChange={uploadCustomAvatar} type="file" hidden />
        </div>
    );
};

export default SelectAvatar;
