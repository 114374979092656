import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";

import gate from "@/gate";

import ToastError from "../error/ToastError";
import AlertBox from "../ui/AlertBox";
import PaymentAnnouncementCopyText from "./PaymentAnnouncementCopyText";
import PaymentAnnouncementCountdown from "./PaymentAnnouncementCountdown";
import PaymentAnnouncementCryptoInputs from "./PaymentAnnouncementCryptoInputs";
import PaymentAnnouncementFormCommonInputs from "./PaymentAnnouncementFormCommonInputs";

type CountdownProps = {
    cashId: number;
    isDigital: boolean;
    wallet?: string;
    link?: string;
    linkTitle: string;
    time: number;
    date?: string;
    service?: string;
    pcode?: string;
    memo?: string;
};

type PaymentAnnouncementSchema = {
    user_attachment: Record<string, File>;
    pay_date?: string;
    wallet?: string;
    tx_id?: string;
    securePassword: string;
};

const PaymentAnnouncementForm: React.FC<CountdownProps> = ({
    cashId,
    isDigital,
    service,
    link,
    wallet,
    linkTitle,
    time,
    date,
    memo,
}) => {
    const queryClient = useQueryClient();

    const securePassRef = useRef(Math.random().toString(36).substring(2));
    const { handleSubmit, control, resetField } = useForm<PaymentAnnouncementSchema>({
        defaultValues: {
            securePassword: securePassRef.current,
            pay_date: "",
            tx_id: "",
            wallet: "",
        },
    });

    const { mutate: settleCash, isLoading } = useMutation({
        mutationFn: gate.settleCash.bind(null, cashId),
        onSuccess(data) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            toast.success(data?.message || "اعلام پرداخت شما با موفقیت ثبت شد");

            queryClient.refetchQueries(["cash", cashId]);
        },
        onError(error: Error) {
            ToastError(error);
        },
    });

    const submitHandler: SubmitHandler<PaymentAnnouncementSchema> = (data) => {
        settleCash({
            service: service,
            wallet: data.wallet,
            tx_id: data.tx_id,
            pay_date: data.pay_date,
            secure_password: securePassRef.current,
            user_attachment: data.user_attachment,
        });
    };

    return (
        <div className="mt-4 space-y-4">
            <p className="text-sm text-text-paragraph">{linkTitle}</p>
            <div className="flex flex-col items-center gap-4 rounded-[6px] border border-primary p-4">
                <PaymentAnnouncementCopyText title={link ? "لینک" : "کیف پول"} text={link || wallet || ""} />
                {service == "HAMSTER" && (
                    <>
                        <hr className="border-slate-250 w-full" />
                        <PaymentAnnouncementCopyText title="ممو (MEMO)" text={memo || ""} />
                    </>
                )}
                {service == "WEBMONEY" && (
                    <>
                        <hr className="border-slate-250 w-full" />
                        <PaymentAnnouncementCopyText title="توضیحات (Description)" text={securePassRef.current || ""} />
                    </>
                )}

                {time == -1 ? (
                    <></>
                ) : (
                    <>
                        <hr className="border-slate-250 w-full" />
                        <PaymentAnnouncementCountdown time={time} date={date} />
                    </>
                )}
            </div>
            {service == "WEBMONEY" && (
                <AlertBox
                    type="warning"
                    title="لطفا توجه کنید"
                    description={
                        "در هنگام انتقال مبلغ فیلد توضیحات (Description) اجباری است لطفاً کد بالا را در قسمت Description در لحظه انتقال وارد نمایید"
                    }
                />
            )}

            <form onSubmit={handleSubmit(submitHandler)} className="flex flex-col gap-4">
                {(isDigital || service == "WEBMONEY") && (
                    <PaymentAnnouncementCryptoInputs service={service} control={control} />
                )}

                <PaymentAnnouncementFormCommonInputs isLoading={isLoading} resetField={resetField} control={control} />
            </form>
        </div>
    );
};

export default PaymentAnnouncementForm;
