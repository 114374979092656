import { motion, Variants } from "framer-motion";
import React, { useEffect, useState } from "react";

import useAnimeSetting from "@/hooks/useAnimeSetting";
import { random } from "@/utils";

type SocialMediaItemProps = {
    icon: any;
    link: string;
    color: string;
    className?: string;
    rotateDeg: string;
};
const SocialMediaItem: React.FC<SocialMediaItemProps> = ({ link, color, className, icon: Icon, rotateDeg }) => {
    const { isAnimationEnabled } = useAnimeSetting();
    const [scale, setScale] = useState(1);

    useEffect(() => {
        const windowSize = window.outerWidth;

        const interval = setInterval(() => {
            if (windowSize > 728) {
                const randomScale = random(85, 100) / 100;
                if (isAnimationEnabled) setScale(randomScale);
            }
        }, 600);

        return () => {
            clearInterval(interval);
        };
    }, [isAnimationEnabled]);

    const x: Variants = {
        "hover-social-item": {
            scale: 1,
        },
    };

    return (
        <motion.a
            animate={{ scale: isAnimationEnabled ? scale : 1 }}
            className="group absolute left-0 flex origin-left cursor-pointer items-center justify-end"
            style={{ rotate: rotateDeg }}
            variants={x}
            transition={{ type: "spring", stiffness: 300, damping: 60 }}
            key={link}
            href={link}
        >
            <div className="relative flex h-[55px] w-[55px] items-center justify-center rounded-full border-2 border-transparent bg-white transition-all group-hover:scale-125 group-hover:!border-primary contrast:!border-black contrast:bg-transparent dark:bg-secondary-200 contrast:dark:!border-white">
                <Icon
                    className={"h-[20px] sm:h-[26px] " + color + " " + className}
                    style={{ rotate: "-" + rotateDeg }}
                />
            </div>
            <div className="block h-[2px] w-[110px] bg-white transition-colors group-hover:bg-primary contrast:!bg-black dark:bg-secondary-200 contrast:dark:!bg-white sm:w-[150px]" />
        </motion.a>
    );
};

export default SocialMediaItem;
