import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Tabs from "@/components/customer-club/Tabs";
import PlaceholderLayout from "@/components/placeholder/PlaceholderLayout";
import PageTitle from "@/components/utility/PageTitle";
import useUser from "@/hooks/queries/useUser";

const CustomerClub = () => {
    const { data: userData } = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        if (!userData.data.user.avalClubIsActive) {
            navigate("/panel");
        }
    }, [userData]);

    return (
        <PlaceholderLayout>
            <PageTitle>باشگاه مشتریان</PageTitle>
            <div className="space-y-8">
                <Tabs />
            </div>
        </PlaceholderLayout>
    );
};

export default CustomerClub;
