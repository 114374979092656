import { useContext } from "react";

import { Context, ContextType } from "@/store/Context-store";

const HueRotationSetting = () => {
    const { setHueRotationValue, setIsSettingsChanged } = useContext(Context) as ContextType;
    const arr = Array.from({ length: 36 }).fill(" ");

    return (
        <>
            <div className="mt-16 flex w-full items-center justify-center max-md:pb-16 md:mt-16">
                <div className="relative flex h-[200px] w-[200px] items-center justify-center xs:h-[250px] xs:w-[250px]">
                    <div className="h-[80px] w-[80px] rounded-full bg-primary"></div>
                    {arr.map((_item, i) => (
                        <button
                            key={i}
                            onClick={() => {
                                const value = (360 - i * (360 / arr.length)) / 3.6;
                                setHueRotationValue(value);
                                setIsSettingsChanged(true);
                            }}
                            className="group absolute h-[18px] w-[50px] border border-transparent [--container-translate-x:80px] md:h-[22px] md:[--container-translate-x:100px]"
                            style={{
                                transform: `rotateZ(${
                                    i * (360 / arr.length)
                                }deg) translateX(var(--container-translate-x))`,
                            }}
                        >
                            <div
                                style={{
                                    transform: `translateX(var(--item-translate-x)) scale(var(--item-scale))`,
                                    filter: `hue-rotate(calc(1turn - var(--theme-filter-hue-rotation) - ${
                                        (i * 10) / 360
                                    }turn))`,
                                }}
                                className="h-[18px] w-[18px] border-2 border-transparent bg-primary transition-all duration-[180ms] group-hover:border-black group-hover:[--item-scale:2] group-hover:[--item-translate-x:40px] group-active:[--item-scale:1.5] dark:group-hover:border-white md:h-[22px] md:w-[22px] md:group-hover:[--item-translate-x:50px]"
                            ></div>
                        </button>
                    ))}
                </div>
            </div>
        </>
    );
};

export default HueRotationSetting;
