import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";

import CashCategories from "@/components/cash-income/CashCategories";
import CashForm from "@/components/cash-income/CashForm";
import ServiceInstruction from "@/components/order/ServiceInstruction";
import PlaceholderLayout from "@/components/placeholder/PlaceholderLayout";
import IconHouse from "@/components/svg/house";
import BoxContainer from "@/components/ui/BoxContainer";
import Breadcrumb from "@/components/ui/Breadcrumb";
import Title from "@/components/ui/Title";
import PageTitle from "@/components/utility/PageTitle";
import useCashServices from "@/hooks/queries/useCashServices";
import useLocalStorage from "@/hooks/useLocalStorage";
import { CashServicesList, ServiceTypeSummary } from "@/interfaces/dashboard/order/order";
import { isEmpty } from "@/utils";

const Cash: React.FC = () => {
    const [allCashServices, setAllCashServices] = useLocalStorage<CashServicesList | object>("cash_services", {});

    const [currencyId, setCurrencyId] = useState<number | undefined>();

    const { serviceName } = useParams();
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ maxWidth: 767.999 });

    const [cashCategories, setCashCategories] = useState<ServiceTypeSummary[] | undefined>();
    const [description, setDescription] = useState<string | null>();

    const { data: services, refetch: refetchServices } = useCashServices(allCashServices || {});

    useEffect(() => {
        if (!serviceName && !isMobile) {
            navigate("/panel/cash-income/sell-paypal", { replace: true });
        }
    }, [isMobile, navigate, serviceName]);

    useEffect(() => {
        if (!isEmpty(services)) {
            setAllCashServices(services);
        } else {
            refetchServices();
        }
    }, [refetchServices, services, setAllCashServices]);

    useEffect(() => {
        if (services) {
            setCashCategories(() => {
                const list = [];
                for (const cash in services) {
                    list.push({
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        title: (cash == "BROKER_CASHINCOME" ? "" : "فروش ") + services[cash].name,
                        slug: "sell-" + cash?.toLowerCase(),
                        type: cash,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        icon: services[cash].icon,
                        id: 0,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        currencies: services[cash].currencies,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        form_file: services[cash].form_file,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        instruction: services[cash].instruction,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        currency: services[cash].currencies,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        time: services[cash].time,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        is_working_time: services[cash].is_working_time,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        name: services[cash].name,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        need_video_verification: services[cash].need_video_verification,
                    });
                }

                return list;
            });
        }
    }, [serviceName, services]);

    return (
        <PlaceholderLayout>
            <PageTitle>نقد درآمد ارزی</PageTitle>
            {isMobile && serviceName && (
                <Breadcrumb
                    sections={[
                        { link: "/panel", text: "صفحه اصلی", icon: IconHouse },
                        { link: `/panel/cash-income`, text: "لیست ارزها" },
                        { link: "#", text: "فرم فروش به اول‌پرداخت", active: true },
                    ]}
                />
            )}
            <div className="flex w-full items-start justify-start gap-4 sm:gap-6 md:gap-10">
                {(!isMobile || (isMobile && !serviceName)) && (
                    <CashCategories
                        isLoading={!services}
                        categories={cashCategories}
                        activeCategory={serviceName}
                        activeCurreny={currencyId}
                    />
                )}
                {(!isMobile || (isMobile && serviceName)) && (
                    <CashForm
                        onDataAvailable={(data) => {
                            setDescription(data.instruction);
                        }}
                        onCurrencyChange={(currencyId) => setCurrencyId(currencyId)}
                        serviceName={serviceName?.replace("sell-", "").toUpperCase() || ""}
                    />
                )}
            </div>

            {serviceName && (
                <div className="mt-6 w-full">
                    <BoxContainer>
                        {typeof description === "string" && serviceName ? (
                            <Title
                                title="نکات و توضیحات"
                                containerClasses="-m-5"
                                className="flex h-full flex-col gap-6 p-0"
                                expandebale
                                expanded={0}
                            >
                                <ServiceInstruction>
                                    <div
                                        className="!text-[1rem]"
                                        dangerouslySetInnerHTML={{ __html: description || "" }}
                                    />
                                </ServiceInstruction>
                            </Title>
                        ) : (
                            <div className="flex items-center justify-between">
                                <Skeleton width={121} />
                                <Skeleton width={20} />
                            </div>
                        )}
                    </BoxContainer>
                </div>
            )}
        </PlaceholderLayout>
    );
};
export default Cash;
