import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import PlaceholderLayout from "@/components/placeholder/PlaceholderLayout";
import AddCreditCard from "@/components/profile/credit-cards/AddCreditCard";
import CreditCard from "@/components/profile/credit-cards/CreditCard";
import PageTitle from "@/components/utility/PageTitle";
import useBankCards from "@/hooks/queries/useBankCards";
import useUser from "@/hooks/queries/useUser";

const CreditCards = () => {
    const { data: user } = useUser();
    const { data: bankCards, isLoading } = useBankCards();

    const renderCards = useMemo(() => {
        if (bankCards && bankCards.cards.length > 0) {
            return bankCards.cards.map((card) => {
                return (
                    <CreditCard
                        name={user.data.user.name}
                        iban={card.iban}
                        serial={card.card_number}
                        status={card.status}
                        key={card.id}
                    />
                );
            });
        }

        if (isLoading) {
            return (
                <>
                    <Skeleton className="aspect-[16/9.5] h-[230px] max-w-[420px]" borderRadius={15} />
                    <Skeleton className="aspect-[16/9.5] h-[230px] max-w-[420px]" borderRadius={15} />
                    <Skeleton className="aspect-[16/9.5] h-[230px] max-w-[420px]" borderRadius={15} />
                    <Skeleton className="aspect-[16/9.5] h-[230px] max-w-[420px]" borderRadius={15} />
                    <Skeleton className="aspect-[16/9.5] h-[230px] max-w-[420px]" borderRadius={15} />
                </>
            );
        } else {
            return <></>;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bankCards]);

    return (
        <PlaceholderLayout>
            <PageTitle>کارت های بانکی</PageTitle>

            <div className="flex h-full w-full items-start justify-center pt-[50px]">
                <div className="grid w-full grid-cols-1 justify-items-center gap-8 min-[900px]:grid-cols-2 min-[900px]:gap-10 min-[1250px]:gap-20 min-[1300px]:w-[80%]">
                    <AddCreditCard />
                    {bankCards && bankCards.main_account && bankCards.main_account.value && (
                        <CreditCard
                            name={user.data.user.name}
                            iban={null}
                            serial={bankCards.main_account.value}
                            status={bankCards.main_account.status}
                        />
                    )}
                    {renderCards}
                </div>
            </div>
        </PlaceholderLayout>
    );
};

export default CreditCards;
