import { useCallback, useContext, useEffect } from "react";

import { PATTERNS, THEMES } from "@/constants/common";
import { Context, ContextType } from "@/store/Context-store";

import useLocalStorage from "./useLocalStorage";

export type Theme = THEMES | "";

export type ThemeFontSizes = "xs" | "sm" | "md" | "lg" | "xl";

export type ThemePatterns = PATTERNS | "";

const useTheme = () => {
    const [storedTheme, storeTheme] = useLocalStorage<Theme>("app-theme", "");
    const { theme, setTheme } = useContext(Context) as ContextType;
    const [storedIsThemeDark, storeIsThemeDark] = useLocalStorage("app-theme-is-dark", false);
    const { isThemeDark, setIsThemeDark, setSystemTheme } = useContext(Context) as ContextType;

    useEffect(() => {
        if (storedTheme) {
            const htmlEl = document.querySelector("html") as HTMLHtmlElement;

            if (storedTheme.includes("dark")) {
                htmlEl.classList.add("dark");
                setIsThemeDark(true);
            } else {
                htmlEl.classList.remove("dark");
                setIsThemeDark(false);
            }

            setTheme(storedTheme);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storedTheme, storedIsThemeDark]);

    const changeThemeTo = useCallback(
        (newTheme: Theme, disableSystemTheme = true) => {
            if (disableSystemTheme) {
                setSystemTheme(false);
            }

            const htmlEl = document.querySelector("html") as HTMLHtmlElement;

            if (newTheme?.includes("dark")) {
                htmlEl.classList.add("dark");
                storeIsThemeDark(true);
                setIsThemeDark(true);
            } else {
                htmlEl.classList.remove("dark");
                storeIsThemeDark(false);
                setIsThemeDark(false);
            }

            let classes: string[] = [];

            htmlEl.classList.forEach((value) => {
                classes.push(value);
            });

            classes = classes.filter((item) => {
                return !item.includes("-dark") && !item.includes("-light");
            });

            classes.push(newTheme);

            htmlEl.className = classes.join(" ");

            storeTheme(newTheme);
            setTheme(newTheme);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setTheme, storeTheme, storeIsThemeDark, setIsThemeDark, storedTheme, setSystemTheme],
    );

    return { theme, changeThemeTo, isThemeDark };
};

export default useTheme;
