import { useMemo } from "react";
import { Link } from "react-router-dom";

import EmptyIndicator from "@/components/dashboard/main/EmptyIndicator";
import IconEyeDuotone from "@/components/svg/eye-duotone";
import IconHouse from "@/components/svg/house";
import BoxContainer from "@/components/ui/BoxContainer";
import Breadcrumb from "@/components/ui/Breadcrumb";
import Pagination from "@/components/ui/Pagination";
import Table from "@/components/ui/Table";
import { TableTdProps } from "@/components/ui/Table/TableTd";
import PageTitle from "@/components/utility/PageTitle";
import useOrders from "@/hooks/queries/useOrders";
import useAfter from "@/hooks/useAfter";
import useQueryParams from "@/hooks/useQueryParams";
import { formatNumber } from "@/utils";
import { orderStatus } from "@/utils/cases";

const Orders = () => {
    const { updateQueryParams, searchParams } = useQueryParams();

    const page = Number(searchParams.get("page") ?? 1);
    const perPageValue = Number(searchParams.get("perPage") ?? 10);
    const perPage = { value: perPageValue, label: perPageValue };

    const { data: orders, isLoading } = useOrders(page, perPage.value);

    const forcePerPage = orders?.per_page ? Number(orders?.per_page) : 1;

    // set force per_page once after data is loaded
    useAfter(() => {
        updateQueryParams({ perPage: forcePerPage });
    }, !!orders);

    const tableRows = useMemo<TableTdProps[][] | undefined>(() => {
        return orders?.list.map((order) => {
            const { icon, color } = orderStatus(order.status);

            return [
                {
                    header: "شماره سفارش",
                    children: "#" + order.id,
                    link: "/panel/order/list/" + order.id,
                },
                {
                    header: "سفارش",
                    children: order.title,
                },
                {
                    header: "میزان",
                    children: order.amount,
                },
                {
                    header: "نرخ(تومان)",
                    children: formatNumber(Number(order.rate)),
                },
                {
                    header: "مبلغ کل (تومان)",
                    children: formatNumber(Number(order.total)),
                },
                {
                    header: "وضعیت",
                    children: order.status,
                    color: color,
                    icon: icon,
                    iconColor: color,
                },
                {
                    header: "عملیات",
                    children: (
                        <Link to={"/panel/order/list/" + order.id}>
                            <IconEyeDuotone className="w-6 cursor-pointer fill-black dark:fill-white" />
                        </Link>
                    ),
                },
            ];
        });
    }, [orders]);

    return (
        <>
            <PageTitle>سفارشات</PageTitle>

            <Breadcrumb
                sections={[
                    { link: "/panel", text: "صفحه اصلی", icon: IconHouse },
                    { link: "#", text: "لیست سفارش‌ها", active: true },
                ]}
            />
            <BoxContainer colorBox={false}>
                <div className="-mx-5 pb-14">
                    {tableRows?.length !== 0 ? (
                        <Table placeholderRows={12} loading={isLoading} rows={tableRows} className="max-sm:-mt-5" />
                    ) : (
                        <EmptyIndicator
                            message="سفارشی برای نمایش وجود ندارد"
                            link="/panel/order"
                            linkText="ثبت اولین سفارش"
                        />
                    )}
                </div>
            </BoxContainer>
            {!isLoading && tableRows?.length !== 0 && (
                <Pagination
                    onPerPageChange={(perPage) => updateQueryParams({ perPage })}
                    onPageChange={(page) => updateQueryParams({ page })}
                    nextPage={() => updateQueryParams({ page: page + 1 })}
                    prevPage={() => updateQueryParams({ page: page - 1 })}
                    paginationInfo={{
                        from: orders?.from,
                        to: orders?.to,
                        pages: orders?.last_page,
                        count: orders?.total,
                        perPage: perPage.value,
                        forcePerPage,
                        page,
                    }}
                />
            )}
        </>
    );
};

export default Orders;
