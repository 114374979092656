import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { rootReducers } from "./reducers/index";

export const store = configureStore({
    reducer: rootReducers,
    devTools: import.meta.env.VITE_MODE_ENV === "development",

    // middleware: (getDefaultMiddleware) =>
    //     getDefaultMiddleware()
    //         .prepend
    //         //additionalMiddleware,
    //         //untypedMiddleware as Middleware<(action: Action<'specialAction'>) => number, RootState>
    //         ()
    //         .concat
    //         // logger
    //         (),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
