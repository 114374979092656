import { ReactNode } from "react";
import Skeleton from "react-loading-skeleton";

type InvoiceDetailProps = {
    title: string | ReactNode;
    value?: string | ReactNode;
};

const InvoiceDetail = ({ title, value }: InvoiceDetailProps) => {
    return (
        <div className="flex w-full justify-between">
            <div className="text-text-paragraph">{title}:</div>
            <div className="text-text-main">{value ?? <Skeleton width={120} height={15} />}</div>
        </div>
    );
};

export default InvoiceDetail;
