import api from "../api";

type Ticket = {
    id: number;
    title: string;
    department: string;
    priority: string;
    created_at: string;
    status: string;
    content: string;
    attachments: { name: string; url: string }[];
    locale_priority: string;
    locale_status: string;
};

type GetTicketsResponse = {
    data: Ticket[];
    links: {
        first: string;
        last: string;
        prev: string | null;
        next: string | null;
    };
    meta: {
        current_page: number;
        from: number;
        last_page: number;
        path: string;
        per_page: string;
        to: number;
        total: number;
    };
};

type Reply = {
    id: number;
    content: string;
    private: number;
    attachments: { name: string; url: string }[];
    created_at: string;
    sender: {
        is_admin: boolean;
        name: string;
        avatar: string;
    };
};

type GetTicketResponse = {
    order?: {
        id: number;
        fk_service_id: number;
        service: {
            id: number;
            title: string;
        };
    };
    is_captcha_enable: boolean;
    has_order: boolean;
    ticket: Ticket & {
        sender: {
            is_admin: boolean;
            name: string;
            avatar: string;
            content: string;
        };
    };
    replies: Reply[];
};

type AddTicketRequest = {
    title: string;
    priority: string;
    fk_department_id: string;
    fk_order_id?: string;
    content: string;
    attachment?: File[];
    "g-recaptcha-response"?: string;
};

type ReplyTicketRequest = {
    id: string;
    content: string;
    attachment?: File[];
    "g-recaptcha-response"?: string;
};

type GetTicketInfoResponse = {
    departments: string[];
    is_captcha_enable: boolean;
    orders: {
        id: number;
        fk_service_id: number;
        service: {
            id: number;
            title: string;
        };
    }[];
};

export default {
    getTicketInfo: () => api.get<GetTicketInfoResponse>("panel/api/v1/tickets/create"),
    getTickets: (page: number, perPage: number) =>
        api.get<GetTicketsResponse>(`panel/api/v1/tickets?per_page=${perPage}&page=${page}`),
    getTicket: (id: number) => api.get<GetTicketResponse>(`panel/api/v1/tickets/show/${id}`),
    addTicket: (data: AddTicketRequest) => api.file("panel/tickets/store", data),
    replyTicket: (data: ReplyTicketRequest) => api.file(`panel/tickets/reply/${data.id}`, data),
};
