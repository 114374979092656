import { motion } from "framer-motion";

export type TabProps = {
    id: string;
    active: string;
    setActive: (active: string) => void;
    text: string;
    disabled?: boolean;
};

const Tab = ({ active, setActive, text, id, disabled }: TabProps) => {
    return (
        <button
            type="button"
            disabled={disabled}
            onClick={() => setActive(id)}
            className={`relative flex flex-1 ${
                disabled ? "grayscale" : "cursor-pointer"
            } items-center justify-center px-4 py-2`}
        >
            <div
                className={`z-20 whitespace-nowrap text-[0.85rem] xs:text-[1rem] ${
                    active === id ? "text-white contrast:text-primary" : "text-text-main"
                }`}
            >
                {text}
            </div>
            {active === id ? (
                <motion.div
                    className="absolute z-10 h-full w-full rounded-[8px] bg-primary contrast:bg-transparent contrast:border contrast:border-primary"
                    layoutId="tab-item"
                    transition={{ type: "spring", stiffness: 600, damping: 40 }}
                />
            ) : null}
        </button>
    );
};

export default Tab;
