import {useContext, useMemo} from "react";

import ThemeContrastDarkImage from "@/assets/images/theme-templates/theme-contrast-dark.svg";
import ThemeContrastLightImage from "@/assets/images/theme-templates/theme-contrast-light.svg";
import ThemeDefaultDarkImage from "@/assets/images/theme-templates/theme-default-dark.svg";
import ThemeDefaultLightImage from "@/assets/images/theme-templates/theme-default-light.svg";
import ThemeGreenDarkImage from "@/assets/images/theme-templates/theme-green-dark.svg";
import ThemeGreenLightImage from "@/assets/images/theme-templates/theme-green-light.svg";
import ThemeYellowDarkImage from "@/assets/images/theme-templates/theme-yellow-dark.svg";
import ThemeYellowLightImage from "@/assets/images/theme-templates/theme-yellow-light.svg";
import { THEMES } from "@/constants/common";
import useTheme, { Theme } from "@/hooks/useTheme";
import {Context, ContextType} from "@/store/Context-store";

type ThemeType = {
    id: Theme;
    title: string;
    image: any;
};

const Themes = () => {
    const { setIsSettingsChanged } = useContext(Context) as ContextType;
    const { theme, changeThemeTo } = useTheme();

    const themes: ThemeType[] = [
        {
            id: THEMES.DEFAULT_LIGHT,
            image: ThemeDefaultLightImage,
            title: "قالب روشن اول‌پرداخت",
        },
        {
            id: THEMES.YELLOW_LIGHT,
            image: ThemeYellowLightImage,
            title: "قالب زرد روشن",
        },
        {
            id: THEMES.GREEN_LIGHT,
            image: ThemeGreenLightImage,
            title: "قالب سبز روشن",
        },
        {
            id: THEMES.HIGH_CONTRAST_LIGHT,
            image: ThemeContrastLightImage,
            title: "قالب کانتراست روشن",
        },
        {
            id: THEMES.DEFAULT_DARK,
            image: ThemeDefaultDarkImage,
            title: "قالب تیره اول‌پرداخت",
        },
        {
            id: THEMES.YELLOW_DARK,
            image: ThemeYellowDarkImage,
            title: "قالب زرد تیره",
        },
        {
            id: THEMES.GREEN_DARK,
            image: ThemeGreenDarkImage,
            title: "قالب سبز تیره",
        },
        {
            id: THEMES.HIGH_CONTRAST_DARK,
            image: ThemeContrastDarkImage,
            title: "قالب کانتراست تیره",
        },
    ];

    const renderThemes = useMemo(() => {
        return themes.map(({ id, image: TemplateImage, title }) => (
            <label
                key={id}
                htmlFor={id}
                className={`bg-secondary-40 group h-fit cursor-pointer overflow-hidden rounded-[10px] border-2 transition-colors ${
                    theme === id ? "border-primary" : "border-secondary-100/50 hover:border-primary/50"
                }`}
            >
                <TemplateImage className="w-full" />
                <div className="flex items-center justify-between border-t-2 border-secondary-100/50 p-4">
                    <span className="text-text-main">{title}</span>
                    <input
                        onChange={(e) => {
                            changeThemeTo(e.target.value as Theme);
                            setIsSettingsChanged(true);
                        }}
                        value={id}
                        type="radio"
                        name="theme"
                        className="cursor-pointer"
                        checked={id === theme}
                        id={id}
                    />
                </div>
            </label>
        ));
    }, [theme]);

    return <div className="mt-6 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">{renderThemes}</div>;
};

export default Themes;
