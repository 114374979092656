import QuickAccessItem from "@/components/dashboard/main/QuickAccessItem";
import IconBitcoinSign from "@/components/svg/bitcoin-sign";
import IconCcVisa from "@/components/svg/cc-visa";
import IconDollarSign from "@/components/svg/dollar-sign";
import IconEllipsis from "@/components/svg/ellipsis";
import IconPaypal from "@/components/svg/paypal";
// import IconPerfectMoney from "@/components/svg/perfect-money";
// import IconWebmoney from "@/components/svg/webmoney";

const QuickAccess = () => {
    return (
        <div className="py-8 sm:py-12">
            <div className="grid w-full grid-cols-3 justify-items-center max-sm:gap-10 sm:grid-cols-6 md:grid-cols-7">

                <QuickAccessItem icon={IconPaypal} link="/panel/order/PAYPAL" text="خدمات پی پال" />
                
                {/* <QuickAccessItem
                    icon={IconPerfectMoney}
                    link="/panel/order/PERFECTMONEY"
                    text="پرفکت مانی"
                    iconClasses="h-[20px] sm:h-[24px]"
                /> */}
                <QuickAccessItem icon={IconCcVisa} link="/panel/order/VISA_MASTER" text="ویزا کارت" iconClasses="h-[18px] sm:h-[20px]" />
                {/* <QuickAccessItem icon={IconWebmoney} link="/panel/order/WEBMONEY" text="وب مانی" /> */}
                <QuickAccessItem icon={IconBitcoinSign} link="/panel/order/OTHER_COIN_BUY" text="کریپتو" />
                <QuickAccessItem icon={IconDollarSign} link="/panel/cash-income" text="فروش ارز به ما" />
                <QuickAccessItem icon={IconEllipsis} link="/panel/order" text="دیگر سفارش‌ها" className="max-md:hidden" />
            </div>
        </div>
    );
};

export default QuickAccess;
