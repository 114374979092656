import { useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";

import Modal from "@/components/ui/Modal";
import { GetNotificationsResponse } from "@/gate/panel";
import useMarkAsRead from "@/hooks/queries/useMarkAsRead";

type NoticesProps = {
    show: boolean;
    setShow: (show: boolean) => void;
    notices: GetNotificationsResponse["notices"]["notifications_users_items"];
    unreadCount: number;
};

const Notices = ({ show, setShow, notices, unreadCount }: NoticesProps) => {
    const queryClient = useQueryClient();
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const selectedNotice = searchParams.get("notice");

    const { mutateAsync: markAsRead } = useMarkAsRead();

    const markAsReadRequest = async (id: string) => {
        await markAsRead({ id });
        await queryClient.refetchQueries(["notifications"]);
    };

    const renderNotices = useMemo(() => {
        if (notices) {
            return notices?.map((notice) => {
                return (
                    <div className="-mx-6 border-b border-secondary-200 py-3 last-of-type:border-none" key={notice.id}>
                        <div className="flex gap-2 px-5">
                            <div className="mt-[6px] h-2 w-2 shrink-0 rounded-full bg-success" />
                            <div>
                                <Link
                                    to={{ search: `?notice=${notice.id}` }}
                                    onClick={() => {
                                        markAsReadRequest(String(notice.id));
                                        setShow(false);
                                    }}
                                    className="text-text-main hover:text-primary"
                                >
                                    {notice.title}
                                </Link>
                                <div className="flex gap-4">
                                    {/*<div className="mt-2 text-[0.85rem]">{notice.title}</div>*/}
                                    <div className="mt-2 text-[0.85rem] empty:hidden">{notice.created_at}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notices]);

    const selectedNoticeObject = useMemo(() => {
        return notices?.find((notice) => notice.id === Number(selectedNotice));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNotice]);

    return (
        <>
            <Modal
                show={show}
                onClose={() => setShow(false)}
                className="max-w-[500px]"
                title={
                    <div>
                        اطلاعیه‌ها <span className="text-primary">({unreadCount ?? ""})</span>
                    </div>
                }
            >
                {unreadCount && unreadCount > 0 ? (
                    <div className="min-h-[300px]">{renderNotices}</div>
                ) : (
                    <div className="flex min-h-[300px] items-center justify-center">اطلاعیه جدید وجود ندارد</div>
                )}
            </Modal>
            {selectedNoticeObject && selectedNoticeObject.content !== "" && (
                <Modal
                    title={selectedNoticeObject.title}
                    show={!!selectedNotice}
                    className="max-w-[1000px]"
                    onClose={() => {
                        navigate({ pathname: location.pathname, search: "" });
                        setShow(true);
                    }}
                >
                    <div dangerouslySetInnerHTML={{ __html: selectedNoticeObject.content }} />
                </Modal>
            )}
        </>
    );
};
//
export default Notices;
