import api from "../api";

type GeneralSettingsResponse = {
    key: string;
    value: string;
}[];

export default {
    generalSettings: () => api.get<GeneralSettingsResponse>("/panel/api/v1/settings/general"),
};
