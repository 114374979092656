import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { useRef } from "react";

import { defaultUser } from "@/data/Defaults";
import gate from "@/gate";
import { UserData } from "@/interfaces/dashboard/layout/panel/panel";

import useLocalStorage from "../useLocalStorage";

const useUser = () => {
    const [userData, setUserData] = useLocalStorage<UserData | object>("user_data", {});
    const userId = useRef<number>(0);

    return useQuery<unknown, Error, UserData>({
        queryFn: async () => {
            const user = await gate.user();
            setUserData(user as UserData);
            const u = user as UserData;
            Sentry.setUser({
                id: u?.data?.user?.id,
            });
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            if (window.clarity && !userId.current) {
                userId.current = u?.data?.user?.id;
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                window.clarity("set", "userId", userId.current.toString());
            }
            return user;
        },
        queryKey: ["userData"],
        initialData: Object.keys(userData).length !== 0 ? userData : defaultUser,
    });
};

export default useUser;
