type SettingItemProps = {
    id: number | string;
    value: any;
    setValue: (value: any) => void;
    children: React.ReactNode;
    className: string;
    name: string;
    subtitle : string;
};

const SettingItem = ({ id, children, className, setValue, value, name ,subtitle}: SettingItemProps) => {
    return (
        <label htmlFor={name + id} className="group flex flex-col items-center justify-center gap-4">
            <div
                className={`flex h-[60px] xs:h-[65px] sm:h-[80px] w-[50px] xs:w-[55px] sm:w-[70px] cursor-pointer items-center justify-center rounded-[8px] sm:rounded-[10px] border-2 transition-colors group-hover:border-primary ${
                    value == id ? "border-primary text-primary" : "border-secondary-100 text-secondary-100"
                }`}
            >
                <span className={className}>{children}</span>
            </div>
            <span className="text-[0.75rem] xs:text-[0.85rem] text-text-main">{subtitle}</span>
            <input
                hidden
                type="radio"
                id={name + id}
                name={name}
                value={id}
                onChange={(e) => setValue(e.target.value)}
            />
        </label>
    );
};

export default SettingItem;
