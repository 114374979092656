import { useEffect } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import FileInput from "@/components/ui/FileInput";
import Input from "@/components/ui/Input";
import Textarea from "@/components/ui/Textarea";
import { NUMBER_INPUT_FLOATING_POINT } from "@/constants/common";
import { CalculationRequestType, ServiceInstructionType, SubmitOrderType } from "@/interfaces/dashboard/order/order";
import { objectToArray } from "@/utils";

type OrderValidationSchema = {
    serviceId: number;
    amount: number | "";
    email: string;
    user_description: string | null | undefined;
    attachments?: Record<string, File>;
};

type SubmitOrderRequest = SubmitOrderType & {
    amount: number | "";
    meta: {
        email?: string;
    };
};

type ServiceInstruction = ServiceInstructionType & {
    onCalculation: (data: CalculationRequestType) => void;
    onFormSubmit: (data: SubmitOrderRequest, serviceId: number) => void;
};

const ChargeWebmoneyForm: React.FC<ServiceInstruction> = ({ instructions, onCalculation, onFormSubmit }) => {
    const [queryString] = useSearchParams();

    const { control, handleSubmit, resetField } = useForm<OrderValidationSchema>({
        defaultValues: {
            amount: queryString.get("amount") ? Number(queryString.get("amount")) : "",
            email: "",
            user_description: "",
        },
    });

    const [watchedAmount] = useWatch({
        control: control,
        name: ["amount"],
        defaultValue: {
            amount: 0,
        },
    });

    useEffect(() => {
        if (Number(watchedAmount) && +watchedAmount >= 0) {
            onCalculation({
                amount: +watchedAmount,
                serviceId: instructions.id,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchedAmount]);

    useEffect(() => {
        const amount = queryString.get("amount");
        onCalculation({
            amount: amount ? +amount : 0,
            serviceId: instructions.id,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submitHandler: SubmitHandler<OrderValidationSchema> = (data) => {
        onFormSubmit(
            {
                amount: data.amount,
                meta: {
                    email: data.email,
                },
                user_description: data.user_description,
                attachments: data.attachments ? objectToArray<File>(data.attachments) : undefined,
            },
            instructions.id,
        );
    };

    return (
        <form id="order-form" onSubmit={handleSubmit(submitHandler)}>
            <div className="flex flex-col gap-4">
                <div className="flex items-end gap-4">
                    <Input
                        ltr
                        containerClassName="w-full"
                        variant="fill"
                        required
                        topTitle="مبلغ سرویس/محصول"
                        inputMode="decimal"
                        type="number"
                        step={NUMBER_INPUT_FLOATING_POINT}
                        min={0}
                        placeholder="مبلغ سرویس/محصول"
                        control={control}
                        name="amount"
                        rules={{
                            required: "مبلغ سرویس/محصول الزامی است",
                        }}
                    />
                </div>
                <Input
                    containerClassName="w-full"
                    variant="fill"
                    topTitle="کیف پول wmz"
                    required
                    inputMode="text"
                    type="text"
                    placeholder="کیف پول wmz"
                    ltr
                    control={control}
                    name="email"
                    rules={{
                        required: "کیف پول wmz الزامی است",
                    }}
                />

                <Textarea
                    name="user_description"
                    control={control}
                    label="توضیحات"
                    placeholder="توضیحات خود را اینجا بنویسید"
                />
                <FileInput control={control} name="attachments" onReset={resetField} repeater />
            </div>
        </form>
    );
};
export default ChargeWebmoneyForm;
