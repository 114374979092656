import { useCallback, useEffect, useState } from "react";

import { getProjectWindow, injectScript, removeScript } from "@/utils/index";

const ERROR_SCRIPT_NOT_AVAILABLE = "Google recaptcha is not available";
const ERROR_NOT_A_WEB_BROWSER = "Running outside a web browser";

const GOOGLE_RECAPTCHA_V3_SCRIPT = "https://www.google.com/recaptcha/api.js";
const SCRIPT_ID = "google-recaptcha-v3";
const GOOGLE_RECAPTCHA_BADGE_CLASSNAME = ".grecaptcha-badge";

interface IGoogleReCaptchaOptions {
    loadOnStart?: boolean;
    action?: string;
}

interface IGoogleReCaptchaV3HookReturn {
    reCaptchaResponseToken?: string;
    executeReCaptcha: (action: string) => Promise<string>;
}

type TGoogleReCaptchaV3Hook = (options: IGoogleReCaptchaOptions) => IGoogleReCaptchaV3HookReturn;

const siteKey = import.meta.env.VITE_RECAPTCHA_SITE_KEY || "";

export const useGoogleReCaptchaV3: TGoogleReCaptchaV3Hook = ({ loadOnStart = false, action = "" }) => {
    const [responseToken, setResponseToken] = useState<string>();

    const executeReCaptcha = useCallback(async (action: string): Promise<string> => {
        const window = getProjectWindow();
        if (!window) {
            throw new Error(ERROR_NOT_A_WEB_BROWSER);
        }

        const { grecaptcha } = window;
        if (!grecaptcha) {
            throw new Error(ERROR_SCRIPT_NOT_AVAILABLE);
        }

        return new Promise((resolve) => {
            grecaptcha.ready(() => {
                grecaptcha.execute(siteKey, { action }).then((token: string) => resolve(token));
            });
        });
    }, []);

    const removeGReCaptchaDivElement = () => {
        const window = getProjectWindow();
        if (!window) {
            return;
        }

        const element = window.document.querySelector(GOOGLE_RECAPTCHA_BADGE_CLASSNAME);
        if (element && element.parentElement) {
            element.parentElement.remove();
        }
    };

    const onLoadInjectedScript = async () => {
        if (!loadOnStart) {
            return;
        }

        try {
            const token = await executeReCaptcha(action);
            setResponseToken(token);
        } catch (e) {
            console.warn(e);
        }
    };

    useEffect(() => {
        if (!siteKey) {
            return;
        }

        const window = getProjectWindow();
        if (window) {
            const scriptTag = window.document.getElementById(SCRIPT_ID);
            if (!scriptTag) {
                injectScript(SCRIPT_ID, `${GOOGLE_RECAPTCHA_V3_SCRIPT}?render=${siteKey}&hl=fa}`, onLoadInjectedScript);
            }
        }

        return () => {
            removeScript(SCRIPT_ID);
            removeGReCaptchaDivElement();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { executeReCaptcha, reCaptchaResponseToken: responseToken };
};

export default useGoogleReCaptchaV3;
