import { lazy, Suspense } from "react";
import { RouteObject } from "react-router-dom";

const BrokerLayout = lazy(() => import("@/pages/broker/Layout/BrokerLayout"));
const BrokerRegister = lazy(() => import("@/pages/broker/BrokerRegister"));
const BrokerBaseAuthentication = lazy(() => import("@/pages/broker/BrokerBaseAuthentication"));
const BrokerNationalCard = lazy(() => import("@/pages/broker/BrokerNationalCard"));
const BrokerSelfie = lazy(() => import("@/pages/broker/BrokerSelfie"));
const BrokerInvoice = lazy(() => import("@/pages/broker/BrokerInvoice"));

const BrokerRoutes: RouteObject[] = [
    {
        path: "broker",
        element: <BrokerLayout />,
        children: [
            {
                path: "register",
                element: (
                    <Suspense>
                        <BrokerRegister />
                    </Suspense>
                ),
            },
            {
                path: "base-authentication",
                element: (
                    <Suspense>
                        <BrokerBaseAuthentication />
                    </Suspense>
                ),
            },
            {
                path: "national-card",
                element: (
                    <Suspense>
                        <BrokerNationalCard />
                    </Suspense>
                ),
            },
            {
                path: "selfie",
                element: (
                    <Suspense>
                        <BrokerSelfie />
                    </Suspense>
                ),
            },
        ],
    },
    {
        path: "broker/invoice",
        element: (
            <Suspense>
                <BrokerInvoice />
            </Suspense>
        ),
    },
];




export default BrokerRoutes;
