import {cashStatus} from "@/utils/cases";

import AlertBox from "../ui/AlertBox";

type CashAlertProps = {
    serviceType: string;
    status:
        | "در انتظار صدور فاکتور"
        | "در انتظار تاییدیه ویدیویی"
        | "در انتظار تاییدیه ویدیویی (اتوماتیک)"
        | "در انتظار پردازش"
        | "در انتظار تسویه"
        | "رد شده"
        | "در انتظار پرداخت"
        | "انجام شده "
        | "اتوماتیک";

    cashType?: "ADD_TO_ACCOUNT_BALANCE" | "REFUND";
};

const CashAlert: React.FC<CashAlertProps> = ({ serviceType, status, cashType }) => {
    const alerts = [
        {
            title: "در انتظار صدور فاکتور",
            description: `درخواست شما با موفقیت ثبت شده است، کارشناسان ما در حال بررسی و صدور فاکتور ${serviceType} برای شما هستند، از صبر و شکیبایی شما متشکریم.`,
        },
        {
            title: "در انتظار تاییدیه ویدیویی",
            description:
                'انجام دهید. پس از تایید ویدیوی ارسالی، اعلام پرداخت شما در وضعیت "در انتظار بررسی و صدور فاکتور نهایی" قرار خواهد گرفت.',
        },
        {
            title: "در انتظار تاییدیه ویدیویی (اتوماتیک)",
            description:
                'انجام دهید. پس از تایید ویدیوی ارسالی، اعلام پرداخت شما در وضعیت "در انتظار بررسی و صدور فاکتور نهایی" قرار خواهد گرفت.',
        },
        {
            title: "در انتظار پردازش",
            description:
                "اعلام پرداخت شما ارسال شد. پس از بررسی و تایید میزان ارز انتقال داده شده و صدور فاکتور نهایی، درخواست شما در صف انتظار تسویه تیم مالی قرار می‌گیرد. از صبر و شکیبایی شما متشکریم.",
        },
        {
            title: "در انتظار تسویه",
            description: `درخواست نقد شما با موفقیت انجام شد. تیم مالی پس از بررسی، مبلغ فوق را
                        ${
                            cashType == "ADD_TO_ACCOUNT_BALANCE"
                                ? " به موجودی حساب کاربری شما در سایت اضافه خواهند کرد. "
                                : " به حساب بانکی شما انتقال می‌دهند."
                        }`,
        },
        { title: "رد شده", description: "" },
        { title: "در انتظار پرداخت", description: "" },
        {
            title: "انجام شده ",
            description: `درخواست نقد درآمد شما انجام شده و مبلغ فاکتور${
                cashType == "ADD_TO_ACCOUNT_BALANCE"
                    ? " به موجودی حساب کاربری شما در سایت افزوده شده "
                    : " به حساب بانکی شما انتقال داده شده "
            } است.`,
        },
        { title: "اتوماتیک", description: "" },
    ];

    const statusDetails = cashStatus(status);

    const alert = alerts.find((alert) => {
        return alert.title == status;
    });

    if (alert?.description) {
        return (
            <AlertBox
                description={alert?.description || ""}
                customColor={statusDetails.color}
            />
        );
    } else {
        return <></>;
    }
};
export default CashAlert;
