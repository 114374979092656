import Skeleton from "react-loading-skeleton";

const PerfectmoneyVoucherCashPlaceholder = () => {
    return (
        <div className="flex flex-col gap-3">
            <Skeleton width={135} />
            <Skeleton height={40} style={{ borderRadius: "10px" }} />

            <Skeleton width={190} />
            <Skeleton height={40} style={{ borderRadius: "10px" }} />

            <Skeleton width={40} />
            <Skeleton height={40} style={{ borderRadius: "10px" }} />

            <div className="flex flex-col gap-2">
                <Skeleton width={50} />
                <Skeleton height={80} style={{ borderRadius: "10px" }} />
            </div>

            <Skeleton width={320} />
            <Skeleton height={40} style={{ borderRadius: "8px" }} />
        </div>
    );
};

export default PerfectmoneyVoucherCashPlaceholder;
