import classNames from "classnames";

import toastError from "@/components/error/ToastError";
import BoxContainer from "@/components/ui/BoxContainer";

type WalletActionButtonProps = {
    color: "success" | "primary";
    text: string;
    icon: any;
    disabled: boolean;
    onClick: () => void;
    disabledMessage?: string;
};

const WalletActionButton: React.FC<WalletActionButtonProps> = ({
    color,
    text,
    icon: Icon,
    disabled,
    onClick,
    disabledMessage,
}) => {
    const classes = classNames({
        "group flex-1 border-b-2": 1,
        "border-b-success cursor-pointer": !disabled && color === "success",
        "border-b-primary cursor-pointer": !disabled && color === "primary",
    });

    const showDisabledMessage = () => {
        if (disabledMessage) {
            toastError(new Error(disabledMessage));
        }
    };

    return (
        <BoxContainer
            className={classes}
            colorBox={false}
            staticColorBox={!disabled}
            staticColorBoxPosition="bottom-center"
            staticColorBoxColor={color === "success" ? "bg-success" : "bg-primary"}
            onClick={!disabled ? onClick : showDisabledMessage}
        >
            <div
                className={`flex h-full w-full items-center justify-center gap-4 max-xs:flex-col-reverse md:gap-6 lg:gap-8 ${
                    disabled && "grayscale"
                }`}
            >
                <span className="whitespace-nowrap text-[1.14rem] font-light text-text-main md:text-[1.42rem] lg:text-[1.71rem]">
                    {text}
                </span>
                <Icon
                    className={`h-[30px] shrink-0 md:h-[40px] lg:h-[50px] ${
                        color === "success" ? "fill-success" : "fill-primary"
                    }`}
                />
            </div>
        </BoxContainer>
    );
};

export default WalletActionButton;
