import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";

import ToastError from "@/components/error/ToastError";
import { TWO_FA } from "@/constants/common";
import gate from "@/gate";
import { LoginRequest, LoginResponse } from "@/interfaces/auth";

import useAuth from "../useAuth";

const useLogin = (setIsLoading: (isLoading: boolean) => void) => {
    const { setAuthToken } = useAuth();
    const navigate = useNavigate();
    const [params] = useSearchParams();

    return useMutation<LoginResponse, Error, LoginRequest>({
        mutationFn: gate.login,
        onSuccess(response) {
            if (response?.tfa_type) {
                const isMobileTwoFA = response?.tfa_type == TWO_FA.MOBILE;

                navigate(`/auth/2fa-validate${params.has("redirect") ? `?redirect=${params.get("redirect")}` : ""}`, {
                    state: {
                        twoFAType: isMobileTwoFA ? TWO_FA.MOBILE : TWO_FA.GOOGLE,
                        email: response.email,
                    },
                    replace: true,
                });
            } else {
                toast.success("خوش آمدید!");
                if (response.token) {
                    setAuthToken(response?.token);
                }

                navigate(params.has("redirect") ? params.get("redirect") || "/panel" : "/panel", { replace: true });
            }
        },
        onError(errors) {
            ToastError(errors);
        },
        onSettled() {
            setIsLoading(false);
        },
    });
};

export default useLogin;
