import { yupResolver } from "@hookform/resolvers/yup";
import { Helmet } from "react-helmet-async";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { InferType, object, ref, string } from "yup";

import Button from "@/components/auth/Button";
import Input from "@/components/auth/Input";
import Spinner from "@/components/loader/Spinner";
import {
    CONFIRM_PASSWORD_REQUIRED_MESSAGE,
    EMAIL_REQUIRED_MESSAGE,
    INVALID_CONFIRM_PASSWORD_MESSAGE,
    INVALID_EMAIL_MESSAGE,
    PASSWORD_REQUIRED_MESSAGE,
    PASSWORD_VALIDATION_MESSAGE,
} from "@/constants/InavlidMessages";
import useResetPassword from "@/hooks/queries/useResetPassword";
import { PASSWORD_VALIDATION } from "@/lib/validations";

const resetPasswordSchema = object({
    email: string().required(EMAIL_REQUIRED_MESSAGE).email(INVALID_EMAIL_MESSAGE),
    password: string().required(PASSWORD_REQUIRED_MESSAGE).matches(PASSWORD_VALIDATION, PASSWORD_VALIDATION_MESSAGE),
    confirmPass: string()
        .oneOf([ref("password"), undefined], INVALID_CONFIRM_PASSWORD_MESSAGE)
        .required(CONFIRM_PASSWORD_REQUIRED_MESSAGE),
}).required();

type ResetPasswordValidationSchema = InferType<typeof resetPasswordSchema>;

const ResetPassword = () => {
    const params = useParams();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ResetPasswordValidationSchema>({
        resolver: yupResolver(resetPasswordSchema),
    });

    const { mutate: resetPassword, isLoading } = useResetPassword();

    const authenticateHandler: SubmitHandler<ResetPasswordValidationSchema> = (data) => {
        resetPassword({
            token: params.token,
            email: data.email,
            password: data.password,
            confirmPass: data.confirmPass,
        });
    };

    return (
        <>
            <Helmet>
                <title>ورود با رمز عبور</title>
            </Helmet>
            <form onSubmit={handleSubmit(authenticateHandler)}>
                <h2 className="mb-8 text-white">رمز عبور جدید خود را وارد کنید.</h2>
                <div className="mx-auto my-4 flex w-10/12 flex-col gap-4">
                    {errors.email && (
                        <span className="mr-0 block pt-1 text-right text-xs text-red-500">{errors.email?.message}</span>
                    )}
                    <Input
                        dir="ltr"
                        placeholder="ایمیل"
                        type="email"
                        // required
                        autoFocus
                        autoComplete="email"
                        className={`${errors.email && "border-red-500"}`}
                        {...register("email")}
                    />
                    {errors.password && (
                        <span className="mr-0 block pt-1 text-right text-xs text-red-500">
                            {errors.password?.message}
                        </span>
                    )}
                    <Input
                        dir="ltr"
                        placeholder="رمز عبور جدید"
                        type="password"
                        // required
                        autoComplete="new-password"
                        className={`${errors.password && "border-red-500"}`}
                        {...register("password")}
                    />
                    {errors.confirmPass && (
                        <span className="mr-0 block pt-1 text-right text-xs text-red-500">
                            {errors.confirmPass?.message}
                        </span>
                    )}
                    <Input
                        dir="ltr"
                        placeholder="تکرار رمز عبور جدید"
                        type="password"
                        // required
                        autoComplete="new-password"
                        className={`${errors.confirmPass && "border-red-500"}`}
                        {...register("confirmPass")}
                    />
                </div>
                <Button disabled={isLoading} className="mx-auto mt-4 w-10/12 rounded-3xl">
                    {isLoading ? <Spinner /> : "تغییر رمز عبور"}
                </Button>
            </form>
        </>
    );
};

export default ResetPassword;
