export enum THEMES {
    DEFAULT_DARK = "default-dark",
    DEFAULT_LIGHT = "default-light",
    YELLOW_DARK = "yellow-dark",
    YELLOW_LIGHT = "yellow-light",
    GREEN_DARK = "green-dark",
    GREEN_LIGHT = "green-light",
    HIGH_CONTRAST_DARK = "high-contrast-dark",
    HIGH_CONTRAST_LIGHT = "high-contrast-light",
}

export enum PATTERNS {
    PATTERN_NONE = "pattern-none",
    PATTERN_1 = "pattern-1",
    PATTERN_2 = "pattern-2",
}

export const TWO_FA = {
    MOBILE: "MOBILE",
    GOOGLE: "GOOGLE",
};

export const ONLINE_PAYMENT_LIMIT = 25_000_000;

export const NUMBER_INPUT_FLOATING_POINT = 0.000001;

export const POL_TRANSFER_LIMIT = 15_000_000;

export const BRIDGE_SUPPORT_BANKS = [
    "سامان",
    "آینده",
    "ملی",
    "صادرات ایران",
    "دی",
    "ایران زمین",
    "اقتصاد نوین",
    "سرمایه",
    "پاسارگاد",
    "قرض‌الحسنه رسالت",
    "گردشگری",
    "توسعه تعاون",
    "معاملات ریالی",
    "خاورمیانه",
    "شهر",
    "صنعت و معدن",
    "کشاورزی",
    "ایران – ونزوئلا",
    "سینا",
    "قرض‌الحسنه مهر ایران",
    "پارسیان",
    "پست بانک",
    "مسکن",
];

export const IMMEDIATE_WITHDRAW_COST = 25_000;
export const IMMEDIATE_WITHDRAW_BANKS = [
    "بانک ملی ایران",
    "بانک صادرات ایران",
    "بانک پاسارگاد",
    "بانک ملت",
    "بانک کشاورزی",
    "بانک تجارت",
    "بانک پارسیان",
    "بانک اقتصاد نوین",
    "بانک سامان",
    "بانک آینده",
    "بانک خاورمیانه",
    "بانک قرض الحسنه رسالت",
    "بانک شهر",
    "بانک کارآفرین",
];
