import { useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

import Notices from "@/components/dashboard/header/Notices";
import Notifications from "@/components/dashboard/header/Notifications";
import IconBarsStroke from "@/components/svg/bars-stroke";
import IconBell from "@/components/svg/bell";
import IconEnvelope from "@/components/svg/envelope";
import IconHeadsetStroke from "@/components/svg/headset-stroke";
import IconLogo from "@/components/svg/logo";
import IconMoon from "@/components/svg/moon";
import IconSun from "@/components/svg/sun";
import IconUniversalAccess from "@/components/svg/universal-access";
import Avatar from "@/components/ui/Avatar";
import ClubPoints from "@/components/ui/ClubPoints";
import api from "@/gate/api";
import useNotifications from "@/hooks/queries/useNotifications";
import useSyncThemeSettings from "@/hooks/queries/useSyncThemeSettings";
import useUser from "@/hooks/queries/useUser";
import useTheme from "@/hooks/useTheme";
import { Context, ContextType } from "@/store/Context-store";
import { loadGoftinoScript } from "@/utils";

const DashboardHeader: React.FC = () => {
    const { data: user } = useUser();
    const [showNotices, setShowNotices] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);
    const [isGoftinoReady, setIsGoftinoReady] = useState(false);
    const [isGoftinoLoaded, setIsGoftinoLoaded] = useState(false);
    const [isGoftinoOpen, setIsGoftinoOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const timer = useRef<null | number>(null);

    const scrollPosRef = useRef(0);

    const { setIsSidebarExpanded, showThemeButton } = useContext(Context) as ContextType;
    const { isThemeDark, theme, changeThemeTo } = useTheme();
    const { syncThemeFunction } = useSyncThemeSettings();

    const { data: notificationsData, refetch: refetchNotifictions } = useNotifications();

    const setGotfinoWidget = async () => {
        setIsGoftinoReady(true);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        Goftino.setWidget({
            hasIcon: false,
            counter: "#goftino-counter",
        });
    };

    useEffect(() => {
        const goftinoOpenEvent = function () {
            setIsGoftinoOpen(true);
        };

        const goftinoCloseEvent = function () {
            setIsGoftinoOpen(false);
        };

        const goftinoNewMessageEvent = function (event: any) {
            if (isGoftinoOpen) return;

            toast.dismiss();
            toast(
                (toastInstance) => (
                    <div
                        className="flex cursor-pointer justify-center gap-4"
                        title="باز کردن چت پشتیانی"
                        onClick={() => {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            Goftino.open();
                            toast.dismiss(toastInstance.id);
                        }}
                    >
                        <div className="flex flex-col">
                            <div className="relative flex h-[40px] w-[40px] items-center justify-center rounded-full bg-secondary-100/50 text-text-main after:absolute after:bottom-1 after:left-1 after:h-2 after:w-2 after:rounded-full after:bg-green-400">
                                <IconLogo className="w-[50%]" />
                            </div>
                        </div>
                        <div>
                            <div className="mt-1 font-semibold text-text-main">پیام از طرف پشتیبانی</div>
                            <p className="mt-3 line-clamp-3 overflow-hidden leading-7 text-text-paragraph">
                                {event.detail.content}
                            </p>
                        </div>
                    </div>
                ),
                { duration: Infinity, className: "!bg-secondary-400 dark:!bg-secondary-200" },
            );
        };

        if (isGoftinoLoaded) {
            window.addEventListener("goftino_ready", setGotfinoWidget);

            window.addEventListener("goftino_openWidget", goftinoOpenEvent);

            window.addEventListener("goftino_closeWidget", goftinoCloseEvent);

            window.addEventListener("goftino_getMessage", goftinoNewMessageEvent);
        } else {
            loadGoftinoScript();

            setIsGoftinoLoaded(true);
        }

        return () => {
            window.removeEventListener("goftino_openWidget", goftinoOpenEvent);
            window.removeEventListener("goftino_closeWidget", goftinoCloseEvent);
            window.removeEventListener("goftino_getMessage", goftinoNewMessageEvent);
        };
    }, [isGoftinoLoaded, isGoftinoOpen]);

    useEffect(() => {
        if (document.getElementById("btn-support")) {
            document.getElementById("btn-support")?.addEventListener("click", function () {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                Goftino.open();
            });
        }
    }, [isGoftinoReady]);

    useEffect(() => {
        if (isGoftinoReady) {
            if (!user.data.user.online_chat_token) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                Goftino.getUser(function (goftinoData) {
                    if (
                        goftinoData.status == "success" &&
                        (goftinoData.phone == "" || goftinoData.phone == user.data.user.mobile)
                    ) {
                        api.post("/panel/profile/chat/token/set", {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            online_chat_token: Goftino.getUserId(),
                        });
                    } else {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        Goftino.unsetUserId();
                    }
                });
            } else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                Goftino.setUserId(user.data.user.online_chat_token);
            }

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            Goftino.setUser({
                email: user.data.user.email,
                name: user.data.user.name,
                phone: user.data.user.mobile,
                avatar: user.data.avatar,
                about: "https://avalpardakht.com/about",
                tags: "AvalPardatkht",
                forceUpdate: true,
            });
        }
    }, [user, isGoftinoReady]);

    useEffect(() => {
        const scrollEvent = () => {
            if (document.body.getBoundingClientRect().top < 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
            // saves the new position for iteration.
            scrollPosRef.current = document.body.getBoundingClientRect().top;
        };

        window.addEventListener("scroll", scrollEvent);

        return () => {
            window.removeEventListener("scroll", scrollEvent);
        };
    }, []);

    return (
        <>
            <div
                className={`sticky top-0 z-[998] justify-center px-3 transition-all duration-300 xs:px-8 ${
                    isScrolled ? "pt-0" : "pt-6"
                }`}
            >
                <div
                    className={`flex w-full justify-between bg-secondary-400 px-4 duration-300 contrast:border ${
                        isScrolled
                            ? "rounded-ee-[10px] rounded-es-[10px] py-4 shadow-[0_1px_50px_rgba(0_0_0_/_0.2)] dark:shadow-[0_1px_30px_rgba(0_0_0_/_0.5)]"
                            : "rounded-[10px] py-2 shadow-main"
                    } xs:px-6`}
                >
                    <div className="flex items-center gap-4">
                        <button
                            onClick={() => setIsSidebarExpanded(true)}
                            className="fill-slate-800 transition-all hover:fill-black dark:fill-slate-300 dark:hover:fill-white min-[1200px]:hidden"
                        >
                            <IconBarsStroke className="h-[22px]" />
                        </button>
                        <Link to="/panel/profile#profile" className="flex items-center gap-3 text-text-main">
                            <Avatar picture={user?.data?.avatar} className="!w-[30px] max-[350px]:hidden" />

                            <div className="hidden flex-col overflow-hidden text-ellipsis whitespace-nowrap max-xs:text-[0.85rem] min-[576px]:flex">
                                <span className="">{user?.data?.user.name}</span>
                            </div>
                        </Link>
                        <ClubPoints className="max-[576px]:hidden" />
                    </div>

                    <div className="flex items-center justify-end gap-5">
                        {isGoftinoReady && (
                            <button
                                type="button"
                                id="btn-support"
                                className="relative flex items-center gap-2 rounded-[40px] bg-secondary-250 fill-text-main px-3 py-2 text-text-main transition-all hover:bg-secondary-200 active:scale-90 contrast:border contrast:bg-transparent max-xs:py-[6px] max-xs:text-[0.85rem]"
                            >
                                <IconHeadsetStroke className="animate-vibrate h-[18px] xs:h-[20px]" />
                                پشتیبانی
                                <div
                                    className="absolute right-[-11px] top-[-8px] z-10 flex aspect-square w-[22px] items-center justify-center rounded-full bg-error text-[0.71rem] text-white"
                                    id="goftino-counter"
                                ></div>
                            </button>
                        )}

                        <button
                            onClick={() => setShowNotices(true)}
                            className="relative fill-slate-800 transition-all hover:fill-black active:scale-90 dark:fill-slate-300 dark:hover:fill-white"
                        >
                            <IconEnvelope className="h-[22px]" />
                            {notificationsData && notificationsData.noticesUnreadCount !== 0 && (
                                <div className="absolute right-[2px] top-[2px] flex items-center justify-center">
                                    <div className="absolute z-10 flex aspect-square w-[22px] items-center justify-center rounded-full bg-error text-[0.71rem] text-white">
                                        <span className="inline-block" dir="ltr">
                                            {notificationsData.noticesUnreadCount > 9
                                                ? "+9"
                                                : notificationsData.noticesUnreadCount}
                                        </span>
                                    </div>
                                    <div className="absolute h-[20px] w-[20px] animate-ping-slow rounded-full bg-error" />
                                </div>
                            )}
                        </button>

                        <button
                            onClick={() => setShowNotifications(true)}
                            className="relative fill-slate-800 transition-all hover:fill-black hover:brightness-125 active:scale-90 dark:fill-slate-300 dark:hover:fill-white"
                        >
                            <IconBell className="h-[22px]" />
                            {notificationsData && notificationsData.notificationsUnreadCount !== 0 && (
                                <div className="absolute right-[2px] top-[2px] flex items-center justify-center">
                                    <div className="absolute z-10 flex aspect-square w-[22px] items-center justify-center rounded-full bg-error text-[0.71rem] text-white">
                                        <span className="inline-block">
                                            {notificationsData.notificationsUnreadCount > 9
                                                ? "9+"
                                                : notificationsData.notificationsUnreadCount}
                                        </span>
                                    </div>
                                    <div className="absolute h-[20px] w-[20px] animate-ping-slow rounded-full bg-error" />
                                </div>
                            )}
                        </button>

                        {showThemeButton ? (
                            <button
                                onClick={() => {
                                    const activeTheme = isThemeDark
                                        ? theme.replace("dark", "light")
                                        : theme.replace("light", "dark");
                                    changeThemeTo(activeTheme as any);

                                    if (timer.current) clearTimeout(timer.current);
                                    timer.current = setTimeout(() => {
                                        syncThemeFunction({
                                            systemTheme: false,
                                            theme: activeTheme,
                                        });
                                    }, 2000);
                                }}
                                className="flex items-center justify-start fill-slate-800 transition-all hover:fill-black active:scale-90 dark:fill-slate-300 dark:hover:fill-white"
                                title="تغییر تم وبسایت"
                            >
                                {isThemeDark ? (
                                    <IconSun className="fill-text-main/70 w-[21px] hover:fill-text-main" />
                                ) : (
                                    <IconMoon className="fill-text-main/70 w-[18px] hover:fill-text-main" />
                                )}
                            </button>
                        ) : (
                            <Link
                                to="/panel/appearance#profile"
                                className="flex items-center justify-start fill-slate-800 transition-all hover:fill-black active:scale-90 dark:fill-slate-300 dark:hover:fill-white"
                                title="تنظیمات دسترسی پذیری"
                            >
                                <IconUniversalAccess className="h-[22px]" />
                            </Link>
                        )}

                        {notificationsData && notificationsData.notifications && (
                            <Notifications
                                show={showNotifications}
                                setShow={(value) => {
                                    setShowNotifications(value);
                                    refetchNotifictions();
                                }}
                                notifications={notificationsData.notifications}
                                unreadCount={notificationsData.notificationsUnreadCount}
                            />
                        )}
                        {notificationsData && notificationsData.notices && (
                            <Notices
                                show={showNotices}
                                setShow={(value) => {
                                    setShowNotices(value);
                                    refetchNotifictions();
                                }}
                                notices={notificationsData.notices}
                                unreadCount={notificationsData.noticesUnreadCount}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default DashboardHeader;
