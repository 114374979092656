import { motion } from "framer-motion";
import { Control, useWatch } from "react-hook-form";

import { EMAIL_VALIDATION } from "@/lib/validations";

import CustomRadio from "../ui/CustomRadio";
import Input from "../ui/Input";
import { OrderValidationSchema } from "./forms/BuyForm";

type BuyFormAccountRequirementType = {
    slug: string;
    control: Control<OrderValidationSchema>;
};

const ARONGROUPS_CHARGE = "arongroups-charge";

const BuyFormAccountRequirement: React.FC<BuyFormAccountRequirementType> = ({ slug, control }) => {
    const [watchedAccount] = useWatch({
        control,
        name: ["account"],
        defaultValue: {
            account: slug == ARONGROUPS_CHARGE ? "بله" : "خیر",
        },
    });

    return (
        <div className="flex flex-col gap-4">
            <span className="text-text-main">آیا برای پرداخت باید به حساب کاربری شما وارد شویم؟</span>
            <div className="flex gap-8">
                <CustomRadio
                    control={control}
                    label="خیر"
                    name="account"
                    checked={watchedAccount == "خیر"}
                    disabled={slug == ARONGROUPS_CHARGE}
                    value="خیر"
                />
                <CustomRadio
                    control={control}
                    label="بله"
                    name="account"
                    checked={watchedAccount == "بله"}
                    disabled={slug == ARONGROUPS_CHARGE}
                    value="بله"
                />
            </div>
            <motion.div
                className={`${watchedAccount == "خیر" && "h-0 overflow-hidden"}`}
                initial={{
                    height: watchedAccount == "بله" ? "auto" : "0",
                }}
                animate={{
                    height: watchedAccount == "بله" ? "auto" : "0",
                }}
                transition={{ duration: 0.15 }}
            >
                <div className="flex flex-col gap-4">
                    <Input
                        containerClassName="w-full"
                        variant="fill"
                        topTitle={slug == ARONGROUPS_CHARGE ? "ایمیل آرون گروپس" : "نام کاربری"}
                        inputMode={slug == ARONGROUPS_CHARGE ? "email" : "text"}
                        placeholder={slug == ARONGROUPS_CHARGE ? "ایمیل آرون گروپس" : "نام کاربری"}
                        ltr
                        autoComplete="off"
                        control={control}
                        name="user_account_username"
                        rules={{
                            required:
                                watchedAccount == "بله"
                                    ? `${slug == ARONGROUPS_CHARGE ? "ایمیل آرون گروپس" : "نام کاربری"} الزامی است`
                                    : false,
                            pattern:
                                slug == ARONGROUPS_CHARGE
                                    ? {
                                          value: EMAIL_VALIDATION,
                                          message: "ایمیل آرون گروپس خود را وارد کنید",
                                      }
                                    : undefined,
                        }}
                    />
                    <Input
                        containerClassName="w-full"
                        variant="fill"
                        topTitle="رمز عبور"
                        inputMode="text"
                        placeholder="رمز عبور"
                        ltr
                        autoComplete="off"
                        control={control}
                        name="user_account_password"
                        rules={{
                            required: watchedAccount == "بله" ? "رمز عبور الزامی است" : false,
                        }}
                    />
                </div>
            </motion.div>
        </div>
    );
};
export default BuyFormAccountRequirement;
