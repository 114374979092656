import { useMemo } from "react";

import IconCircleCheckDuotone from "@/components/svg/circle-check-duotone";
import IconCircleExclamation from "@/components/svg/circle-exclamation";
import IconCircleXmarkDuotone from "@/components/svg/circle-xmark-duotone";

type SlideSection = {
    children: React.ReactNode;
    title: string;
    subTitle?: string;
    className?: string;
    containerClasses?: string;
    separator?: boolean;
    subTitleStatus?: "" | "info" | "success" | "error" | "warning";
};

const SlideSection: React.FC<SlideSection> = ({
    title,
    subTitle,
    children,
    className,
    separator,
    containerClasses = "w-full",
    subTitleStatus = "info",
}) => {
    const statusIcon = useMemo(() => {
        switch (subTitleStatus) {
            case "info":
                return <IconCircleExclamation className="h-[16px] -translate-y-px fill-primary" />;
            case "success":
                return <IconCircleCheckDuotone className="h-[16px] -translate-y-px fill-success" />;
            case "error":
                return <IconCircleXmarkDuotone className="h-[16px] -translate-y-px fill-error" />;
            case "warning":
                return <IconCircleExclamation className="h-[16px] -translate-y-px fill-warning" />;
            default:
                return <></>;
        }
    }, [subTitleStatus]);

    const statusTextColor = useMemo(() => {
        switch (subTitleStatus) {
            case "info":
                return "text-primary";
            case "success":
                return "text-success";
            case "error":
                return "text-error";
            case "warning":
                return "text-warning";
            default:
                return "text-text-paragraph";
        }
    }, [subTitleStatus]);

    return (
        <div className={"group flex flex-col items-start justify-start " + containerClasses}>
            <div
                className={`w-full ${
                    separator && "xs:border-r"
                } flex items-start justify-start gap-2 border-b border-secondary-200 px-4 pb-3 pt-5 max-sm:flex-col sm:items-center md:px-6`}
            >
                <h2 className="text-[1.28rem] font-semibold text-text-main xs:text-[1.42rem]">{title}</h2>
                {subTitle && (
                    <span
                        className={`${statusTextColor} flex items-center justify-start gap-[6px] text-[0.85rem] xs:text-[1rem]`}
                    >
                        ( {statusIcon} {subTitle} )
                    </span>
                )}
            </div>
            <div className={`${separator && "xs:border-r"} w-full h-full border-secondary-200 px-4 py-4 md:px-6 lg:min-h-[450px] ` + className}>
                {children}
            </div>
        </div>
    );
};

export default SlideSection;
