import { useEffect } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

import background from "@/assets/images/aval.jpg";
import IconLogoDark from "@/components/svg/logo-dark";
import IconLogoWhite from "@/components/svg/logo-white";
import useAuth from "@/hooks/useAuth";
import useTheme from "@/hooks/useTheme";

import classes from "./AuthLayout.module.css";

const AuthLayout = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { token } = useAuth();
    const { isThemeDark } = useTheme();

    useEffect(() => {
        if (pathname != "/auth/logout" && token) {
            navigate("/panel", { replace: true });
            return;
        }

        if (pathname === "/auth" || pathname === "/auth/") {
            navigate("/auth/login", { replace: true });
        }
    }, [pathname, navigate, token]);

    return (
        <div className="flex min-h-screen items-stretch">
            <div className="flex w-full items-center justify-center md:w-6/12">
                <div className="w-11/12 py-8 md:w-8/12">
                    {location.pathname != "/auth/logout" && (
                        <div className="my-4 flex">
                            <Link
                                to="/"
                                className={`logo flex ${location.pathname === "/auth/2fa-validate" ? "mx-auto" : ""}`}
                            >
                                {isThemeDark ? (
                                    <IconLogoWhite className="mx-auto w-64 drop-shadow-md" key={1} />
                                ) : (
                                    <IconLogoDark className="mx-auto w-64 drop-shadow-md" key={2} />
                                )}
                            </Link>
                        </div>
                    )}
                    <div
                        className={`flex w-full flex-col items-center py-6 md:items-start ${
                            isThemeDark ? classes.auth : classes.authLight
                        }`}
                    >
                        <Outlet />
                    </div>
                    <div className="mx-3">
                        {(location.pathname == "/auth/login" || location.pathname == "/auth/register") &&
                            (location.pathname == "/auth/login" ? (
                                <>
                                    <span>حساب کاربری ندارید؟</span>{" "}
                                    <Link to={"register"} className="text-primary hover:text-primary">
                                        ثبت نام کنید.
                                    </Link>
                                </>
                            ) : (
                                <>
                                    <span>حساب کاربری دارید؟</span>{" "}
                                    <Link to={"login"} className="text-primary hover:text-primary">
                                        وارد شوید.
                                    </Link>
                                </>
                            ))}
                    </div>
                </div>
            </div>
            <div
                className="hidden flex-shrink-0 md:flex md:w-6/12"
                style={{
                    backgroundImage: `url('${background}')`,
                    backgroundAttachment: "scroll",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                }}
            ></div>
        </div>
    );
};

export default AuthLayout;
