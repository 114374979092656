import { useContext } from "react";
import { Link } from "react-router-dom";

import IconStarSolid from "@/components/svg/star-solid";
import useUser from "@/hooks/queries/useUser";
import { Context, ContextType } from "@/store/Context-store";
import { formatNumber } from "@/utils";

type ClubPointsProps = {
    className?: string;
    linked?: boolean;
};

const ClubPoints = ({ className, linked = true }: ClubPointsProps) => {
    const { setIsSidebarExpanded } = useContext(Context) as ContextType;
    const { data: user } = useUser();

    const classes = `${className} items-center gap-1 flex items-center rounded-full bg-gold bg-opacity-10 px-3 py-1 text-[0.78rem] text-text-paragraph`;

    const content = (
        <>
            <span className="translate-y-[2px]">{formatNumber(user.data.user.avalClubPoint ?? 0)}</span>
            <IconStarSolid className="h-[10px] fill-gold" />
        </>
    );

    return user.data.user.avalClubIsActive ? (
        linked ? (
            <Link to="/panel/customer-club" onClick={() => setIsSidebarExpanded(false)} className={classes}>
                {content}
            </Link>
        ) : (
            <div className={classes}>{content}</div>
        )
    ) : null;
};

export default ClubPoints;
