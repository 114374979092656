import classNames from "classnames";
import { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { NavLink, useLocation } from "react-router-dom";

import { Context, ContextType } from "@/store/Context-store";

export type SubMenuItemProps = {
    link: string;
    text: string;
};

const SubMenuItem: React.FC<SubMenuItemProps> = ({ link, text }) => {
    const { pathname, hash } = useLocation();
    const isMobile = useMediaQuery({ maxWidth: 1200 });
    const { setIsSidebarExpanded } = useContext(Context) as ContextType;

    const isActive = pathname + hash === link;

    const containerClasses = classNames({
        "hover:text-primary transition-[colors,transform] duration-150 translate-0 active:translate-y-[2px]": 1,
        "text-primary": isActive,
        "text-text-paragraph": !isActive,
    });

    return (
        <li className={containerClasses}>
            <NavLink
                onClick={isMobile ? () => setIsSidebarExpanded(false) : undefined}
                to={link}
                end
                className="whitespace-nowrap block"
            >
                {text}
            </NavLink>
        </li>
    );
};

export default SubMenuItem;
