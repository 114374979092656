import { useMemo, useState } from "react";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { DateObject } from "react-multi-date-picker";

import Button from "@/components/ui/Button";
import Input from "@/components/ui/Input";
import useOrderAddCard from "@/hooks/queries/useOrderAddCard";
import useUser from "@/hooks/queries/useUser";
import { ShowOrderResponse } from "@/interfaces/dashboard/order/order";

type CompletePaymentSchema = {
    national_code: string;
    birthday: string;
    card_number: string;
};

type CompletePaymentType = {
    onRefetch: () => void;
    order: ShowOrderResponse["data"];
};

const CompletePayment: React.FC<CompletePaymentType> = ({ order, onRefetch }) => {
    const { data: userData } = useUser();

    const [isLoading, setIsLoading] = useState(false);
    const { mutateAsync: orderAddCard } = useOrderAddCard();

    const { handleSubmit, control } = useForm<CompletePaymentSchema>({
        defaultValues: {
            birthday: userData.data.user.birthday_fa ?? undefined,
            national_code: userData.data.user.national_code ?? undefined,
        },
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit: SubmitHandler<CompletePaymentSchema> = async (data: any) => {
        const formsToSubmit = Object.keys(data)
            .filter((key) => key.includes("card_number"))
            .map((key) => {
                const cardNumber = data[key].replace(/[_\s]/g, "");

                return {
                    id: key.split("_")[2],
                    national_code: data.national_code,
                    birthday: new DateObject(data.birthday).toString(),
                    card_number: cardNumber.replace(/(^\d{6})|(\d{4}$)/g, ""),
                };
            });

        setIsLoading(true);

        try {
            for (const item of formsToSubmit) {
                await orderAddCard(item);
            }

            toast.success("اطلاعات با موفقیت ثبت شد");
            onRefetch();
        } catch (e) {
            console.log(e);
        }

        setIsLoading(false);
    };

    const renderCards = useMemo(() => {
        return order?.transactions
            .filter((transaction) => {
                return (
                    transaction.pay_type === "online" &&
                    transaction.card_number &&
                    transaction.pay_with_verify_card === 0
                );
            })
            .map((transaction, index) => {
                const first4Digits = transaction.card_number.slice(0, 4);
                const first2Digits = transaction.card_number.slice(4, 6);
                const last4Digits = transaction.card_number.slice(-4);

                return (
                    <Input
                        ltr
                        topTitle={index === 0 ? "کارت بانکی" : undefined}
                        key={transaction.transaction_id}
                        containerClassName="w-full"
                        className="[&>input]:!text-center [&>input]:!tracking-[4px] [&>input]:placeholder:text-center max-[360px]:[&>input]:!text-[0.85rem] xs:[&>input]:!tracking-[6px]"
                        variant="fill"
                        name={`card_number_${transaction.transaction_id}`}
                        rules={{
                            required: "لطفا شماره کارت خود را وارد کنید",
                            validate: (data: string) => {
                                const cardNumber = data.replace(/[_\s]/g, "");
                                if (cardNumber.length < 16) return "لطفا کارت بانکی خود را تکمیل کنید";
                                return true;
                            },
                        }}
                        maskProps={{
                            mask: `${first4Digits.replace(/9/g, "\\9")}  ${first2Digits.replace(
                                /9/g,
                                "\\9",
                            )}99  9999  ${last4Digits.replace(/9/g, "\\9")}`,
                            maskChar: "_",
                        }}
                        placeholder={`${first4Digits}  ${first2Digits}__  ____  ${last4Digits}`}
                        control={control}
                    />
                );
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <div className="gap-5 max-xs:space-y-4 xs:flex">
                {order?.settings.is_national_id_verified && (
                    <Input
                        ltr
                        containerClassName="flex-1"
                        variant="fill"
                        topTitle="کد ملی"
                        name="national_code"
                        control={control}
                        rules={{
                            required: "لطفا کد ملی خود را وارد کنید",
                            minLength: {
                                value: 10,
                                message: "کد ملی را کامل وارد کنید",
                            },
                        }}
                        maxLength={10}
                        placeholder="کد ملی را وارد کنید"
                    />
                )}
                {order?.settings.is_credit_verified && (
                    <Input
                        ltr
                        containerClassName="flex-1"
                        variant="fill"
                        topTitle="تاریخ تولد"
                        name="birthday"
                        control={control}
                        datePicker
                        rules={{
                            required: "تاریخ تولد اجباری است",
                        }}
                        datePickerProps={{
                            format: "YYYY/MM/DD",
                            calendar: persian,
                            locale: persian_fa,
                            currentDate: new DateObject({ calendar: persian }).subtract(18, "year"),
                            maxDate: new DateObject({ calendar: persian }).subtract(18, "year"),
                        }}
                        placeholder="تاریخ تولد را وارد کنید"
                    />
                )}
            </div>
            {renderCards}
            <Button type="submit" className="!mt-10 w-full" loading={isLoading} disabled={isLoading} disabledTooltip="دکمه غیرفعال است">
                ثبت اطلاعات
            </Button>
        </form>
    );
};

export default CompletePayment;
