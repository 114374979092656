import classNames from "classnames";
import { useMemo } from "react";
import { useSwiper } from "swiper/react";

import { VerificationStatus } from "@/interfaces/dashboard/layout/panel/panel";

type ProgressItemProps = {
    index: number;
    status: VerificationStatus;
    active: boolean;
    title: string;
    disabled?: boolean;
    setActiveIndex: (index: number) => void;
    icon: any;
};

const ProgressItem: React.FC<ProgressItemProps> = ({
    active,
    disabled,
    index,
    status,
    title,
    setActiveIndex,
    icon: Icon,
}) => {
    const swiper = useSwiper();

    const statusColor = useMemo(() => {
        switch (status) {
            case "CONFIRMED":
                return "#06b6d4";
            case "CANCELED":
                return "#FF4747";
            case "REJECTED":
                return "#FF4747";
            default:
                return undefined;
        }
    }, [status]);

    const iconContainerClasses = classNames({
        "border-secondary-200 contrast:border-primary/50 flex h-[55px] xs:h-[45px] lg:h-[55px] w-[55px] xs:w-[45px] lg:w-[55px] items-center justify-center rounded-full border-[3px] transition-colors select-none": 1,
        "!border-primary !bg-primary contrast:!bg-transparent": active,
        "!border-primary contrast:!border-primary/50 border-dashed": status === "PENDING",
        "contrast:!border-primary/50": status === "CONFIRMED" && !active,
    });

    const iconClasses = classNames({
        "fill-icon-disabled h-[22px] xs:h-[18px] lg:h-[22px] transition-colors": 1,
        "!fill-white contrast:!fill-primary": active,
    });

    const textClasses = classNames(
        "absolute bottom-[-30px] select-none whitespace-nowrap text-[0.71rem] font-semibold text-text-main md:text-[0.85rem] transition-opacity",
        active ? "max-xs:opacity-1" : "max-xs:opacity-0",
    );

    return (
        <div className={`group flex items-center justify-center ${disabled ? "opacity-50" : ""}`}>
            <div
                className={`relative flex justify-center transition-transform duration-75 ${
                    !disabled ? "cursor-pointer active:scale-110" : ""
                }`}
                onClick={() => {
                    if (!disabled) {
                        if (swiper) swiper.slideTo(index - 1);
                        setActiveIndex(index);
                    }
                }}
            >
                <div className={iconContainerClasses} style={{ borderColor: statusColor }}>
                    <Icon className={iconClasses} style={{ fill: statusColor }} />
                </div>
                <div className={textClasses}>{title}</div>
            </div>
            <div className="h-[3px] w-[45px] bg-secondary-200 group-last:hidden md:w-[100px] lg:w-[120px]" />
        </div>
    );
};

export default ProgressItem;
