import { useMemo } from "react";
import { Link } from "react-router-dom";

import EmptyIndicator from "@/components/dashboard/main/EmptyIndicator";
import IconHouse from "@/components/svg/house";
import BoxContainer from "@/components/ui/BoxContainer";
import Breadcrumb from "@/components/ui/Breadcrumb";
import Pagination from "@/components/ui/Pagination";
import Table from "@/components/ui/Table";
import { TableTdProps } from "@/components/ui/Table/TableTd";
import PageTitle from "@/components/utility/PageTitle";
import useNotificationsList from "@/hooks/queries/useNotificationsList";
import useAfter from "@/hooks/useAfter";
import useQueryParams from "@/hooks/useQueryParams";

const Notifications = () => {
    const { updateQueryParams, searchParams } = useQueryParams();

    const page = Number(searchParams.get("page") ?? 1);
    const perPageValue = Number(searchParams.get("perPage") ?? 10);
    const perPage = { value: perPageValue, label: perPageValue };

    const { data: notifications, isLoading } = useNotificationsList(page, perPage.value);

    const forcePerPage = notifications?.per_page ? Number(notifications?.per_page) : 1;

    // set force per_page once after data is loaded
    useAfter(() => {
        updateQueryParams({ perPage: forcePerPage });
    }, !!notifications);

    const tableRows = useMemo<TableTdProps[][] | undefined>(() => {
        return notifications?.list.map((notification) => {
            return [
                {
                    header: "عنوان",
                    children: notification.type,
                },
                {
                    header: "متن رویداد",
                    children: notification.content,
                },
                {
                    header: "تاریخ",
                    children: notification.created_at,
                },
                {
                    header: "عملیات",
                    children: (
                        <Link to={notification.data.url} className="text-primary hover:text-primary">
                            مشاهده
                        </Link>
                    ),
                },
            ];
        });
    }, [notifications]);

    return (
        <>
            <PageTitle>پیغام ها</PageTitle>

            <Breadcrumb
                sections={[
                    { link: "/panel", text: "صفحه اصلی", icon: IconHouse },
                    { link: "#", text: "لیست رویدادها", active: true },
                ]}
            />
            <BoxContainer colorBox={false}>
                <div className="-mx-5 pb-14">
                    {tableRows?.length !== 0 ? (
                        <Table placeholderRows={12} loading={isLoading} rows={tableRows} className="max-sm:-mt-5" />
                    ) : (
                        <EmptyIndicator message="رویدادی برای نمایش وجود ندارد" />
                    )}
                </div>
            </BoxContainer>
            {!isLoading && tableRows?.length !== 0 && (
                <Pagination
                    onPerPageChange={(perPage) => updateQueryParams({ perPage })}
                    onPageChange={(page) => updateQueryParams({ page })}
                    nextPage={() => updateQueryParams({ page: page + 1 })}
                    prevPage={() => updateQueryParams({ page: page - 1 })}
                    paginationInfo={{
                        from: notifications?.from,
                        to: notifications?.to,
                        pages: notifications?.last_page,
                        count: notifications?.total,
                        perPage: perPage.value,
                        forcePerPage,
                        page,
                    }}
                />
            )}
        </>
    );
};

export default Notifications;
