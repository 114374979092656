import classNames from "classnames";
import { Link } from "react-router-dom";

import IconArrowUpRightFromSquare from "../svg/arrow-up-right-from-square";
import IconDollarSign from "../svg/dollar-sign";

type ServiceProps = {
    setSelectedService: (service: number) => void;
    image: string;
    text: string;
    link: string;
    active?: boolean;
    id: number;
};

const Service: React.FC<ServiceProps> = ({ image, link, text, active, setSelectedService, id }) => {
    const iconClasses = classNames({
        "h-[25px] w-[25px] rounded-full": 1,
        "fill-primary": !active,
        "fill-white": active,
    });

    return (
        <Link
            className={`flex w-full grow items-center justify-between rounded-[10px] border-2 px-4 py-4 transition-colors sm:py-5 ${
                active
                    ? "border-primary bg-transparent"
                    : "border-transparent bg-secondary-300 hover:bg-secondary-250 contrast:border-[initial] contrast:bg-transparent"
            }`}
            onMouseEnter={() => setSelectedService(id)}
            to={link}
        >
            <div className="flex w-full items-center justify-start gap-4">
                {image && <img src={image} className={iconClasses} />}
                <span className={`text-text-main ${active ? "contrast:text-primary" : ""}`}>{text}</span>
            </div>
            {id == 0 || id == -1 ? (
                <IconDollarSign className="mr-2 h-[16px] fill-primary sm:h-[20px]" />
            ) : (
                <IconArrowUpRightFromSquare className="mr-2 h-[16px] fill-amber-400 sm:h-[20px]" />
            )}
        </Link>
    );
};

export default Service;
