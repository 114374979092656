import { useQuery } from "@tanstack/react-query";

import gate from "@/gate";
import { Currency } from "@/interfaces/dashboard/order/order";

type CalcucationData =
    | {
          serviceName: string;
          amount: number;
          currency: number | string;
      }
    | undefined;

const useCashCalculation = (calcucationData: CalcucationData) => {
    return useQuery<unknown, Error, Currency>({
        queryFn: gate.cashCalculation.bind(
            null,
            calcucationData?.serviceName,
            calcucationData?.amount,
            calcucationData?.currency,
        ),
        queryKey: ["calcucation"],
        enabled: false,
    });
};

export default useCashCalculation;
