import { AnimatePresence, motion, Variants } from "framer-motion";
import { useEffect, useState } from "react";
import gregorian from "react-date-object/calendars/gregorian";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import { useParams, useSearchParams } from "react-router-dom";

import Spinner from "@/components/loader/Spinner";
import Button from "@/components/ui/Button";
import CashTotal from "@/components/ui/CashTotal";
import CustomCheckbox from "@/components/ui/CustomCheckbox";
import CustomRadio from "@/components/ui/CustomRadio";
import Input from "@/components/ui/Input";
import Select from "@/components/ui/Select";
import Textarea from "@/components/ui/Textarea";
import { NUMBER_INPUT_FLOATING_POINT } from "@/constants/common";
import {
    CashCalculationRequestType,
    CashCreateResponse,
    CashCreateService,
    Currency,
} from "@/interfaces/dashboard/order/order";

import CashRefundOptions from "../CashRefundOptions";

type PaypalWiseCashRequest = {
    service: string;
    amount: number | "";
    fk_currency_id: number;
    account_type: "personal" | "business";
    wallet?: string;
    register_avalp_to_site: "0" | "1" | "";
    company_address?: string;
    pay_date?: string;
    cash_type: "ADD_TO_ACCOUNT_BALANCE" | "REFUND";
    bank_id: number;
    user_desc?: string;
    rules: boolean;
};

type ServiceInstruction = {
    isLoading: boolean;
    instructions?: CashCreateResponse;
    isCashPricesLoading: boolean;
    calculatedPrice?: Currency;
    onCalculation: (data: CashCalculationRequestType) => void;
    onFormSubmit: (data: PaypalWiseCashRequest) => void;
    onCurrencyChange: (currencyId: number) => void;
};

type PaypalWiseCash = {
    amount: number | "";
    currency: {
        label: string;
        value: number;
    };
    account_type: "personal" | "business";
    // Personal
    wallet?: string;
    // Businuess
    register_avalp_to_site: "0" | "1" | "";
    company_address?: string;
    pay_date?: DateObject | "";

    cash_type: "ADD_TO_ACCOUNT_BALANCE" | "REFUND";
    bank_id?: {
        label: string;
        value: number;
    };
    user_desc?: string;
    rules: boolean;
    is_immediate: boolean;
};

const PaypalWiseCashForm: React.FC<ServiceInstruction> = ({
    isLoading,
    instructions,
    isCashPricesLoading,
    calculatedPrice,
    onCurrencyChange,
    onCalculation,
    onFormSubmit,
}) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const [serviceData, setServiceData] = useState<CashCreateService>(instructions?.service);

    const [accountType, setAccountType] = useState<"" | "personal" | "business">("personal");
    const [isAvalpardakhtRegistered, setIsAvalpardakhtRegistered] = useState<"0" | "1" | "">("");
    const [acceptRules, setAcceptRules] = useState<boolean>(false);
    const [total, setTotal] = useState<number>(0);

    const [isImmediate, setIsImmediate] = useState<boolean>(false);

    const [queryString] = useSearchParams();

    const { serviceName } = useParams();

    useEffect(() => {
        if (instructions) {
            setServiceData(instructions.service);
            setTotal(0);
        }
    }, [instructions]);

    const {
        control,
        handleSubmit,
        formState: { errors },
        resetField,
        getValues,
        setValue,
    } = useForm<PaypalWiseCash>({
        defaultValues: {
            amount: queryString.get("amount") ? Number(queryString.get("amount")) : "",
            currency: {
                value: queryString.get("currency")
                    ? serviceData.currencies.find((currency) => currency.abbreviation == queryString.get("currency"))
                          ?.id
                    : serviceData?.currencies[0]?.id,
                label: queryString.get("currency")
                    ? serviceData.currencies.find((currency) => currency.abbreviation == queryString.get("currency"))
                          ?.name
                    : serviceData?.currencies[0]?.name,
            },
            company_address: "",
            wallet: "",
            pay_date: "",
            account_type: "personal",
            cash_type: "ADD_TO_ACCOUNT_BALANCE",
            rules: false,
            register_avalp_to_site: "0",
            user_desc: "",
            is_immediate: isImmediate,
        },
    });

    const [watchedAmount, watchedCurrency, watchedAccountType, watchedIsAvalpardakhtRegistered, watchedCashType] =
        useWatch({
            control,
            name: ["amount", "currency", "account_type", "register_avalp_to_site", "cash_type"],
            defaultValue: {
                account_type: "personal",
                amount: "",
                currency: {
                    value: serviceData.currencies[0]?.id,
                    label: serviceData.currencies[0]?.name,
                },
                register_avalp_to_site: "0",
                cash_type: "ADD_TO_ACCOUNT_BALANCE",
            },
        });

    useEffect(() => {
        if (instructions) {
            if (instructions.service.currencies[0]) {
                setValue("currency", {
                    value:
                        (queryString.get("currency")
                            ? serviceData.currencies.find((currency) => {
                                  return currency.abbreviation == queryString.get("currency");
                              })?.id
                            : serviceData?.currencies[0]?.id) || 0,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    label: queryString.get("currency")
                        ? serviceData.currencies.find(
                              (currency) => currency.abbreviation == queryString.get("currency"),
                          )?.name
                        : serviceData?.currencies[0]?.name,
                });
            }
        }
    }, [instructions, queryString, serviceData.currencies, setValue]);

    useEffect(() => {
        if (watchedCurrency && watchedAmount && Number(watchedAmount) >= 0) {
            onCalculation({
                serviceName: serviceName?.replace("sell-", "").toUpperCase() || "",
                amount: +watchedAmount,
                currency: watchedCurrency.value || "",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchedAmount, watchedCurrency]);

    useEffect(() => {
        if (watchedCurrency && watchedCurrency.value) {
            onCurrencyChange(watchedCurrency.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchedCurrency]);

    useEffect(() => {
        setAccountType(watchedAccountType);
        resetField("register_avalp_to_site");
        resetField("company_address");
        resetField("pay_date");
        resetField("wallet");
    }, [resetField, watchedAccountType]);

    useEffect(() => {
        setIsAvalpardakhtRegistered(watchedIsAvalpardakhtRegistered);
        resetField("pay_date");
    }, [resetField, watchedIsAvalpardakhtRegistered]);

    useEffect(() => {
        setTotal(calculatedPrice?.total || 0);
    }, [calculatedPrice]);

    const submitHandler: SubmitHandler<PaypalWiseCash> = (data) => {
        let payDate = "";
        if (data?.pay_date) {
            const date = data.pay_date?.toDate();

            payDate =
                date.getFullYear().toString().padStart(2, "0") +
                "-" +
                date.getMonth().toString().padStart(2, "0") +
                "-" +
                date.getDay().toString().padStart(2, "0") +
                " " +
                date.getHours().toString().padStart(2, "0") +
                ":" +
                date.getMinutes().toString().padStart(2, "0");
        }

        const submitData = {
            service: serviceName?.replace("sell-", "").toUpperCase() || "",
            amount: data.amount,
            fk_currency_id: data.currency.value,
            account_type: data.account_type,
            wallet: data.wallet,
            register_avalp_to_site: data.register_avalp_to_site,
            company_address: data.company_address,
            pay_date: payDate,
            user_desc: data.user_desc,
            rules: acceptRules,
            cash_type: data.cash_type,
            bank_id: data.bank_id?.value || -1,
            is_immediate: isImmediate,
        };

        onFormSubmit(submitData);
    };

    const testVariants: Variants = {
        hide: {
            height: 0,
            opacity: 0,
            transition: {
                duration: 0.15,
            },
        },
        show: {
            height: "auto",
            opacity: 1,
            transition: {
                duration: 0.15,
            },
        },
    };

    return (
        <form id="order-form" onSubmit={handleSubmit(submitHandler)}>
            <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-4 lg:flex-row">
                    <div className="w-full lg:w-7/12">
                        <Input
                            containerClassName="w-full"
                            variant="fill"
                            required
                            ltr
                            autoComplete="off"
                            name="amount"
                            topTitle="میزان"
                            inputMode="decimal"
                            type="number"
                            min={0}
                            step={NUMBER_INPUT_FLOATING_POINT}
                            placeholder="میزان"
                            control={control}
                            rules={{
                                required: "میزان الزامی است",
                            }}
                        />
                    </div>
                    <div className="w-full lg:w-5/12">
                        <Select
                            control={control}
                            topTitle="نوع ارز"
                            required
                            name="currency"
                            variant="fill"
                            placeholder="انتخاب کنید"
                            rules={{
                                required: "انتخاب نوع ارز الزامی است",
                            }}
                            defaultValue={{
                                value: serviceData.currencies[0]?.id,
                                label: serviceData.currencies[0]?.name,
                            }}
                            options={serviceData.currencies.map((currency) => {
                                return {
                                    value: currency.id,
                                    label: currency.name,
                                };
                            })}
                        />
                    </div>
                </div>

                <CashTotal isLoading={isCashPricesLoading} total={total} />

                <div className="flex flex-col gap-4 rounded-[10px] bg-secondary-400 p-4">
                    <span className="text-text-main">
                        نوع حساب {instructions?.service.form_file.includes("wise") ? "وایز" : "پی پال"} واریز کننده
                    </span>
                    <div className="flex gap-8">
                        <CustomRadio
                            label="شخصی"
                            name="account_type"
                            control={control}
                            checked={getValues("account_type") == "personal"}
                            value="personal"
                            rules={{
                                required: `انتخاب نوع حساب ${
                                    instructions?.service.form_file.includes("wise") ? "وایز" : "پی پال"
                                } واریز کننده الزامی است`,
                            }}
                        />
                        <CustomRadio
                            label="شرکتی"
                            name="account_type"
                            control={control}
                            checked={getValues("account_type") == "business"}
                            value="business"
                            rules={{
                                required: `انتخاب نوع حساب ${
                                    instructions?.service.form_file.includes("wise") ? "وایز" : "پی پال"
                                } واریز کننده الزامی است`,
                            }}
                        />
                    </div>
                    {errors && errors.account_type && (
                        <div className="-mt-2 text-[0.78rem] text-error">
                            <span>{errors.account_type.message}</span>
                        </div>
                    )}

                    <div className="flex flex-col gap-4">
                        <div>
                            <label className="text-text-paragraph">نوع حساب انتخابی: </label>
                            <span className="font-bold text-success">
                                {accountType == "personal" ? "شخصی" : "شرکتی"}
                            </span>
                        </div>
                        <AnimatePresence mode="wait" initial={false}>
                            {accountType == "personal" ? (
                                <motion.div variants={testVariants} initial="hide" animate="show" exit="hide" key={1}>
                                    <Input
                                        containerClassName="w-full"
                                        name="wallet"
                                        variant="fill"
                                        topTitle={`${
                                            instructions?.service.form_file.includes("wise")
                                                ? "ایمیل وایز، Wisetag یا Account Number وایز"
                                                : "ایمیل حساب پی پال"
                                        } جهت صدور فاکتور`}
                                        inputMode="text"
                                        placeholder={`${
                                            instructions?.service.form_file.includes("wise")
                                                ? "ایمیل وایز، Wisetag یا Account Number وایز"
                                                : "ایمیل حساب پی پال"
                                        }`}
                                        ltr
                                        autoComplete="off"
                                        control={control}
                                        rules={{
                                            required:
                                                accountType == "personal"
                                                    ? `${
                                                          instructions?.service.form_file.includes("wise")
                                                              ? "ایمیل وایز، Wisetag یا Account Number وایز"
                                                              : "ایمیل حساب پی پال"
                                                      } الزامی است`
                                                    : false,
                                        }}
                                    />
                                </motion.div>
                            ) : (
                                <motion.div variants={testVariants} initial="hide" animate="show" exit="hide" key={2}>
                                    <div className="flex flex-col gap-4 rounded-[10px] bg-secondary-400 p-4">
                                        <span className="text-text-main">
                                            حساب کاربری اول پرداخت در سایت مبدا ثبت شده؟
                                        </span>
                                        <div className="flex gap-8">
                                            <CustomRadio
                                                label="بله"
                                                control={control}
                                                name="register_avalp_to_site"
                                                value="1"
                                                rules={{
                                                    required:
                                                        accountType == "business"
                                                            ? "انتخاب این گزینه الزامی است"
                                                            : false,
                                                }}
                                                checked={watchedIsAvalpardakhtRegistered == "1"}
                                            />
                                            <CustomRadio
                                                label="خیر"
                                                control={control}
                                                name="register_avalp_to_site"
                                                value="0"
                                                rules={{
                                                    required: accountType == "business",
                                                }}
                                                checked={watchedIsAvalpardakhtRegistered == "0"}
                                            />
                                        </div>
                                        {errors && errors.register_avalp_to_site && (
                                            <div className="-mt-2 text-[0.78rem] text-error">
                                                <span>{errors.register_avalp_to_site.message}</span>
                                            </div>
                                        )}
                                        <Input
                                            containerClassName="w-full"
                                            name="company_address"
                                            variant="fill"
                                            topTitle={
                                                instructions?.service.form_file.includes("wise")
                                                    ? "آدرس سایت یا حساب واریز کننده"
                                                    : "آدرس سایت واریز کننده"
                                            }
                                            inputMode="text"
                                            type="text"
                                            placeholder="آدرس سایت"
                                            ltr
                                            autoComplete="off"
                                            control={control}
                                            rules={{
                                                required:
                                                    accountType == "business"
                                                        ? "آدرس سایت واریز کننده الزامی است"
                                                        : false,
                                            }}
                                        />
                                        <motion.div
                                            className={`${
                                                isAvalpardakhtRegistered == "0" ? "h-0 overflow-hidden" : ""
                                            }`}
                                            initial={{
                                                height: isAvalpardakhtRegistered == "1" ? "auto" : "0",
                                            }}
                                            animate={{
                                                height: isAvalpardakhtRegistered == "1" ? "auto" : "0",
                                            }}
                                            transition={{ duration: 0.15 }}
                                        >
                                            <Input
                                                name="pay_date"
                                                control={control}
                                                ltr
                                                containerClassName="w-full"
                                                variant="fill"
                                                topTitle="تاریخ واریز"
                                                placeholder="تاریخ واریز"
                                                autoComplete="off"
                                                datePicker
                                                datePickerProps={{
                                                    calendar: gregorian,
                                                    maxDate: new DateObject({ calendar: gregorian }),
                                                    format: "YYYY-MM-DD HH:mm",
                                                    plugins: [<TimePicker position="bottom" hideSeconds key={1} />],
                                                }}
                                                rules={{
                                                    required:
                                                        isAvalpardakhtRegistered == "1"
                                                            ? "تاریخ واریز الزامی است"
                                                            : false,
                                                }}
                                            />
                                        </motion.div>
                                    </div>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
                </div>

                {instructions && instructions?.can_choose_payment_method && (
                    <CashRefundOptions
                        control={control}
                        balance={instructions.balance}
                        instructions={instructions}
                        cashType={watchedCashType}
                        onIsImmediateChange={(value: boolean) => setIsImmediate(value)}
                    />
                )}

                <Textarea
                    name="user_desc"
                    control={control}
                    label="توضیحات"
                    placeholder="توضیحات خود را اینجا بنویسید"
                />

                <CustomCheckbox
                    onChange={() => setAcceptRules((prev) => !prev)}
                    label={
                        <span>
                            <a
                                href="https://avalpardakht.com/terms"
                                target="_blank"
                                rel="noreferrer nofollow"
                                className="text-primary hover:text-primary"
                            >
                                قوانین و مقررات
                            </a>{" "}
                            سایت را مطالعه کرده‌ام و آن را قبول دارم.
                        </span>
                    }
                />
                <Button type="submit" disabled={!acceptRules || isLoading} disabledTooltip="دکمه غیرفعال است">
                    {isLoading ? <Spinner /> : "ثبت درخواست"}
                </Button>
            </div>
        </form>
    );
};

export default PaypalWiseCashForm;
