import { InputHTMLAttributes, ReactNode } from "react";

interface CustomCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string | ReactNode;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ label, ...rest }) => {
    return (
        <label className="cursor-pointer text-text-main">
            <div className="flex items-center gap-2">
                <input type="checkbox" className="peer invisible absolute opacity-0" {...rest} />
                <span
                    className={`relative h-4 w-4 rounded-sm border-2 border-gray-400 before:absolute
                        peer-checked:border-primary peer-checked:bg-primary
                    peer-checked:before:left-0 peer-checked:before:top-1/2 peer-checked:before:h-[3px] peer-checked:before:w-[4px] peer-checked:before:rotate-45 peer-checked:before:rounded-md peer-checked:before:bg-secondary peer-checked:after:absolute peer-checked:after:left-[1px] peer-checked:after:top-[30%] peer-checked:after:h-[3px] peer-checked:after:w-[10px] peer-checked:after:-rotate-[50deg] peer-checked:after:rounded-md peer-checked:after:bg-secondary`}
                ></span>{" "}
                {label}
            </div>
        </label>
    );
};

export default CustomCheckbox;
