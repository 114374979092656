import classNames from "classnames";
import { useId, useMemo, useState } from "react";
import { Control, useController } from "react-hook-form";
import toast from "react-hot-toast";

import IconCircleXmarkDuotone from "@/components/svg/circle-xmark-duotone";
import IconCircleXmarkSolid from "@/components/svg/circle-xmark-solid";
import IconSpinnerThirdDuotone from "@/components/svg/spinner-third-duotone";
import IconUploadDuotone from "@/components/svg/upload-duotone";
import IconWandMagicSparkles from "@/components/svg/wand-magic-sparkles";
import Button from "@/components/ui/Button";
import { compressImage } from "@/utils/image-compressor";
import showQuestion from "@/utils/show-question";

type UploadHandlerArgs = {
    fileType?: "pdf" | "image" | "video";
    buttonClassName?: string;
    className?: string;
    title?: string;
    name: string;
    accept?: string;
    rules?: any;
    control: Control<any>;
    disabled?: boolean;
    error?: boolean;
    setFormValue: any;
};

const useUploadHandler = ({
    fileType = "image",
    buttonClassName,
    className,
    title,
    accept,
    name,
    rules,
    control,
    disabled,
    error,
    setFormValue,
}: UploadHandlerArgs) => {
    const labelButtonId = useId();
    const [isDragAreaActive, setIsDragAreaActive] = useState(false);
    const [uploadFile, setUploadFile] = useState<File | null>(null);

    const { field } = useController({
        name: name ?? "",
        control,
        rules,
    });

    const preventDefaults = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const dragHandler = (e: any, isAreaActive: boolean) => {
        preventDefaults(e);
        setIsDragAreaActive(isAreaActive);
    };

    const onDrop = async (e: any, isInput?: boolean) => {
        preventDefaults(e);

        let file: File;

        if (isInput) file = e.target.files[0];
        else file = e.dataTransfer.files[0];

        setIsDragAreaActive(false);

        if (fileType === "pdf" && file.type === "application/pdf") {
            setUploadFile(file);
        } else if (
            fileType === "image" &&
            (file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/webp")
        ) {
            if (file.size > 2 * 1024 * 1024) {
                const shouldCompress = await showQuestion({
                    text: "حجم فایل انتخاب شده باید زیر 2 مگابایت باشد، آیا مایلید که حجم فایل تصویر شما توسط هوش مصنوعی کاهش پیدا کند؟",
                    avatar: URL.createObjectURL(file),
                    cancelButtonText: "آپلود عکس دیگر",
                    acceptButtonText: "کاهش بده",
                    duration: 20000,
                });

                if (shouldCompress) {
                    const toastId = toast(
                        () => (
                            <span className="text-text-main" dir="ltr">
                                <div className="flex items-center justify-between gap-4">
                                    <IconSpinnerThirdDuotone className="h-[18px] animate-spin fill-primary" />
                                    <span>درحال فشرده سازی فایل تصویر</span>
                                </div>
                                <div className="-mx-6">
                                    <div className="mt-4 flex items-center justify-between gap-4 border-t border-secondary-100 px-6 pt-4">
                                        <span className="max-w-[150px] flex-1 overflow-hidden text-ellipsis">
                                            {file.name}
                                        </span>
                                        <span className="" id="compression-progress">
                                            0%
                                        </span>
                                    </div>
                                </div>
                                <div className="mt-4 h-[6px] w-full rounded-full bg-secondary-100">
                                    <div
                                        className="h-full rounded-full bg-primary transition-all"
                                        id="compression-progressbar"
                                    ></div>
                                </div>
                            </span>
                        ),
                        {
                            duration: Infinity,
                            className: "!bg-toast",
                        },
                    );

                    setTimeout(async () => {
                        const progressEl = document.querySelector("#compression-progress") as HTMLDivElement;
                        const progressBarEl = document.querySelector("#compression-progressbar") as HTMLDivElement;

                        const compressedFile = await compressImage({
                            file,
                            progress: (p) => {
                                progressEl.innerHTML = `${p}%`;
                                progressBarEl.style.width = `${p}%`;
                            },
                        });

                        toast.dismiss(toastId);
                        
                        setUploadFile(compressedFile);
                        setFormValue(name, compressedFile, {
                            shouldValidate: true,
                            shouldDirty: true,
                            shouldTouch: true,
                        });
                    }, 100);
                } else {
                    setFormValue(name, "", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
                }
            } else {
                setUploadFile(file);
                setFormValue(name, file, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
            }
        } else {
            setFormValue(name, "", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
            toast.error("فایل انتخاب شده اشتباه است");
        }
    };

    const reset = () => {
        setUploadFile(null);
        setIsDragAreaActive(false);
        setFormValue(name, null, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
    };

    const containerClasses = classNames({
        "@container flex h-full w-full flex-col items-center justify-center gap-4 rounded-[15px] border-dashed transition-colors relative": 1,
        "border-2": !uploadFile,
        "border-none": uploadFile,
        "border-secondary-100": !error && !isDragAreaActive,
        "border-primary": isDragAreaActive,
        "border-error": error,
    });

    const dragAreaClasses = classNames(
        {
            "flex justify-center items-center flex-col gap-4 sm:gap-6 py-4 w-full h-full rounded-[15px] transition-colors": 1,
            "fill-secondary-400 bg-transparent": !isDragAreaActive,
            "fill-primary bg-primary bg-opacity-10": isDragAreaActive,
            "grayscale": disabled,
        },
        className,
    );

    const inputElement = (
        <>
            <div className={`flex items-center justify-center gap-4 ${className}`}>
                {uploadFile && (
                    <button
                        onClick={(e) => {
                            preventDefaults(e);
                            reset();
                        }}
                    >
                        <IconCircleXmarkDuotone className="h-[20px] fill-error-300" />
                    </button>
                )}

                <Button
                    type="button"
                    variant="stroke"
                    htmlFor={labelButtonId}
                    className={buttonClassName ?? "max-w-[200px] px-3"}
                    as="label"
                    disabled={disabled}
                    disabledTooltip="دکمه غیرفعال است"
                >
                    <div className="max-w-full overflow-hidden whitespace-nowrap">
                        {uploadFile ? uploadFile.name : "انتخاب فایل"}
                    </div>
                </Button>
            </div>
            <input
                {...field}
                accept={accept}
                id={labelButtonId}
                className="absolute -z-10 opacity-0"
                type="file"
                onChange={(e) => {
                    field.onChange(e);
                    onDrop(e, true);
                }}
                disabled={disabled}
            />
        </>
    );

    const dragDropTemplate = useMemo(() => {
        return (
            <div className={containerClasses}>
                {uploadFile ? (
                    <div
                        className={`relative h-full min-h-[200px] w-full overflow-hidden rounded-[15px] ${
                            disabled ? "grayscale" : ""
                        } ${!uploadFile && className}`}
                    >
                        <button
                            onClick={reset}
                            className="group absolute right-[15px] top-[15px] z-20 flex items-center justify-center rounded-full bg-white fill-red-500"
                        >
                            <IconCircleXmarkSolid className="fill-red h-[26px] opacity-[0.8] transition-opacity group-hover:opacity-[1]" />
                        </button>
                        <img
                            src={URL.createObjectURL(uploadFile)}
                            className="reset-hue-rotate absolute h-full w-full object-cover"
                            alt="alt"
                        />
                    </div>
                ) : (
                    <div
                        className={dragAreaClasses}
                        onDragOver={(e) => !disabled && dragHandler(e, true)}
                        onDragLeave={(e) => !disabled && dragHandler(e, false)}
                        onDragEnter={(e) => !disabled && dragHandler(e, true)}
                        onDrop={!disabled ? onDrop : undefined}
                    >
                        <IconUploadDuotone className="h-[45px] fill-primary @xs:h-[55px] @sm:h-[60px] @md:h-[70px]" />
                        <span className="max-w-[250px] px-4 text-center text-text-paragraph">
                            {title ?? "حجم تصویر می بایست کمتر از 2000  باشد"}
                        </span>
                        {inputElement}
                    </div>
                )}
            </div>
        );
    }, [uploadFile, inputElement]);

    return {
        reset,
        uploadFile,
        setUploadFile,
        renderTemplate: dragDropTemplate,
    };
};

export default useUploadHandler;
