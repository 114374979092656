import { useQueryClient } from "@tanstack/react-query";

import ExampleImage from "@/assets/images/test.webp";
import IconChevronLeft from "@/components/svg/chevron-left";
import IconCircleCheckDuotone from "@/components/svg/circle-check-duotone";
import IconSpinnerThirdDuotone from "@/components/svg/spinner-third-duotone";
import useEarnPoint from "@/hooks/queries/useEarnPoint";
import { ClubTask } from "@/interfaces/dashboard/club/club";

type TaskProps = ClubTask;

const Task = ({ information, can_earn, shouldCallForEarn, slug }: TaskProps) => {
    const queryClient = useQueryClient();
    const { mutateAsync: earnPoint, isLoading } = useEarnPoint();

    const earnPointHandler = async () => {
        if (shouldCallForEarn) {
            await earnPoint(slug);
        }

        window.location.href = information.button.link!;
        await queryClient.refetchQueries(["userData"]);
    };

    return (
        <div className="flex gap-5 rounded-[10px] border p-4 dark:border-none dark:bg-secondary-250 max-xs:flex-col">
            <div className="relative aspect-square h-min w-full shrink-0 xs:w-[100px] sm:w-[130px]">
                <img
                    className={`absolute h-full w-full rounded-[10px] object-cover ${
                        !can_earn ? "opacity-[0.15] grayscale" : ""
                    }`}
                    alt={information.title}
                    src={information.image ?? ExampleImage}
                />
                {!can_earn && (
                    <div className="absolute flex h-full w-full items-center justify-center rounded-[10px] bg-success/25">
                        <IconCircleCheckDuotone className="h-[40px] fill-success" />
                    </div>
                )}
            </div>
            <div className="flex h-full w-full flex-col justify-between max-xs:px-[6px] xs:py-2">
                <div className="min-h-[60px]">
                    <span className="font-semibold text-text-main sm:text-[1.28rem]">{information.title}</span>
                    <p className="py-3 pl-2 text-text-paragraph max-sm:text-[0.85rem] max-xs:pb-5">
                        {information.description}
                    </p>
                </div>
                <div className="flex w-full items-center justify-between">
                    <div className="flex w-min gap-2 whitespace-nowrap rounded-full bg-gold/10 px-4 pb-1 pt-[5px] text-gold">
                        <span className="max-sm:text-[0.85rem]">{information.point}</span>
                        <span className="font-bold">امتیاز</span>
                        {/*<IconStarSolid className="h-[14px] translate-y-px fill-gold sm:translate-y-[2px]" />*/}
                    </div>
                    {can_earn && (
                        <button
                            disabled={isLoading}
                            onClick={earnPointHandler}
                            className="flex w-min gap-2 whitespace-nowrap rounded-full bg-primary/10 px-4 pb-1 pt-[5px] text-primary transition-all hover:brightness-90 dark:hover:brightness-[120%]"
                        >
                            <span className="max-sm:text-[0.85rem]">{information.button.label}</span>
                            {isLoading ? (
                                <IconSpinnerThirdDuotone className="h-[14px] translate-y-px animate-spin fill-primary sm:translate-y-[2px]" />
                            ) : (
                                <IconChevronLeft className="h-[14px] translate-y-px fill-primary sm:translate-y-[2px]" />
                            )}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Task;
