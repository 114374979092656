import { useContext, useEffect, useRef } from "react";

import { PATTERNS } from "@/constants/common";
import useSyncThemeSettings from "@/hooks/queries/useSyncThemeSettings";
import useTheme from "@/hooks/useTheme";
import { Context, ContextType } from "@/store/Context-store";

const useLoadSettings = () => {
    useSyncThemeSettings();
    const { theme, changeThemeTo } = useTheme();
    const htmlElementRef = useRef<HTMLHtmlElement>();
    const grayscaleTimer = useRef<null | number>(null);
    const hueRotationTimer = useRef<null | number>(null);

    const {
        linkHighlight,
        linkUnderline,
        grayScaleValue,
        fontWeightValue,
        fontSizeValue,
        wordSpacingValue,
        hueRotationValue,
        pattern,
        lineHeightValue,
        systemTheme,
    } = useContext(Context) as ContextType;

    useEffect(() => {
        htmlElementRef.current = document.querySelector("html") as HTMLHtmlElement;
    }, []);

    // Select new user's theme based on their system theme :)
    useEffect(() => {
        const onSystemThemeChange = async (event: any) => {
            if (event.matches) {
                changeThemeTo(theme.replace("light", "dark") as any, false);
            } else {
                changeThemeTo(theme.replace("dark", "light") as any, false);
            }
        };

        if (systemTheme && window.matchMedia) {
            window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", onSystemThemeChange);
        }

        return () => {
            window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", onSystemThemeChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [systemTheme, theme]);

    /**
     * Font size setting
     */
    useEffect(() => {
        const isLargeScreen = window.innerWidth > 1024;

        const sizes = {
            "xl": 20,
            "lg": 16,
            "md": 14,
            "sm": 12,
            "xs": 10,
        };

        htmlElementRef.current!.style.fontSize = `${sizes[fontSizeValue]}px`;
    }, [fontSizeValue]);

    /**
     * Font weight setting
     */
    useEffect(() => {
        htmlElementRef.current!.style.fontWeight = `${fontWeightValue}`;
    }, [fontWeightValue]);

    /**
     * Hue rotation setting
     */
    useEffect(() => {
        if (hueRotationTimer.current) clearTimeout(hueRotationTimer.current);
        hueRotationTimer.current = setTimeout(() => {
            htmlElementRef.current!.style.setProperty(
                "--theme-filter-hue-rotation",
                String(hueRotationValue / 100) + "turn",
            );
        }, 100);
    }, [hueRotationValue]);

    /**
     * Gray scale setting
     */
    useEffect(() => {
        if (grayscaleTimer.current) clearTimeout(grayscaleTimer.current);
        grayscaleTimer.current = setTimeout(() => {
            htmlElementRef.current!.style.setProperty("--theme-filter-grayscale", String(grayScaleValue / 100));
        }, 100);
    }, [grayScaleValue]);

    /**
     * Link highlight setting
     */
    useEffect(() => {
        if (linkHighlight) htmlElementRef.current!.classList.add("theme-link-highlight");
        else htmlElementRef.current!.classList.remove("theme-link-highlight");
    }, [linkHighlight]);

    /**
     * Link underline setting
     */
    useEffect(() => {
        if (linkUnderline) htmlElementRef.current!.classList.add("theme-link-underline");
        else htmlElementRef.current!.classList.remove("theme-link-underline");
    }, [linkUnderline]);

    /**
     * Word spacing setting
     */
    useEffect(() => {
        if (wordSpacingValue > 0) htmlElementRef.current!.classList.add("theme-custom-word-space");
        else htmlElementRef.current!.classList.remove("theme-custom-word-space");

        htmlElementRef.current!.style.wordSpacing = `${wordSpacingValue / 10}px`;
    }, [wordSpacingValue]);

    /**
     * Theme pattern setting
     */
    useEffect(() => {
        const patternImages = {
            [PATTERNS.PATTERN_1]: "pattern-1.png",
            [PATTERNS.PATTERN_2]: "pattern-2.png",
            [PATTERNS.PATTERN_NONE]: null,
        };

        let patternImage = patternImages ? patternImages[pattern as PATTERNS] : null;

        if (theme?.includes("contrast")) {
            patternImage = null;
        }

        const patternContainerEl = document.querySelector("#background-pattern") as HTMLDivElement;

        if (patternImage) patternContainerEl.style.backgroundImage = `url("/images/theme-patterns/${patternImage}")`;
        else patternContainerEl.style.backgroundImage = "none";
    }, [pattern, theme]);

    /**
     * Line height setting
     */
    useEffect(() => {
        if (lineHeightValue === 0) {
            htmlElementRef.current!.style.setProperty("--theme-text-line-height", "2");
        } else {
            htmlElementRef.current!.style.setProperty("--theme-text-line-height", String(lineHeightValue / 100 + 2));
        }
    }, [lineHeightValue]);
};

export default useLoadSettings;
