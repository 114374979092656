import { RouteObject } from "react-router-dom";

import Cash from "@/pages/dashboard/cash-income/Cash";
import CashIncomeList from "@/pages/dashboard/cash-income/CasheIncomeList";
import CashItem from "@/pages/dashboard/cash-income/CashItem";

const CashIncomeRoutes: RouteObject[] = [
    {
        path: "cash-income",
        element: <Cash />,
    },
    {
        path: "cash-income/:serviceName",
        element: <Cash />,
    },
    {
        path: "cash-income/list",
        element: <CashIncomeList />,
    },
    {
        path: "cash-income/list/:cashId",
        element: <CashItem />,
    },
];

export default CashIncomeRoutes;
