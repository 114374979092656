import Skeleton from "react-loading-skeleton";

import PlaceholderLayout from "./PlaceholderLayout";

const InvoiceFactorPlaceholder: React.FC = () => {
    return (
        <PlaceholderLayout>
            <div className="flex flex-col">
                <div className="flex w-full items-center justify-center">
                    <Skeleton style={{ borderRadius: "15px" }} containerClassName="mt-8" width={300} height={170} />
                </div>
               <div className="flex justify-center">
                   <div className="flex gap-4 flex-col mt-20 w-full">
                       <div className="flex gep-4">
                           <div className="w-full">
                               <Skeleton containerClassName="block pt-1" width="20%" height={15} />
                               <Skeleton containerClassName="block pt-1" width="100%" height={35} />
                           </div>
                           <div className="w-full">
                               <Skeleton containerClassName="block pt-1" width="20%" height={15} />
                               <Skeleton containerClassName="block pt-1" width="100%" height={35} />
                           </div>
                       </div>
                       <div className="w-full">
                           <Skeleton containerClassName="block pt-1" width="20%" height={15} />
                           <Skeleton containerClassName="block pt-1" width="100%" height={35} />
                       </div>
                   </div>
               </div>

            </div>
        </PlaceholderLayout>
    );
};
export default InvoiceFactorPlaceholder;
