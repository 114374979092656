import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "react-responsive";

import PlaceholderLayout from "./PlaceholderLayout";

type TablePlaceholderProps = {
    placeholderRows?: number;
    placeholderCols?: number;
};

const TablePlaceholder: React.FC<TablePlaceholderProps> = ({ placeholderRows = 10, placeholderCols = 7 }) => {
    const isMobile = useMediaQuery({ maxWidth: 640 });

    const renderHeaders = () => {
        if (isMobile) {
            return <></>;
        }

        return Array(placeholderCols)
            .fill(null)
            .map((_, index) => {
                return (
                    <td
                        key={index}
                        className="h-[50px] first:pr-8 max-sm:[&:nth-child(4)]:hidden max-md:[&:nth-child(5)]:hidden max-lg:[&:nth-child(6)]:hidden max-xl:[&:nth-child(7)]:hidden"
                    >
                        <Skeleton
                            style={{ borderRadius: "10px" }}
                            containerClassName="w-[55px] xs:w-[65px] h-[10px] flex self-stretch"
                        />
                    </td>
                );
            });
    };

    const renderRows = () => {
        const rows = Array(placeholderRows).fill(null);
        const cols = Array(isMobile ? 2 : placeholderCols).fill(null);

        return rows.map((_, index) => {
            return (
                <tr key={index} className="group table-row h-[50px] w-full">
                    {cols.map((_, index) => {
                        if (isMobile && index % 2 === 0) {
                            return (
                                <td
                                    key={index}
                                    className="w-[150px] first:pr-8 group-odd:bg-secondary-250 max-sm:[&:nth-child(4)]:hidden max-md:[&:nth-child(5)]:hidden max-lg:[&:nth-child(6)]:hidden max-xl:[&:nth-child(7)]:hidden"
                                >
                                    <Skeleton
                                        style={{ borderRadius: "10px" }}
                                        containerClassName="w-[55px] xs:w-[65px] h-[10px] flex self-stretch group-odd:brightness-90 group-odd:dark:brightness-125 "
                                    />
                                </td>
                            );
                        }
                        return (
                            <td
                                key={index}
                                className="first:pr-8 group-odd:bg-secondary-250 max-sm:[&:nth-child(4)]:hidden max-md:[&:nth-child(5)]:hidden max-lg:[&:nth-child(6)]:hidden max-xl:[&:nth-child(7)]:hidden"
                            >
                                <Skeleton
                                    style={{ borderRadius: "10px" }}
                                    containerClassName="w-[40px] xs:w-[50px] h-[10px] flex self-stretch group-odd:brightness-90 group-odd:dark:brightness-125 "
                                />
                            </td>
                        );
                    })}
                </tr>
            );
        });
    };

    return (
        <PlaceholderLayout>
            <table className="w-full table-auto">
                <thead className="table-header-group">
                    <tr>{renderHeaders()}</tr>
                </thead>
                <tbody className="table-row-group">{renderRows()}</tbody>
            </table>
        </PlaceholderLayout>
    );
};
export default TablePlaceholder;
