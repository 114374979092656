import { AxiosError } from "axios";
import { createContext, PropsWithChildren, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { THEMES } from "@/constants/common";
import useLocalStorage from "@/hooks/useLocalStorage";
import { Theme, ThemeFontSizes, ThemePatterns } from "@/hooks/useTheme";

type User = {
    name: string;
    email: string;
    mobile: string;
};

export type ContextType = {
    isSidebarExpanded: boolean;
    setIsSidebarExpanded: (isExpanded: boolean) => void;
    user: User | undefined;
    setUser: (user: User) => void;
    isLoggedIn: boolean;
    login: () => void;
    logout: () => void;
    error: AxiosError | undefined;
    setError: (error: AxiosError | undefined) => void;

    isSettingsChanged: boolean;
    setIsSettingsChanged: (isSettingsChanged: boolean) => void;

    // Theme settings types
    theme: Theme;
    setTheme: (theme: Theme) => void;
    isThemeDark: boolean;
    setIsThemeDark: (isThemeDark: boolean) => void;
    systemTheme: boolean;
    setSystemTheme: (systemTheme: boolean) => void;
    pattern: ThemePatterns;
    setPattern: (pattern: ThemePatterns) => void;

    // Appearance settings types
    linkHighlight: boolean;
    setLinkHighlight: (isLinkHighlighted: boolean) => void;
    linkUnderline: boolean;
    setLinkUnderline: (isLinkUnderlined: boolean) => void;
    grayScaleValue: number;
    setGrayScaleValue: (grayScaleValue: number) => void;
    hueRotationValue: number;
    setHueRotationValue: (hueRotationValue: number) => void;
    fontSizeValue: ThemeFontSizes;
    setFontSizeValue: (fontSizeValue: ThemeFontSizes) => void;
    fontWeightValue: number;
    setFontWeightValue: (fontWeightValue: number) => void;
    lineHeightValue: number;
    setLineHeightValue: (lineHeightValue: number) => void;
    wordSpacingValue: number;
    setWordSpacingValue: (wordSpacingValue: number) => void;
    animationStatus: boolean;
    setAnimationStatus: (animationStatus: boolean) => void;
    showThemeButton: boolean;
    setShowThemeButton: (showThemeButton: boolean) => void;
};

export const Context = createContext<ContextType | null>(null);

export const ContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [isSidebarExpandedLocal, setIsSidebarExpandedLocal] = useLocalStorage(
        "sidebar_state",
        window.outerWidth >= 1200 ? "1" : "0",
    );
    const [isSidebarExpanded, setIsSidebarExpanded] = useState(isSidebarExpandedLocal === "1");

    const [error, setError] = useState<AxiosError>();

    const [isSettingsChanged, setIsSettingsChanged] = useState(false);

    // Theme settings states
    const [theme, setTheme] = useState<Theme>(THEMES.DEFAULT_LIGHT);
    const [isThemeDark, setIsThemeDark] = useState(false);
    const [animationStatus, setAnimationStatus] = useState(true);
    const [pattern, setPattern] = useLocalStorage<ThemePatterns>("app-theme-pattern", "");
    const [showThemeButton, setShowThemeButton] = useLocalStorage("app-theme-show-theme-button", true);
    const [systemTheme, setSystemTheme] = useLocalStorage("app-theme-system-theme", false);

    // Appearance settings states
    const [linkHighlight, setLinkHighlight] = useLocalStorage("app-theme-link-highlight", false);
    const [linkUnderline, setLinkUnderline] = useLocalStorage("app-theme-link-underline", false);
    const [grayScaleValue, setGrayScaleValue] = useLocalStorage("app-theme-grayscale", 0);
    const [hueRotationValue, setHueRotationValue] = useLocalStorage("app-theme-hue-rotation", 0);
    const [fontSizeValue, setFontSizeValue] = useLocalStorage<ThemeFontSizes>("app-theme-font-size", "md");
    const [fontWeightValue, setFontWeightValue] = useLocalStorage("app-theme-font-weight", 500);
    const [lineHeightValue, setLineHeightValue] = useLocalStorage("app-theme-line-height", 1);
    const [wordSpacingValue, setWordSpacingValue] = useLocalStorage("app-theme-word-spacing", 0);

    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [user, putUser] = useState<User>();

    const is1200px = useMediaQuery({ minWidth: 1200 });
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (!isFirstRender.current) {
            if (is1200px) {
                setSidebarExpandedState(true);
            } else {
                setSidebarExpandedState(false);
            }
        } else {
            isFirstRender.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is1200px]);

    const setUser = (userData: User) => {
        putUser(userData);
    };

    const login = () => {
        setIsLoggedIn(true);
    };

    const logout = () => {
        setIsLoggedIn(false);
    };

    const setSidebarExpandedState = (state: boolean) => {
        setIsSidebarExpandedLocal(state ? "1" : "0");
        setIsSidebarExpanded(state);
    };

    return (
        <Context.Provider
            value={{
                isSidebarExpanded,
                setIsSidebarExpanded: setSidebarExpandedState,
                isLoggedIn,
                user,
                setUser,
                login,
                logout,
                error,
                setError,

                isSettingsChanged,
                setIsSettingsChanged,

                // Theme states
                theme,
                setTheme,
                isThemeDark,
                setIsThemeDark,
                pattern,
                setPattern,
                showThemeButton,
                setShowThemeButton,
                systemTheme,
                setSystemTheme,

                // Appearance states
                animationStatus,
                setAnimationStatus,
                fontSizeValue,
                setFontSizeValue,
                fontWeightValue,
                setFontWeightValue,
                grayScaleValue,
                setGrayScaleValue,
                hueRotationValue,
                setHueRotationValue,
                linkHighlight,
                setLinkHighlight,
                linkUnderline,
                setLinkUnderline,
                lineHeightValue,
                setLineHeightValue,
                wordSpacingValue,
                setWordSpacingValue,
            }}
        >
            {children}
        </Context.Provider>
    );
};

export default ContextProvider;
