import { FC } from "react";

import IconBriefcase from "@/components/svg/briefcase";
import IconCamera from "@/components/svg/camera";
import IconEnvelopeDuotone from "@/components/svg/envelope-duotone";
import IconFileContract from "@/components/svg/file-contract";
import IconHouseDuotone from "@/components/svg/house-duotone";
import IconIdCard from "@/components/svg/id-card";
import IconMobile from "@/components/svg/mobile";
import IconPhone from "@/components/svg/phone";
import { AccountStatusType } from "@/interfaces/dashboard/layout/panel/panel";

import Status from "./Status";

const verificationTypes = [
    {
        type: "EMAIL",
        icon: IconEnvelopeDuotone,
        tooltip: "ایمیل",
    },
    {
        type: "MOBILE",
        icon: IconMobile,
        tooltip: "شماره همراه",
    },
    {
        type: "NATIONAL_ID",
        icon: IconIdCard,
        tooltip: "عکس کارت ملی",
    },
    {
        type: "CREDIT",
        icon: IconFileContract,
        tooltip: "تعهدنامه",
    },
    {
        type: "PHONE",
        icon: IconPhone,
        tooltip: "تلفن ثابت",
    },
    {
        type: "VIDEO",
        icon: IconCamera,
        tooltip: "ویدیو",
    },
    {
        type: "POSTAL_CODE",
        icon: IconHouseDuotone,
        tooltip: "کد پستی",
    },
    {
        type: "BUSINESS",
        icon: IconBriefcase,
        tooltip: "اطلاعات حقوقی",
    },
];

const AccountStatus: FC<AccountStatusType> = ({ verifications, needVideoVerification }) => {
    const mappedVerifications = verificationTypes
        .filter((verificationType) =>
            verifications?.find((verification) => {
                return verification.verification_type == verificationType.type;
            }),
        )
        .map((verificationType) => {
            const verification = verifications?.find((verification) => {
                return verification.verification_type == verificationType.type;
            });

            return {
                type: verificationType.type,
                icon: verificationType.icon,
                tooltip: verificationType.tooltip,
                status: verification?.resultStatus,
                needVideoVerification: verificationType.type == "VIDEO" ? needVideoVerification : undefined,
            };
        });

    return (
        <div className="flex w-full justify-center xs:justify-start mt-3">
            <div className="flex w-full flex-row-reverse flex-wrap items-center justify-center gap-4 px-2 max-xs:max-w-[200px] xs:justify-between h-full">
                {mappedVerifications?.map((verification) => {
                    return <Status key={verification.type} {...verification} />;
                })}
            </div>
        </div>
    );
};

export default AccountStatus;
