import { AnimatePresence, motion, Variants } from "framer-motion";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import InvoiceAlert from "@/components/invoice/InvoiceAlert";
import InvoiceFactorPlaceholder from "@/components/placeholder/InvoiceFactorPlaceholder";
import PlaceholderLayout from "@/components/placeholder/PlaceholderLayout";
import IconHouse from "@/components/svg/house";
import AlertBox from "@/components/ui/AlertBox";
import Badge from "@/components/ui/Badge";
import BoxContainer from "@/components/ui/BoxContainer";
import Breadcrumb from "@/components/ui/Breadcrumb";
import FactorBox from "@/components/ui/FactorBox";
import Tabs from "@/components/ui/Tabs";
import Title from "@/components/ui/Title";
import PageTitle from "@/components/utility/PageTitle";
import { ONLINE_PAYMENT_LIMIT, POL_TRANSFER_LIMIT } from "@/constants/common";
import useInvoice from "@/hooks/queries/useInvoice";
import useUser from "@/hooks/queries/useUser";
import ApplyVoucher from "@/pages/dashboard/invoice/ApplyVoucher";
import DraftPayment from "@/pages/dashboard/invoice/DraftPayment";
import InvoiceDetail from "@/pages/dashboard/invoice/InvoiceDetail";
import OnlinePayment from "@/pages/dashboard/invoice/OnlinePayment";
import WalletPayment from "@/pages/dashboard/invoice/WalletPayment";
import { formatNumber } from "@/utils";
import { transactionStatus } from "@/utils/cases";

const Invoice = () => {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { data: userData } = useUser();
    const { data: invoice } = useInvoice(Number(params.invoiceId));

    const message = searchParams.get("message");
    const hasError = searchParams.get("error");

    const statusDetails = transactionStatus(invoice?.transaction.status);

    const canAbove25 =
        invoice?.transaction && invoice?.transaction.amount > ONLINE_PAYMENT_LIMIT && !invoice?.can_pay_online;

    const isPaymentDisabled = invoice?.transaction.status !== "INIT" && userData.data.user.can_addfund === 0;

    const [active, setActive] = useState(canAbove25 || (invoice && !invoice?.is_online_active) ? "draft" : "online");

    useEffect(() => {
        setActive(
            (canAbove25 || (invoice && !invoice?.is_online_active)) && invoice?.draft_card?.length > 0
                ? "draft"
                : "online",
        );
    }, [canAbove25, invoice]);

    useEffect(() => {
        if (
            invoice &&
            !invoice?.is_online_active &&
            invoice?.transaction &&
            invoice?.transaction?.id &&
            userData &&
            userData.data.verificationSettings.bank_card_verification_status !== "CONFIRMED"
        ) {
            navigate("/panel/authentication#profile", {
                replace: true,
                state: { label: "draft-verification-redirect", id: invoice.transaction.id },
            });
        }
    }, [invoice, navigate, userData]);

    const tabsVariants: Variants = {
        init: {
            opacity: 0,
            x: "-100%",
        },
        hide: {
            opacity: 0,
            x: "100%",
        },
        show: {
            opacity: 1,
            x: 0,
        },
    };

    return (
        <PlaceholderLayout>
            <PageTitle>صفحه پرداخت</PageTitle>

            <Breadcrumb
                sections={[
                    { link: "/panel", text: "صفحه اصلی", icon: IconHouse },
                    { link: "#", text: `پرداخت صورت حساب ${params.invoiceId}#`, active: true },
                ]}
            />
            <div
                className={`flex gap-10 max-lg:mt-4 max-lg:flex-col-reverse ${
                    invoice?.transaction.status === "PENDING" ? "" : "justify-center"
                }`}
            >
                {invoice?.transaction.status === "INIT" && (
                    <div className="min-lg:w-[55%] w-full">
                        <BoxContainer className="mt-2" colorBox={false}>
                            <Title title="انتخاب روش پرداخت" containerClasses="-m-5" />
                            <div className="-mx-2 mt-10">
                                <Tabs
                                    active={active}
                                    setActive={setActive}
                                    tabs={[
                                        {
                                            id: "draft",
                                            text:
                                                invoice.transaction.amount <= POL_TRANSFER_LIMIT
                                                    ? "حواله یا پُل"
                                                    : "حواله",
                                            disabled: userData.data.user.can_addfund === 0,
                                            hide: invoice.draft_card.length === 0,
                                        },
                                        {
                                            id: "online",
                                            text: "پرداخت آنلاین",
                                            disabled: isPaymentDisabled,
                                            hide: !invoice.is_online_active,
                                        },
                                        {
                                            id: "wallet",
                                            text: "کسر از کیف پول",
                                            disabled: isPaymentDisabled,
                                            hide: invoice?.transaction.type === "addfund",
                                        },
                                    ]}
                                />

                                {message && (
                                    <AlertBox
                                        description={message}
                                        type={hasError == "0" ? "success" : "error"}
                                        className="mb-4 mt-8"
                                    />
                                )}

                                {invoice?.transaction.type !== "addfund" && userData.data.user.can_addfund && (
                                    <ApplyVoucher />
                                )}

                                {invoice ? (
                                    <div className="relative flex flex-col items-center justify-start">
                                        <div className="w-full">
                                            {!isPaymentDisabled ? (
                                                <AnimatePresence mode="popLayout" initial={false}>
                                                    {active === "online" && invoice && invoice?.is_online_active && (
                                                        <motion.div
                                                            transition={{ type: "spring", stiffness: 600, damping: 50 }}
                                                            variants={tabsVariants}
                                                            initial="init"
                                                            animate="show"
                                                            exit="hide"
                                                            key={1}
                                                        >
                                                            <OnlinePayment />
                                                        </motion.div>
                                                    )}
                                                    {active === "draft" && invoice.draft_card.length > 0 && (
                                                        <motion.div
                                                            transition={{ type: "spring", stiffness: 600, damping: 50 }}
                                                            variants={tabsVariants}
                                                            initial="init"
                                                            animate="show"
                                                            exit="hide"
                                                            key={2}
                                                        >
                                                            <DraftPayment draftCard={invoice?.draft_card} />
                                                        </motion.div>
                                                    )}
                                                    {active === "wallet" && (
                                                        <motion.div
                                                            transition={{ type: "spring", stiffness: 600, damping: 50 }}
                                                            variants={tabsVariants}
                                                            initial="init"
                                                            animate="show"
                                                            exit="hide"
                                                            key={3}
                                                        >
                                                            <WalletPayment />
                                                        </motion.div>
                                                    )}
                                                </AnimatePresence>
                                            ) : (
                                                <div className="flex h-full w-full items-center justify-center py-10">
                                                    <InvoiceAlert
                                                        type={invoice.transaction.type}
                                                        status={invoice.transaction.locale_status}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <InvoiceFactorPlaceholder />
                                )}
                            </div>
                        </BoxContainer>
                    </div>
                )}
                <div className="min-lg:w-[45%] w-full">
                    <FactorBox icon={statusDetails.icon} title="فاکتور پرداخت" color={statusDetails.color}>
                        <div
                            className={`flex items-center justify-center gap-3 pb-10 pt-4 text-[2.28rem] text-gold ${
                                invoice && "drop-shadow-gold"
                            }`}
                        >
                            {invoice ? (
                                formatNumber(invoice.transaction.amount)
                            ) : (
                                <Skeleton className="h-[15px]" width={140} />
                            )}
                            <span className="text-[1.14rem]">تومان</span>
                        </div>
                        {invoice?.transaction.order_prices.map((price, index) => {
                            return (
                                <InvoiceDetail
                                    key={index}
                                    title={
                                        price.type == "AMOUNT" && price.title != invoice?.transaction.order ? (
                                            <>
                                                {invoice?.transaction.order}&nbsp;
                                                <span className="text-sm text-text-main">({price.title})</span>
                                            </>
                                        ) : price.type == "DISCOUNT" ? (
                                            "تخفیف " + price.title.replace(/^\s*تخفیف/, "")
                                        ) : (
                                            price.title
                                        )
                                    }
                                    value={
                                        price.type === "DISCOUNT" ? (
                                            <span className="text-red-500">{formatNumber(price.total) + " تومان"}</span>
                                        ) : (
                                            formatNumber(price.total) + " تومان"
                                        )
                                    }
                                />
                            );
                        })}

                        <InvoiceDetail
                            title={invoice?.transaction.type == "addfund" ? "تاریخ" : "تاریخ سفارش"}
                            value={invoice?.transaction.created_at}
                        />
                        <InvoiceDetail title={"شماره صورت حساب"} value={invoice?.transaction.id} />
                        <InvoiceDetail
                            title="وضعیت"
                            value={
                                invoice ? (
                                    <Badge customColor={statusDetails.color}>{invoice.transaction.locale_status}</Badge>
                                ) : undefined
                            }
                        />
                    </FactorBox>
                </div>
            </div>
        </PlaceholderLayout>
    );
};

export default Invoice;
