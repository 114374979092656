import { useQuery } from "@tanstack/react-query";

import gate from "@/gate";
import { CashCreateResponse } from "@/interfaces/dashboard/order/order";

const useCashCreate = (serviceName: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return useQuery<any, Error, CashCreateResponse>(
        [serviceName + "_instruction"],
        gate.getCashInstruction.bind(null, serviceName || ""),
        {
            refetchOnWindowFocus: false,
            enabled: false,
        },
    );
};

export default useCashCreate;
