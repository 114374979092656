import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";

import ToastError from "@/components/error/ToastError";
import gate from "@/gate";
import useAuth from "@/hooks/useAuth";

type TwoFaValidateRequest = {
    isGoogleTwoFA: boolean;
    email: string;
    token: string;
};

const useTwoFaValidate = (fromBroker = false) => {
    const { setAuthToken } = useAuth();
    const navigate = useNavigate();
    const [params] = useSearchParams();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return useMutation<any, Error, TwoFaValidateRequest>({
        mutationFn: gate.twoFAValidation,
        onError(error) {
            ToastError(error);
        },
        onSuccess(data) {
            if (data && data.token) {
                setAuthToken(data.token);

                toast.success("خوش آمدید!");
                if (fromBroker) {
                    navigate("/broker/base-authentication");
                } else {
                    navigate(params.has("redirect") ? params.get("redirect") || "/panel" : "/panel", { replace: true });
                }
            } else {
                ToastError(new Error("خطایی رخ داده است"));
            }
        },
    });
};

export default useTwoFaValidate;
