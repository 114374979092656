import { useMutation } from "@tanstack/react-query";

import ToastError from "@/components/error/ToastError";
import gate from "@/gate";

const useSendVideoVerification = (setProgress: (progress: number) => void) => {
    return useMutation({
        mutationFn: (data: any) =>
            gate.sendVideoVerification(data, (e) => {
                const progress = Math.round(e.progress! * 100);
                if (progress < 100) {
                    setProgress(progress);
                } else {
                    setTimeout(() => setProgress(0), 2000);
                }
            }),
        onError: (error: Error) => {
            ToastError(error);
        },
    });
};

export default useSendVideoVerification;
