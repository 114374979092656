import { useEffect, useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";

import Spinner from "@/components/loader/Spinner";
import Button from "@/components/ui/Button";
import CustomCheckbox from "@/components/ui/CustomCheckbox";
import Input from "@/components/ui/Input";
import Select from "@/components/ui/Select";
import Textarea from "@/components/ui/Textarea";
import { CashCreateResponse, CashCreateService } from "@/interfaces/dashboard/order/order";

import CashRefundOptions from "../CashRefundOptions";

type PerfectmoneyVoucherCashRequest = {
    service: string;
    voucher: string;
    voucher_code: string;
    fk_currency_id: number;
    cash_type: "ADD_TO_ACCOUNT_BALANCE" | "REFUND";
    bank_id: number;
    user_desc?: string;
    rules: boolean;
};

type ServiceInstruction = {
    instructions?: CashCreateResponse;
    isLoading: boolean;
    onFormSubmit: (data: PerfectmoneyVoucherCashRequest) => void;
};

type PerfectmoneyVoucherCash = {
    voucher: string;
    voucher_code: string;
    currency: {
        label: string;
        value: number;
    };
    cash_type: "ADD_TO_ACCOUNT_BALANCE" | "REFUND";
    bank_id?: {
        label: string;
        value: number;
    };
    user_desc?: string;
    rules: boolean;
    is_immediate: boolean;
};

const PerfectmoneyVoucherCashForm: React.FC<ServiceInstruction> = ({ instructions, onFormSubmit, isLoading }) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const [serviceData, setServiceData] = useState<CashCreateService>(instructions?.service);

    const [acceptRules, setAcceptRules] = useState<boolean>(false);

    const [isImmediate, setIsImmediate] = useState<boolean>(false);

    const { serviceName } = useParams();

    const { control, handleSubmit } = useForm<PerfectmoneyVoucherCash>({
        defaultValues: {
            voucher: "",
            voucher_code: "",
            currency: {
                value: serviceData?.currencies[0]?.id,
                label: serviceData?.currencies[0]?.name,
            },
            user_desc: "",
            cash_type: "ADD_TO_ACCOUNT_BALANCE",
        },
    });

    const [watchedCashType] = useWatch({
        control,
        name: ["cash_type"],
        defaultValue: {
            cash_type: "ADD_TO_ACCOUNT_BALANCE",
        },
    });

    useEffect(() => {
        if (instructions) {
            setServiceData(instructions.service);
        }
    }, [instructions]);

    const submitHandler: SubmitHandler<PerfectmoneyVoucherCash> = (data) => {
        const submitData = {
            service: serviceName?.replace("sell-", "").toUpperCase() || "",
            voucher: data.voucher,
            voucher_code: data.voucher_code,
            fk_currency_id: data.currency.value,
            user_desc: data.user_desc,
            cash_type: data.cash_type,
            bank_id: data.bank_id?.value || -1,
            rules: acceptRules,
            is_immediate: isImmediate,
        };

        onFormSubmit(submitData);
    };

    return (
        <form id="order-form" onSubmit={handleSubmit(submitHandler)}>
            <div className="flex flex-col gap-4">
                <Input
                    containerClassName="w-full"
                    variant="fill"
                    required
                    name="voucher"
                    topTitle="کد ووچر (e-voucher)"
                    inputMode="text"
                    type="text"
                    ltr
                    placeholder="کد ووچر"
                    control={control}
                    rules={{
                        required: "کد ووچر الزامی است",
                    }}
                />
                <Input
                    containerClassName="w-full"
                    variant="fill"
                    required
                    name="voucher_code"
                    topTitle="کد فعال سازی (Activation code)"
                    inputMode="text"
                    type="text"
                    ltr
                    placeholder="کد فعال سازی"
                    control={control}
                    rules={{
                        required: "کد فعال سازی الزامی است",
                    }}
                />
                <Select
                    control={control}
                    topTitle="نوع ارز"
                    required
                    name="currency"
                    variant="fill"
                    placeholder="انتخاب کنید"
                    rules={{
                        required: "انتخاب نوع ارز الزامی است",
                    }}
                    defaultValue={{
                        value: serviceData.currencies[0]?.id,
                        label: serviceData.currencies[0]?.name,
                    }}
                    options={serviceData.currencies.map((currency: { id: number; name: string }) => {
                        return {
                            value: currency.id,
                            label: currency.name,
                        };
                    })}
                />

                {instructions && instructions?.can_choose_payment_method && (
                    <CashRefundOptions
                        control={control}
                        balance={instructions.balance}
                        instructions={instructions}
                        cashType={watchedCashType}
                        onIsImmediateChange={(value: boolean) => setIsImmediate(value)}
                    />
                )}

                <Textarea
                    name="user_desc"
                    control={control}
                    label="توضیحات"
                    placeholder="توضیحات خود را اینجا بنویسید"
                />

                <CustomCheckbox
                    onChange={() => setAcceptRules((prev) => !prev)}
                    label={
                        <span>
                            <a
                                href="https://avalpardakht.com/terms"
                                target="_blank"
                                rel="noreferrer nofollow"
                                className="text-primary hover:text-primary"
                            >
                                قوانین و مقررات
                            </a>{" "}
                            سایت را مطالعه کرده‌ام و آن را قبول دارم.
                        </span>
                    }
                />
                <Button type="submit" disabled={!acceptRules || isLoading} disabledTooltip="دکمه غیرفعال است">
                    {isLoading ? <Spinner /> : "ثبت درخواست"}
                </Button>
            </div>
        </form>
    );
};

export default PerfectmoneyVoucherCashForm;
