import { useMemo } from "react";

import Tab from "@/components/ui/Tabs/Tab";

type TabsProps = {
    active: string;
    setActive: (active: string) => void;
    tabs: { id: string; text: string; disabled?: boolean ,hide ?: boolean}[];
};

const Tabs = ({ tabs, active, setActive }: TabsProps) => {
    const renderTabs = useMemo(() => {
        return tabs.filter(tab => !tab.hide).map((tab) => (
            <Tab
                id={tab.id}
                text={tab.text}
                active={active}
                setActive={setActive}
                disabled={tab.disabled}
                key={tab.id}
            />
        ));
    }, [active, tabs]);

    return <div className="flex w-full select-none rounded-[10px] bg-secondary-400 dark:bg-secondary-200 contrast:!bg-transparent contrast:border p-2">{renderTabs}</div>;
};

export default Tabs;
