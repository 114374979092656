import { useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

import Button from "@/components/ui/Button";
import Input from "@/components/ui/Input";
import useInvoice from "@/hooks/queries/useInvoice";
import useVoucher from "@/hooks/queries/useVoucher";

const ApplyVoucher = () => {
    const params = useParams();
    const [voucher, setVoucher] = useState("");
    const [voucherError, setVoucherError] = useState<string | undefined>();

    const { refetch: refetchInvoice } = useInvoice(Number(params.invoiceId));

    const {
        mutateAsync: applyVoucher,
        isLoading: isVoucherLoading,
        isSuccess,
        reset: resetVoucherStatus,
    } = useVoucher();

    const onVoucherSubmit = async () => {
        if (voucher) {
            const response = await applyVoucher({ id: Number(params.invoiceId), code: voucher });

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (response && response?.message) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                toast.success(response.message);
                refetchInvoice();
            }
        } else {
            setVoucherError("پر کردن فیلد کد تخفیف برای اعمال تخفیف الزامی است");
        }
    };

    return (
        <div className="flex w-full flex-col py-4">
            <div className={`flex w-full items-end justify-center gap-4`}>
                <Input
                    type="text"
                    value={voucher}
                    onChange={(e) => {
                        setVoucher(e.target.value);
                        resetVoucherStatus();
                        setVoucherError(e.target.value ? "" : "پر کردن فیلد کد تخفیف برای اعمال تخفیف الزامی است");
                    }}
                    topTitle="کد تخفیف"
                    variant="fill"
                    dir="auto"
                    showConfirmedStatus={isSuccess}
                    placeholder="کد تخفیف خود را وارد کنید"
                    containerClassName="flex-1"
                    readOnly={isSuccess}
                />
                <Button
                    type="button"
                    onClick={onVoucherSubmit}
                    loading={isVoucherLoading}
                    disabled={isVoucherLoading || isSuccess}
                    disabledTooltip="دکمه غیرفعال است"
                    className="w-[90px] xs:w-[150px]"
                    variant="stroke"
                    color="success"
                >
                    اعمال کد
                </Button>
            </div>
            <span className="mt-2 text-sm text-error">{voucherError}</span>
        </div>
    );
};

export default ApplyVoucher;
