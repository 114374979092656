import { useEffect, useMemo, useRef, useState } from "react";
import { Control, RegisterOptions } from "react-hook-form";

import IconCirclePlus from "@/components/svg/circle-plus";
import FileInputButton from "@/components/ui/FileInput/FileInputButton";

type FileInputProps = {
    disabled?: boolean;
    name: string;
    rules?: RegisterOptions<any, string> | undefined;
    control: Control<any>;
    buttonClassName?: string;
    className?: string;
    buttonContainerClassName?: string;
    repeater?: boolean;
    onReset: (fieldName: any) => void;
    direction?: "row" | "column";
};

const FileInput = ({
    repeater,
    name,
    disabled,
    rules,
    control,
    buttonClassName,
    className,
    buttonContainerClassName,
    onReset,
    direction = "row",
}: FileInputProps) => {
    const [inputsIds, setInputsIds] = useState([0]);
    const lastInputsCount = useRef(0);

    useEffect(() => {
        if (inputsIds.length > 1 && inputsIds.length > lastInputsCount.current) {
            const inputEl = document.querySelector(`#file-input-${inputsIds[inputsIds.length - 1]}`) as HTMLInputElement;
            inputEl?.click();
        }
    }, [inputsIds]);

    const renderInputs = useMemo(() => {
        return inputsIds.map((id) => {
            return (
                <FileInputButton
                    key={id}
                    id={id}
                    inputsIds={inputsIds}
                    setInputsIds={setInputsIds}
                    disabled={disabled}
                    name={repeater ? `${name}[file-${id}]` : name}
                    onReset={onReset}
                    className={buttonClassName}
                    containerClassName={buttonContainerClassName}
                    rules={rules}
                    control={control}
                />
            );
        });
    }, [inputsIds, repeater, disabled]);

    return (
        <div
            className={`flex ${
                direction === "column" ? "flex-col" : " items-end"
            } flex-wrap gap-x-5 gap-y-8 ${className}`}
        >
            {renderInputs}
            {repeater && (
                <button
                    disabled={disabled}
                    type="button"
                    onClick={() => {
                        setInputsIds((oldVal) => {
                            lastInputsCount.current = oldVal.length;
                            return [...oldVal, oldVal[oldVal.length - 1] + 1];
                        });
                    }}
                    className="group flex h-[50px] flex-row-reverse items-center justify-center gap-4 rounded-full border border-primary/50 bg-primary/10 px-5 outline outline-[3px] outline-transparent transition-all duration-100 hover:bg-primary/[0.15] active:outline-primary/50"
                >
                    <IconCirclePlus className="h-[25px] fill-primary" />
                    افزودن فایل
                </button>
            )}
        </div>
    );
};

export default FileInput;
