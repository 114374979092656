import toast from "react-hot-toast";
import Skeleton from "react-loading-skeleton";

import ToastError from "@/components/error/ToastError";
import IconCopyRegular from "@/components/svg/copy-regular";

type PaymentAnnouncementCopyTextProps = {
    text: string;
    title?: string;
};

const PaymentAnnouncementCopyText: React.FC<PaymentAnnouncementCopyTextProps> = ({ text, title }) => {
    const copy = async (text: string | undefined) => {
        if (text) {
            try {
                await navigator.clipboard.writeText(text);
                toast.success("کپی شد");
            } catch (e) {
                ToastError(new Error("کپی نشد"));
            }
        }
    };

    return (
        <div className="flex w-full flex-col text-center">
            <span className="mb-1 text-sm text-text-paragraph">{title}</span>
            <div className="group flex cursor-pointer justify-center gap-4 text-text-paragraph transition-colors hover:text-text-main">
                <p
                    className="max-w-[250px] overflow-hidden text-ellipsis whitespace-nowrap font-sans"
                    onClick={() => copy(text)}
                >
                    {text ?? <Skeleton width={250} height={15} />}
                </p>
                <span className="cursor-pointer" onClick={() => copy(text)}>
                    <IconCopyRegular className="w-4 fill-text-paragraph group-hover:fill-text-main" />
                </span>
            </div>
        </div>
    );
};

export default PaymentAnnouncementCopyText;
