import { useQuery } from "@tanstack/react-query";

import gate from "@/gate";
import { CategoryPriceType} from "@/interfaces/dashboard/order/order";

const useCategoryPrices = (serviceName: string | undefined) => {
    return useQuery<unknown, Error, CategoryPriceType>({
        queryFn: gate.getCategoryPrices.bind(null, serviceName || ""),
        queryKey: ["price_" + serviceName?.toLowerCase()],
        initialData: [],
        enabled: false,
    });
};

export default useCategoryPrices;
