import useTheme from "@/hooks/useTheme";

const EmptySVG = () => {
    const { isThemeDark } = useTheme();

    const mainFill = isThemeDark ? "#333949" : "#f9f9f9";
    const secondaryFill = isThemeDark ? "#282D3B" : "#fff";

    return (
        <svg viewBox="0 0 490 215" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_389_2389)">
                <path
                    d="M116.277 74.2132C115.502 74.2141 114.759 74.5236 114.21 75.0739C113.662 75.6242 113.354 76.3703 113.353 77.1486V177.268C113.354 178.046 113.662 178.792 114.21 179.343C114.759 179.893 115.502 180.203 116.277 180.204H456.336C457.111 180.203 457.854 179.893 458.402 179.343C458.95 178.792 459.259 178.046 459.26 177.268V77.1486C459.259 76.3703 458.95 75.6242 458.402 75.0739C457.854 74.5236 457.111 74.214 456.336 74.2131L116.277 74.2132Z"
                    fill={mainFill}
                />
                <path d="M120.748 172.809H451.865V81.6079H120.748V172.809Z" fill={secondaryFill} />
            </g>
            <g filter="url(#filter1_d_389_2389)">
                <path
                    d="M32.924 51.8926C32.1488 51.8935 31.4056 52.2031 30.8575 52.7534C30.3093 53.3037 30.0009 54.0498 30 54.828L30 154.948C30.0009 155.726 30.3093 156.472 30.8575 157.022C31.4056 157.572 32.1488 157.882 32.924 157.883H372.983C373.758 157.882 374.501 157.572 375.049 157.022C375.597 156.472 375.906 155.726 375.907 154.948V54.828C375.906 54.0498 375.597 53.3037 375.049 52.7534C374.501 52.2031 373.758 51.8935 372.983 51.8926L32.924 51.8926Z"
                    fill={mainFill}
                />
                <path d="M37.3945 150.488H368.512V59.2871H37.3945V150.488Z" fill={secondaryFill} />
                <path
                    d="M240.612 99.5468H123.391C121.938 99.5468 120.545 98.9408 119.518 97.8622C118.491 96.7836 117.914 95.3207 117.914 93.7954C117.914 92.27 118.491 90.8071 119.518 89.7285C120.545 88.6499 121.938 88.0439 123.391 88.0439H240.612C242.064 88.0439 243.457 88.6499 244.484 89.7285C245.512 90.8071 246.088 92.27 246.088 93.7954C246.088 95.3207 245.512 96.7836 244.484 97.8622C243.457 98.9408 242.064 99.5468 240.612 99.5468Z"
                    fill={mainFill}
                />
            </g>
            <g filter="url(#filter2_d_389_2389)">
                <path
                    d="M98.2394 26C97.4642 26.0009 96.721 26.3105 96.1729 26.8608C95.6247 27.4111 95.3163 28.1572 95.3154 28.9355V129.055C95.3163 129.833 95.6247 130.579 96.1729 131.13C96.721 131.68 97.4642 131.989 98.2394 131.99H438.298C439.073 131.989 439.816 131.68 440.365 131.13C440.913 130.579 441.221 129.833 441.222 129.055V28.9354C441.221 28.1572 440.913 27.4111 440.365 26.8608C439.816 26.3105 439.073 26.0009 438.298 26L98.2394 26Z"
                    fill={mainFill}
                />
                <path d="M102.71 124.596H433.828V33.3948L102.71 33.3948V124.596Z" fill={secondaryFill} />
                <path
                    d="M305.927 73.6544H188.706C187.254 73.6544 185.861 73.0485 184.834 71.9699C183.806 70.8913 183.229 69.4284 183.229 67.903C183.229 66.3777 183.806 64.9148 184.834 63.8362C185.861 62.7576 187.254 62.1516 188.706 62.1516H305.927C307.38 62.1516 308.773 62.7576 309.8 63.8362C310.827 64.9148 311.404 66.3777 311.404 67.903C311.404 69.4284 310.827 70.8913 309.8 71.9699C308.773 73.0485 307.38 73.6544 305.927 73.6544Z"
                    fill={mainFill}
                />
                <path
                    d="M362.893 73.6541C361.44 73.6541 360.047 74.2167 359.02 75.2183C357.993 76.2198 357.416 77.5783 357.416 78.9947C357.416 80.4111 357.993 81.7695 359.02 82.771C360.047 83.7726 361.44 84.3353 362.893 84.3353H384.803C386.256 84.3353 387.649 83.7726 388.676 82.771C389.704 81.7695 390.281 80.4111 390.281 78.9947C390.281 77.5783 389.704 76.2198 388.676 75.2183C387.649 74.2167 386.256 73.6541 384.803 73.6541H362.893Z"
                    fill="#38BDF8"
                />
                <path
                    d="M143.381 107.341C159.036 107.341 171.727 94.6504 171.727 78.9952C171.727 63.34 159.036 50.6489 143.381 50.6489C127.726 50.6489 115.035 63.34 115.035 78.9952C115.035 94.6504 127.726 107.341 143.381 107.341Z"
                    fill="#38BDF8"
                />
                <path
                    d="M187.845 88.4441C186.621 88.4441 185.447 88.9202 184.581 89.7677C183.716 90.6151 183.229 91.7646 183.229 92.9631C183.229 94.1616 183.716 95.311 184.581 96.1585C185.447 97.0059 186.621 97.482 187.845 97.482H236.128C237.352 97.482 238.526 97.0059 239.392 96.1585C240.257 95.311 240.744 94.1616 240.744 92.9631C240.744 91.7646 240.257 90.6151 239.392 89.7677C238.526 88.9202 237.352 88.4441 236.128 88.4441H187.845Z"
                    fill={mainFill}
                />
                <path
                    d="M251.932 88.4438C250.708 88.4438 249.534 88.92 248.669 89.7674C247.803 90.6149 247.317 91.7643 247.317 92.9628C247.317 94.1613 247.803 95.3107 248.669 96.1582C249.534 97.0057 250.708 97.4818 251.932 97.4818H300.216C301.44 97.4818 302.614 97.0057 303.479 96.1582C304.345 95.3107 304.831 94.1613 304.831 92.9628C304.831 91.7643 304.345 90.6149 303.479 89.7674C302.614 88.92 301.44 88.4438 300.216 88.4438H251.932Z"
                    fill={mainFill}
                />
                <path
                    d="M419.569 80.9769C418.857 80.0259 417.92 79.2728 416.846 78.7871C415.52 78.2197 414.151 77.763 412.753 77.4216V69.2996C414.902 69.6288 416.919 70.5625 418.573 71.9951L420.197 68.4803C419.263 67.6356 418.191 66.963 417.03 66.4928C415.742 65.9634 414.391 65.6113 413.011 65.446V61.3293H409.252V65.4864C407.801 65.652 406.4 66.1296 405.144 66.8873C404.01 67.5693 403.055 68.5202 402.361 69.6587C401.699 70.7697 401.355 72.0467 401.37 73.3454C401.305 74.7528 401.707 76.1416 402.51 77.2901C403.25 78.2845 404.227 79.0712 405.348 79.576C406.695 80.1601 408.087 80.6287 409.511 80.9769V88.76C406.974 88.5656 404.557 87.586 402.585 85.9532L400.962 89.4731C402.064 90.3989 403.318 91.1212 404.666 91.6072C406.144 92.1442 407.687 92.4709 409.252 92.5782V96.6595H413.011V92.5024C415.1 92.2706 417.058 91.3587 418.593 89.9029C419.269 89.2443 419.804 88.4502 420.162 87.5709C420.52 86.6917 420.695 85.7465 420.675 84.7951C420.737 83.4326 420.348 82.0882 419.569 80.9769ZM409.511 76.5619C408.595 76.2798 407.744 75.8157 407.007 75.1964C406.714 74.9323 406.483 74.6058 406.33 74.2403C406.177 73.8748 406.105 73.4794 406.12 73.0825C406.093 72.1817 406.419 71.3068 407.026 70.6499C407.7 69.9607 408.569 69.5027 409.511 69.3401V76.5619ZM415.113 87.3237C414.478 87.9854 413.65 88.4231 412.753 88.5729V81.8771C413.611 82.1109 414.414 82.5186 415.113 83.0756C415.378 83.3055 415.588 83.5932 415.729 83.9171C415.869 84.2411 415.936 84.593 415.924 84.9468C415.959 85.8148 415.669 86.6639 415.113 87.3237Z"
                    fill="#38BDF8"
                />
                <path
                    d="M143.377 97.4818L154.473 81.8708L143.377 88.4157L132.289 81.8708L143.377 97.4818H143.377Z"
                    fill="white"
                />
                <path
                    d="M143.38 85.9791H143.381L154.473 79.2889L143.381 60.5085H143.38L132.289 79.2889L143.38 85.9791Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_389_2389"
                    x="83.353"
                    y="48.2131"
                    width="405.907"
                    height="165.99"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="15" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_389_2389" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_389_2389" result="shape" />
                </filter>
                <filter
                    id="filter1_d_389_2389"
                    x="0"
                    y="25.8926"
                    width="405.907"
                    height="165.99"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="15" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_389_2389" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_389_2389" result="shape" />
                </filter>
                <filter
                    id="filter2_d_389_2389"
                    x="65.3154"
                    y="0"
                    width="405.907"
                    height="165.99"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="15" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_389_2389" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_389_2389" result="shape" />
                </filter>
            </defs>
        </svg>
    );
};

export default EmptySVG;
