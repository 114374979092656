import { useMutation } from "@tanstack/react-query";

import ToastError from "@/components/error/ToastError";
import gate from "@/gate";

const useUpdateAvatar = () => {
    return useMutation({
        mutationFn: gate.updateAvatar,
    });
};

export default useUpdateAvatar;
