import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import Button from "@/components/ui/Button";
import { ServiceTypeSummary } from "@/interfaces/dashboard/order/order";
import { formatNumber } from "@/utils";

import ServicesPricePlaceholder from "../placeholder/ServicesPricePlaceholder";

type DetailsProps = {
    prices: { title: string; price: number }[];
    description: string;
    isLoading: "0" | "1";
    service: ServiceTypeSummary | undefined;
};

const Details: React.FC<DetailsProps> = ({ prices, description, isLoading, service }) => {
    const navigate = useNavigate();
    const renderPrices = useMemo(() => {
        return prices?.map((item, index) => {
            return (
                <div className="flex w-full items-center justify-between" key={index}>
                    <span className="text-sm text-text-main dark:text-white lg:text-base">{item.title}</span>
                    <div className="flex items-center justify-end gap-2">
                        <span className="text-primary">{formatNumber(item.price)}</span>
                        <span className="text-sm text-text-main dark:text-white lg:text-base">تومان</span>
                    </div>
                </div>
            );
        });
    }, [prices]);

    return (
        <div className="sticky top-[70px] overflow-y-hidden mt-2 hidden w-1/2 flex-col items-start justify-start gap-3 md:flex">
            <div className="w-full">
                <div className="w-full px-4 pb-3 text-[1.42rem] text-text-main dark:text-white">قیمت</div>
                {isLoading == "0" ? (
                    <div className="flex w-full flex-col items-start justify-start gap-4 rounded-[10px] bg-secondary-300 contrast:bg-transparent contrast:border p-4">
                        {renderPrices}
                    </div>
                ) : (
                    <ServicesPricePlaceholder />
                )}
            </div>
            {service && (
                <Button
                    className="mt-4 w-full !gap-1 !text-[1rem]"
                    onClick={() =>
                        navigate(
                            service.id == 0 || service.id == -1
                                ? `/panel/cash-income/${service.slug}`
                                : `/panel/order/new/${service.slug}`,
                        )
                    }
                >
                    {service.id != 0 && service.id != -1 && <span>ثبت سفارش</span>}
                    {service.title}
                </Button>
            )}
            <div
                className="custom-scrollbar mt-4 max-h-[500px] min-h-[500px] w-full overflow-x-hidden overflow-y-hidden px-4 pb-[120px] pr-[18px] leading-[2.5] text-text-paragraph hover:overflow-y-auto hover:pr-[8px]"
                dangerouslySetInnerHTML={{ __html: description }}
            ></div>
            <div className="h-[100px] w-full translate-y-[-100px] bg-gradient-to-t from-secondary-400 to-transparent"></div>
        </div>
    );
};

export default Details;
