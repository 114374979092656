import Skeleton from "react-loading-skeleton";

import PlaceholderLayout from "./PlaceholderLayout";

const ServicesListPlaceholder: React.FC = () => {
    return (
        <PlaceholderLayout>
            <Skeleton
                style={{ borderRadius: "10px" }}
                containerClassName="w-full flex self-stretch"
                height={"100%"}
            />
        </PlaceholderLayout>
    );
};
export default ServicesListPlaceholder;
