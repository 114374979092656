import Skeleton from "react-loading-skeleton";

import { formatNumber } from "@/utils";

type CashTotalProps = {
    total: number;
    isLoading: boolean;
};

const CashTotal: React.FC<CashTotalProps> = ({ total, isLoading }) => {
    return (
        <div className="flex items-center justify-between rounded-[8px] bg-secondary p-3">
            <label className="font-bold text-text-main">
                <span className="hidden lg:inline">میزان</span> دریافتی تومانی در این لحظه
            </label>
            <span className="text-lg font-bold text-yellow-500">
                {isLoading ? (
                    <Skeleton width={90} />
                ) : (
                    <>
                        {formatNumber(total)} <span className="text-sm">تومان</span>
                    </>
                )}
            </span>
        </div>
    );
};

export default CashTotal;
