import { useMemo } from "react";

import WizardSlider from "@/components/profile/authentication/WizardSlider";
import PageTitle from "@/components/utility/PageTitle";
import useUser from "@/hooks/queries/useUser";
import { VerificationStatus } from "@/interfaces/dashboard/layout/panel/panel";
import { verificationStatus } from "@/utils";

const Authentication = () => {
    const { data: user } = useUser();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const stepStatus = (type: any, status: any) => {
        return verificationStatus(user, type)(status);
    };

    const getVerificationValue = (verification: string): VerificationStatus => {
        return user.data.verifications.find((item) => {
            return item.verification_type === verification;
        })?.resultStatus;
    };

    const getPhonePostalVerification = () => {
        const PHONE = getVerificationValue("PHONE");
        const ADDRESS = getVerificationValue("POSTAL_CODE");

        return PHONE === "CANCELED" || PHONE === "INIT" || ADDRESS === "CANCELED" || ADDRESS === "INIT"
            ? "INIT"
            : PHONE === "PENDING" || ADDRESS === "PENDING"
            ? "PENDING"
            : PHONE === "REJECTED" || ADDRESS === "REJECTED"
            ? "REJECTED"
            : "CONFIRMED";
    };

    const stepsProgress = useMemo(() => {
        const progress = {
            step0: getVerificationValue("BUSINESS"),
            step1:
                user.data.verificationSettings.bank_card_verification_status === "UNVERIFIED"
                    ? "INIT"
                    : user.data.verificationSettings.bank_card_verification_status,
            step2: getVerificationValue("NATIONAL_ID"),
            step3: getVerificationValue("CREDIT"),
            step4: getPhonePostalVerification(),
            step5: getVerificationValue("VIDEO"),
        };

        return progress;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const stepsDisabled = useMemo(() => {
        const step1Status = user.data.verificationSettings.bank_card_verification_status;

        return {
            step1: stepStatus("BUSINESS", "CANCELED") || stepStatus("BUSINESS", "REJECTED"),
            step2:
                step1Status ===
                "UNVERIFIED" /* || stepStatus("EMAIL", "CANCELED") || stepStatus("EMAIL", "REJECTED") */,
            step3:
                stepStatus("NATIONAL_ID", "CANCELED") ||
                stepStatus("NATIONAL_ID", "REJECTED") ||
                stepStatus("NATIONAL_ID", "INIT"),
            step4: stepStatus("CREDIT", "CANCELED") || stepStatus("CREDIT", "REJECTED") || stepStatus("CREDIT", "INIT"),
            step5:
                stepStatus("PHONE", "CANCELED") ||
                stepStatus("PHONE", "REJECTED") ||
                stepStatus("PHONE", "INIT") ||
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                (!user.data.verifications.find((item) => item == "POSTAL_CODE") &&
                    (stepStatus("POSTAL_CODE", "CANCELED") ||
                        stepStatus("POSTAL_CODE", "REJECTED") ||
                        stepStatus("POSTAL_CODE", "INIT"))),
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
        <div className="w-full overflow-x-hidden">
            <PageTitle>احرازهویت</PageTitle>

            {/* // eslint-disable-next-line react/jsx-no-comment-textnodes
            //@ts-ignore */}
            <WizardSlider stepsProgress={stepsProgress} stepsDisabled={stepsDisabled} />
        </div>
    );
};

export default Authentication;
