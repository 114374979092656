import { AnimatePresence, motion, Variants } from "framer-motion";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import VideoPlaceholder from "@/assets/videos/verification-video-placeholder.mp4";
import SlideSection from "@/components/profile/authentication/SlideSection";
import Webcam from "@/components/profile/authentication/Webcam";
import IconCameraRotate from "@/components/svg/camera-rotate";
import IconSpinnerThirdDuotone from "@/components/svg/spinner-third-duotone";
import IconTrash from "@/components/svg/trash";
import IconUploadDuotone from "@/components/svg/upload-duotone";
import IconVideo from "@/components/svg/video";
import IconVideoSlash from "@/components/svg/video-slash";
import Button from "@/components/ui/Button";
import UploadStatus from "@/components/ui/UploadStatus";
import useReportError from "@/hooks/queries/error/useReportError";
import useSendVideoVerification from "@/hooks/queries/useSendVideoVerification";
import useUser from "@/hooks/queries/useUser";

type VideoVerification = {
    isActive: boolean;
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
};

const VideoVerification = ({ isActive, setActiveIndex }: VideoVerification) => {
    const { data: user, refetch: refetchUser } = useUser();
    const [videoUploadProgress, setVideoUploadProgress] = useState(0);
    const { mutateAsync: sendVideoVerification, isLoading: isSendVideoLoading } =
        useSendVideoVerification(setVideoUploadProgress);

    const videoVerificationStatus = user.data.user.need_video_verification
        ? user.data.verifications.find((verification) => {
              return verification.verification_type === "VIDEO";
          })?.resultStatus ?? "INIT"
        : "CONFIRMED";

    const videoVerificationStatusMessage = useMemo(() => {
        if (videoVerificationStatus === "INIT") return "لطفا ویدیو خود را ضبط کنید";
        else if (videoVerificationStatus === "PENDING") return "ویدیو در حال بررسی است";
        else if (videoVerificationStatus === "CONFIRMED") return "ویدیو تایید شده است";
        else return "ویدیو رد شده است مجدد ارسال کنید";
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const canCaptureVideo = videoVerificationStatus !== "CONFIRMED" && videoVerificationStatus !== "PENDING";

    const webcamRef = useRef<Webcam>(null);
    const mediaRecorderRef = useRef<MediaRecorder>();
    const [error, setError] = useState<string | null>(null);
    const [isWebcamLoading, setIsWebcamLoading] = useState(true);

    const captureTimer = useRef<number>();
    const textTimer = useRef<number>();
    const stopTimer = useRef<number>();
    const [duration, setDuration] = useState(0);

    const [capturing, setCapturing] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState<Blob>();
    const [deviceIndex, setDeviceIndex] = useState(0);
    const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);

    const [url, setUrl] = useState("");
    const [text, setText] = useState<string[]>([]);
    const [textIndex, setTextIndex] = useState(-1);
    const [mimeType, setMimeType] = useState<string>();

    const { report } = useReportError();

    const deviceIsFront = useMemo(() => {
        if (devices.length !== 0) {
            const frontDevice = devices.find((device) => {
                const x = device.label.toLocaleLowerCase().includes("front");
                return x;
            });

            if (!frontDevice && devices.length === 1) {
                return true;
            }

            if (frontDevice && frontDevice.deviceId === devices[deviceIndex].deviceId) {
                return true;
            }
        }
        return false;
    }, [devices, deviceIndex]);

    // generate user text when user is available
    useEffect(() => {
        const mainText = user ? user.data.verificationSettings.video_verification_text : "";

        setText(mainText.split(" "));
    }, [user]);

    // check user media devices on first render
    useEffect(() => {
        if (canCaptureVideo) {
            navigator.mediaDevices.enumerateDevices().then((devices) => {
                const videoDevices = devices.filter((device) => device.kind === "videoinput");
                if (videoDevices.length > 0) {
                    setDevices(videoDevices);
                } else {
                    setError("دوربین یافت نشد");
                }
            });
        }
    }, [canCaptureVideo]);

    // if textIndex === text stop capturing
    useEffect(() => {
        if (textIndex === text.length - 1 && capturing) {
            if (stopTimer.current) clearTimeout(stopTimer.current);
            stopTimer.current = setTimeout(() => {
                handleStopCaptureClick();
            }, 5000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textIndex, text]);

    useEffect(() => {
        if (recordedChunks?.size) {
            const url = URL.createObjectURL(recordedChunks);
            setUrl(url);
        }
    }, [recordedChunks]);

    // const [test, setTest] = useState<BlobEvent>();

    const handleDataAvailable = (event: BlobEvent) => {
        // setTest(event);
        if (event.data && event.data.size > 0) {
            setRecordedChunks(() => {
                return event.data;
            });
        }
    };

    useEffect(() => {
        if (!isWebcamLoading && !error) {
            handleStartCaptureClick();
            handleStopCaptureClick();
            handleResetClick();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWebcamLoading, error]);

    const handleStartCaptureClick = () => {
        try {
            if (!webcamRef.current?.stream) return;

            captureTimer.current = setInterval(() => setDuration((duration) => duration + 1), 1000);
            textTimer.current = setInterval(() => setTextIndex((textIndex) => textIndex + 1), 500);

            setCapturing(true);

            if (MediaRecorder.isTypeSupported("video/webm;codecs=vp9")) {
                setMimeType("video/webm;codecs=vp9");
            } else if (MediaRecorder.isTypeSupported("video/webm;codecs=vp8,opus")) {
                setMimeType("video/webm;codecs=vp8,opus");
            } else if (MediaRecorder.isTypeSupported("video/x-matroska;codecs=avc1,opus")) {
                setMimeType("video/x-matroska;codecs=avc1,opus");
            } else if (MediaRecorder.isTypeSupported("video/mp4")) {
                setMimeType("video/mp4");
            }

            mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
                videoBitsPerSecond: 2000000,
                mimeType: mimeType,
                bitsPerSecond: 2000000,
                audioBitsPerSecond: 128000,
            });

            mediaRecorderRef.current.addEventListener("dataavailable", handleDataAvailable);

            mediaRecorderRef.current.addEventListener("error", (event) => {
                console.log(event);
            });

            mediaRecorderRef.current.start();
        } catch (error) {
            report("خطا در ضبط ویدیو", error);
        }
    };

    const handleStopCaptureClick = useCallback(() => {
        mediaRecorderRef?.current?.stop();
        setCapturing(false);
        setDuration(0);
        setTextIndex(-1);

        if (captureTimer.current) clearInterval(captureTimer.current);
        if (textTimer.current) clearInterval(textTimer.current);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaRecorderRef, setCapturing, recordedChunks]);

    const handleResetClick = useCallback(() => {
        setDuration(0);
        setRecordedChunks(undefined);
        setUrl("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaRecorderRef, webcamRef, setCapturing]);

    const videoVariants: Variants = {
        show: {
            opacity: 1,
            scale: 1,
        },
        hide: {
            opacity: 0,
            scale: 0.9,
        },
    };

    const buttonsVariants: Variants = {
        init: {
            opacity: 0,
            y: 20,
        },
        show: {
            opacity: 1,
            y: 0,
        },
        hide: {
            opacity: 0,
            scale: 0.6,
        },
    };

    const devicesVariants: Variants = {
        show: {
            opacity: 1,
            scale: 1,
        },
        hide: {
            opacity: 0,
            scale: 0.8,
        },
    };

    const videoConstraints = {
        width: 720,
        height: 720,
        deviceId: devices.length > 0 ? devices[deviceIndex]?.deviceId : undefined,
    };

    const sendVideo = async () => {
        try {
            await sendVideoVerification({ face_id: recordedChunks });
            handleResetClick();
            await refetchUser();
        } catch (error) {
            report("خطا در ارسال ویدیو", error);
        }
    };

    const renderDevices = useMemo(() => {
        return devices.map((device) => {
            return (
                device === devices[deviceIndex] && (
                    <motion.div
                        variants={devicesVariants}
                        animate="show"
                        initial="hide"
                        exit="hide"
                        key={device.deviceId}
                    >
                        <Button
                            disabled={capturing}
                            disabledTooltip="دکمه غیرفعال است"
                            className="w-[50px]"
                            circle
                            icon={IconCameraRotate}
                            onClick={() =>
                                setDeviceIndex((prevDeviceIndex) => {
                                    return prevDeviceIndex + 1 > devices.length - 1 ? 0 : prevDeviceIndex + 1;
                                })
                            }
                        />
                    </motion.div>
                )
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [devices, deviceIndex, capturing]);

    return (
        <div className="flex h-full w-full flex-col">
            <div className="flex max-lg:flex-col">
                <SlideSection title="توضیحات">
                    <p className="leading-loose text-text-main">
                        در این مرحله نیاز است با دوربین سلفی موبایل (یا وب‌کم) از چهره{" "}
                        {user.data.user.is_business ? "مدیرعامل" : "خود"} ویدیویی تهیه نمایید و در زمان ضبط ویدیو با{" "}
                        <span className="font-semibold text-error">صدای بلند، رسا و شمرده</span> از روی متن زیر ویدیو
                        بخوانید.
                        <br />
                        <br />
                        <span className="font-semibold text-error">
                            خواهشمندیم در زمان ضبط ویدیو، شئونات اسلامی را رعایت فرمایید.
                        </span>
                        <br />
                        <br />
                        <span className="text-primary">
                            جهت ضبط ویدیو نیاز است به میکروفون و دوربین اجازه دسترسی بدهید.
                        </span>
                        <br />
                        <span className="text-primary">
                            جهت آموزش روند ضبط ویدیو&nbsp;
                            <a
                                href="#"
                                target="_blank"
                                rel="noreferrer"
                                className="transition-colors hover:text-primary"
                            >
                                اینجا
                            </a>
                            &nbsp;را کلیک نمایید.
                        </span>
                    </p>

                    {/* <div className="text-red-500">Debugging data</div>
                <p>{test?.data.size}</p>
                <p>{mimeType}</p>

                <p>{recordedChunks?.size}</p> */}
                </SlideSection>
                <SlideSection
                    title="ضبط ویدیو"
                    subTitle={videoVerificationStatusMessage}
                    subTitleStatus={
                        videoVerificationStatus === "CONFIRMED"
                            ? "success"
                            : videoVerificationStatus === "PENDING" || videoVerificationStatus === "INIT"
                            ? "warning"
                            : "error"
                    }
                    className="flex justify-center pb-6"
                    separator
                >
                    <div className="flex w-full max-w-[300px] flex-col gap-10">
                        <AnimatePresence mode="wait">
                            {!recordedChunks ? (
                                <motion.div
                                    variants={videoVariants}
                                    initial="hide"
                                    animate="show"
                                    exit="hide"
                                    key={1}
                                    className="relative"
                                >
                                    {devices.length > 1 && !capturing && canCaptureVideo && (
                                        <div className="absolute -bottom-[20px] left-1/2 z-40 -translate-x-1/2">
                                            <AnimatePresence mode="wait">{renderDevices}</AnimatePresence>
                                        </div>
                                    )}
                                    {isWebcamLoading && canCaptureVideo && (
                                        <div className="flex h-[300px] w-[300px] items-center justify-center rounded-[20px] bg-secondary-200 shadow-main">
                                            {!error ? (
                                                <IconSpinnerThirdDuotone className="h-[30px] animate-spin fill-primary" />
                                            ) : (
                                                <div className="h-[300px] w-full rounded-[20px] bg-secondary-250 shadow-main">
                                                    <UploadStatus
                                                        video={VideoPlaceholder}
                                                        text={error}
                                                        status="error"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {isActive && canCaptureVideo ? (
                                        <Webcam
                                            audio
                                            muted={capturing}
                                            mirrored={deviceIsFront}
                                            onUserMedia={() => setIsWebcamLoading(false)}
                                            videoConstraints={videoConstraints}
                                            ref={webcamRef}
                                            className={`h-[300px] rounded-[20px] ${isWebcamLoading ? "absolute" : ""}`}
                                            onErrorCapture={(error) => {
                                                report("", error);
                                            }}
                                            onUserMediaError={(error) => {
                                                setError(
                                                    "در دریافت اطلاعات از دوربین مشکلی به وجود آمده است! لطفا دسترسی دوربین مرورگر را چک کنید.",
                                                );
                                                report("در دریافت اطلاعات از دوربین مشکلی به وجود امده است!", error);
                                            }}
                                        />
                                    ) : (
                                        <div className="h-[300px] w-full rounded-[20px] bg-secondary-250 shadow-main">
                                            <UploadStatus
                                                video={VideoPlaceholder}
                                                text={videoVerificationStatusMessage}
                                                status={videoVerificationStatus === "CONFIRMED" ? "success" : "pending"}
                                            />
                                        </div>
                                    )}
                                    <div className="absolute left-0 top-0 h-[300px] w-full overflow-hidden rounded-[20px]">
                                        <div
                                            className={`move-scanner absolute flex w-full items-center justify-center ${
                                                capturing && "opacity-100"
                                            } opacity-0`}
                                        >
                                            <div className="h-[50px] w-full flex-col">
                                                <div className="h-full w-full bg-gradient-to-t from-primary/35 to-transparent opacity-60" />
                                                <div className="h-[3px] w-full rounded-full bg-primary" />
                                                <div className="h-full w-full bg-gradient-to-b from-primary/35 to-transparent opacity-60" />
                                            </div>
                                        </div>

                                        <div className="opacity-100">
                                            <div
                                                className={`absolute left-5 top-5 h-[4px] w-[20px] rounded-full ${
                                                    error ? "bg-error" : "bg-white"
                                                }`}
                                            />
                                            <div
                                                className={`absolute left-5 top-[16px] h-[4px] w-[20px] origin-left rotate-90 rounded-full ${
                                                    error ? "bg-error" : "bg-white"
                                                }`}
                                            />

                                            <div
                                                className={`absolute bottom-5 left-5 h-[4px] w-[20px] rounded-full ${
                                                    error ? "bg-error" : "bg-white"
                                                }`}
                                            />
                                            <div
                                                className={`absolute bottom-[15px] left-5 h-[4px] w-[20px] origin-left -rotate-90 rounded-full ${
                                                    error ? "bg-error" : "bg-white"
                                                }`}
                                            />

                                            <div
                                                className={`absolute bottom-5 right-5 h-[4px] w-[20px] rounded-full ${
                                                    error ? "bg-error" : "bg-white"
                                                }`}
                                            />
                                            <div
                                                className={`absolute bottom-[15px] right-5 h-[4px] w-[20px] origin-right rotate-90 rounded-full ${
                                                    error ? "bg-error" : "bg-white"
                                                }`}
                                            />

                                            <div
                                                className={`absolute right-5 top-5 h-[4px] w-[20px] rounded-full ${
                                                    error ? "bg-error" : "bg-white"
                                                }`}
                                            />
                                            <div
                                                className={`absolute right-5 top-[16px] h-[4px] w-[20px] origin-right -rotate-90 rounded-full ${
                                                    error ? "bg-error" : "bg-white"
                                                }`}
                                            />
                                        </div>
                                    </div>
                                </motion.div>
                            ) : (
                                <motion.div variants={videoVariants} initial="hide" animate="show" exit="hide" key={2}>
                                    <video
                                        src={url}
                                        className="[-webkit-media-controls]:!hidden [-webkit-media-controls-enclosure]:!hidden [-webkit-media-controls-panel]:!hidden h-[300px] w-[300px] rounded-[20px]"
                                        autoPlay
                                        playsInline
                                        // eslint-disable-next-line react/no-unknown-property
                                        webkit-playsinline
                                        loop
                                    ></video>
                                </motion.div>
                            )}
                        </AnimatePresence>

                        {canCaptureVideo && !isWebcamLoading && (
                            <>
                                <AnimatePresence mode="popLayout">
                                    {!recordedChunks ? (
                                        <motion.div
                                            className="mt-4 flex w-full items-center justify-center"
                                            variants={buttonsVariants}
                                            initial="init"
                                            animate="show"
                                            exit="hide"
                                            key={1}
                                        >
                                            <Button
                                                type="button"
                                                onClick={capturing ? handleStopCaptureClick : handleStartCaptureClick}
                                                icon={capturing ? IconVideoSlash : IconVideo}
                                                color={capturing ? "error" : "default"}
                                                disabledTooltip="دکمه غیرفعال است"
                                                disabled={recordedChunks || isWebcamLoading || !canCaptureVideo}
                                                loading={isWebcamLoading && !error && canCaptureVideo}
                                                className="min-w-[180px]"
                                            >
                                                {capturing ? `توقف | ${duration}` : "ضبط ویدیو"}
                                            </Button>
                                        </motion.div>
                                    ) : !isSendVideoLoading ? (
                                        <motion.div
                                            className="mt-4 flex w-full items-center justify-center gap-4"
                                            variants={buttonsVariants}
                                            initial="init"
                                            animate="show"
                                            exit="hide"
                                            key={2}
                                        >
                                            {canCaptureVideo && (
                                                <Button
                                                    type="button"
                                                    onClick={handleResetClick}
                                                    icon={IconTrash}
                                                    color="error"
                                                    variant="stroke"
                                                    className="flex-1"
                                                    disabled={isSendVideoLoading}
                                                    disabledTooltip="دکمه غیرفعال است"
                                                >
                                                    ضبط مجدد
                                                </Button>
                                            )}
                                            <Button
                                                type="button"
                                                className="flex-1"
                                                onClick={sendVideo}
                                                icon={IconUploadDuotone}
                                                disabled={
                                                    recordedChunks?.size === 0 || isSendVideoLoading || !canCaptureVideo
                                                }
                                                loading={(isWebcamLoading || isSendVideoLoading) && !error}
                                                disabledTooltip="دکمه غیرفعال است"
                                            >
                                                ارسال
                                            </Button>
                                        </motion.div>
                                    ) : (
                                        <div className="flex flex-col items-center justify-center">
                                            <div className="relative flex h-[15px] w-full items-center justify-end overflow-hidden rounded-full bg-secondary-200 text-text-main">
                                                <div
                                                    className="absolute h-full rounded-full bg-primary transition-all"
                                                    style={{
                                                        width: `${videoUploadProgress}%`,
                                                    }}
                                                />
                                            </div>
                                            <div className="mt-4">{videoUploadProgress}%</div>
                                        </div>
                                    )}
                                </AnimatePresence>

                                <p className="w-full text-center text-[1.14rem] text-text-main">
                                    {user && user.data.verificationSettings.video_verification_text}
                                </p>
                            </>
                        )}
                    </div>
                </SlideSection>
            </div>
            <div className="flex w-full items-center justify-between gap-4 border-t border-secondary-200 px-4 py-6 max-xs:pb-4 xs:px-6">
                <Button
                    type="button"
                    className="w-[90px] flex-shrink-0 bg-zinc-400 xs:w-[150px]"
                    disabled={capturing || isSendVideoLoading}
                    disabledTooltip="دکمه غیرفعال است"
                    onClick={() => setActiveIndex((oldIndex) => oldIndex - 1)}
                >
                    قبلی
                </Button>
            </div>
        </div>
    );
};

export default VideoVerification;
