import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import ToastError from "@/components/error/ToastError";
import gate from "@/gate";

type ResetPasswordRequest = {
    token?: string;
    email: string;
    password: string;
    confirmPass: string;
};

const useResetPassword = () => {
    const navigate = useNavigate();

    return useMutation<
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any,
        Error,
        ResetPasswordRequest
    >({
        mutationFn: gate.resetPassword,
        onError(error) {
            ToastError(error);
        },
        onSuccess() {
            toast.success("رمز عبور شما با موفقیت بروزرسانی شد.");
            navigate("/auth/login", { replace: true });
        },
    });
};

export default useResetPassword;
