import { LoginRequest, LoginResponse } from "@/interfaces/auth";

import api from "../api";

export default {
    login: (data: LoginRequest) => api.post<LoginResponse>("/panel/api/v1/auth/login", data),
    register: (data: any) => api.post("/panel/api/v1/auth/register", data),
    resendToken: (data: any) => api.post("/panel/api/v1/auth/token/resend", data),
    twoFAValidation: (data: any) => api.post("/panel/api/v1/auth/validate/token", data),
    resetPassword: (data: any) => api.post("/panel/api/v1/auth/password/reset", data),
    forgotPassword: (data: any) => api.post("/panel/api/v1/auth/password/forget", data),
    logout: (data: any) => api.post("/panel/api/v1/auth/logout", data),
};
