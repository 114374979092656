import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";

import CustomRadio from "@/components/ui/CustomRadio";
import FileInput from "@/components/ui/FileInput";
import Input from "@/components/ui/Input";
import Select from "@/components/ui/Select";
import Textarea from "@/components/ui/Textarea";
import useCities from "@/hooks/queries/useCities";
import useProvinces from "@/hooks/queries/useProvinces";
import { CalculationRequestType, ServiceInstructionType, SubmitOrderType } from "@/interfaces/dashboard/order/order";
import { objectToArray } from "@/utils";

type OrderValidationSchema = {
    serviceId: number;
    planId: number;
    inShiraz: "خیر" | "بله";
    province: {
        label: string;
        value: number;
    };
    city: {
        label: string;
        value: number;
    };
    address: string;
    postalCode: number | "";
    user_description: string | null | undefined;
    attachments?: Record<string, File>;
};

type SubmitOrderRequest = SubmitOrderType & {
    fk_plan_id: number;
    meta: {
        in_shiraz: "خیر" | "بله";
        province?: number;
        city?: number;
        address?: string;
        post_code?: number | "";
    };
};

type ServiceInstruction = ServiceInstructionType & {
    onCalculation: (
        data: CalculationRequestType & {
            meta: {
                in_shiraz: "بله" | "خیر";
            };
        },
    ) => void;
    onFormSubmit: (data: SubmitOrderRequest, serviceId: number) => void;
};

const SafePalForm: React.FC<ServiceInstruction> = ({ instructions, onCalculation, onFormSubmit }) => {
    const [selectedProvince, setSelectedProvince] = useState(-1);

    const { control, handleSubmit, setValue, getValues, resetField } = useForm<OrderValidationSchema>({
        defaultValues: {
            planId: instructions.plans[0]?.id,
            inShiraz: "خیر",
            address: "",
            postalCode: "",
            user_description: "",
        },
    });

    const {
        refetch: refetchProvinces,
        isFetching: isProvincesFetching,
        isLoading: isProvincesLoading,
        isFetched: isProvincesFetched,
        data: provinces,
    } = useProvinces(false);

    const {
        data: cities,
        remove: removeCities,
        refetch: refetchCities,
        isLoading: isCitiesLoading,
    } = useCities(selectedProvince, true);

    const [watchedProvince, watchedInShiraz] = useWatch({
        control: control,
        name: ["province", "inShiraz"],
        defaultValue: {
            inShiraz: "خیر",
        },
    });

    useEffect(() => {
        setSelectedProvince(getValues()?.province?.value || -1);

        if (isProvincesFetching) {
            removeCities();
        }

        if (isProvincesFetched) {
            refetchCities();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchedProvince]);

    useEffect(() => {
        setValue("province", {
            label: "",
            value: -1,
        });
        setValue("city", {
            label: "",
            value: -1,
        });
        resetField("address");
        resetField("postalCode");

        onCalculation({
            fk_plan_id: instructions.plans[0]?.id || 0,
            serviceId: instructions.id,
            meta: {
                in_shiraz: watchedInShiraz,
            },
        });

        if (watchedInShiraz == "بله") {
            refetchProvinces();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchedInShiraz]);

    const submitHandler: SubmitHandler<OrderValidationSchema> = (data) => {
        onFormSubmit(
            {
                fk_plan_id: instructions.plans[0]?.id || -1,
                meta: {
                    in_shiraz: data.inShiraz,
                    province: data.province?.value,
                    city: data.city?.value,
                    address: data?.address,
                    post_code: data?.postalCode,
                },
                user_description: data.user_description,
                attachments: data.attachments ? objectToArray(data.attachments) : undefined,
            },
            instructions.id,
        );
    };

    return (
        <form id="order-form" onSubmit={handleSubmit(submitHandler)}>
            <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-4">
                    <span className="text-text-main">تحویل در دفتر شیراز؟</span>
                    <div className="flex gap-8">
                        <CustomRadio
                            control={control}
                            label="بله"
                            name="inShiraz"
                            value="خیر"
                            checked={watchedInShiraz == "خیر"}
                        />
                        <CustomRadio
                            control={control}
                            label="خیر"
                            name="inShiraz"
                            value="بله"
                            checked={watchedInShiraz == "بله"}
                        />
                    </div>
                    <motion.div
                        className={`${watchedInShiraz == "خیر" && "h-0 overflow-hidden"}`}
                        initial={{
                            height: watchedInShiraz == "بله" ? "auto" : "0",
                        }}
                        animate={{
                            height: watchedInShiraz == "بله" ? "auto" : "0",
                        }}
                        transition={{ duration: 0.15 }}
                    >
                        <div className="flex flex-col gap-4">
                            <Select
                                topTitle="استان"
                                control={control}
                                name="province"
                                variant="fill"
                                required
                                placeholder="انتخاب استان"
                                isDisabled={isProvincesLoading}
                                rules={{
                                    required: watchedInShiraz == "بله" ? "انتخاب استان الزامی است" : undefined,
                                }}
                                options={isProvincesLoading ? [] : provinces}
                                isLoading={isProvincesLoading}
                            />
                            <Select
                                topTitle="شهرستان"
                                name="city"
                                control={control}
                                variant="fill"
                                required
                                placeholder="انتخاب شهرستان"
                                isDisabled={isCitiesLoading || selectedProvince == -1}
                                rules={{
                                    required: watchedInShiraz == "بله" ? "انتخاب شهرستان الزامی است" : undefined,
                                }}
                                options={isCitiesLoading ? [] : cities}
                                isLoading={isCitiesLoading}
                            />
                            <Input
                                containerClassName="w-full"
                                variant="fill"
                                topTitle="آدرس"
                                inputMode="text"
                                placeholder="آدرس"
                                required
                                control={control}
                                name="address"
                                rules={{
                                    required: watchedInShiraz == "بله" ? "آدرس الزامی است" : undefined,
                                }}
                            />
                            <Input
                                containerClassName="w-full"
                                variant="fill"
                                topTitle="کد پستی"
                                inputMode="numeric"
                                placeholder="کد پستی"
                                required
                                ltr
                                control={control}
                                name="postalCode"
                                rules={{
                                    required: watchedInShiraz == "بله" ? "کد پستی الزامی است" : undefined,
                                }}
                            />
                        </div>
                    </motion.div>
                </div>

                <Textarea
                    name="user_description"
                    control={control}
                    label="توضیحات"
                    placeholder="توضیحات خود را اینجا بنویسید"
                />
                <FileInput control={control} name="attachments" onReset={resetField} repeater />
            </div>
        </form>
    );
};
export default SafePalForm;
