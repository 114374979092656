import { useQuery } from "@tanstack/react-query";

import gate from "@/gate";

const useOrder = (id: number) => {
    return useQuery({
        queryFn: () => gate.showOrder(id),
        queryKey: ["order", id],
        select: (data) => data.data,
    });
};

export default useOrder;
